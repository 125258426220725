/* eslint-disable react/no-array-index-key */

import React from 'react';

function Breadcrumb(props) {
  const { links } = props;

  if (!links || links.length < 1) return null;
  return (
    <nav className="breadcrumb-container" aria-label="breadcrumb">
      <ol className="breadcrumb">
        { links.map((link, i) => (
          <li key={`link-${i}`} className="breadcrumb-item">{ link }</li>
        )) }
      </ol>
    </nav>
  );
}

export default Breadcrumb;
