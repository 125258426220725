import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import Icon from 'components/ui/Icon';
import StatBlock from 'components/ui/StatBlock';

function OrgStats(props) {
  const { organization } = props;
  return (
    <div className="stats no-cols">
      <StatBlock
        api={`/products?organization=${organization.pk}&count=1`}
        link={`/${organization.slug}/manage/products`}
        title={t`Products`}>
        <h3><Trans>You don’t have a product yet</Trans></h3>
        {organization.can_add_product && (
          <>
            <Icon name="plus"/>
            <Link className="link" to="/products/add">
              <Trans>Add a new product</Trans></Link>
          </>
        )}
      </StatBlock>
      <StatBlock
        api={`/members?organization=${organization.pk}&count=1`}
        link={`/${organization.slug}/manage/member`}
        title={t`Members`}/>
    </div>
  );
}

export default OrgStats;
