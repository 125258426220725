import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import { manageFormErrors } from 'components/form/Form';
import { LoaderContainer } from 'components/ui/Loader';

function PasswordField(props) {
  const { id, name, label, error, setValue } = props;
  const [validated, setValidated] = useState(false);

  const handleChange = e => {
    const { value } = e.target;
    setValidated(true);
    setValue(name, value);
  };

  const groupClasses = ['form-group', 'required'];
  if (validated && !error) groupClasses.push('was-validated');
  if (error) groupClasses.push('is-invalid');

  return (
    <div id={'form-group-' + id} className={groupClasses.join(' ')}>
      <label className="form-label" htmlFor={id}>
        { label }
      </label>
      <input
        onInput={handleChange}
        type="password"
        id={id}
        name={name}
        className={'form-control' + (error ? ' is-invalid' : '')}
        required />
      { error && (
        <div className="invalid-feedback">{ error.join(' ') }</div>
      ) }
    </div>
  );
}

function ChangePasswordForm(props) {
  const { onCancel, onSave } = props;
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const fields = ['old_password', 'new_password', 'new_password_conf'];
  const values = useRef();

  const onSaveHandler = (e) => {
    e.preventDefault();
    setErrors({});
    const errs = {};
    const vals = values.current || {};
    for (const field of fields) {
      const input = document.querySelector(`[name=${field}]`);
      if (!input.validity.valid) {
        errs[field] = [input.validationMessage];
      }
    }
    if (vals.new_password !== vals.new_password_conf) {
      const errMess = t`Passwords does not match.`;
      if (errs.new_password) {
        errs.new_password.push(errMess);
      }
      else {
        errs.new_password = [errMess];
      }
      if (errs.new_password_conf) {
        errs.new_password_conf.push(errMess);
      }
      else {
        errs.new_password_conf = [errMess];
      }
    }
    setErrors(errs);
    if (Object.keys(errs).length < 1) {
      setIsLoading(true);
      axios.post('/auth/change-password', vals).then(() => {
        setIsLoading(false);
        if (onSave) onSave();
        addMessage('password-changed', t`Password`, t`Your password has been updated`);
      }).catch(err => {
        const { globalErrors, fieldsErrors } = manageFormErrors(fields, err);
        setIsLoading(false);
        setErrors(fieldsErrors);
        setError(globalErrors.map(er => er.error).join('<br/>'));
      });
    }
  };

  const setValue = (name, value) => {
    if (!values.current) values.current = {};
    values.current[name] = value;
  };

  return (
    <form
      className="relative"
      method="POST"
      id="user-change-password"
      noValidate
      onSubmit={onSaveHandler}>
      { isLoading && (
        <LoaderContainer />
      ) }
      { error && (
        <div className="alert alert-danger mb-2" role="alert">
          { error }
        </div>
      ) }
      <PasswordField
        label={t`Current password`}
        id="old_password"
        name="old_password"
        error={errors.old_password || null}
        setValue={setValue}
        required />
      <PasswordField
        label={t`New password`}
        id="new_password"
        name="new_password"
        error={errors.new_password || null}
        setValue={setValue}
        required />
      <PasswordField
        label={t`Confirm password`}
        id="new_password_conf"
        name="new_password_conf"
        error={errors.new_password_conf || null}
        setValue={setValue}
        required />
      <div className="actions">
        <Button variant="primary" type="submit"><Trans>Change password</Trans></Button>
        <Button variant="outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></Button>
      </div>
    </form>
  );
}

function ChangePasswordToggle(props) {
  const [isOpened, setIsOpened] = useState(false);
  if (!isOpened) {
    return (
      <button type="button" className="btn btn-outline-secondary" onClick={() => { setIsOpened(true); }}>
        <Trans>Change password</Trans>
      </button>
    );
  }
  return (
    <ChangePasswordForm
      onSave={() => { setIsOpened(false); }}
      onCancel={() => { setIsOpened(false); }} />
  );
}

export default ChangePasswordForm;
export { ChangePasswordToggle };
