const Features = Object.freeze({
  TRIGGERS: 'triggers',
  // Allow to manage automatic triggers for changes in
  // backlog item values.
  ITEMS_IMPORT: 'items_import',
  // Allow to import backlog items (create or update)
  // from a CSV or spreadsheet file.
});

export default Features;
