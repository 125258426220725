import React, { useContext, useEffect, useState } from 'react';
import * as BS from 'react-bootstrap';
import { t } from '@lingui/macro';
import { FormContext } from './FormContext';
import BaseField from './BaseField';

export default function Select({ children, ...props }) {
  const { item, version, setItem, meta, errors } = useContext(FormContext);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setValidated(false);
  }, [version]);

  const handleChange = e => {
    const { name, value } = e.target;
    setValidated(true);
    const itemValue = meta.fields[props.name].choices.filter(
      c => c.value === value)[0];
    item[name] = itemValue;
    setItem(item);
  };

  const { label, ...fieldProps } = props;
  const groupClasses = ['form-group'];
  if (props.required) groupClasses.push('required');
  if (validated) groupClasses.push('was-validated');

  let defaultOption = null;
  if (props.required && !item[props.name]) {
    defaultOption = t`Please choose a value`;
  }
  else if (!props.required) {
    defaultOption = t`None`;
  }

  if (!meta || !meta.fields || !(props.name in meta.fields)) return null;

  return (
    <BaseField
      {...props}
      className={groupClasses.join(' ')}
      label={label || meta && meta.fields && meta.fields[props.name] && meta.fields[props.name].name}
      humanValue={item[props.name] ? item[props.name].label : ''}>
      <BS.Form.Select
        value={item[props.name] && item[props.name].value || item[props.name] || ''}
        onChange={handleChange}
        isInvalid={!!errors[props.name]}
        as="select"
        id={props.id}
        {...fieldProps}>
        { defaultOption && (
          <option value="">{ defaultOption }</option>
        ) }
        {meta && meta.fields && meta.fields[props.name].choices && meta.fields[props.name].choices.map((it) => (
          !(props.excludes || []).includes(it.value) && (
            <option key={it.value} value={it.value}>{it.label}</option>
          )
        ))}
      </BS.Form.Select>
    </BaseField>
  );
}
Select.defaultProps = {
};
