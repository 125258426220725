import React from 'react';
import ReactDOM from 'react-dom';
import 'scss/app.scss';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from 'App';
import ServiceWorkerLoader from 'utils/ServiceWorkerLoader';

if (['stage', 'production'].includes(process.env.REACT_APP_ENVIRONMENT)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_PUBLIC_URL],
    })],
    environment: process.env.REACT_APP_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <ServiceWorkerLoader/>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);
