import React, { useContext, useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import axios from 'axios';
import OrganizationContext from 'components/utils/OrganizationContext';
import StatBlock from 'components/ui/StatBlock';
import ProductContext from 'components/utils/ProductContext';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';
import { useStateItem } from 'utils/Item';

function ProdStats() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const [items, setItems] = useState(null);
  const refresh = useStateItem('backlog-items');

  useEffect(() => {
    axios.get(`/backlog-items/count-items?product=${product.pk}&group_by=status`).then((response) => {
      setItems(response.data.items);
    }).catch((err) => { addMessage('error-prod-stats', t`Unknown error`, t`Can't retrieve statistics`); });
  }, [product, refresh]);

  if (items === null) {
    return (
      <div className="stats">
        <LoaderContainer height="3"/>
      </div>
    );
  }
  const itemsValues = {};
  for (let i = 0; i < items.length; i++) {
    if (items[i].status.status_type.value in itemsValues) {
      itemsValues[items[i].status.status_type.value] += items[i].count_items;
    }
    else {
      itemsValues[items[i].status.status_type.value] = items[i].count_items;
    }
  }

  return (
    <div className="stats">
      { product.enable_backlog && (
        <StatBlock
          value={itemsValues.backlog || 0}
          link={`/${organization.slug}/${product.slug}/product-backlog`}
          title={t`Backlog`}
          linkName={t`Go to backlog`}>
        </StatBlock>
      ) }
      <StatBlock
        value={itemsValues.todo || 0}
        link={`/${organization.slug}/${product.slug}/kanban`}
        title={t`To do`}
        linkName={t`View in kanban`}/>
      <StatBlock
        value={itemsValues.in_progress || 0}
        link={`/${organization.slug}/${product.slug}/kanban`}
        title={t`In progress`}
        linkName={t`View in kanban`}/>
      <StatBlock
        value={itemsValues.done || 0}
        link={`/${organization.slug}/${product.slug}/kanban`}
        title={t`Done`}
        linkName={t`View in kanban`}/>
    </div>
  );
}

export default ProdStats;
