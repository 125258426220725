import React from 'react';
import { BacklogModalContextProvider } from 'components/modal/BacklogModalContext';
import BacklogItemViewModal from 'components/modal/BacklogItemViewModal';
import ReadonlyWarning from 'components/utils/ReadOnlyWarning';
import InternalPage from './InternalPage';

export default function ProductPage(props) {
  return (
    <InternalPage {...props}>
      <ReadonlyWarning />
      <BacklogModalContextProvider>
        <BacklogItemViewModal />
        {props.children}
      </BacklogModalContextProvider>
    </InternalPage>
  );
}
