/* eslint-disable
  jsx-a11y/no-static-element-interactions,
  jsx-a11y/click-events-have-key-events */

import React, { useCallback, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import optera from 'assets/optera.svg';
import opteraLib from 'assets/optera-lib.svg';
import Icon from 'components/ui/Icon';
import ThemeSelector from 'components/topnav/ThemeSelector';
import { LanguageSelector } from 'components/topnav';
import Search from './Search';

function Sidebar(props) {
  const [menuOpened, setMenuOpened] = useState(false);
  const { expandBtn, reduced } = props;

  const closeMenu = useCallback(() => {
    setMenuOpened(false);
    document.removeEventListener('click', closeMenu);
  }, []);

  const openMenu = (e) => {
    e.stopPropagation();
    if (!menuOpened) {
      document.addEventListener('click', closeMenu);
      setMenuOpened(true);
    }
    else {
      closeMenu();
    }
  };
  const classes = ['sidebar'];
  if (menuOpened) classes.push('menu-opened');
  if (reduced) classes.push('reduced');

  return (
    <nav id="main-sidebar" className={classes.join(' ')}>
      { expandBtn }
      <div className="sidebar-head">
        <Link to="/" className="app-icon show-reduced mini-link">
          <img src={opteraLib} alt="Optera.io"/>
          <span><Trans>Home</Trans></span>
        </Link>
        <Link to="/" className="app-icon full-log hide-reduced">
          <img src={optera} alt="Optera.io"/>
        </Link>

        { props.context }
        <Search />
      </div>
      <button
        type="button"
        className="button menu-button"
        onClick={openMenu}>
        <Icon name="three-dots-vertical" title={t`Menu`}/>
      </button>
      <div className="main-menu" onClick={(e => e.stopPropagation())}>
        { props.children }
        <div className="mobile-links text-end d-block d-md-none px-3">
          <LanguageSelector />
          <ThemeSelector />
        </div>
      </div>
    </nav>
  );
}
export default Sidebar;
