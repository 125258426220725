import React, { useCallback, useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';
import { Form, FormContext, FormContextProvider } from 'components/form';
import { addMessage } from 'components/ui/Messages';
import RichContent from 'components/form/RichContent';

function BacklogItemFinalForm(props) {
  const { onCancel, onSave, product, organization, sprint, layout, showCreateMessage, statuses, hasNext, onSkip } = props;
  const { item, meta } = useContext(FormContext);
  const productField = product ? '' : (
    <Form.Many2One id="product" name="product" api={'/products?organization=' + organization.pk} required />
  );

  const onDelete = useCallback(function () {
    item.delete().then(() => {
      addMessage(
        'delete-backlog-' + item.pk,
        t`Item removed`,
        t`${item.title} has been removed.`
      );
      onSave();
    }).catch(err => {
      addMessage(
        'delete-backlog-error-' + item.pk,
        t`Unknown error`,
        t`Impossible to remove this backlog item.`
      );
      onCancel();
    });
  }, [item, onCancel, onSave]);

  const onSaveHandler = (data, created, submitter) => {
    if (onSave) onSave(data, created, submitter);
    if (showCreateMessage) {
      addMessage('new-backlogitem-' + data.pk, t`Item created`, <Trans>
        Backlog item <a className="btn btn-slug" href={data.path}>{ data.reference }</a> <em>{ data.title }</em> has been created
      </Trans>);
    }
  };

  const onBeforeSaveHandler = obj => {
    if (!sprint) return;
    if (obj.status.status_type.value !== 'backlog' && obj.status.status_type.value !== 'closed') {
      obj.sprint = sprint.pk;
    }
    else {
      delete obj.sprint;
    }
  };

  useEffect(() => {
    if (item.item_type && meta && meta.fields) {
      const el = document.querySelector('#form-group-item_type .form-select');
      if (el) {
        el.focus();
      }
    }
  }, [meta, item]);

  return (
    <Form
      name="backlogitem-add-form"
      noValidate
      onSave={onSaveHandler}
      onBeforeSave={onBeforeSaveHandler}>
      {productField}
      { !item.parent && (
        <Form.Many2One
          api={'/item-types?product=' + product.pk}
          id="item_type"
          name="item_type"
          required />
      ) }
      <Form.Field id="title" name="title" required />
      { statuses && statuses.length > 1 && (
        <Form.Many2One
          options={statuses}
          id="status"
          name="status"
          required />
      ) }
      <RichContent id="description" mention product={product} name="description" />
      <div className="row">
        { layout !== 'backlog' && (
          <>
            { !item.parent && (
              <div className="col-6">
                <Form.Many2One
                  api={'/item-status?product=' + product.pk}
                  id="status"
                  name="status"
                  required />
              </div>
            ) }
            <div className="col-6">
              <Form.Many2One
                api={'/members?organization=' + organization.pk}
                id="assignee"
                name="assignee"
                nullText={t`Unassigned`} />
            </div>
          </>
        ) }
        { product.can_see_estimates && (
          <div className="col-6">
            { product.estimate_mode.value === 'hours' ? (
              <Form.Field id="hours_estimate" name="hours_estimate" placeholder="1h 30m"/>
            ) : (
              <Form.Field id="points_estimate" name="points_estimate" />
            ) }
          </div>
        )}
        <div className="col-6">
          <Form.Select id="priority" name="priority" />
        </div>
        <div className="col-6">
          <Form.Field id="business_value" name="business_value" />
        </div>
      </div>
      <div className="actions">
        { layout === 'storymap' ? (
          <>
            { hasNext ? (
              <>
                <Button variant="primary bt-save-new" type="submit"><Trans>Save and next</Trans></Button>
                <Button variant="light" type="button" onClick={onSkip}><Trans>Skip</Trans></Button>
              </>
            ) : (
              <Button variant="primary" type="submit"><Trans>Save</Trans></Button>
            ) }
            <Button variant="light" type="button" onClick={onCancel}><Trans>Cancel</Trans></Button>
          </>
        ) : (
          <>
            <Button variant="primary" type="submit">
              { layout === 'backlog' ? (
                <Trans>Add a new item</Trans>
              ) : (
                <Trans>Save</Trans>
              )}
            </Button>
            {item.pk && (
              <Button variant="light" type="button" onClick={onDelete}><Trans>Delete</Trans></Button>
            )}
            { layout !== 'backlog' && (
              <>
                { !item.parent && (
                  <Button variant="primary bt-save-new" type="submit"><Trans>Save and create new</Trans></Button>
                ) }
                <Button variant="light" type="button" onClick={onCancel}><Trans>Cancel</Trans></Button>
              </>
            ) }
          </>
        ) }
        
      </div>
    </Form>
  );
}
BacklogItemFinalForm.defaultProps = {
  layout: 'default',
  showCreateMessage: true
};

export default function BacklogItemForm(props) {
  const { organization, backlogitem, meta, ...rest } = props;

  if (props.product && backlogitem) {
    backlogitem.product = props.product;
  }

  return (
    <FormContextProvider meta={meta} item={backlogitem} api="/backlog-items">
      <BacklogItemFinalForm organization={organization} {...rest} />
    </FormContextProvider>
  );
}
