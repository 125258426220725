import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { getStateItem, setStateItem } from 'utils/Item';
import { LoaderContainer } from 'components/ui/Loader';
import { Trans } from '@lingui/macro';
import StoryMapTableHeader from './StoryMapTableHeader';
import StoryMapContext from './StoryMapContext';
import StoryMapBody from './StoryMapBody';

function buildData(activities, tasks, goals, storyMapReleases, personas) {
  const data = {
    goalsData: {},
    personasData: {},
    rows: {},
    columnCount: 0
  };
  const columns = [];
  for (const personaPk of personas) {
    data.personasData[personaPk] = {
      goals: [],
      columnCount: 0
    };
  }
  for (const goalPk of goals) {
    const goal = getStateItem('goals:' + goalPk);
    if (goal.persona && (goal.persona.pk in data.personasData)) {
      data.personasData[goal.persona.pk].goals.push(goal.pk);
    }
    data.goalsData[goal.pk] = {
      name: goal.name,
      activities: [],
      columnCount: 0
    };
  }
  for (const activityPk of activities) {
    const activity = getStateItem('activities:' + activityPk);
    if (activity.goal && (activity.goal.pk in data.goalsData)) {
      data.goalsData[activity.goal.pk].activities.push(activity.pk);
    }
  }
  for (const personaPk of personas) {
    if (data.personasData[personaPk].goals.length < 1) {
      columns.push(null);
    }
    else {
      for (const goalPk of data.personasData[personaPk].goals) {
        if (data.goalsData[goalPk].activities.length < 1) {
          columns.push(null);
        }
        else {
          for (const activityPk of data.goalsData[goalPk].activities) {
            columns.push(activityPk);
          }
        }
      }
    }
  }

  for (const storyMapReleasePk of storyMapReleases) {
    const releaseData = {
      columns: {
        NONE: []
      }
    };
    for (const pk of columns) {
      if (pk !== null) releaseData.columns[pk] = [];
    }
    data.rows[storyMapReleasePk] = releaseData;
  }
  data.rows.NONE = { columns: { NONE: [] } };
  for (const pk of columns) {
    if (pk !== null) data.rows.NONE.columns[pk] = [];
  }
  for (const taskPk of tasks) {
    const task = getStateItem('tasks:' + taskPk);
    if (task.release && (task.release.pk in data.rows)) {
      if (task.activity && (task.activity.pk in data.rows[task.release.pk].columns)) {
        data.rows[task.release.pk].columns[task.activity.pk].push(task.pk);
      }
      else if (!task.activity) {
        data.rows[task.release.pk].columns.NONE.push(task.pk);
      }
    }
    else if (!task.release) {
      // eslint-disable-next-line no-lonely-if
      if (task.activity && (task.activity.pk in data.rows.NONE.columns)) {
        data.rows.NONE.columns[task.activity.pk].push(task.pk);
      }
      else if (!task.activity) {
        data.rows.NONE.columns.NONE.push(task.pk);
      }
    }
  }
  for (const persona in data.personasData) {
    let cols = 0;
    for (const goalPk of data.personasData[persona].goals) {
      data.goalsData[goalPk].columnCount = Math.max(1, data.goalsData[goalPk].activities.length);
      cols += data.goalsData[goalPk].columnCount;
    }
    data.personasData[persona].columnCount = Math.max(1, cols);
    data.columnCount += data.personasData[persona].columnCount;
  }

  data.columns = columns;
  return data;
}

export default function StoryMapTable(props) {
  const { storyMap } = props;
  const { 
    activities, tasks, goals, storyMapReleases, personas, socketReady, socketDisconnected, reconnectWebSocket
  } = useContext(StoryMapContext);
  const { goalsData, personasData, rows, columns, columnCount } =
    buildData(activities, tasks, goals, storyMapReleases, personas);

  useEffect(() => {
    for (const api of [
      'story-map-releases',
      'personas',
      'goals',
      'activities',
    ]) {
      axios.get(`${api}/meta`).then(res => {
        setStateItem('meta:' + api, res.data);
      });
    }
  }, []);

  if (!socketReady) {
    return <LoaderContainer height="3" />;
  }

  return (
    <div className="story-map-table">
      { socketDisconnected && (
        <div className="alert alert-warning">
          <Trans>Storymap updates are no longer active.</Trans>
          <button
            type="button"
            className="ms-2 btn btn-outline-dark"
            onClick={() => {
              reconnectWebSocket();
            }}>
            <Trans>Reconnect</Trans>
          </button>
        </div>
      ) }
      <StoryMapTableHeader
        storyMap={storyMap}
        goalsData={goalsData}
        personasData={personasData}
        columnCount={columnCount} />
      <StoryMapBody rows={rows} columns={columns} />
    </div>
  );
}
