import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import BacklogItemForm from 'components/forms/BacklogItemForm';
import { setStateItem } from 'utils/Item';

function BacklogItemModal(props) {
  const { title, modalTitle, onClose, onSave, hideButton,
    show, sprint, attrs, btnVariant, status, ...rest } = props;
  const [isShown, setShow] = useState(!!show);
  const backlogitem = {};
  if (props.product) {
    backlogitem.product = props.product.pk;
    backlogitem.status = props.product.statuses.find(s => s.status_type.value === status);
    if (sprint && (status !== 'backlog' || status !== 'closed')) {
      backlogitem.sprint = sprint;
    }
  }
  if (props.parent) {
    backlogitem.parent = props.parent;
    backlogitem.status = props.parent.status;
    backlogitem.sprint = props.parent.sprint;
    backlogitem.item_type = props.parent.item_type;
  }
  if (attrs) {
    for (const attr in attrs) {
      backlogitem[attr] = attrs[attr];
    }
  }
  const [blitem, setBlitem] = useState(backlogitem);

  const handleSave = (data, created, submitter) => {
    if (onSave) {
      onSave(data);
    }
    else if (onClose) {
      onClose(true);
    }
    setStateItem('backlog-items', d => (d ? d + 1 : 1));
    if (submitter.classList.contains('bt-save-new')) {
      const b = {};
      b.product = data.product.pk;
      b.status = data.status;
      b.sprint = data.sprint;
      b.item_type = data.item_type;
      setBlitem(b);
    }
    else {
      setShow(false);
    }

  };
  const handleCancel = () => {
    if (onClose) onClose(false);
    setShow(false);
  };
  const handleShow = (e) => {
    setShow(true);
  };

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <ModalForm
      show={isShown}
      handleClose={handleCancel}
      handleShow={handleShow}
      title={modalTitle || t`New backlog item`}
      buttonTitle={title || t`Add backlog item`}
      hideButton={hideButton}
      variant={btnVariant}
      confirmClose>
      <BacklogItemForm
        backlogitem={blitem}
        {...rest}
        sprint={sprint}
        onSave={handleSave}
        onCancel={handleCancel}>
      </BacklogItemForm>
    </ModalForm>
  );
}
BacklogItemModal.defaultProps = {
  status: 'backlog'
};
export default BacklogItemModal;
