import React from 'react';
import SidebarOrganization from 'components/sidebar/SidebarOrganization';
import Page from 'components/page/Page';
import ReadonlyWarning from 'components/utils/ReadOnlyWarning';

export default function AdminPage(props) {
  return (
    <Page PageSidebar={SidebarOrganization} {...props}>
      <ReadonlyWarning />
      {props.children}
    </Page>
  );
}
