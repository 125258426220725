import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';

function TriggerFinalForm(props) {
  const { onSave, onCancel, isEdit } = props;

  return (
    <Form
      name="account-add-form"
      noValidate
      onSave={onSave}>
      <Form.Field id="name" name="name" required />
      <Form.Select id="when" name="when" required />
      <div className="actions">
        <button className="btn btn-primary" type="submit">
          { isEdit ? t`Save` : t`Add trigger` }
        </button>
        <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
      </div>
    </Form>
  );
}

const TriggerForm = function(props) {
  const { onSave, onCancel, product, trigger } = props;
  if (!trigger) return null;
  trigger.product = { pk: product.pk };
  return (
    <FormContextProvider item={trigger} api="/triggers">
      <TriggerFinalForm
        isEdit={trigger && !!trigger.pk}
        product={product}
        onSave={onSave}
        onCancel={onCancel} />
    </FormContextProvider>
  );
};

export default TriggerForm;
