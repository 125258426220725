/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useMemo, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import Datatable from 'components/datatable/Datatable';
import Icon from 'components/ui/Icon';
import AppContext from 'AppContext';
import OrganizationContext from 'components/utils/OrganizationContext';
import UserPage from 'components/page/UserPage';
import Product from 'entity/Product';
import AdminPage from 'components/page/AdminPage';
import ProductModal from 'components/modal/ProductModal';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';

function ProductListContent(props) {
  const { organization } = props;
  const [editItem, setEditItem] = useState(null);
  const [refresh, setRefresh] = useState(0);

  const apiContext = {};
  if (organization) {
    apiContext.organization = organization.pk;
  }

  const onEditClose = () => {
    setEditItem(null);
  };
  const onSaveClose = () => {
    setEditItem(null);
    setRefresh(oldVal => oldVal + 1);
  };
  const onEdit = (item) => {
    setEditItem(item);
  };
  const onArchive = item => {
    axios.put(`/products/${item.pk}`, { archived: true })
      .then((response) => {
        addMessage(
          'archive-success', t`Success`, response.data.meta.message);
        setRefresh(current => current + 1);
      }).catch((error) => addMessage(
        'archive-error', t`Server error`, error.response.data.message));
  };
  const onUnArchive = item => {
    axios.put(`/products/${item.pk}`, { archived: false })
      .then((response) => {
        addMessage(
          'unarchive-success', t`Success`, response.data.meta.message);
        setRefresh(current => current + 1);
      }).catch((error) => addMessage(
        'unarchive-error', t`Server error`, error.response.data.message));
  };

  const toProduct = (item) => {
    const product = new Product();
    product.loadFromObject(item);
    return product;
  };

  const getActions = item => {
    const prod = toProduct(item);
    if (!prod.is_product_administrator) return [];
    if (!item.archived) {
      return [
        {
          key: 'onarchive',
          onClick: () => onArchive(item),
          content: <><Trans>Archive</Trans> <Icon name="archive"/></>
        }
      ];
    }
    return [
      {
        key: 'onunarchive',
        onClick: () => onUnArchive(item),
        content: <><Trans>Unarchive</Trans> <Icon name="arrow-counterclockwise"/></>
      }
    ];
  };

  const filtersBase = ['isarchived', 'organization'];
  const customFilters = useMemo(
    () => ([
      {
        is_group: false,
        label: t`Archived`,
        name: 'isarchived',
        type: 'callback',
        callback: filter => {
          filter.check.push({
            name: 'isarchived',
            label: t`Archived`
          });
          filter.total += 1;
        },
        buildParams: apiParams => {
          if (apiParams.has('isarchived')) {
            apiParams.delete('isarchived');
            apiParams.append('archived', 'true');
            apiParams.append('archived', 'false');
          }
          else {
            apiParams.delete('isarchived');
          }
        }
      }
    ]),
    []
  );

  return (
    <>
      <ProductModal
        product={editItem}
        organization={organization}
        onClose={onEditClose}
        onSave={onSaveClose}
        show={!!editItem}
        modalTitle={t`Edit product`}
        hideButton />
      <Datatable
        api="products"
        editHandler={onEdit}
        canEdit={item => toProduct(item).can_edit}
        canDelete={item => toProduct(item).can_delete}
        filtersBase={filtersBase}
        customFilters={customFilters}
        deleteConfirmation={item => (
          <Trans>
            <p>Are you sure you want to delete
              the product <strong>{item.name}</strong>?</p>
            <p>Deleting a product will definitively delete
              all backlog items and worklogs.</p></Trans>
        )}
        confirmValue={item => item.name}
        confirmValueLabel={t`Please confirm by typing the product name`}
        apiContext={apiContext}
        refresh={refresh + props.refresh}
        actions={getActions}>
        <Datatable.Column
          name="name"
          label={t`Name`}
          link={(item, children) => (
            <Link to={`/${item.organization.resource_slug}/${item.resource_slug}`}>
              { children }
            </Link>
          )}/>
        <Datatable.Column
          name="prefix"
          label={t`Prefix`}/>
        <Datatable.Column
          name="organization"
          label={t`Organization`}>
          {(item) => item.organization.resource_name}
        </Datatable.Column>
        <Datatable.Column
          name="restricted"
          label={t`Limited access to the team`}>
          {(item) => (item.team_restricted ? <Icon name="check2-square"/> : '')}
        </Datatable.Column>
      </Datatable>
    </>
  );
}

export function ProductListOrganization() {
  const organization = useContext(OrganizationContext);
  const [refresh, setRefresh] = useState(0);
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    t`Products`
  ];

  const actions = [];
  if (organization.can_add_product) {
    actions.push(
      <Link to={`/${organization.slug}/manage/products/import`}>
        <Trans>Import products</Trans>
      </Link>
    );
  }

  return (
    <AdminPage
      name="product-list-page"
      title={t`Products`}
      breadcrumb={breadcrumb}
      actions={actions}>
      { organization.can_add_product && (
        <ProductModal
          variant="primary"
          organization={organization}
          onSave={() => setRefresh(oldVal => oldVal + 1)}
          title={(<><Icon name="plus-circle"/> <Trans>Add product</Trans></>)}
          key="1" />
      ) }
      <ProductListContent refresh={refresh} organization={organization}/>
    </AdminPage>
  );
}

export default function ProductList() {
  const { user } = useContext(AppContext);
  const [refresh, setRefresh] = useState(0);
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    t`Products`
  ];

  return (
    <UserPage
      name="product-list-page"
      title={t`Products`}
      breadcrumb={breadcrumb}>
      { user.can_add_product && (
        <ProductModal
          variant="primary"
          onSave={() => setRefresh(oldVal => oldVal + 1)}
          title={(<><Icon name="plus-circle"/> <Trans>Add product</Trans></>)} />
      ) }
      <ProductListContent refresh={refresh} />
    </UserPage>
  );
}
