import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import badgeColor from './badgeColor';

export default function BacklogItemGitLabLinks(props) {
  const { data } = props;

  const { merge_requests } = data;
  if (!merge_requests || merge_requests.length === 0) {
    return '';
  }

  return (
    <div className="hstack field-value field-gitlab-links align-items-start">
      <div className="text-secondary">Merge requests</div>
      <div className="ms-auto">
        { merge_requests.slice(0).reverse().map((mr) => (
          <div key={mr.id} className="mt-1">
            <OverlayTrigger
              placement="top"
              overlay={(
                <Tooltip id={`tooltip-${mr.id}`}>
                  { mr.source_branch }
                </Tooltip>
              )}
            >
              {({ ref, ...triggerHandler }) => (
                <a href={mr.url} {...triggerHandler} ref={ref}>!{mr.iid}</a>
              )}
            </OverlayTrigger>
            {' '}
            <span className={`badge border ${badgeColor(mr.state)} ms-2`}>
              {mr.state.replaceAll('_', ' ').toUpperCase()}
            </span>
          </div>
        )) }
      </div>
    </div>
  );

}
