import React, { useContext } from 'react';
import { NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import AppContext from 'AppContext';
import Icon from 'components/ui/Icon';
import AboutModal from 'components/modal/AboutModal';
import LanguageSelector from './LanguageSelector';
import Notifications from './Notifications';
import ThemeSelector from './ThemeSelector';
import Jobs from './Jobs';

export default function TopNav() {
  const appContext = useContext(AppContext);
  const { user } = appContext;
  const title = <Icon name="person-circle" title={t`Account`}/>;
  return (
    <div className="topmenu">
      <div className="nav">
        {user && (
          <>
            <Jobs/>
            <Notifications/>
          </>
        )}
        <LanguageSelector/>
        <ThemeSelector/>
        {user && (
          <NavDropdown
            key="user"
            title={title}
            className="user-dropdown"
            id="basic-nav-dropdown">
            <NavDropdown.Item as={NavLink} to="/user/profile" >{user.full_name}</NavDropdown.Item>
            <NavDropdown.Item as={NavLink} to="/user/worklog?group_by_date=date&group_by=item" ><Trans>My work</Trans></NavDropdown.Item>
            <AboutModal isDropDown />
            <NavDropdown.Divider />
            <NavDropdown.Item key="logout" as="button" onClick={e => appContext.logout()}>Logout</NavDropdown.Item>
          </NavDropdown>
        )}
      </div>
    </div>
  );
}
