import React from 'react';
import Actions from './Actions';
import Breadcrumb from './Breadcrumb';

function ContentBlock(props) {
  const { breadcrumb, actions, title, nobody } = props;
  const classes = 'content-block' + (('className' in props) ? ' ' + props.className : '');
  return (
    <div className={classes}>
      { (breadcrumb || actions || title) && (
        <div className={'content-block-header d-md-flex justify-content-between align-items-center' + (nobody ? ' nobody' : '')}>
          <Breadcrumb links={breadcrumb}/>
          { title && (
            <h3 className="mb-2 mb-md-0">{ title }</h3>
          ) }
          <Actions links={actions}/>
        </div>
      ) }
      { !nobody && (
        <div className="content-block-body">
          { props.children }
        </div>
      ) }
    </div>
  );
}

export default ContentBlock;
