/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';
import Icon from 'components/ui/Icon';
import ImageViewer from 'components/ui/ImageViewer';
import CKWrapper from './CKWrapper';

function keydownHandler (event) {
  // space key
  if (event.keyCode === 32) {
    event.preventDefault();
  }
  // enter key
  else if (event.keyCode === 13) {
    event.preventDefault();
    event.target.click();
  }
}

function keyupHandler (event) {
  // space key
  if (event.keyCode === 32) {
    event.preventDefault();
    event.target.click();
  }
}

function RichContent(props) {
  const { data } = props;
  const contentRef = useRef();
  const [imageList, setImageList] = useState([]);
  const [imageViewed, setImageViewed] = useState(null);

  useEffect(() => {
    if (contentRef.current) {
      const images = contentRef.current.querySelectorAll(':not(.btn-simplelink) > img');
      const check = /\/images\/medium\//;
      const list = [];
      images.forEach(function(image) {
        const wrapper = document.createElement('button');
        wrapper.classList.add('btn-simplelink');
        wrapper.setAttribute('data-link', image.src.replace(check, ''));
        image.parentNode.insertBefore(wrapper, image);
        wrapper.appendChild(image);
        wrapper.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          setImageViewed(wrapper);
        }, false);
        list.push({
          button: wrapper,
          link: image.src.replace(check, '/images/large/'),
          source: image.src.replace(check, ''),
        });
      });
      setImageList(list);
    }
  }, [data]);

  return (
    <>
      { imageList && (
        <ImageViewer imageList={imageList} image={imageViewed} setImage={setImageViewed} />
      ) }
      <div
        ref={contentRef}
        className="rich-content"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: data }}/>
    </>
  );
}

export default function RichContentEditable(props) {
  const {
    content, field, pk, api, editButton, editMode, onUpdate, onBlur, placeholder, onEdit,
    onEditClose, backlogItem, mention, saveButtons, readonly, product } = props;
  const [isEdit, setIsEdit] = useState(editMode);
  const [data, setData] = useState(content);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef();

  const setIsEditHandler = useCallback((edit) => {
    if (edit) {
      if (onEdit) onEdit(field + '-' + pk);
    }
    else if (onEditClose) {
      onEditClose(field + '-' + pk);
    }
    setIsEdit(edit);
  }, [onEdit, onEditClose, field, pk]);

  const richClickHandler = (e) => {
    if (!editButton && e.target.tagName.toLowerCase() !== 'a') {
      setIsEditHandler(true);
    }
  };

  const saveHandler = useCallback((event, editor) => {
    const obj = {};
    obj[field] = editor.data.get();
    setLoading(true);
    axios.put(api + '/' + pk, obj).then((response) => {
      setData(response.data[field]);
      setIsEditHandler(false);
      setLoading(false);
      if (onUpdate) onUpdate(response.data[field]);
    }).catch((error) => {
      addMessage(
        'field-update-' + field + '-' + pk,
        t`Unknown error`,
        t`Impossible to update ${field}`
      );
      setIsEditHandler(false);
      setLoading(false);
      if (onUpdate) onUpdate(false);
    });
  }, [api, field, pk, onUpdate, setIsEditHandler]);

  const onReadyHandler = useCallback((editor) => {
    editorRef.current = editor;
    editor.focus();
    let editor_changed = false;
    editor.model.document.on('change:data', () => { editor_changed = true; });
    if (!saveButtons) {
      editor.ui.focusTracker.on('change:isFocused', (evt, name, isFocused) => {
        if (!isFocused) {
          if (editor_changed) {
            saveHandler(evt, editor);
          }
          else {
            setIsEditHandler(false);
            if (onBlur) {
              onBlur();
            }
          }
        }
      });
    }
  }, [onBlur, saveHandler, saveButtons, setIsEditHandler]);

  if (readonly) {
    return (
      <RichContent data={data}/>
    );
  }
  if (!isEdit) {
    const attrs = {};
    if (!editButton) {
      attrs.role = 'button';
      attrs.onClick = richClickHandler;
    }
    return (
      <div
        className={editButton ? 'rich-content-with-button' : 'rich-content-editable-button'}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex="0"
        onKeyDown={keydownHandler}
        onKeyUp={keyupHandler}
        {...attrs}>
        { editButton ? (
          <button type="button" className="btn btn-outline-secondary" onClick={() => { setIsEditHandler(true); }}>
            <Icon name="pencil"/> <Trans>Edit</Trans>
          </button>
        ) : (
          <Icon name="pencil"/>
        ) }
        { data ? (
          <RichContent data={data} />
        ) : (
          <div className="rich-content-placeholder">
            { placeholder || t`Please provide a content` }
          </div>
        ) }
      </div>
    );
  }
  return (
    <div className="relative">
      { loading && (
        <LoaderContainer/>
      ) }
      <CKWrapper
        data={data}
        backlogItem={backlogItem}
        onReady={onReadyHandler}
        mention={mention}
        product={product} />
      { saveButtons && (
        <>
          <button
            className="btn btn-primary me-2"
            type="button"
            onClick={() => {
              saveHandler(null, editorRef.current);
            }}>
            <Trans>Save</Trans>
          </button>
          <button
            className="btn btn-outline-secondary"
            type="button"
            onClick={() => {
              setIsEditHandler(false);
              if (onBlur) {
                onBlur();
              }
            }}>
            <Trans>Cancel</Trans>
          </button>
        </>
      ) }

    </div>
  );
}
RichContent.defaultProps = {
  editButton: false
};

export { RichContent };
