import React, { useContext } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import AppContext from 'AppContext';

const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear();
};

const DateBlock = function(props) {
  const { datestring, date, id, ...rest } = props;
  const { lang } = useContext(AppContext);
  let dateObj;
  let dateLong;
  try {
    dateObj = date || new Date(datestring);
    dateLong = new Intl.DateTimeFormat(lang.pk, rest).format(dateObj);
  } catch (e) {
    console.error('impossible to parse date: ' + datestring);
  }

  if (isToday(dateObj)) {
    const dateShort = new Intl.DateTimeFormat(lang.pk, {
      hour: 'numeric',
      minute: 'numeric'
    }).format(dateObj);
    return (
      <OverlayTrigger overlay={<Tooltip id={`date-${id}`}>{ dateLong }</Tooltip>}>
        {({ ref, ...triggerHandler }) => (
          <span className="d-inline-block dateblock" {...triggerHandler} ref={ref}>
            { dateShort }
          </span>
        )}
      </OverlayTrigger>
    );
  }

  return (
    <span className="d-inline-block dateblock">
      { dateLong }
    </span>
  );
};
DateBlock.defaultProps = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric'
};

export default DateBlock;
