import React, { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';
import axios from 'axios';
import CodeField from 'components/form/CodeField';
import RequestCodeByEmail from './RequestCodeByEmail';

function OtpDisableFinalForm(props) {
  const { onSave, onCancel } = props;
  const [validated, setValidated] = useState(false);

  const onInvalid = () => {
    setValidated(true);
  };

  return (
    <div className="text-center">
      <p>
        <Trans>Please provide a valid TOTP code with your authenticator app.</Trans>
      </p>
      <Form
        method="put"
        name="otp-disable-form"
        onInvalid={onInvalid}
        onSave={onSave}>
        <CodeField size="6" id="otp_code" name="otp_code" required autofocus validated={validated} />
        <p>
          <Trans>You can‘t access your authenticator app?</Trans><br />
          <RequestCodeByEmail />
        </p>
        <div className="actions">
          <button className="btn btn-primary" type="submit">
            <Trans>Disable</Trans>
          </button>
          <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
        </div>
      </Form>
    </div>
  );
}

const OtpDisableForm = function(props) {
  const { onSave, onCancel } = props;
  const item = {
    
    clone: () => ({
      otp_code: '',
      save: (it) => axios.put('auth/otp/disable', { otp_code: it.otp_code }),
    })
  };

  return (
    <FormContextProvider
      item={item}
      api="/auth/otp/disable"
      meta={{
        fields: {
          otp_code: {
            type: 'CharField',
            name: t`Code`,
            readonly: {
              post: false,
              put: false
            },
            required: true
          }
        }
      }}>
      <OtpDisableFinalForm
        onSave={onSave}
        onCancel={onCancel} />
    </FormContextProvider>
  );
};

export default OtpDisableForm;
