import React, { useContext, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { t, Trans } from '@lingui/macro';
import OrganizationContext from 'components/utils/OrganizationContext';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import AdminPage from 'components/page/AdminPage';
import NotFoundPage from 'pages/NotFoundPage';

function SlackSettings(props) {
  const { organization } = props;
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState(null);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/integrations`}><Trans key="2">Integrations</Trans></Link>,
    <span key="3">Slack</span>
  ];

  useEffect(() => {
    axios.get(`/integrations/slack/${organization.slug}/settings`).then(res => {
      setTeams(res.data.teams);
    }).catch(err => {
      addMessage('load-slack-settings', t`Unknown error`, t`Can't load settings`);
    });
  }, [organization.slug]);

  const handleInstall = e => {
    e.preventDefault();
    setLoading(true);
    axios.put(`/integrations/slack/${organization.slug}/settings`).then(res => {
      window.location.href = res.data.url;
    }).catch(error => {
      setLoading(false);
      addMessage(
        'slack-install-failed', t`Slack install`, t`Slack installation failed.`);
    });
  };

  return (
    <AdminPage breadcrumb={breadcrumb} name="slack-page slack-page-config" title={t`Slack integration`}>
      { teams === null ? (
        <LoaderContainer height="4" />
      ) : (
        <div className="relative">
          { loading && (
            <LoaderContainer />
          ) }
          {teams.length ? (
            <>
              <div className="content-block-header">
                <h3>Slack teams</h3>
              </div>
              <div className="datatable w-50">
                <table className="table table-datatable table-responsive"><tbody>
                  {teams.map(team => (
                    <tr key={team.id}>
                      <td>{team.name}</td>
                      <td className="col__actions">
                        <a href={`https://app.slack.com/apps-manage/${team.id}/integrations/installed`}>
                          Slack applications admin panel.</a>
                      </td>
                    </tr>
                  ))}
                </tbody></table>
              </div>
              <p>Uninstall the application from the slack team admin panel to remove the authorization.
                The integration will be uninstalled if there are no more authorized teams.</p>
              <div className="slack-settings-actions">
                <Button
                  variant="primary"
                  onClick={handleInstall}>
                  <Trans>Add another Slack team</Trans>
                </Button>
              </div>
            </>
          ) : (
            <div className="slack-settings-actions">
              <Button
                variant="primary"
                onClick={handleInstall}>
                <Trans>Install Slack integration</Trans>
              </Button>
            </div>
          )}
        </div>
      )}
    </AdminPage>
  );
}

function SlackAuthorize(props) {
  const history = useHistory();
  const { organization } = props;
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const state = params.get('state');

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/integrations`}><Trans key="2">Integrations</Trans></Link>,
    <span key="3"><Trans>Slack authorization</Trans></span>
  ];

  useEffect(() => {
    const redirect = () => {
      history.push(`/${organization.slug}/integrations/slack`);
    };

    if (code && state) {
      axios.post(`/integrations/slack/${organization.slug}/settings`, {
        code: code,
        state: state,
      }).then(res => {
        addMessage(
          'slack-authorize-success', t`Slack authorization`, t`Slack authorization successful.`);
        organization.reload().then(() => {
          redirect();
        }).catch((err) => {
          addMessage('org-load', t`Unknown error`, t`Impossible to load organization`);
          redirect();
        });
      }).catch(err => {
        addMessage(
          'slack-authorize-failed', t`Slack authorization`, t`Slack authorization failed.`);
        redirect();
      });
    } else {
      redirect();
    }
  }, [history, organization, code, state]);

  return (
    <AdminPage breadcrumb={breadcrumb} name="slack-page slack-page-authorize" title={t`Slack authorization`}>
      <LoaderContainer height="4" />
    </AdminPage>
  );
}

export default function SlackPage(props) {
  const organization = useContext(OrganizationContext);
  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>
  ];

  return (
    <Switch>
      <Route exact path="/:orgSlug/integrations/slack">
        <SlackSettings organization={organization}/>
      </Route>
      <Route exact path="/:orgSlug/integrations/slack/authorize">
        <SlackAuthorize organization={organization}/>
      </Route>
      <Route>
        <NotFoundPage breadcrumb={breadcrumb} />
      </Route>
    </Switch>
  );
}
