import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import ProductPage from 'components/page/ProductPage';
import Icon from 'components/ui/Icon';
import StoryMapModal from 'components/modal/StoryMapModal';
import Datatable from 'components/datatable/Datatable';

function StoryMapsPageContent(props) {
  const { product, organization } = props;

  const [refresh, setRefresh] = useState(0);
  const [editItem, setEditItem] = useState(null);

  const onRefresh = () => {
    setRefresh(refresh + 1);
  };

  const onEditClose = () => {
    setRefresh(oldVal => oldVal + 1);
    setEditItem(null);
  };
  const onEdit = useCallback((item) => {
    setEditItem(item);
  }, []);

  const apiContext = useMemo(() => ({ product: product.pk }), [product.pk]);

  return (
    <>
      { product.can_manage_storymaps && (
        <StoryMapModal
          variant="primary"
          onClose={onRefresh}
          product={product}
          organization={organization}
          title={(<><Icon name="plus-circle"/> <Trans>Add story map</Trans></>)}
          key="storymap-add-modal" />
      ) }
      <StoryMapModal
        storyMap={editItem}
        product={product}
        organization={organization}
        onClose={onEditClose}
        show={!!editItem}
        modalTitle={t`Edit story map`}
        hideButton />
      <Datatable
        api="/story-maps"
        apiContext={apiContext}
        editHandler={product.can_manage_storymaps && onEdit}
        canDelete={product.can_manage_storymaps}
        search={false}
        refresh={refresh}>
        <Datatable.Column name="name" label={t`Name`}/>
        <Datatable.Column name="view" label={t`View`}>
          {(item) => (
            <Link
              to={`/${organization.slug}/${product.slug}/storymap/${item.pk}`}
              className="btn btn-outline-secondary">
              <Trans>Story map</Trans>{' '}<Icon name="signpost" />
            </Link>
          )}
        </Datatable.Column>
      </Datatable>
    </>
  );
}

export default function StoryMapsPage(props) {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Story map`
  ];

  return (
    <ProductPage
      name="storymaps-page"
      title={t`Story map`}
      breadcrumb={breadcrumb}>
      <StoryMapsPageContent
        organization={organization}
        product={product} />
    </ProductPage>
  );

}
