/* eslint-disable
  no-lonely-if,
  jsx-a11y/no-noninteractive-element-interactions,
  jsx-a11y/no-static-element-interactions,
  jsx-a11y/click-events-have-key-events */
import { Trans, t } from '@lingui/macro';
import React, { useEffect, useRef } from 'react';
import axios from 'axios';
import { apiSearchUrl } from 'utils/urlUtils';
import { Loader } from './Loader';
import Icon from './Icon';

function stringify(str) {
  return str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

function getInputWidth(element, minimum, offset) {
  if (element === null) return (minimum + offset + 'px');
  const text = element.value || element.placeholder;
  const elementStyle = window.getComputedStyle(element);
  const fontProperty = elementStyle.font;
  const horizontalBorder = parseFloat(elementStyle.borderLeftWidth) + parseFloat(elementStyle.borderRightWidth);
  const horizontalPadding = parseFloat(elementStyle.paddingLeft) + parseFloat(elementStyle.paddingRight);

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = fontProperty;
  const textWidth = context.measureText(text).width;

  const totalWidth = Math.max(horizontalBorder + horizontalPadding + textWidth + offset, minimum + offset);
  return totalWidth + 'px';
}

function itemsHaveChanged(list1, list2) {
  if (list1.length !== list2.length) {
    return true;
  }
  for (let i = 0; i < list1.length; i++) {
    if (list2.findIndex(it => it.pk === list1[i].pk) === -1) {
      return true;
    }
  }
  return false;
}

const resourceName = function(obj) {
  if (obj.resource_reference) {
    return obj.resource_reference + ': ' + obj.resource_name;
  }
  return obj.resource_name;
};

const removeDoubles = function(array) {
  return array.filter((item, index) => array.findIndex(
    i => ((typeof i === 'object') ? (i.pk === item.pk) : (item === i))
  ) === index);
};

const Item = function(props) {
  const { option, searchVal, preselection, changeSelected, validate, focusedItem } = props;
  const ref = useRef();

  useEffect(() => {
    if (preselection && focusedItem === option.pk) ref.current.focus();
  }, [preselection, focusedItem, option.pk]);

  const mouseEnter = () => {
    changeSelected(option.pk);
  };

  const onItemClick = () => {
    if (!preselection) {
      changeSelected(option.pk, validate);
    }
    else {
      validate();
    }
  };

  const resName = resourceName(option);
  let finalText = resName;
  if (searchVal) {
    const re = new RegExp(stringify(searchVal), 'gi');
    const array1 = re.exec(resName);
    if (array1 !== null) {
      finalText = (
        <>
          { resName.substring(0, re.lastIndex - searchVal.length) }
          <mark>{ resName.substring(re.lastIndex - searchVal.length, re.lastIndex) }</mark>
          { resName.substring(re.lastIndex) }
        </>
      );
    }
  }
  return (
    <button
      className={'btn ' + (preselection ? 'btn-primary' : 'btn-outline-secondary')}
      onMouseEnter={mouseEnter}
      onClick={onItemClick}
      ref={ref}
      type="button"
      tabIndex="-1">
      { finalText }
    </button>
  );
};

/**
 * props:
 *  apiParams: params to pass to api {}
 *  nullText: Text shown for null value (opt | default to t'None')
 *  value: Actual field value (opt, object with pk and resource_name)
 *  maxItems: max items shown in autocomplete (default 10)
 *  required
 */
class Autocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      options: [],
      preselection: props.value && props.value.pk,
      focusedItem: null,
      loading: false,
      showSearch: true,
      items: props.items ? props.items : [],
      showAddBtn: false
    };

    this.inputWrapper = React.createRef();
    this.popinRef = React.createRef();
    this.inputRef = this.props.inputRef || React.createRef();

    this.close = this.close.bind(this);
    this.handleBtnKeyDown = this.handleBtnKeyDown.bind(this);
    this.handlePopinKeyDown = this.handlePopinKeyDown.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleContainerClick = this.handleContainerClick.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.changeSelected = this.changeSelected.bind(this);
    this.validate = this.validate.bind(this);
    this.validateAddItem = this.validateAddItem.bind(this);
  }

  handleContainerClick(e) {
    if (this.props.mode === 'continuous') {
      this.inputRef.current.focus();
    }
  }

  handleBtnKeyDown(e) {
    if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      if (!this.state.opened) {
        this.setState({ opened: true });
      }
      else {
        this.handlePopinKeyDown(e);
      }
      e.preventDefault();
    }
    else if (e.code === 'Escape') {
      this.close();
    }
  }

  handlePopinKeyDown(e, noReturnFocus) {
    if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      if (this.state.options.length > 0) {
        this.setState(prevState => {
          const index = prevState.options.findIndex(el => el.pk === this.state.preselection);
          if (index !== -1) {
            if (e.code === 'ArrowDown') {
              if (index >= prevState.options.length - 1) {
                return { preselection: prevState.options[0].pk };
              }
              return { preselection: prevState.options[index + 1].pk };
            }
            if (index <= 0) {
              if (this.props.autocreate && !noReturnFocus) {
                return { preselection: null };
              }
              return { preselection: prevState.options[prevState.options.length - 1].pk };
            }
            return { preselection: prevState.options[index - 1].pk };
          }
          return e.code === 'ArrowDown' ? {
            preselection: prevState.options[0].pk
          } : {
            preselection: prevState.options[prevState.options.length - 1].pk
          };
        }, () => {
          if (this.state.preselection === null) {
            this.inputRef.current.focus();
          }
          this.setState(
            prevState => ({ focusedItem: prevState.preselection })
          );
        });
      }
      e.preventDefault();
    }
    else if (e.code === 'Enter' || e.code === 'Space') {
      this.validate();
    }
    else if (e.code === 'Escape') {
      this.close();
    }
  }

  validate() {
    if (this.state.preselection !== false) {
      const newState = this.props.single ? {
        opened: false,
        items: [this.state.options.find(el => el.pk === this.state.preselection)]
      } : prevState => ({
        opened: false,
        items: removeDoubles(
          [...prevState.items, prevState.options.find(el => el.pk === prevState.preselection)]
        )
      });
      this.setState(newState, () => {
        if (this.props.handleSelect) {
          this.props.handleSelect(this.props.single ? this.state.items[0] : this.state.items, this.inputRef.current);
        }
        this.inputRef.current.value = '';
        if (this.props.mode === 'continuous') {
          this.inputRef.current.focus();
        }
      });
    }
  }

  handleInputKeyDown(e) {
    if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
      this.handlePopinKeyDown(e, true);
      e.preventDefault();
      e.stopPropagation();
    }
    else if (e.code === 'Escape') {
      this.close();
    }
    if (e.code === 'Enter') {
      if (this.props.autocreate) {
        this.validateAddItem();
      }
      else if (this.state.preselection) {
        this.validate();
      }
      else if (this.props.onValidateText) {
        this.props.onValidateText(this.inputRef.current.value);
      }

    }
  }

  validateAddItem() {
    const val = this.inputRef.current.value.trim();
    this.addItem({
      pk: val,
      resource_name: val
    });
    this.inputRef.current.value = '';
    this.setState({ showAddBtn: false });
  }

  onOpen() {
    document.addEventListener('click', this.close);
    this.setState(prevState => ({ focusedItem: prevState.preselection }));
  }

  onClose() {
    document.removeEventListener('click', this.close);
  }

  close() {
    this.setState({ opened: false, focusedItem: null });
  }

  handleSearchChange(e) {
    
    const value = this.inputRef.current.value.trim();
    if (this.props.autocreate) {
      this.setState({ showAddBtn: value.length > 0 });
    }
    if (this.props.mode === 'continuous' && this.props.autocreate && value.length < 1) {
      this.setState({ options: [], opened: false });
    }
    else {
      this.setState({ loading: true });
      axios.get(
        apiSearchUrl(this.props.searchApi, {
          search: value,
          apiParams: { ...this.props.apiParams, exclude: this.state.items.map(i => i.pk) },
          limit: this.props.maxItems
        })
      ).then((res) => {
        const items = [];
        if (res.data.items) {
          for (const item of res.data.items) {
            if (typeof item === 'string') {
              items.push({
                pk: item,
                resource_name: item
              });
            }
            else {
              items.push(item);
            }
          }
        }
        this.setState({ options: items, loading: false, opened: true });
      });
    }
    
  }

  addItem(item) {
    this.setState(prevState => ({
      opened: false,
      items: removeDoubles(
        [...prevState.items, item]
      )
    }), () => {
      if (this.props.handleSelect) {
        this.props.handleSelect(this.state.items);
      }
    });
  }

  removeItem(item) {
    this.setState(prevState => ({
      items: prevState.items.filter(i => i.pk !== item.pk)
    }));
  }

  changeSelected(value, cb) {
    this.setState({ preselection: value, focusedItem: value }, () => {
      if (cb) cb();
    });
  }

  componentDidMount() {
    if (this.props.autofocus) {
      this.inputRef.current.focus();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.opened !== prevState.opened) {
      if (this.state.opened) {
        this.onOpen();
      }
      else {
        this.onClose();
      }
    }
    if (this.state.options !== prevState.options) {
      if (this.props.onOptionsChange) {
        this.props.onOptionsChange(this.state.options);
      }
      if (this.state.options.length === 1 && !this.props.autocreate) {
        this.setState(pState => ({ preselection: pState.options[0].pk }));
      }
      else if (this.state.preselection) {
        this.setState(pState => {
          if (!pState.options.find(el => el.pk === pState.preselection)) {
            return { preselection: null };
          }
          return {};
        });
      }
    }
    if (prevProps.items !== this.props.items) {
      this.setState({ items: this.props.items });
    }
    if (itemsHaveChanged(this.state.items, prevState.items)) {
      this.props.onChange(this.state.items);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.close);
  }

  render() {
    const editAreaAttrs = {};
    if (this.props.mode === 'continuous') {
      editAreaAttrs.style = {
        width: getInputWidth(
          this.inputRef.current ? this.inputRef.current : null,
          this.props.autocreate ? 30 : 20,
          this.props.autocreate ? 20 : 10
        )
      };
    }

    const searchVal = (this.state.showSearch && this.inputRef.current) ?
      this.inputRef.current.value :
      null;

    return (
      <div
        className={'autocomplete autocomplete-' + this.props.mode + (this.props.autocreate ? ' autocomplete-autocreate' : '')}
        tabIndex="-1"
        onClick={this.handleContainerClick}>
        { !this.props.hideItems && this.state.items.map(item => (
          <button
            type="button"
            key={item.pk}
            className="bt-active-item btn btn-outline-secondary btn-sm rounded-pill me-2 mb-1"
            onClick={() => { this.removeItem(item); }}>
            { resourceName(item) } <Icon name="x-circle"/>
          </button>
        )) }
        <div className="autocomplete-edit-area" {...editAreaAttrs} >
          <div className={this.props.error ? 'is-invalid' : ''}>
            <label
              className={this.props.mode === 'continuous' ? 'visually-hidden' : ''}
              htmlFor={this.props.id}>{this.props.inputLabel}</label>
            <div
              className="autocomple-input-wrapper"
              ref={this.inputWrapper}
              tabIndex="-1"
              onClick={e => { e.stopPropagation(); }}>
              <input
                ref={this.inputRef}
                id={this.props.id}
                onChange={this.handleSearchChange}
                onKeyDown={this.handleInputKeyDown}
                onFocus={() => {
                  this.handleSearchChange();
                }}
                type="text"
                className={'form-control' + (this.props.error ? ' is-invalid' : '')}/>
              { this.state.loading && (
                <Loader size="tiny" />
              ) }
              { this.props.error && (
                <div className="invalid-feedback">
                  { this.props.error.join(<br />) }
                </div>
              ) }
              { this.state.showAddBtn && (
                <button type="button" className="bt-add-item btn btn-outline-secondary text-small" onClick={this.validateAddItem}>
                  { this.props.mode === 'continuous' ? (
                    <Icon name="plus" title={t`Add`} />
                  ) : (
                    <Trans>Add</Trans>
                  ) }
                  
                </button>
              ) }
              <div
                className={'options' + (this.state.opened ? ' opened' : ' closed')}
                ref={this.popinRef}
                onKeyDown={this.handlePopinKeyDown}>
                <div className="btn-group-vertical">
                  { this.state.options.map(option => (
                    <Item
                      key={option.pk}
                      focusedItem={this.state.focusedItem}
                      option={option}
                      preselection={option.pk === this.state.preselection}
                      changeSelected={this.changeSelected}
                      validate={this.validate}
                      searchVal={searchVal}/>
                  )) }
                </div>
              </div>

            </div>
          </div>
          { this.props.children }
        </div>
      </div>
    );
  }
}
Autocomplete.defaultProps = {
  apiParams: {},
  onChange: () => {},
  autocreate: false,
  autofocus: false,
  maxItems: 10,
  hideItems: false,
  single: false,
  error: null,
  mode: 'separated'
};

export default Autocomplete;
