export default function copyTextToClipboard(text, isHtml) {
  const textArea = isHtml ? document.createElement('div') : document.createElement('textarea');

  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.width = '2em';
  textArea.style.height = '2em';
  textArea.style.padding = 0;
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';
  textArea.style.background = 'transparent';

  if (isHtml) {
    textArea.style.overflow = 'hidden';
    textArea.innerHTML = text;
  }
  else {
    textArea.value = text;
  }
  
  document.body.appendChild(textArea);

  if (isHtml) {
    const selection = window.getSelection();
    selection.removeAllRanges();

    const range = document.createRange();
    range.selectNodeContents(textArea);
    selection.addRange(range);
  }
  else {
    textArea.focus();
    textArea.select();
  }
  
  let ret = false;
  try {
    const successful = document.execCommand('copy');
    ret = !!successful;
  } catch (err) {
    ret = false;
  }
  document.body.removeChild(textArea);
  return ret;
}
