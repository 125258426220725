import React, { useState } from 'react';
import { Toast } from 'react-bootstrap';

const messages = {
  setter: null,
  messages: [],
};

function getMessageIndex(key) {
  return messages.messages.findIndex(mess => mess.key === key);
}

export function getMessage(key) {
  return messages.messages.find(mess => mess.key === key);
}

export function addMessage(key, title, message) {
  if (getMessageIndex(key) !== -1) {
    return;
  }
  messages.messages.push({ key: key, message: message, title: title });
  if (messages.setter) messages.setter([...messages.messages]);
}

export function removeMessage(key) {
  const index = getMessageIndex(key);
  if (index === -1) {
    return;
  }
  messages.messages.splice(index, 1);
  if (messages.setter) messages.setter([...messages.messages]);
}

function Message(props) {
  const onClose = () => {
    removeMessage(props.id);
  };
  return (
    <Toast onClose={onClose} delay={10000} autohide>
      <Toast.Header>
        <strong className="me-auto">{ props.title }</strong>
      </Toast.Header>
      <Toast.Body>{ props.children }</Toast.Body>
    </Toast>
  );
}

export function MessagesContainer() {
  const [appMessages, setAppMessages] = useState([]);

  messages.setter = setAppMessages;

  return (
    <div className="messages-container">
      { appMessages.map(({ key, message, title }) => (
        <Message key={key} id={key} title={title}>{ message }</Message>
      )) }
    </div>
  );
}
