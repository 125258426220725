import React, { useState, useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import * as BS from 'react-bootstrap';
import axios from 'axios';
import { Trans, t } from '@lingui/macro';
import AppContext from 'AppContext';
import AboutModal from 'components/modal/AboutModal';
import SigninForm from './SigninForm';
import LoginOtp from './LoginOtp';

function LoginForm(props) {
  const appContext = useContext(AppContext);
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [rememberMe, setRememberMe] = useState();
  const [error, setError] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [validated, setValidated] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setError(null);
    axios.post('/auth/login?mode=cookie', {
      username: username,
      password: password,
      remember_me: rememberMe }
    ).then(function ({ data }) {
      if (data.need_otp) {
        setUserToken(data.token);
      }
      else {
        appContext.setUser(data.user);
      }
    }).catch(function (err) {
      if (err.response) {
        if (err.response.status === 429) {
          setError(err.response.data.message);
        }
        else {
          setError(t`Wrong user or password !`);
        }
      }
      else if (err.request) {
        console.error(err);
        setError(t`Service currently unavailable.`);
      }
      else {
        console.error(err);
        setError(t`An error occured, impossible to log you in.`);
      }      
    });
  };

  if (appContext.user) {
    return <Redirect to={props.from}/>;
  }

  if (userToken) {
    return <LoginOtp userToken={userToken} from={props.from} />;
  }

  const onInvalid = () => {
    setValidated(true);
  };

  return (
    <>
      <h1 className="h2 mb-3 font-weight-normal">{ t`Please Log In` }</h1>
      <SigninForm onSubmit={handleSubmit} onInvalid={onInvalid}>
        <BS.FormGroup className={'form-group' + (validated ? ' was-validated' : '')}>
          <BS.Form.Label htmlFor="username"><Trans>Email Address</Trans></BS.Form.Label>
          <BS.Form.Control
            type="email"
            name="username"
            id="username"
            autoComplete="username"
            onChange={e => setUserName(e.target.value)}
            required
            autoFocus/>
        </BS.FormGroup>
        <BS.FormGroup className={'form-group' + (validated ? ' was-validated' : '')}>
          <BS.Form.Label htmlFor="password"><Trans>Password</Trans></BS.Form.Label>
          <BS.Form.Control
            type="password"
            name="password"
            id="password"
            autoComplete="current-password"
            required
            onChange={e => setPassword(e.target.value)}/>
        </BS.FormGroup>
        <div className="checkbox mb-3">
          <BS.FormGroup className="form-group">
            <BS.Form.Label htmlFor="remember-me">
              <BS.Form.Check
                name="remember-me"
                id="remember-me"
                onChange={e => setRememberMe(e.target.value)}
                label={t`Remember me`}/>
            </BS.Form.Label>
          </BS.FormGroup>
        </div>
        { error && (
          <div className="mb-2">
            <BS.Alert variant="danger">{error}</BS.Alert>
          </div>
        )}
        <div className="mb-3">
          <button
            className="btn btn-cta"
            type="submit">Login</button>
        </div>
        <div>
          <Trans>Don’t have an account?</Trans> <Link to="/auth/signup"><Trans>Sign Up</Trans></Link><br/>
          <Link to="/auth/forgotten-password"><Trans>Forgot Password</Trans></Link>
          <hr />
          <AboutModal variant="simplelink" />
        </div>
      </SigninForm>
    </>
  );
}
export default LoginForm;
