const HoursDuration = function({ value, display, type }) {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;
  if (type === 'clock') {
    return display(
      (hours || '0') + ':' +
      minutes.toString().padStart(2, '0')
    );
  }
  return display(
    (hours ? `${hours}h` : '') + ' ' +
    (minutes ? `${minutes}m` : '')).trim();
};
HoursDuration.defaultProps = {
  type: 'human_format',
  display: time => time,
};
export default HoursDuration;
