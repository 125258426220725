import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import AppContext from 'AppContext';
import Sidebar from './Sidebar';
import SidebarLinks from './SidebarLinks';

function SidebarUser(props) {
  const { user } = useContext(AppContext);

  const links = [];
  if (user.can_add_product) links.push({ name: 'product', icon: 'stack', to: '/products', text: t`Products` });
  links.push({ name: 'organizations', icon: 'building', to: '/organizations', text: t`Organizations` });
  links.push({ name: 'account', icon: 'person', to: '/user/profile', text: t`Profile` });
  links.push({
    name: 'items',
    icon: 'list-check',
    to: '/user/items',
    text: t`Items list` });

  return (
    <Sidebar {...props}>
      <ul className="nav flex-column">
        <SidebarLinks links={links} />
      </ul>
    </Sidebar>
  );
}

export default SidebarUser;
