import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Icon from 'components/ui/Icon';
import { setStateItem, useStateItem } from 'utils/Item';

const getStoredTheme = () => localStorage.getItem('theme');
const setStoredTheme = theme => localStorage.setItem('theme', theme);
const getPreferredTheme = () => {
  const storedTheme = getStoredTheme();
  if (storedTheme) {
    return storedTheme;
  }

  const theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  setStoredTheme(theme);
  return theme;
};

function ThemeSelector() {
  const theme = useStateItem('theme:key') || getPreferredTheme();

  useEffect(() => {
    setStoredTheme(theme);
  }, [theme]);

  return (
    <>
      <Helmet htmlAttributes={{ 'data-bs-theme': theme }}/>
      <button
        className="btn-simplelink bt-theme"
        type="button"
        onClick={() => { setStateItem('theme:key', theme === 'dark' ? 'light' : 'dark'); }}>
        <Icon name={theme === 'dark' ? 'sun-fill' : 'sun'} />
      </button>
    </>
  );
}

export default ThemeSelector;
