/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import OrganizationContext from 'components/utils/OrganizationContext';
import axios from 'axios';
import ProductContext from 'components/utils/ProductContext';
import ProductPage from 'components/page/ProductPage';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Trigger from 'entity/Trigger';
import { useStateItem } from 'utils/Item';
import { LoaderContainer } from 'components/ui/Loader';
import TriggerActions from 'components/triggers/TriggerActions';
import TriggerConditions from 'components/triggers/TriggerConditions';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import DropdownEditable from 'components/form/DropdownEditable';
import CheckboxEditable from 'components/form/CheckboxEditable';

function TriggerDetailPageContent(props) {
  const { trigger, meta } = props;

  const getOpts = () => (
    new Promise(resolve => {
      const options = [];
      for (let i = 0; i < meta.fields.when.choices.length; i++) {
        options.push({
          label: meta.fields.when.choices[i].label,
          value: meta.fields.when.choices[i].value
        });
      }
      resolve(options);
    })
  );

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-6">
          <ul className="list-group list-group-flush">
            <li className="list-group-item px-0">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-4">
                  <strong><Trans>Name</Trans></strong>
                </div>
                <div className="col-12 col-md-7 col-lg-8 mt-2 mt-md-0">
                  <FieldShowUpdate
                    stateKey={trigger._getStateKey()}
                    pk={trigger.pk}
                    api={trigger._genericApi}
                    field="name"
                    label={t`Name`}>
                  </FieldShowUpdate>
                </div>
              </div>
            </li>
            <li className="list-group-item px-0">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-4">
                  <strong><Trans>Event</Trans></strong>
                </div>
                <div className="col-12 col-md-7 col-lg-8">
                  <DropdownEditable
                    stateKey={trigger._getStateKey()}
                    pk={trigger.pk}
                    api={trigger._genericApi}
                    getOptions={getOpts}
                    variant="light rounded-pill"
                    field="when"
                    fieldName={t`Event`}
                    fieldValue="value"
                    fieldLabel="label"
                    required />
                </div>
              </div>
            </li>
            <li className="list-group-item px-0">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-4">
                </div>
                <div className="col-12 col-md-7 col-lg-8">
                  <CheckboxEditable
                    stateKey={trigger._getStateKey()}
                    pk={trigger.pk}
                    api={trigger._genericApi}
                    field="active"
                    label={meta.fields.active.name} />
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-12 col-lg-6">
          { !trigger.active && (
            <div className="alert alert-warning mb-2" role="alert">
              <h4 className="h5"><Trans>This trigger is inactive</Trans></h4>
            </div>
          ) }
          { trigger.error && (
            <div className="alert alert-danger" role="alert">
              <h4 className="h5"><Trans>An error occured on this trigger</Trans></h4>
              <p>{ trigger.error_message }</p>
            </div>
          ) }
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <h3><Trans>Actions</Trans></h3>
          <TriggerActions trigger={trigger} />
        </div>
        <div className="col-6">
          <h3><Trans>Conditions</Trans></h3>
          <TriggerConditions trigger={trigger} />
        </div>
      </div>
    </>
    
  );
}
export default function TriggerDetailPage() {
  const { triggerPk } = useParams();
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const trigger = useStateItem('triggers:' + triggerPk, true);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    axios.get('/triggers/meta').then(res => {
      setMeta(res.data);
    });
    const tr = new Trigger();
    tr.load(triggerPk);
  }, [triggerPk]);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    <Link key="2" to={`/${organization.slug}/${product.slug}/triggers`}><Trans>Triggers</Trans></Link>,
    trigger ? trigger.name : t`Loading…`
  ];

  return (
    <ProductPage
      name="trigger-list-page"
      title={trigger ? trigger.name : t`Loading…`}
      breadcrumb={breadcrumb}>
      { (trigger && meta) ? (
        <TriggerDetailPageContent trigger={trigger} meta={meta} />
      ) : (
        <LoaderContainer height="3" />
      ) }
      
    </ProductPage>
  );
}
