import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import { ConfirmButton } from 'components/utils';
import OrganizationContext from 'components/utils/OrganizationContext';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { LoaderContainer } from 'components/ui/Loader';
import ProductContext from 'components/utils/ProductContext';
import ProductPage from 'components/page/ProductPage';
import DropdownEditable from 'components/form/DropdownEditable';
import SelectSearchEditable from 'components/form/SelectSearchEditable';
import CheckboxEditable from 'components/form/CheckboxEditable';
import { defaultGitPattern, GitPatternFields } from 'components/utils/gitcheckout';
import cache from 'components/utils/cache';
import { setStateItem } from 'utils/Item';

function ProductPageContent(props) {
  const { product, organization } = props;
  const [meta, setMeta] = useState();
  const history = useHistory();

  useEffect(() => {
    axios.get('/products/meta').then(res => {
      setMeta(res.data);
    }).catch();
  }, []);

  if (!meta) {
    return (
      <LoaderContainer height="3"/>
    );
  }

  const handleDelete = () => {
    axios.delete(`/products/${product.pk}`).then((res) => {
      history.push('/');
    }).catch(err => {

    });
  };

  const handleChangeMode = () => {
    axios.put(`/products/${product.pk}/change-mode`,
      {
        mode: (product.mode.value === 'kanban' ? 'scrum' : 'kanban')
      }
    ).then((res) => {
      product.loadFromObject(res.data);
      setStateItem(product._getStateKey(), product, { isFull: true });
    }).catch(err => {

    });
  };

  const getEvalOpts = () => (
    new Promise(resolve => {
      const options = [];
      for (let i = 0; i < meta.fields.estimate_mode.choices.length; i++) {
        options.push({
          label: meta.fields.estimate_mode.choices[i].label,
          value: meta.fields.estimate_mode.choices[i].value
        });
      }
      resolve(options);
    })
  );

  return (
    <div className="row">
      <div className="col-12 col-lg-6">
        <ul className="list-group list-group-flush">
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <strong><Trans>Name</Trans></strong>
              </div>
              <div className="col-12 col-md-7 col-lg-8 mt-2 mt-md-0">
                <FieldShowUpdate
                  stateKey={product._getStateKey()}
                  pk={product.pk}
                  api="/products"
                  field="name"
                  label={t`Name`}
                  attributes={{ 'data-cy': 'bt-fieldupadte-name' }}>
                </FieldShowUpdate>
              </div>
            </div>
          </li>
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <strong>{meta.fields.prefix.name}</strong>
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                {product.prefix}
              </div>
            </div>
          </li>
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <strong>{meta.fields.mode.name}</strong>
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                {product.mode.label}
                { ' ' }
                <ConfirmButton
                  variant="outline-secondary ms-2"
                  confirmLabel={t`Change mode`}
                  confirmVariant="danger"
                  message={product.mode.value === 'kanban' ? (
                    <Trans>
                      <p>Are you sure you want to switch to <strong>Scrum mode</strong>?</p>
                      <p>Switching into Scrum mode will put all
                        the unclosed items back into the product backlog.</p></Trans>
                  ) : (
                    <Trans>
                      <p>Are you sure you want to switch to <strong>simple project mode</strong>?</p>
                      <p>Switching into simple mode will remove all the sprint and sprint statistics.
                        All backlog items will remain in their status.</p></Trans>
                  )}
                  confirmValue={product.name}
                  confirmValueLabel={t`Please confirm by typing the product name`}
                  onConfirm={handleChangeMode} >
                  { product.mode.value === 'kanban' ? (
                    <Trans>Switch to Scrum mode</Trans>
                  ) : (
                    <Trans>Switch to simple project mode</Trans>
                  )}
                </ConfirmButton>
              </div>
            </div>
          </li>
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <strong>{meta.fields.estimate_mode.name}</strong>
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                <DropdownEditable
                  stateKey={product._getStateKey()}
                  pk={product.pk}
                  api={product._genericApi}
                  getOptions={getEvalOpts}
                  variant="light rounded-pill"
                  field="estimate_mode"
                  fieldName={t`estimate mode`}
                  fieldValue="value"
                  fieldLabel="label"
                  required />
              </div>
            </div>
          </li>
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                <CheckboxEditable
                  stateKey={product._getStateKey()}
                  pk={product.pk}
                  api={product._genericApi}
                  field="team_restricted"
                  label={meta.fields.team_restricted.name} />
              </div>
            </div>
          </li>
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                <CheckboxEditable
                  stateKey={product._getStateKey()}
                  pk={product.pk}
                  api={product._genericApi}
                  field="enable_release"
                  label={meta.fields.enable_release.name} />
              </div>
            </div>
          </li>
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                <CheckboxEditable
                  stateKey={product._getStateKey()}
                  pk={product.pk}
                  api={product._genericApi}
                  field="enable_story_maps"
                  label={meta.fields.enable_story_maps.name} />
              </div>
            </div>
          </li>
          { product.mode.value === 'kanban' && (
            <li className="list-group-item px-0">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-4">
                </div>
                <div className="col-12 col-md-7 col-lg-8">
                  <CheckboxEditable
                    stateKey={product._getStateKey()}
                    pk={product.pk}
                    api={product._genericApi}
                    field="enable_backlog"
                    onUpdate={() => cache.clear('prod-issue-status-' + product.pk)}
                    label={meta.fields.enable_backlog.name}/>
                </div>
              </div>
            </li>
          )}
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <strong><Trans>Definition of done</Trans></strong>
              </div>
              <div className="col-12 col-md-7 col-lg-8 mt-2 mt-md-0">
                <FieldShowUpdate
                  stateKey={product._getStateKey()}
                  type="textarea"
                  pk={product.pk}
                  api={product._genericApi}
                  field="definition_of_done"
                  label={t`Definition of done`}
                  attributes={{ 'data-cy': 'bt-fieldupdate-done' }}>
                  {pr => (
                    !pr.definition_of_done ? (
                      <span className="text-disabled"><em><Trans>Add definition of done</Trans></em></span>
                    ) : (
                      <pre>{product.definition_of_done}</pre>
                    )
                  )}
                </FieldShowUpdate>
              </div>
            </div>
          </li>
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <strong><Trans>Default Account</Trans></strong>
              </div>
              <div className="col-12 col-md-7 col-lg-8">
                <SelectSearchEditable
                  pk={product.pk}
                  api={product._genericApi}
                  stateKey={product._getStateKey()}
                  searchApi={'/accounts?organization=' + organization.pk + '&product=' + product.pk}
                  variant="light rounded-pill"
                  field="default_account"
                  fieldName={t`Default Account`}
                  fieldLabel="resource_name"
                  overlay="left" />
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="col-12 col-lg-6">
        <strong><Trans>Git pattern for checkout</Trans></strong>
        <GitPatternFields entity={product} defaultPattern={organization.git_checkout_pattern || defaultGitPattern} />
        {product.can_delete && (
          <>
            <h5 className="mt-3"><Trans>Product management</Trans></h5>
            <ConfirmButton
              variant="outline-danger"
              confirmLabel={t`Delete Permanently`}
              confirmVariant="danger"
              message={(
                <Trans>
                  <p>Are you sure you want to delete
                    product <strong>{product.name}</strong>?</p>
                  <p>Deleting a product will definitively delete
                    all backlog items and worklogs.</p></Trans>
              )}
              confirmValue={product.name}
              confirmValueLabel={t`Please confirm by typing the product name`}
              onConfirm={handleDelete}>
              <Trans>Delete product</Trans>
            </ConfirmButton>
          </>
        ) }
      </div>
    </div>
  );
}

export default function ProductSettingsPage(props) {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Settings`
  ];

  return (
    <ProductPage
      name="product-settings-page"
      title={t`Settings`}
      breadcrumb={breadcrumb}>
      <ProductPageContent
        organization={organization}
        product={product} />
    </ProductPage>
  );

}
