import React, { useContext, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { FormCheck, FormSelect } from 'react-bootstrap';
import axios from 'axios';
import { LoaderContainer } from 'components/ui/Loader';
import BaseField from 'components/form/BaseField';
import SelectSearch from 'components/ui/SelectSearch';
import { modelsApi } from 'components/utils/constants';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import { manageFormErrors } from 'components/form/Form';

function TriggerConditionFinalForm(props) {
  const { meta, triggerCondition, onSave, onCancel, trigger } = props;
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const item = {};
  if (triggerCondition) {
    item.action = triggerCondition.action.value;
    if (item.action && (item.action in meta.fields.value.actions)) {
      const actionMeta = meta.fields.value.actions[item.action];
      if (actionMeta.use_value) {
        if ('options' in actionMeta && actionMeta.options.length > 0) {
          item.value_select = triggerCondition.value.type === 'option' ? triggerCondition.value.value : 'model';
        }
        item.value = triggerCondition.value ? triggerCondition.value.value : null;
      }
    }
  }
  const [state, setState] = useState({ field: null, value_select: null, value: null, ...item });
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);

  // eslint-disable-next-line arrow-body-style
  const handleChange = (field, activeField) => {
    return e => {
      setState(old => {
        old[field] = ('target' in e) ? e.target.value : e;
        if (typeof old[field] === 'object' && old[field] !== null && activeField) {
          old[field].activeField = activeField;
        }
        if (field === 'field') {
          old.value = null;
          if (!meta.fields.operator.fields[old.field].operators.includes(old.operator)) {
            // eslint-disable-next-line prefer-destructuring
            old.operator = meta.fields.operator.fields[old.field].operators[0];
          }
        }
        return { ...old };
      });
    };
  };

  const operatorDefault = state.operator;
  let operatorChoices = [];
  if (state.field) {
    operatorChoices = meta.fields.operator.choices.filter(c => meta.fields.operator.fields[state.field].operators.includes(c.value));
  }

  const defaultValue = state.value;
  let needValue = false;
  let valueField = null;
  if (state.field && operatorDefault && (operatorDefault in meta.fields.value.operators)) {
    needValue = meta.fields.value.operators[operatorDefault].need_value;
    const operatorMeta = meta.fields.value.fields[state.field];
    if (needValue && operatorMeta.type === 'ModelObject') {
      const [parent, model] = operatorMeta.model.split('.');
      if (model in modelsApi) {
        let { api } = modelsApi[model];
        if (parent === 'organizations') {
          api += `?organization=${organization.pk}`;
        }
        else if (parent === 'products') {
          api += `?product=${product.pk}`;
        }
        
        valueField = (
          <BaseField
            key={'value-' + operatorMeta.model}
            label={modelsApi[model].name}
            name="value"
            className="form-group"
            humanValue={defaultValue ? defaultValue.resource_name : ''}
            fieldErrors={errors}
            id="value">
            <SelectSearch
              value={defaultValue}
              handleSelect={handleChange('value', state.field)}
              className={errors.value ? 'is-invalid' : ''}
              searchApi={api}
              isInvalid={!!errors.value}
              isValid={defaultValue !== null}/>
          </BaseField>
        );
      }
      
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const condition = {
      trigger: { pk: trigger.pk },
      field: state.field,
      operator: state.operator
    };
    if (needValue) {
      condition.value = state.value;
    }
    if (triggerCondition) {
      condition.pk = triggerCondition.pk;
      axios.put('trigger-conditions/' + triggerCondition.pk, condition).then(res => {
        setIsLoading(false);
        onSave(res.data);
      }).catch(err => {
        const { globalErrors, fieldsErrors } = manageFormErrors(['field', 'operator', 'value'], err);
        setErrors(fieldsErrors);
        if (globalErrors.length) setError(globalErrors.map(er => er.error).join(' '));
        setIsLoading(false);
      });
    }
    else {
      axios.post('trigger-conditions', condition).then(res => {
        setIsLoading(false);
        onSave(res.data);
      }).catch(err => {
        const { globalErrors, fieldsErrors } = manageFormErrors(['field', 'operator', 'value'], err);
        setErrors(fieldsErrors);
        if (globalErrors.length) setError(globalErrors.map(er => er.error).join(' '));
        setIsLoading(false);
      });
    }
  };

  return (
    <form
      className="relative"
      method="POST"
      id="trigger-conditions-form"
      noValidate
      onSubmit={onSubmit}>
      { isLoading && (
        <LoaderContainer />
      ) }
      { error && (
        <div className="alert alert-danger mb-2" role="alert">
          { error }
        </div>
      ) }
      <BaseField
        className="form-group"
        label={meta.fields.field.name}
        fieldErrors={errors}
        name="action"
        id="action">
        <FormSelect
          value={state.field || ''}
          onChange={handleChange('field')}
          isInvalid={!!errors.field}
          isValid={state.field !== null}
          as="select"
          id="field"
          required>
          { !state.field && (
            <option>- { t`Please choose a value`} -</option>
          ) }
          {meta && meta.fields.field.choices.map((it) => (
            <option key={it.value} value={it.value}>{it.label}</option>
          ))}
        </FormSelect>
      </BaseField>
      { operatorChoices.length > 0 && (
        <BaseField
          className="form-group"
          label={meta.fields.operator.name}
          fieldErrors={errors}
          name="operator"
          id="operator"
          type="checkbox">
          <div className="form-label">
            {meta.fields.operator.name}
          </div>
          {operatorChoices.map((it) => (
            <FormCheck
              inline
              label={it.label}
              name="operator"
              type="radio"
              id={'operator-' + it.value}
              checked={it.value === operatorDefault}
              onChange={handleChange('operator')}
              value={it.value}
              key={it.value}
            />
          ))}
        </BaseField>
      ) }
      { valueField }
      <div className="actions">
        <button type="submit" className="btn btn-primary">{ triggerCondition ? t`Save` : t`Add condition` }</button>
        <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
      </div>
    </form>
  );
}

const TriggerConditionForm = function(props) {
  const { onSave, onCancel, trigger, triggerCondition } = props;
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    axios.get('/trigger-conditions/meta').then(res => {
      setMeta(res.data);
    });
  }, []);

  if (!trigger) return null;
  if (!meta) {
    return (
      <LoaderContainer height="3" />
    );
  }
  return (
    <div className="card mt-2">
      <div className="card-body">
        <TriggerConditionFinalForm
          meta={meta}
          trigger={trigger}
          isEdit={triggerCondition && !!triggerCondition.pk}
          onSave={onSave}
          onCancel={onCancel}
          triggerAction={triggerCondition} />
      </div>
    </div>
  );
};

export default TriggerConditionForm;
