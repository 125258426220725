import React, { useContext, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Card } from 'react-bootstrap';
import axios from 'axios';
import Icon from 'components/ui/Icon';
import BacklogModalContext from 'components/modal/BacklogModalContext';
import { addMessage } from 'components/ui/Messages';
import { useStateItem } from 'utils/Item';
import BacklogItem from 'entity/BacklogItem';

function BacklogItemSubtasks(props) {
  const { backlogItem } = props;
  if (!backlogItem || backlogItem.subtasks_count < 1) {
    return null;
  }

  let subtasksCount = '';
  const subtaskProps = {};

  if (backlogItem.subtasks_open_count !== backlogItem.subtasks_count) {
    subtasksCount = backlogItem.subtasks_open_count + ' / ' + backlogItem.subtasks_count;
    subtaskProps.title = t`Todo` + ' / ' + t`Done`;
  }
  else {
    subtasksCount = backlogItem.subtasks_count;
  }

  return (
    <span className="parent-ref badge bg-white text-dark" {...subtaskProps}>
      <Icon name="diagram-3-fill"/>
      <Trans>
        {subtasksCount} subtasks
      </Trans>
    </span>
  );
}
function Evals(props) {
  const { backlogItem, product, layout } = props;
  const [, setRefresh] = useState(0);

  const onAskEstimate = () => {
    axios.put(`${backlogItem._genericApi}/${backlogItem.pk}`, { need_estimate: !backlogItem.need_estimate })
      .then(response => {
        backlogItem.need_estimate = response.data.need_estimate;
        setRefresh(v => v + 1);
      })
      .catch((error) => {
        addMessage('update-need-eval', t`Unknown error`, t`Impossible to request an estimate for this backlog item.`);
      });
  };

  return (
    <>
      { layout === 'backlog' && !backlogItem.isFullyEstimated(product.estimate_mode.value) && product.can_see_estimates && (
        <>
          <button type="button" className="btn btn-simplelink" onClick={onAskEstimate}>
            { backlogItem.need_estimate ? t`Estimate asked` : t`Ask estimate` }
          </button>{' '}
        </>
      ) }
      { backlogItem.business_value && (
        <span className="business-value badge bg-white text-dark align-middle rounded-pill val" title={t`Business Value`}>
          { backlogItem.business_value }
        </span>
      ) }
      { (product.can_see_worklogs && backlogItem.total_work && backlogItem.total_work.human_format) ? (
        <span className="work val">
          <span title={t`Total Work`}>{ backlogItem.total_work.human_format }</span>
          {' '}/{' '}
          { product.can_see_estimates && (
            <span title={t`Estimate`}>
              { backlogItem.hasEstimate(product.estimate_mode.value) ? backlogItem.getEstimate(product.estimate_mode.value) : '-' }
            </span>
          )}
        </span>
      ) : (product.can_see_estimates && backlogItem.hasEstimate(product.estimate_mode.value) && (
        <span className="estimate val" title={t`Estimate`}>
          { backlogItem.getEstimate(product.estimate_mode.value) }
        </span>
      )) }

    </>
  );
}
function BacklogItemTeaser(props) {
  const { state } = props;
  if (state) {
    return <BacklogItemTeaserState {...props}/>;
  }
  return <BacklogItemTeaserContent {...props}/>;
}
BacklogItemTeaser.defaultProps = {
  state: true,
  layout: 'normal',
  showEval: true
};

function BacklogItemTeaserState(props) {
  const { backlogItem } = props;
  const item = useStateItem('backlog-items:' + backlogItem.pk);
  if (!item) return null;
  if (!(item instanceof BacklogItem)) {
    const it = new BacklogItem();
    it.loadFromObject(item);
    return <BacklogItemTeaserContent {...{ ...props, backlogItem: it }}/>;
  }
  return <BacklogItemTeaserContent {...{ ...props, backlogItem: item }}/>;
}

function BacklogItemTeaserContent(props) {
  const { backlogItem, showStatus, layout, product, hideMore, showEval } = props;
  const backlogModalContext = useContext(BacklogModalContext);
  const showModal = backlogModalContext ? backlogModalContext.showModal : null;

  if (layout === 'subtask') {
    return <BacklogItemTeaserSubtask {...props}/>;
  }

  return (
    <Card.Body className={'item-type-' + (backlogItem.item_type ? backlogItem.item_type.color : 'none')}>
      { backlogItem.item_type && (
        <div className={'item-type bg-col-' + (backlogItem.item_type.color ? backlogItem.item_type.color : 'none')}>
          <span>{ backlogItem.item_type.resource_name }</span>
        </div>
      ) }
      <div className="title">
        <h4 className="h6">
          <span className="ref">
            { backlogItem.reference }
            { backlogItem.parent && (
              <span className="parent-ref">(&nbsp;{ backlogItem.parent.resource_reference }&nbsp;)</span>
            ) }
            <BacklogItemSubtasks backlogItem={backlogItem}/>
          </span>
          <span className="h-title">{ backlogItem.title }</span>
        </h4>
        {(!hideMore && showModal) && (
          <button
            type="button"
            className="btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => { showModal(backlogItem); }} >
            <Icon name="plus" title={t`Open`}/>
          </button>
        ) }
      </div>
      <div>
        { backlogItem.epic && (
          <span className={`badge bg-col-${backlogItem.epic.color} epic me-2`}>{ backlogItem.epic.resource_name }</span>
        )}
        { backlogItem.assignee && layout === 'normal' && (
          <span className="badge border text-dark assignee me-2">{ backlogItem.assignee.resource_name }</span>
        )}
        { backlogItem.priority.value !== 'undefined' && (
          <span className={`badge border badge-priority-${backlogItem.priority.value} priority me-2`}>
            { backlogItem.priority.label }
          </span>
        )}
        { showStatus && (
          <span className="badge border text-dark status">{ backlogItem.status.resource_name }</span>
        )}
      </div>
      { layout !== 'link' && showEval && (
        <div className="text-end values">
          <Evals layout={layout} backlogItem={backlogItem} product={product}/>
        </div>
      ) }
      { (hideMore || !showModal) && (
        <div className="org-prod text-end">
          { backlogItem.product.organization.resource_slug } / { backlogItem.product.resource_slug }
        </div>
      ) }
    </Card.Body>
  );
}

function BacklogItemTeaserSubtask(props) {
  const { backlogItem, showStatus, hideMore, product, showEval, parent, isModal } = props;
  const backlogModalContext = useContext(BacklogModalContext);
  const showModal = backlogModalContext ? backlogModalContext.showModal : null;

  return (
    <Card.Body className={'item-type-' + (backlogItem.item_type ? backlogItem.item_type.color : 'none')}>
      { backlogItem.item_type && (
        <div className={'item-type bg-col-' + (backlogItem.item_type.color ? backlogItem.item_type.color : 'none')}>
          <span>{ backlogItem.item_type.resource_name }</span>
        </div>
      ) }
      <div className="backlog-item--infos d-flex align-items-start">
        <div className="title">
          <h4 className="h6">
            <span className="ref">
              { backlogItem.reference }
              { backlogItem.parent && (
                <span className="parent-ref">(&nbsp;{ backlogItem.parent.resource_reference }&nbsp;)</span>
              ) }
              <BacklogItemSubtasks backlogItem={backlogItem}/>
            </span>
          </h4>
        </div>
        <div className="flex-grow-1">
          { backlogItem.epic && (
            <span className={`badge bg-col-${backlogItem.epic.color} epic me-2`}>{ backlogItem.epic.resource_name }</span>
          )}
          { backlogItem.assignee && (
            <span className="badge border text-dark assignee me-2">{ backlogItem.assignee.resource_name }</span>
          )}
          { backlogItem.priority.value !== 'undefined' && (
            <span className={`badge border badge-priority-${backlogItem.priority.value} priority me-2`}>
              { backlogItem.priority.label }
            </span>
          )}
          { showStatus && (
            <span className="badge border text-dark status">{ backlogItem.status.resource_name }</span>
          )}
        </div>
        {(!hideMore && showModal) && (
          <button
            type="button"
            className="bt-more btn btn-sm btn-outline-secondary ms-auto"
            onClick={() => { showModal(backlogItem, { parent: parent, isModal: isModal }); }} >
            <Icon name="plus" title={t`Open`}/>
          </button>
        ) }
      </div>
      { showEval ? (
        <div className="backlog-item--evals d-flex">
          <span className="h-title flex-grow-1 pe-2">{ backlogItem.title }</span>
          <div className="values">
            <Evals backlogItem={backlogItem} product={product}/>
          </div>
        </div>
      ) : (
        <span className="h-title">{ backlogItem.title }</span>
      ) }

    </Card.Body>
  );
}

export default BacklogItemTeaser;
