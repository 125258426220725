import React, { useContext } from 'react';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { useStateItem } from 'utils/Item';
import StoryMapContext from './StoryMapContext';

export function StoryMapTableHeaderActivities(props) {
  const { activities, ...rest } = props;
  const { goal } = props;
  return (
    <Droppable droppableId={'g-' + goal.pk} direction="horizontal" type="activities">
      {(provided, snapshot) => (
        <div
          className={'story-map-activities droppable-container' + (snapshot.isDraggingOver ? ' is-drag-over' : '')}
          ref={provided.innerRef}>
          { activities.map((a, index2) => (
            <Draggable
              key={a}
              draggableId={'a-' + String(a)}
              index={index2}>
              {(provided2, snapshot2) => (
                <div
                  ref={provided2.innerRef}
                  className="story-map-activity"
                  data-colid={'a-' + a}
                  key={'a-' + a}
                  {...provided2.draggableProps}>
                  <StoryMapActivity
                    dragHandleProps={provided2.dragHandleProps}
                    isDragging={snapshot2.isDragging}
                    activityPk={a}
                    {...rest}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

function EditActivityDropdown(props) {
  // eslint-disable-next-line no-unused-vars
  const { activity, product, persona } = props;
  const { loadActivities, loadTasks } = useContext(StoryMapContext);

  const onHide = () => {
    activity.hidden = !activity.hidden;
    axios.put('/activities/' + activity.pk, activity).then(() => {
      loadActivities();
    }).catch(err => {
      addMessage('edit-activity', t`Unknown error`, t`Impossible to edit activity`);
    });
  };

  const onRemoved = () => {
    axios.delete('/activities/' + activity.pk).then(() => {
      loadActivities();
      loadTasks();
    }).catch(err => {
      addMessage('delete-activity', t`Unknown error`, t`Impossible to delete activity`);
    });
  };

  // const onSaveActivity = (item) => {
  //   loadActivities();
  //   loadTasks();
  // };

  return (
    <Dropdown className="no-carret">
      <Dropdown.Toggle variant="sm">
        <Icon name="three-dots" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={onHide}>
          { activity.hidden ? t`Show activity` : t`Hide activity`}
        </Dropdown.Item>
        <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={onRemoved}>
          <Trans>Remove activity</Trans>
        </Dropdown.Item>
        {/* <Dropdown.Item
          as={StoryMapItemModal}
          api="activities"
          product={product}
          organization={product.organization}
          onSave={onSaveActivity}
          modalTitle={activity.name}
          title={<><Icon name="pencil"/> <Trans>Edit</Trans></>}
          parent="goal"
          parentApi="goals"
          item={activity}
          parentContext={'persona=' + persona.pk}
          variant="outline-secondary dropdown-item" /> */}
      </Dropdown.Menu>
    </Dropdown>
  );
}

function StoryMapActivity(props) {
  const { activityPk, product, persona, dragHandleProps, isDragging } = props;
  const a = useStateItem('activities:' + activityPk);
  if (!a) return null;
  return (
    <div
      className={'story-map-card story-map-card--activity' + (a.hidden ? ' hidden' : '') + (isDragging ? ' is-draging' : '')}
      {...dragHandleProps}>
      <div className="story-map-card__header bg-col-red">
        <Icon size="18" name="arrow-down" />
        {' '}
        <span><Trans>Activity</Trans></span>
        { product.can_edit_item && (
          <EditActivityDropdown
            activity={a}
            persona={persona}
            product={product} />
        ) }
      </div>
      <div className="story-map-card__body">
        <StoryMapActivity activity={a} product={product} />
        { product.can_edit_item ? (
          <FieldShowUpdate
            element={a}
            pk={a.pk}
            api="activities"
            field="name"
            label={t`Name`}>
            {it => (
              <span className="name">{ it.resource_name }</span>
            )}
          </FieldShowUpdate>
        ) : a.name }
      </div>

    </div>
  );
}

export { EditActivityDropdown };
export default StoryMapActivity;
