import React, { useContext } from 'react';
import CKWrapper from 'components/richcontent/CKWrapper';
import { FormContext } from './FormContext';
import BaseField from './BaseField';

export default function RichContent({ children, ...props }) {
  const { item, setItem, meta } = useContext(FormContext);
  const { label, mention, product } = props;

  const handleChange = (event, editor) => {
    item[props.name] = editor.getData();
    setItem(item);
  };

  const groupClasses = ['form-group'];
  if (props.required) groupClasses.push('required');

  return (
    <BaseField
      {...props}
      label={label || meta && meta.fields && meta.fields[props.name] && meta.fields[props.name].name}
      className={groupClasses.join(' ')}>
      <CKWrapper
        data={item[props.name] || ''}
        mention={mention}
        onChange={handleChange}
        product={product} />
    </BaseField>
  );
}
