import React, { useContext, useMemo, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link, NavLink } from 'react-router-dom';
import Datatable from 'components/datatable/Datatable';
import ProductPage from 'components/page/ProductPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import DateFormat from 'components/ui/DateFormat';
import Icon from 'components/ui/Icon';
import ReleaseModal from 'components/modal/ReleaseModal';
import ReleaseNoteModal from 'components/modal/ReleaseNoteModal';

function ReleasesPageContent(props) {
  const { product, organization } = props;
  const [refresh, setRefresh] = useState(0);
  const [editItem, setEditItem] = useState(0);

  const apiContext = useMemo(
    () => ({ product: product.pk, organization: organization.pk }),
    [product.pk, organization.pk]
  );
  const onEdit = (item) => {
    setEditItem(item);
  };
  const onEditClose = () => {
    setEditItem(null);
  };
  const onSaveClose = () => {
    setEditItem(null);
    setRefresh(oldVal => oldVal + 1);
  };
  const baseUrl = `/${organization.slug}/${product.slug}/items`;

  return (
    <>
      { product.can_add_release && (
        <div className="mb-3">
          <ReleaseModal
            variant="primary"
            product={product}
            onSave={() => setRefresh(oldVal => oldVal + 1)}
            title={(<><Icon name="plus-circle"/> <Trans>Add release</Trans></>)} />
          <ReleaseModal
            product={product}
            onClose={onEditClose}
            onSave={onSaveClose}
            show={!!editItem}
            release={editItem}
            modalTitle={t`Edit release`}
            hideButton />
        </div>
      ) }
      <Datatable
        api="/releases"
        editHandler={product.can_add_release && onEdit}
        canDelete={product.can_add_release}
        refresh={refresh}
        apiContext={apiContext}>
        <Datatable.Column name="name" label={t`Name`}/>
        <Datatable.Column name="links" label={t`Links`}>
          {(item) => (
            <NavLink className="text-small d-block" to={baseUrl + '?fix_versions=' + item.pk}><Trans>View fixed items</Trans></NavLink>
          )}
        </Datatable.Column>
        <Datatable.Column name="release-notes" label={t`Notes`}>
          {(item) => (
            <ReleaseNoteModal release={item} />
          )}
        </Datatable.Column>
        <Datatable.Column name="start_at" label={t`Start at`}>
          {(item) => <DateFormat datestring={item.start_at}/>}
        </Datatable.Column>
        <Datatable.Column name="released_at" label={t`Released at`}>
          {(item) => <DateFormat datestring={item.released_at}/>}
        </Datatable.Column>
        <Datatable.Column name="released" label={t`Released`}>
          {(item) => (item.released ? <Icon name="check-square"/> : <span className="text-disabled"><Icon name="square"/></span>) }
        </Datatable.Column>
        <Datatable.Column name="archived" label={t`Archived`}>
          {(item) => (item.archived ? <Icon name="check-square"/> : <span className="text-disabled"><Icon name="square"/></span>) }
        </Datatable.Column>
      </Datatable>
    </>
  );
}

export default function ReleasesPage() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Releases`
  ];

  return (
    <ProductPage
      name="releases-list-page"
      title={t`Releases`}
      breadcrumb={breadcrumb}>
      <ReleasesPageContent
        organization={organization}
        product={product} />
    </ProductPage>
  );
}
