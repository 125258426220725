import React from 'react';
import { Button } from 'react-bootstrap';
import { Trans, t } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';

function WorklogFormFinal(props) {
  const { onCancel, onSave, product, isEdit } = props;

  return (
    <Form
      name="backlogitem-add-form"
      noValidate
      onSave={onSave}>
      <div className="d-flex">
        <div className="me-3">
          <Form.Field type="date" id="date" name="date" required />
          <Form.Many2One
            id="account"
            name="account"
            api={`/accounts?organization=${product.organization.pk}&product=${product.pk}&state=active`}
            dMenu="w-260"
            required />
        </div>
        <div className="me-3">
          <Form.Field id="work" name="work" placeholder={t`1h 30m or 1:30`} required />
          <Form.Field id="comment" name="comment" />
        </div>
        <div className={isEdit ? '' : 'align-self-end'}>
          { isEdit && (
            <Form.Many2One
              groupClassName="mb-5"
              className="fixed-width w-4"
              id="item"
              name="item"
              api={`/backlog-items?organization=${product.organization.pk}`}
              dMenu="w-260"
              required />
          ) }
          <div className="actions mb-3">
            <Button variant="primary" type="submit"><Trans>Save</Trans></Button>
            <Button variant="outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></Button>
          </div>
        </div>
      </div>
    </Form>
  );
}

const WorklogForm = function(props) {
  const { worklog, backlogItem, product, ...rest } = props;
  let isEdit = true;
  if (!worklog.pk) {
    worklog.account = backlogItem.default_account || product.default_account;
    worklog.worker = product.member.pk;
    worklog.item = backlogItem.pk;
    isEdit = false;
  }
  return (
    <FormContextProvider item={worklog} api="/worklogs">
      <WorklogFormFinal {...rest} product={product} isEdit={isEdit} />
    </FormContextProvider>
  );
};
WorklogForm.defaultProps = {
  worklog: {
    date: new Date().toISOString().split('T')[0]
  }
};

export default WorklogForm;
