import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import Datatable from 'components/datatable/Datatable';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductPage from 'components/page/ProductPage';
import ProductContext from 'components/utils/ProductContext';
import ItemTypeModal from 'components/modal/ItemTypeModal';
import { ColorStickerName } from 'components/ui/ColorSticker';
import Icon from 'components/ui/Icon';

const apiContext = { product: null };

export default function ItemTypesList() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const [refresh, setRefresh] = useState(0);
  const [editItem, setEditItem] = useState(null);

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Item types`
  ];

  const onRefresh = () => {
    setRefresh(refresh + 1);
  };

  const onEditClose = () => {
    setEditItem(null);
  };
  const onSave = () => {
    setRefresh(refresh + 1);
    setEditItem(null);
  };
  const onEdit = item => {
    setEditItem(item);
  };
  apiContext.product = product.pk;

  return (
    <ProductPage
      name="item-types-list-page"
      title={t`Item types`}
      breadcrumb={breadcrumb}>
      { product.can_add_item_type && (
        <ItemTypeModal
          variant="primary"
          onSave={onRefresh}
          product={product}
          title={(<><Icon name="plus-circle"/> <Trans>Add type</Trans></>)}
          key="item-type-add-modal" />
      )}
      
      <ItemTypeModal
        itemType={editItem}
        product={product}
        onClose={onEditClose}
        onSave={onSave}
        show={!!editItem}
        modalTitle={t`Edit account`}
        hideButton />
      <Datatable
        api="/item-types"
        apiContext={apiContext}
        editHandler={product.can_add_item_type && onEdit}
        canDelete={product.can_add_item_type}
        search={false}
        refresh={refresh}>
        <Datatable.Column name="name" label={t`Name`}/>
        <Datatable.Column name="color" label={t`Color`}>
          {(item) => <ColorStickerName color={item.color}/>}
        </Datatable.Column>
        <Datatable.Column name="git_checkout_prefix" label={t`Git prefix`}/>
      </Datatable>
    </ProductPage>
  );
}
