/* eslint-disable no-new,react/no-unescaped-entities,prefer-destructuring */

import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { Alert, Card, Dropdown } from 'react-bootstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LoaderContainer } from 'components/ui/Loader';
import getBacklogItems from 'entity/BacklogItems';
import { addMessage } from 'components/ui/Messages';
import Icon from 'components/ui/Icon';
import { statuslistKanban } from 'components/utils/constants';
import AppContext from 'AppContext';
import getSensor from 'mocks/cypressSensor';
import { getStateItem, setStateItem } from 'utils/Item';
import BulkItemsModal from 'components/modal/BulkItemsModal';
import BulkExportModal from 'components/modal/BulkExportModal';
import BulkPrintModal from 'components/modal/BulkPrintModal';
import { SelectedActions } from 'components/datatable/DatatableFilters';
import PersistentSocket from 'components/utils/PersistentSocket';
import BacklogItemTeaser from '../BacklogItemTeaser';

function sortBacklogItems(items, columns, prevState) {
  const ret = [];
  let total = 0;
  React.Children.forEach(columns, col => {
    const colData = {
      title: col.props.children,
      id: col.props.id,
      props: col.props,
      hide: 'hideCol' in col.props ? col.props.hideCol : false,
      items: items
        .filter(item => {
          let filter = true;
          if ('status' in col.props) {
            filter = filter && item.status.pk === col.props.status.pk;
          }
          if ('sprint' in col.props) {
            filter = filter &&
              (item.sprint ? item.sprint.pk === col.props.sprint : col.props.sprint === null);
          }
          return filter;
        })
        .map((item, index) => {
          item.weight = index;
          return item;
        })
    };
    total += colData.items.length;
    ret.push(colData);
  });
  const newState = { total, columns: ret };
  if (prevState.activeCol === null) {
    newState.activeCol = ret[0].id;
  }
  return newState;
}

class Kanban extends React.Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    this.state = {
      error: null,
      columns: null,
      columnUpdate: 0,
      columnWillUpdate: 0,
      activeCol: null,
      total: null,
      isDragging: null,
      showEval: 'show_eval' in this.props.filters ? this.props.filters.show_eval : true,
      getTitle: 'getTitle' in props ? props.getTitle : (col) => <h2>{ col.title }</h2>,
      socketReady: false,
      socketDisconnected: false
    };
  }

  load(firstLoad) {
    const params = ('params' in this.props.itemsOptions) && this.props.itemsOptions.params ? this.props.itemsOptions.params.split('&') : [];
    for (const name in this.props.filters) {
      for (let i = 0; i < this.props.filters[name].length; i++) {
        params.push(name + '=' + this.props.filters[name][i]);
      }
    }
    if (!firstLoad) this.setState(prevState => ({ columnWillUpdate: prevState.columnWillUpdate + 1 }));
    getBacklogItems(this.props.product.pk, {
      url: '/backlog-items/kanban',
      ...this.props.itemsOptions,
      params: params.map(it => it.split('=')) })
      .then((result) => {
        if (this._isMounted) {
          this.setState((prevState) => {
            const newState = sortBacklogItems(result, this.props.children, prevState);
            if (!firstLoad) newState.columnUpdate = prevState.columnUpdate + 1;
            return newState;
          });
        }
      })
      .catch((err) => { if (this._isMounted) this.setState({ error: true });
      });
  }

  connectWebSocket() {
    this.kanbanSocket = new PersistentSocket(
      `${process.env.REACT_APP_WS_URL}/ws/products/${this.props.product.pk}/kanban`,
      {
        onopen: () => {
          this.setState({
            socketReady: true,
            socketDisconnected: false
          });
        },
        onfail: () => {
          if (this._isMounted) {
            this.setState({
              socketReady: true,
              socketDisconnected: true
            });
          }
        },
        onmessage: e => {
          if (e.data) {
            const data = JSON.parse(e.data);
            data.message.items.forEach((item, i) => {
              if (getStateItem('backlog-items:' + item.pk)) {
                setStateItem('backlog-items:' + item.pk, item);
              }
            });
            if (data.message.items_order_changed.length > 0 || data.message.sprint_status_changed.length > 0) {
              this.load();
            }
          }
        },
        onwakeup: () => {
          this.load();
        }
      }
    );
  }

  componentDidMount() {
    document.documentElement.classList.add('kanban-page');
    this._isMounted = true;
    this.load(true);
    this.connectWebSocket();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.refresh !== prevProps.refresh || this.props.filters !== prevProps.filters) {
      this.load();
    }
    if (this.state.columnWillUpdate !== prevState.columnWillUpdate && ('onWillUpdate' in this.props)) {
      this.props.onWillUpdate(this);
    }
    if (this.state.columnUpdate !== prevState.columnUpdate && this.state.columns && ('onUpdate' in this.props)) {
      this.props.onUpdate(this);
    }
    if (this.props.getTitle !== prevProps.getTitle) {
      if (this._isMounted) this.setState({ getTitle: this.props.getTitle });
    }
    if ('show_eval' in this.props.filters && this.props.filters.show_eval[0] !== this.state.showEval) {
      this.setState({ showEval: this.props.filters.show_eval[0] });
    }
  }

  componentWillUnmount() {
    document.documentElement.classList.remove('kanban-page');
    this._isMounted = false;
    this.kanbanSocket.close();
  }

  onDragUpdate = (result) => {
    const { destination } = result;
    const colIds = this.state.columns.map(c => c.id);
    if (destination && destination.droppableId && colIds.indexOf(destination.droppableId) !== -1) {
      this.setState({ isDragging: destination.droppableId });
    }
  };

  onDragStart = (result) => {
    const { source } = result;
    this.setState({ isDragging: source.droppableId });
  };

  onDragend = (result) => {
    const { source, destination, draggableId } = result;
    this.setState({ isDragging: null });
    if (!destination) {
      return;
    }
    const pk = parseInt(draggableId, 10);
    const sourceColumnIndex = this.state.columns.findIndex(
      col => col.id === source.droppableId
    );
    let isTopBottom = false;
    let destDroppableId;
    let action = null;
    if (destination.droppableId.substr(0, 4) === 'top-') {
      destDroppableId = destination.droppableId.substr(4);
      isTopBottom = true;
      action = 'top';
    }
    else if (destination.droppableId.substr(0, 4) === 'bot-') {
      destDroppableId = destination.droppableId.substr(4);
      isTopBottom = true;
      action = 'bottom';
    }
    else {
      destDroppableId = destination.droppableId;
    }
    const destinationColumnIndex = this.state.columns.findIndex(
      col => col.id === destDroppableId
    );
    const sourceColumn = this.state.columns[sourceColumnIndex];
    const destColumn = this.state.columns[destinationColumnIndex];
    const item = sourceColumn.items.find(i => i.pk === pk);

    let status;
    let target = null;
    let sprint;
    let assign_me;
    if (action === null) {
      action = 'bottom';
    }
    if (source.droppableId !== destDroppableId) {
      if ('status' in destColumn.props) {
        status = destColumn.props.status.pk;
        assign_me = destColumn.props.status.auto_assign;
        if (assign_me) {
          item.assignee = {
            resource_name: this.context.user.resource_name
          };
        }
      }
      if ('sprint' in destColumn.props) {
        sprint = destColumn.props.sprint;
      }
    }
    const destColumnItems = destColumn.items.filter(i => i.pk !== pk);
    if (!isTopBottom && destination.index < destColumnItems.length) {
      action = 'above';
      target = destColumnItems[destination.index].pk;
    }
    item.move(status, action, target, sprint, assign_me).catch(err => {
      addMessage('backlog-move-' + item.pk,
        t`Impossible to change the order of the backlog items`,
        t`Reloading the kanban`);
      this.setState({ columns: null }, () => {
        this.load();
      });
    });
    if (source.droppableId === destDroppableId) {
      const copiedItems = [...sourceColumn.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(isTopBottom ? (
        action === 'top' ? 0 : copiedItems.length
      ) : destination.index, 0, removed);
      this.setState((prevState) => {
        prevState.columns[sourceColumnIndex].items = copiedItems;
        return { columns: [...prevState.columns] };
      });
    } else {
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(isTopBottom ? (
        action === 'top' ? 0 : destItems.length
      ) : destination.index, 0, removed);
      this.setState((prevState) => {
        prevState.columns[sourceColumnIndex].items = sourceItems;
        prevState.columns[destinationColumnIndex].items = destItems;
        return { columns: [...prevState.columns] };
      });
    }
  };

  columnParams = (col) => {
    const params = new URLSearchParams(this.props.itemsOptions.params);
    if ('status' in col.props) {
      params.set('status', col.props.status.pk);
    }
    if ('sprint' in col.props) {
      params.set('sprint', col.props.sprint || 'null');
    }
    return new URLSearchParams([...new URLSearchParams(this.props.filters), ...params]);
  };

  render() {
    if (this.state.error) {
      return (
        <Alert>
          <p><Trans>Impossible to load backlog items</Trans></p>
        </Alert>
      );
    }
    if (this.state.columns && this.state.total < 1) {
      return (
        <Card className="Products-list">
          <Card.Body>
            { this.props.filters ? (
              <p className="text-center mt-3"><Trans>There are no backlog items matching your search criteria</Trans></p>
            ) : (
              <p className="text-center mt-3"><Trans>You don't have any backlog items</Trans></p>
            ) }
          </Card.Body>
        </Card>
      );
    }
    if (this.state.columns === null || this.state.socketReady === false) {
      return (
        <LoaderContainer height="3"/>
      );
    }
    const sensors = [];
    if (this.props.product.can_edit_item && process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
      sensors.push(getSensor('.kanban'));
    }

    const selectedComps = [];
    if (this.props.product.can_edit_item) {
      selectedComps.push(BulkItemsModal);
    }
    if (this.props.product.can_export_items) {
      selectedComps.push(BulkExportModal);
    }
    if (this.props.product.can_print_items) {
      selectedComps.push(BulkPrintModal);
    }
    selectedComps.push(({ params }) => (
      <Link
        className="btn btn-outline-dark dropdown-item"
        to={`/${this.props.product.organization.resource_slug}/${this.props.product.resource_slug}/items?${params}`}>
        <Trans>Items list</Trans>
      </Link>
    ));
    return (
      <div className={'kanban' + (this.props.responsive ? '' : ' no-responsive')}>
        { this.state.socketDisconnected && (
          <div className="alert alert-warning">
            <Trans>Kanban updates are no longer active.</Trans>
            <button
              type="button"
              className="ms-2 btn btn-outline-dark"
              onClick={() => {
                this.connectWebSocket();
                this.load();
              }}>
              <Trans>Reconnect</Trans>
            </button>
          </div>
        ) }
        { this.props.responsive && this.state.columns.length > 1 && this.state.columns.length < 4 && (
          <div className="btn-group btn-group-status d-md-none" role="group">
            {this.state.columns.map(col => (
              <button
                key={col.id}
                type="button"
                className={'btn btn-outline-primary' + (this.state.activeCol === col.id ? ' active' : '')}
                onClick={() => this.setState({ activeCol: col.id })}>
                { col.title }
              </button>
            ))}
          </div>
        ) }
        { this.props.responsive && this.state.columns.length > 3 && (
          <div className="btn-group-status d-md-none">
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary">
                { this.state.columns.find(c => c.id === this.state.activeCol).title }
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {this.state.columns.map(col => (
                  <Dropdown.Item
                    as="button"
                    key={col.id}
                    className="btn btn-outline-secondary"
                    onClick={() => this.setState({ activeCol: col.id })}>
                    { col.title }
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        ) }
        <div className="row gx-2 flex-nowrap">
          <DragDropContext
            sensors={sensors}
            enableDefaultSensors={this.props.product.can_edit_item}
            onDragEnd={this.onDragend}
            onDragStart={this.onDragStart}
            onDragUpdate={this.onDragUpdate}>
            {this.state.columns.filter(col => !col.hide).map(col => (
              <div
                key={col.id}
                data-colid={col.id}
                className={'col-md kanban-col' + (this.state.activeCol === col.id ? ' active' : '')}>
                <div className="kanban-col__title">
                  { this.state.getTitle(col, this) }
                  { selectedComps.length > 0 && (
                    <SelectedActions
                      selectedComponents={selectedComps}
                      pks={col.items.map(i => i.pk)}
                      params={this.columnParams(col)}
                      align="end"
                      variant="link"
                      dropdown>
                      <Icon name="three-dots-vertical" />
                    </SelectedActions>
                  ) }
                </div>
                <Droppable droppableId={col.id}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className={`backlog-items droppable-container${snapshot.isDraggingOver ? ' is-drag-over' : ''}`}>
                      <Droppable droppableId={'bot-' + col.id}>
                        {(prov, snap) => {
                          const classes = ['top-bot'];
                          if (this.state.isDragging && this.state.isDragging === col.id) classes.push('is-active');
                          if (snap.isDraggingOver) classes.push('is-drag-over');
                          return (
                            <div
                              ref={prov.innerRef}
                              className={classes.join(' ')}>
                              <Icon name="arrow-down-circle"/> <Trans>Send to bottom</Trans>
                              {prov.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>
                      {col.items.map((item, index) => (
                        <Draggable
                          key={item.pk}
                          draggableId={String(item.pk)}
                          index={index}
                          isDragDisabled={this.props.readonly}>
                          {(provided2, snapshot2) => (
                            <div
                              ref={provided2.innerRef}
                              key={item.pk}
                              id={'backlog-item-' + item.pk}
                              className={
                                `card backlog-item-teaser backlog-item-${this.props.layout}${snapshot2.isDragging ? ' is-draging' : ''}`
                              }
                              {...provided2.draggableProps}
                              {...provided2.dragHandleProps}>
                              <BacklogItemTeaser
                                product={this.props.product}
                                layout={this.props.layout}
                                backlogItem={item}
                                showEval={this.state.showEval}/>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                      <Droppable droppableId={'top-' + col.id}>
                        {(prov, snap) => {
                          const classes = ['top-bot'];
                          if (this.state.isDragging && this.state.isDragging === col.id) classes.push('is-active');
                          if (snap.isDraggingOver) classes.push('is-drag-over');
                          return (
                            <div
                              ref={prov.innerRef}
                              className={classes.join(' ')}>
                              <Icon name="arrow-up-circle"/> <Trans>Send to top</Trans>
                              {prov.placeholder}
                            </div>
                          );
                        }}
                      </Droppable>

                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </DragDropContext>
        </div>
      </div>
    );
  }
}
Kanban.defaultProps = {
  itemsOptions: {},
  statuslist: statuslistKanban,
  layout: 'normal',
  filters: [],
  responsive: true
};
Kanban.contextType = AppContext;

const KanbanCol = (props) => props.children;

export { KanbanCol };
export default Kanban;
