import React, { useState } from 'react';
import * as BS from 'react-bootstrap';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { manageFormErrors } from 'components/form/Form';
import SigninForm from './SigninForm';

export default function ForgottenPasswordForm() {
  const [email, setEmail] = useState();
  const [error, setError] = useState(null);
  const [sent, send] = useState();

  const handleSubmit = e => {
    e.preventDefault();
    setError(null);
    axios.post(
      '/auth/forgotten-password', {
        email: email
      }
    ).then(function (response) {
      send(true);
    }).catch(function (err) {
      const { globalErrors } = manageFormErrors(null, err, true);
      setError(globalErrors.map(er => er.error).join(<br/>));
    });
  };
  if (sent) {
    return (
      <>
        <h2><Trans>We have sent you an email</Trans></h2>
        <p>
          <Trans>
            You will receive an email shortly with a link to choose your new password.
          </Trans>
        </p>
        <Link to="/" className="my-2 btn btn-cta btn-cta__secondary">
          <Trans>Ok</Trans>
        </Link>
      </>
    );
  }

  return (
    <SigninForm title={t`Please enter your email address to receive a link to reset your password`} onSubmit={handleSubmit}>
      { error && (
        <div className="mb-2">
          <BS.Alert variant="danger">{error}</BS.Alert>
        </div>
      )}
      <BS.Form.Label htmlFor="email" className="sr-only"><Trans>Email address</Trans></BS.Form.Label>
      <BS.Form.Control
        type="email"
        name="email"
        id="email"
        autoComplete="username"
        placeholder={t`Email Address`}
        onChange={e => setEmail(e.target.value)}/>
      <div className="mt-3 mb-3">
        <BS.Button
          className="btn btn-cta"
          type="submit"><Trans>Reset password</Trans></BS.Button>
        <Link to="/" className="ms-2 btn btn-cta btn-cta__secondary">
          <Trans>Cancel</Trans>
        </Link>
      </div>
    </SigninForm>
  );
}
