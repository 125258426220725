import React, { useState } from 'react';
import { t } from '@lingui/macro';
import { useLocation } from 'react-router';
import Icon from 'components/ui/Icon';
import SidebarLink from './SidebarLink';

function SidebarLinks(props) {
  const location = useLocation();
  const [subActive, setSubActive] = useState(null);
  const { links } = props;
  let finalLinks = links;
  if (subActive !== null) {
    const activeLink = links.find(l => l.name === subActive);
    finalLinks = [
      { name: 'item-back', icon: 'arrow-left', to: 'BACK', text: t`Back` },
      ...activeLink.children
    ];
  }
  const output = finalLinks.map((link) => {
    const { text, name, ...rest } = link;
    if (location.pathname === rest.to) rest.isActive = true;
    return (
      <React.Fragment key={name}>
        {'children' in link ? (
          <li className="nav-item">
            <button onClick={() => { setSubActive(name); }} type="button" className="btn-simplelink nav-link">
              <Icon name={rest.icon}/> <span>{text}</span>
            </button>
          </li>
        ) : (
          <SidebarLink setSubActive={setSubActive} {...rest}>{ text }</SidebarLink>
        )}
      </React.Fragment>
    );

  });
  return output;
}
export default SidebarLinks;
