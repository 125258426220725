import React, { useContext } from 'react';
import * as BS from 'react-bootstrap';
import { FormContext } from './FormContext';
import BaseField from './BaseField';

export default function Check({ children, label, ...props }) {
  const { item, setItem, meta } = useContext(FormContext);

  const handleChange = e => {
    const { name, checked } = e.target;
    item[name] = checked;
    setItem(item);
  };

  return (
    <BaseField {...props}>
      <BS.Form.Check
        checked={item[props.name] || false}
        onChange={handleChange}
        label={label || meta && meta.fields && meta.fields[props.name] && meta.fields[props.name].name}
        className="ml-2 mb-3"
        {...props} />
    </BaseField>
  );
}
Check.defaultProps = {
  type: 'checkbox',
};
