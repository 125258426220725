import React, { useState } from 'react';
import ModalForm from 'components/modal/ModalForm';
import ProductImportForm from 'components/forms/ProductImportForm';

export default function ImportProductModal(props) {
  const { meta, jiraToken, project, onSave,
    variant, title, buttonTitle, ...rest } = props;
  const [isShown, setShow] = useState(false);
  const handleCancel = () => {
    setShow(false);
  };
  const handleSave = (data, created) => {
    if (onSave) onSave(data, created);
  };
  const handleShow = (e) => {
    setShow(true);
  };

  return (
    <ModalForm
      show={isShown}
      handleClose={handleCancel}
      handleShow={handleShow}
      title={title}
      buttonTitle={buttonTitle}
      variant={variant}>
      <ProductImportForm
        meta={meta}
        jiraToken={jiraToken}
        project={project}
        onSave={handleSave}
        onCancel={handleCancel}
        {...rest}/>
    </ModalForm>
  );
}
