import React, { useCallback, useContext } from 'react';
import * as Sentry from '@sentry/react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { TopNav } from 'components/topnav';
import Routes from 'Routes';
import AppContext, { AppContextProvider } from 'AppContext';
import { MessagesContainer } from 'components/ui/Messages';
import { SearchSidebar } from 'components/sidebar/Search';
import ErrorPage from 'pages/ErrorPage';
import Ribbon from 'components/ui/Ribbon';
import NotificationsProvider from 'components/utils/NotificationsObserver';

export function MainApp() {
  const { lang } = useContext(AppContext);
  return (
    <NotificationsProvider>
      <Helmet htmlAttributes={{ lang: lang.pk }}/>
      <TopNav/>
      <SearchSidebar/>
      <Routes/>
    </NotificationsProvider>
  );
}

export function AppRouter() {
  return (
    <BrowserRouter>
      <MainApp/>
    </BrowserRouter>
  );
}

export function BaseApp(props) {
  const myFallback = useCallback(
    params => (<ErrorPage params={params} />),
    []
  );
  return (
    <HelmetProvider>
      <AppContextProvider>
        <Sentry.ErrorBoundary
          fallback={myFallback}
          showDialog={false}>
          <MessagesContainer />
          { props.children }
        </Sentry.ErrorBoundary>
      </AppContextProvider>
    </HelmetProvider>
  );
}

export default function App() {
  const campaignParams = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  const campaign = {};
  const params = new URLSearchParams(window.location.search);
  const environment = process.env.REACT_APP_ENVIRONMENT;

  for (const param of campaignParams) {
    if (params.has(param)) {
      campaign[param] = params.get(param);
    }
  }
  if (campaign) {
    window.sessionStorage.setItem('campaign', JSON.stringify(campaign));
  }

  return (
    <BaseApp>
      <Ribbon show={process.env.REACT_APP_SHOW_RIBBON} environment={environment} />
      <AppRouter/>
    </BaseApp>
  );
}
