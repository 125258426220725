import React from 'react';
import { Switch, Route } from 'react-router';
import { PrivateRoute } from 'components/auth';
import * as Pages from 'pages';
import IntegrationPage from 'integrations/IntegrationPage';
import DashboardOrganizationPage from 'pages/DashboardOrganizationPage';

export default function Routes(props) {
  return (
    <Switch>
      <PrivateRoute key="dashboard" exact path="/">
        <Route exact path="/" component={Pages.DashboardPage}/>
      </PrivateRoute>
      <PrivateRoute path="/organizations" >
        <Route exact path="/organizations" component={Pages.OrganizationsListPage} />
        <Route path="/organizations/add" component={Pages.OrganizationAddPage} />
        <Route path="/organizations/integrations" component={IntegrationPage} />
      </PrivateRoute>
      <PrivateRoute path="/user/profile" component={Pages.UserFormPage} />
      <PrivateRoute path="/user/items" component={Pages.UserBacklogItemsPage} />
      <PrivateRoute path="/user/worklog" component={Pages.UserWorklog} />
      <PrivateRoute path="/products">
        <Route exact path="/products" component={Pages.ProductList} />
      </PrivateRoute>

      <Route path="/auth">
        <Route key="login" exact path="/auth/login" component={Pages.LoginPage} />
        <Route key="signup" exact path="/auth/signup" component={Pages.SignupPage} />
        <Route key="set-password" exact path="/auth/set-password" component={Pages.SetPasswordPage} />
        <Route key="forgotten-password" exact path="/auth/forgotten-password" component={Pages.ForgottenPasswordPage} />
        <Route key="validate-email" exact path="/auth/validate-email" component={Pages.ValidateEmailPage}/>
      </Route>
      <PrivateRoute key="organization" path="/:orgSlug">
        <DashboardOrganizationPage/>
      </PrivateRoute>

    </Switch>
  );
}
