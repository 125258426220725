import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import UserPage from 'components/page/UserPage';
import Organization from 'entity/Organization';
import SubscriptionForm from 'components/forms/SubscriptionForm';
import OrganizationForm from 'components/forms/OrganizationForm';
import AppContext from 'AppContext';
import Icon from 'components/ui/Icon';
import OrganizationContext from 'components/utils/OrganizationContext';

function OrganizationAddPageFirst(props) {
  const history = useHistory();
  const { user } = useContext(AppContext);
  const organization = new Organization();

  return (
    <div className="row justify-content-center mb-4">
      <div className="col-12 col-sm-6 mt-3 pt-3 bg-theme">
        <div className="hero-block text-white text-end px-3 py-5">
          <Icon size="60" name="building" />
          <h1 className="mt-2">
            <Trans>Add a new organization</Trans>
          </h1>
          <Trans>
            <p>To start using Optera, you must create or be invited to an organization.</p>
            <p>The organization can be your company, a society, an association, etc. Subscriptions are per organization.</p>
          </Trans>
        </div>
      </div>
      <div className="col-12 col-sm-6 mt-3 p-4 bg-body">
        <h2 className="pt-3"><Trans>Please choose your organization name</Trans></h2>
        <OrganizationForm
          organization={organization}
          onSave={(data) => {
            user.load(user.pk).catch(err => {
              console.error(t`User can't be loaded`);
            });
            history.push(`/${data.slug}/manage/subscriptions`);
          }} />
      </div>
    </div>
  );
}

function OrganizationAddPageSecond(props) {
  const organization = useContext(OrganizationContext);
  const history = useHistory();

  return (
    <>
      <h2 className="pt-3 text-center"><Trans>Please choose a subscription</Trans></h2>
      <SubscriptionForm
        organization={organization}
        onSave={() => {
          history.push('/' + organization.slug);
        }}
        isNew />
    </>
  );
}

export default function OrganizationAddPage(props) {
  const { orgSlug } = useParams();

  return (
    <UserPage
      name={'org-form-page org-form-page__new_' + (orgSlug ? 2 : 1)}
      title={t`New organization`}>
      { orgSlug ? (
        <OrganizationAddPageSecond />
      ) : (
        <OrganizationAddPageFirst />
      )}
      
    </UserPage>
  );

}
