/* eslint-disable jsx-a11y/click-events-have-key-events,
                  jsx-a11y/no-noninteractive-element-interactions */
import { t } from '@lingui/macro';
import React, { useEffect, useRef, useState } from 'react';
import Icon from './Icon';
import { Loader } from './Loader';

const ImageLoader = function(props) {
  const { data } = props;
  const imageRef = useRef();
  const [sizes, setSizes] = useState({});
  const [loaded, setLoaded] = useState(false);

  const setNaturalSizes = () => {
    setLoaded(true);
    if (imageRef.current.naturalWidth + imageRef.current.naturalHeight === 0) {
      // Use large image size if no natural size (like SVG)
      setSizes({
        maxWidth: '1200px',
        maxHeight: '700px',
      });
    } else {
      setSizes({
        maxWidth: imageRef.current.naturalWidth + 'px',
        maxHeight: imageRef.current.naturalHeight + 'px',
      });
    }
  };

  useEffect(() => {
    setLoaded(false);
    if (imageRef.current && imageRef.current.complete) {
      setNaturalSizes();
    }
  }, [data]);

  return (
    <>
      {!loaded && (
        <Loader />
      )}
      <img style={loaded ? sizes : { display: 'none', ...sizes }} src={data.link} ref={imageRef} onLoad={setNaturalSizes} alt=""/>
    </>
  );
};

const ImageViewer = function(props) {
  const { imageList, image, setImage } = props;

  if (!image) {
    return null;
  }

  const index = imageList.findIndex(el => el.button === image);
  const data = index !== -1 ? imageList[index] : null;
  if (!data) {
    return null;
  }

  let prev = null;
  let next = null;
  if (imageList.length > 1) {
    prev = imageList[index > 0 ? (index - 1) : (imageList.length - 1)].button;
    next = imageList[index === (imageList.length - 1) ? 0 : (index + 1)].button;
  }
  const filename = data.source.substring(data.source.lastIndexOf('/') + 1);

  return (
    <div
      role="dialog"
      className="image-viewer image-viewer__overlay"
      onClick={e => { e.stopPropagation(); }}>
      <div className="image-viewer__container">
        <div className="header hstack">
          { filename }
          <div className="actions ms-auto">
            <a target="_blank" rel="noreferrer" className="link" href={data.source} download={filename}><Icon name="download" /></a>
            <button type="button" onClick={() => { setImage(null); }} className="btn-close btn-close-white" aria-label={t`Close`}></button>
          </div>
        </div>
        <div className="image-viewer__image">
          { prev && (
            <button type="button" className="btn bt-left" onClick={e => { setImage(prev); }}>
              <Icon name="chevron-left"></Icon>
            </button>
          ) }
          <ImageLoader data={data}/>
          { next && (
            <button type="button" className="btn bt-right" onClick={e => { setImage(next); }}>
              <Icon name="chevron-right"></Icon>
            </button>
          ) }
        </div>
      </div>
    </div>
  );
};

export default ImageViewer;
