import React from 'react';
import { Trans } from '@lingui/macro';
import { Dropdown } from 'react-bootstrap';
import Icon from 'components/ui/Icon';
import { ConfirmButton } from 'components/utils';

function ActionsButtons(props) {
  const { onEdit, canEdit, onDelete, canDelete, item, deleteConfirmation,
    confirmValue, confirmValueLabel, actions } = props;
  const deleteShown = typeof canDelete === 'function' ? canDelete(item) : canDelete;
  return (
    <>
      { actions(item).map(bt => (
        <button key={bt.key} className="btn btn-outline-secondary" type="button" onClick={bt.onClick}>{ bt.content }</button>
      )) }
      { canEdit(item) && onEdit && (
        <button type="button" className="btn btn-outline-secondary" onClick={() => onEdit(item)}>
          <Trans>Edit</Trans> <Icon name="pen"/>
        </button>
      )}
      { deleteShown && (
        <ConfirmButton
          message={deleteConfirmation ?
            (typeof deleteConfirmation === 'function' ? deleteConfirmation(item) : deleteConfirmation) :
            (<Trans>Are you sure you want to delete this element?</Trans>)}
          confirmValue={typeof confirmValue === 'function' ? confirmValue(item) : confirmValue}
          confirmValueLabel={typeof confirmValueLabel === 'function' ? confirmValueLabel(item) : confirmValueLabel}
          variant="outline-secondary"
          onConfirm={() => onDelete(item)}>
          <Trans>Remove</Trans> <Icon name="trash"/>
        </ConfirmButton>
      ) }
    </>
  );
}
function ActionsDropdown(props) {
  const { onEdit, canEdit, onDelete, canDelete, item, deleteConfirmation,
    confirmValue, confirmValueLabel, actions } = props;
  const deleteShown = typeof canDelete === 'function' ? canDelete(item) : canDelete;
  
  const actionsList = actions(item);
  if (actionsList.length < 1 && !(canEdit(item) && onEdit) && !deleteShown) {
    return null;
  }
  return (
    <Dropdown>
      <Dropdown.Toggle variant="outline-secondary">
        <Icon name="gear" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        { actionsList.map(bt => (
          <Dropdown.Item as="button" key={bt.key} className="btn btn-outline-secondary" onClick={bt.onClick}>
            { bt.content }
          </Dropdown.Item>
        )) }
        { canEdit(item) && onEdit && (
          <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={() => onEdit(item)}>
            <Trans>Edit</Trans> <Icon name="pen"/>
          </Dropdown.Item>
        )}
        { deleteShown && (
          <Dropdown.Item
            as={ConfirmButton}
            message={deleteConfirmation ?
              (typeof deleteConfirmation === 'function' ? deleteConfirmation(item) : deleteConfirmation) :
              (<Trans>Are you sure you want to delete this element?</Trans>)}
            confirmValue={typeof confirmValue === 'function' ? confirmValue(item) : confirmValue}
            confirmValueLabel={typeof confirmValueLabel === 'function' ? confirmValueLabel(item) : confirmValueLabel}
            variant="outline-secondary"
            onConfirm={() => onDelete(item)}>
            <Trans>Remove</Trans> <Icon name="trash"/>
          </Dropdown.Item>
        ) }
      </Dropdown.Menu>
    </Dropdown>
  );
}

function DatatableRow(props) {
  const { actionsDropdown, rowClasses } = props;
  const classes = ['row-item-' + props.item.pk];
  if (rowClasses) {
    rowClasses(props.item, classes);
  }
  return (
    <tr className={classes.join(' ')}>
      {props.children}
      <td className="col__actions">
        { actionsDropdown ? <ActionsDropdown {...props}/> : <ActionsButtons {...props} /> }
      </td>
    </tr>
  );
}

function Cell(props) {
  const { name, link, item, label, columnsList, contentBuilder, value } = props;
  
  const colDef = columnsList.find(c => c.name === name);
  if (colDef) {
    if (!('active' in colDef) || !colDef.active) {
      return null;
    }
  }
  const finalLabel = label || (colDef ? colDef.label : '');
  let content = contentBuilder ? contentBuilder(item) : value;
  if (content && typeof content === 'object' && 'resource_name' in content) {
    content = content.resource_name;
  }

  const children = link ? link(item, content) : content;
  return (
    <td key={name} data-key={name} data-label={finalLabel}>
      {children}
    </td>
  );
}
Cell.defaultProps = {
  columnsList: []
};

export default DatatableRow;
export { Cell };
