import axios from 'axios';

const getMemberFeedConfig = (product) => ({
  marker: '@',
  feed: getFeedItems(product),
});

function getMemberMentionCustomization() {
  return function(editor) {
    editor.conversion.for('upcast').elementToAttribute({
      view: {
        name: 'span',
        key: 'data-mention',
        classes: 'mention'
      },
      model: {
        key: 'mention',
        value: viewItem => {
          const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
            id: viewItem.getAttribute('data-mention'),
            pk: viewItem.getAttribute('data-member-id'),
          });

          return mentionAttribute;
        }
      },
      converterPriority: 'high'
    });

    editor.conversion.for('downcast').attributeToElement({
      model: 'mention',
      view: (modelAttributeValue, { writer }) => {
        if (!modelAttributeValue || modelAttributeValue.link) {
          return;
        }

        // eslint-disable-next-line consistent-return
        return writer.createAttributeElement('span', {
          class: 'mention',
          'data-mention': modelAttributeValue.id,
          'data-member-id': modelAttributeValue.pk,
        }, {
          priority: 20,
          id: modelAttributeValue.pk
        });
      },
      converterPriority: 'high'
    });
  };
}

function getFeedItems(product) {
  return function(queryText) {
    // @TODO workaround for bug https://github.com/ckeditor/ckeditor5/issues/11541
    // https://app.optera.io/opteraio/opteraio/item/OPT-869
    if ((queryText.match(/\s/g) || []).length > 0) return [];
    return new Promise((resolve, reject) => {
      axios.get('/members?organization=' + product.organization.pk + '&page_size=5&search=' + encodeURIComponent(queryText))
        .then(response => {
          const items = [];
          for (const bl of response.data.items) {
            items.push({
              id: '@' + bl.resource_name,
              pk: bl.pk,
              name: bl.resource_name,
            });
          }
          resolve(items);
        })
        .catch(err => {
          console.error('Impossible to find members');
          reject();
        });
    });
  };
}

export { getMemberFeedConfig, getMemberMentionCustomization };
