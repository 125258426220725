/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Icon from 'components/ui/Icon';
import ProductPage from 'components/page/ProductPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import EpicModal from 'components/modal/EpicModal';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';
import { ConfirmButton } from 'components/utils';
import EpicViewModal from 'components/modal/EpicViewModal';

const EpicsPageContent = function(props) {
  const { product, organization, addModal, refresh } = props;
  const [epics, setEpics] = useState(null);
  const [editItem, setEditItem] = useState(null);
  const [viewItem, setViewItem] = useState(null);
  const onEditClose = () => {
    setEditItem(null);
  };
  const onViewClose = () => {
    setViewItem(null);
  };
  const onSave = (data, created) => {
    const index = epics.findIndex(ep => ep.pk === data.pk);
    if (index !== -1) {
      epics[index] = data;
      setEpics([...epics]);
    }
    setEditItem(null);
  };

  useEffect(() => {
    axios.get('/epics?product=' + product.pk).then(response => {
      setEpics(response.data.items);
    }).catch(err => {
      addMessage('load-epics', t`Unknown error`, t`Impossible to load epics`);
    });
  }, [product, refresh]);

  const onDelete = epic => {
    axios.delete('/epics/' + epic.pk)
      .then(() => {
        setEpics(epics.filter(ep => ep.pk !== epic.pk));
      })
      .catch((error) => addMessage('delete-error', t`Server error`, error.response.data.message));
  };

  if (epics === null) {
    return (
      <LoaderContainer height="4"/>
    );
  }
  if (epics.length < 0) {
    return (
      <div className="alert alert-secondary" role="alert">
        <Trans>You don't have any epics</Trans>
        { addModal }
      </div>
    );
  }
  return (
    <>
      <EpicModal
        product={product}
        organization={organization}
        onClose={onEditClose}
        onSave={onSave}
        epic={editItem}
        show={!!editItem}
        modalTitle={t`Edit epic`}
        hideButton />
      <EpicViewModal
        product={product}
        organization={organization}
        onClose={onViewClose}
        epic={viewItem}
        show={!!viewItem}
        modalTitle={viewItem && viewItem.name}
        hideButton />
      <div className="row mt-2">
        { epics.map(epic => (
          <div key={epic.pk} className="col col-xl-3 col-sm-6 col-12 mb-3">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title text-center">
                  <span className={'badge break-line py-2 px-3 rounded-pill bg-col-' + epic.color}>{ epic.name }</span>
                </h4>
                <div className="d-grid gap-2">
                  { product.can_edit_item && (
                    <>
                      <button onClick={e => setViewItem(epic)} type="button" className="btn btn-outline-secondary">
                        <Icon name="list"/> <Trans>Manage items</Trans>
                      </button>
                      <button onClick={e => setEditItem(epic)} type="button" className="btn btn-outline-secondary">
                        <Icon name="pencil"/> <Trans>Edit</Trans>
                      </button>
                    </>
                  ) }
                  { product.can_delete_item && (
                    <ConfirmButton
                      message={t`Are you sure you want to delete this epic?`}
                      variant="outline-secondary"
                      onConfirm={() => onDelete(epic)}>
                      <Icon name="x-circle"/> <Trans>Delete</Trans>
                    </ConfirmButton>
                  ) }
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default function EpicsPage() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const [refresh, setRefresh] = useState(1);

  const onClose = () => setRefresh(refresh + 1);

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Epics`
  ];
  const addModal = (
    <EpicModal
      variant="primary"
      key="1"
      product={product}
      organization={organization}
      onClose={onClose}
      title={(<><Icon name="plus-circle"/> <Trans>Add epic</Trans></>)}
      onSave={onClose} />
  );

  return (
    <ProductPage
      name="epics-list-page"
      title={t`Epics`}
      breadcrumb={breadcrumb}>
      { product.can_add_item && addModal }
      <EpicsPageContent
        product={product}
        organization={organization}
        addModal={addModal}
        refresh={refresh}/>
    </ProductPage>
  );
}
