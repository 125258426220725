import React, { useCallback, useContext, useRef, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import CKWrapper from 'components/richcontent/CKWrapper';
import Icon from 'components/ui/Icon';
import ProductContext from 'components/utils/ProductContext';

export default function BacklogItemCommentsAdd(props) {
  const richEditor = useRef(null);
  const product = useContext(ProductContext);
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { backlogItem, onAddComment, onEdit, onEditClose } = props;

  const setShowFormHandler = useCallback((show) => {
    if (show) {
      if (onEdit) onEdit('addcomment-' + backlogItem.pk);
    }
    else if (onEditClose) {
      onEditClose('addcomment-' + backlogItem.pk);
    }
    setShowForm(show);
  }, [onEdit, onEditClose, backlogItem.pk]);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setLoading(true);
    axios.post('/item-comments', {
      item: { pk: backlogItem.pk },
      comment: richEditor.current.getData()
    }).then((response => {
      onAddComment(response.data);
      setLoading(false);
      setShowFormHandler(false);
      richEditor.current.setData('');
    })).catch(err => {
      addMessage(
        'add-message-' + backlogItem.pk,
        t`Unknown error`,
        t`Impossible to add comment`,
      );
      setLoading(false);
      setShowFormHandler(false);
    });

  }, [backlogItem, onAddComment, setShowFormHandler]);

  const onReadyHandler = useCallback((editor) => {
    richEditor.current = editor;
    editor.focus();
  }, []);

  if (!showForm && product.can_add_comment) {
    return (
      <div className="backlog-comments-add">
        <button type="button" className="btn btn-outline-secondary" onClick={() => { setShowFormHandler(true); }}>
          <Icon name="plus"/> <Trans>Add comment</Trans>
        </button>
      </div>
    );
  }
  return (
    <div className="backlog-comments-add with-form">
      <form className="relative" onSubmit={onSubmit}>
        { loading && (
          <LoaderContainer/>
        ) }
        <div className="label-container">
          <div className="label">
            <Trans>Add comment</Trans>
          </div>
        </div>
        <CKWrapper
          arial-label={t`Enter your comment`}
          data=""
          onReady={onReadyHandler}
          backlogItem={backlogItem}
          mention
          product={product}/>
        <input type="submit" value={t`Save`} className="btn btn-primary me-2"/>
        <button type="button" className="btn btn-outline-secondary" onClick={() => { setShowFormHandler(false); }} >
          <Trans>Cancel</Trans>
        </button>
      </form>
    </div>
  );
}
