import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';

function ItemTypeFinalForm(props) {
  const { onSave, onCancel, isEdit } = props;

  return (
    <Form
      name="account-add-form"
      noValidate
      onSave={onSave}>
      <Form.Field id="name" name="name" required />
      <Form.Color id="color" name="color" />
      <Form.Field id="git_checkout_prefix" name="git_checkout_prefix" />
      <div className="actions">
        <button className="btn btn-primary" type="submit">
          { isEdit ? t`Save` : t`Add item type` }
        </button>
        <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
      </div>
    </Form>
  );
}

const ItemTypeForm = function(props) {
  const { onSave, onCancel, product, itemType } = props;
  if (!itemType) return null;
  itemType.product = { pk: product.pk };
  return (
    <FormContextProvider item={itemType} api="/item-types">
      <ItemTypeFinalForm
        isEdit={itemType && !!itemType.pk}
        product={product}
        onSave={onSave}
        onCancel={onCancel} />
    </FormContextProvider>
  );
};

export default ItemTypeForm;
