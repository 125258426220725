import React, { useContext } from 'react';
import SelectSearch from 'components/ui/SelectSearch';
import { FormContext } from './FormContext';
import BaseField from './BaseField';

function Many2One({ children, ...props }) {
  const { item, setItem, meta, errors } = useContext(FormContext);
  const { label, onSelectValue, api, groupClassName, ...fieldProps } = props;

  const handleSelect = option => {
    item[props.name] = option;
    setItem(item);
    if (onSelectValue) onSelectValue(option);
  };

  if (!('id' in props)) {
    props.id = props.name;
  }
  const groupClasses = ['form-group'];
  if (groupClassName.length > 0) groupClasses.push(groupClassName);
  if (props.required) groupClasses.push('required');
  if (errors[props.name]) {
    fieldProps.className = 'is-invalid';
  }
  return (
    <BaseField
      {...props}
      label={label || meta && meta.fields && meta.fields[props.name] && meta.fields[props.name].name}
      className={groupClasses.join(' ')}
      humanValue={item[props.name] ? item[props.name].resource_name : ''}>
      <SelectSearch
        value={item[props.name]}
        handleSelect={handleSelect}
        searchApi={api}
        isInvalid={!!errors[props.name]}
        {...fieldProps}/>
    </BaseField>
  );
}
Many2One.defaultProps = {
  groupClassName: ''
};

export default Many2One;
