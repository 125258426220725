import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import ImportBindingForm from 'components/forms/ImportBindingForm';
import BacklogItemImportModal from 'components/modal/BacklogItemImportModal';
import ProductPage from 'components/page/ProductPage';
import { LoaderContainer } from 'components/ui/Loader';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

function BacklogItemsImportBindingContent(props) {
  // eslint-disable-next-line no-unused-vars
  const { product, organization, importPk } = props;
  const [error, setError] = useState(null);
  const [bindingData, setBindingData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setError(null);
    axios.get(`backlog-item-imports/${importPk}/binding`).then(res => {
      setBindingData(res.data);
      setLoading(false);
    }).catch((e) => {
      if (e.response && e.response.status === 400 && e.response.data.type === 'UserMessage') {
        setError(e.response.data.message);
      } else {
        setError(t`Impossible to load import.`);
      }
      setLoading(false);
    });
  }, [importPk]);
  if (loading) {
    return <LoaderContainer height="3" />;
  }
  if (error) {
    return (
      <>
        <h3><Trans>Items import</Trans></h3>
        <div className="alert alert-danger" role="alert">
          { error }
        </div>
        <div className="btn-group">
          <BacklogItemImportModal
            buttonTitle={t`Retry with another file`}
            key="1"
            product={product}
            organization={organization}/>
          <Link className="btn btn-outline-secondary" key="4" to={`/${organization.slug}/${product.slug}/items`}><Trans>Back</Trans></Link>
        </div>
      </>
    );
  }
  return (
    <>
      <h3><Trans>Confirm binding</Trans></h3>
      <ImportBindingForm importPk={importPk} binding={bindingData} organization={organization} product={product} />
    </>
  );
}

export default function BacklogItemsImportBindingPage(props) {
  const { importPk } = useParams();
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    <Link key="4" to={`/${organization.slug}/${product.slug}/items`}><Trans>Search items</Trans></Link>,
    t`Fields binding`
  ];

  return (
    <ProductPage
      name="backlog-items-import-binding-page"
      title={t`Fields binding`}
      breadcrumb={breadcrumb}>
      <BacklogItemsImportBindingContent
        importPk={importPk}
        organization={organization}
        product={product} />
    </ProductPage>
  );
}
