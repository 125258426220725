import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import ItemStatusForm from 'components/forms/ItemStatusForm';

function ItemStatusModal(props) {
  const { title, modalTitle, hideButton, onSave,
    onClose, show, variant, ...rest } = props;
  const [showModal, setShowModal] = useState(!!show);
  const handleClose = () => {
    if (onClose) onClose();
    setShowModal(false);
  };
  const handleSave = () => {
    if (onSave) onSave();
    setShowModal(false);
  };
  const handleShow = (e) => {
    setShowModal(true);
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <ModalForm
      variant={variant}
      show={showModal}
      handleClose={handleClose}
      handleShow={handleShow}
      title={modalTitle || t`New backlog item status`}
      buttonTitle={title || t`Add status`}
      hideButton={hideButton}>
      <ItemStatusForm
        {...rest}
        onSave={handleSave}
        onCancel={handleClose}>
      </ItemStatusForm>
    </ModalForm>
  );
}
ItemStatusModal.defaultProps = {
  itemStatus: { name: '', color: '' }
};

export default ItemStatusModal;
