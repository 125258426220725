/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import { LoaderContainer } from 'components/ui/Loader';
import copyTextToClipboard from 'components/utils/copyTextToClipboard';
import { FormCheck } from 'react-bootstrap';
import SimpleModal from './SimpleModal';

function ReleaseNoteModalContent(props) {
  const { release } = props;
  const [releaseNote, setReleaseNote] = useState(null);
  const [links, setLinks] = useState(true);
  const [error, setError] = useState(null);

  const loadReleaseNote = useCallback(() => {
    setReleaseNote(null);
    axios.get('/releases/' + release.pk + '/notes?links=' + (links ? 'true' : 'false'))
      .then(response => {
        setReleaseNote(response.data);
      })
      .catch((err) => setError(err.response || err.message));
  }, [release.pk, links]);

  useEffect(() => {
    loadReleaseNote();
  }, [loadReleaseNote]);

  if (error) {
    return (
      <div className="alert alert-danger mb-2" role="alert">
        { error }
      </div>
    );
  }

  const html = releaseNote ? releaseNote.html
    .replace(/<h1.+<\/h1>/gm, '')
    .replace(/<(\/?)h([0-9])>/gm, (match, p1, p2) => '<' + p1 + 'h' + (parseInt(p2, 10) + 2) + '>') : null;
  
  const copyFormated = e => {
    e.preventDefault();
    copyTextToClipboard(releaseNote.html, true);
  };
  const copyHtml = e => {
    e.preventDefault();
    copyTextToClipboard(releaseNote.html);
  };
  const copyMarkdown = e => {
    e.preventDefault();
    copyTextToClipboard(releaseNote.markdown);
  };
  const handleLinksChange = e => {
    const { checked } = e.target;
    setLinks(checked); 
  };
  return (
    <div className="release-note relative">
      { !releaseNote && (
        <LoaderContainer />
      ) }
      <FormCheck
        id={'links-' + release.pk}
        checked={links}
        onChange={handleLinksChange}
        label={t`Show links`}
        className="mb-2" />
      <div className="mb-3">
        <button type="button" className="btn btn-outline-secondary me-2" onClick={copyFormated}>
          <Trans>Copy formatted</Trans>
        </button>
        <button type="button" className="btn btn-outline-secondary me-2" onClick={copyHtml}>
          <Trans>Copy HTML</Trans>
        </button>
        <button type="button" className="btn btn-outline-secondary" onClick={copyMarkdown}>
          <Trans>Copy markdown</Trans>
        </button>
      </div>
      { releaseNote && (
        <div className="release-notes--content" dangerouslySetInnerHTML={{ __html: html }}>

        </div>
      ) }
    </div>
  );
}
export default function ReleaseNoteModal(props) {
  return (
    <SimpleModal
      size="lg"
      title={t`Release notes`}
      buttonTitle={t`Release notes`}>
      <ReleaseNoteModalContent {...props} />
    </SimpleModal>
  );
}
