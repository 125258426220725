import React, { useContext, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';
import StoryMapItemModal from 'components/modal/StoryMapItemModal';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { useStateItem } from 'utils/Item';
import StoryMapContext from './StoryMapContext';
import { StoryMapTableHeaderActivities } from './StoryMapActivity';

export function StoryMapTableHeaderGoals(props) {
  const { persona, goals } = props;
  
  return (
    <Droppable droppableId={'p-' + persona.pk} direction="horizontal" type="goals">
      {(provided, snapshot) => (
        <div className={'story-map-goals droppable-container' + (snapshot.isDraggingOver ? ' is-drag-over' : '')} ref={provided.innerRef}>
          { goals.map((g, index2) => (
            <Draggable
              key={g}
              draggableId={'g-' + String(g)}
              index={index2}>
              {(provided2, snapshot2) => (
                <div
                  ref={provided2.innerRef}
                  className="story-map-goal"
                  data-colid={'g-' + g}
                  key={'g' + g}
                  {...provided2.draggableProps}>
                  <StoryMapGoal
                    goalPk={g}
                    isDragging={snapshot2.isDragging}
                    dragHandleProps={provided2.dragHandleProps}
                    {...props} />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

function EditGoalDropdown(props) {
  const { goal } = props;
  const { loadGoals, loadTasks, loadActivities } = useContext(StoryMapContext);
  const [showActivityModal, setShowActivityModal] = useState(false);

  const onHide = () => {
    goal.hidden = !goal.hidden;
    axios.put('/goals/' + goal.pk, goal).then(() => {
      loadGoals();
    }).catch(err => {
      addMessage('edit-goal', t`Unknown error`, t`Impossible to edit goal`);
    });
  };

  const onRemoved = () => {
    axios.delete('/goals/' + goal.pk, goal).then(() => {
      loadGoals();
      loadTasks();
    }).catch(err => {
      addMessage('delete-goal', t`Unknown error`, t`Impossible to delete goal`);
    });
  };

  const onClose = () => {
    setShowActivityModal(false);
  };
  const onActivitySave = () => {
    setShowActivityModal(false);
    loadActivities();
  };

  return (
    <>
      <StoryMapItemModal
        api="activities"
        title={t`New activity`}
        buttonTitle={t`Add activity`}
        onClose={onClose}
        onSave={onActivitySave}
        parent="goal"
        parentApi="goals"
        parentContext={'persona=' + goal.persona.pk}
        parentItem={goal}
        show={showActivityModal}
        hideButton />
      <Dropdown className="no-carret">
        <Dropdown.Toggle variant="sm">
          <Icon name="three-dots" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={onHide}>
            { goal.hidden ? t`Show goal` : t`Hide goal`}
          </Dropdown.Item>
          <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={onRemoved}>
            <Trans>Delete goal</Trans>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={() => { setShowActivityModal(true); }}>
            <Trans>Add activity</Trans>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

function StoryMapGoal(props) {
  const { goalPk, dragHandleProps, isDragging, ...rest } = props;
  const { product, goalsData } = props;
  const g = useStateItem('goals:' + goalPk);
  if (!g) return null;
  return (
    <>
      <div
        className={'story-map-card story-map-card--goal' + (g.hidden ? ' hidden' : '') + (isDragging ? ' is-draging' : '')}
        {...dragHandleProps}>
        <div className="story-map-card__header bg-col-orange">
          <Icon size="18" name="arrow-right" />
          {' '}
          <span><Trans>Goal</Trans></span>
          { product.can_edit_item && (
            <EditGoalDropdown
              product={product}
              goal={g} />
          ) }
        </div>
        <div className="story-map-card__body">
          { product.can_edit_item ? (
            <FieldShowUpdate
              element={g}
              pk={g.pk}
              api="goals"
              field="name"
              label={t`Name`}>
              {it => (
                <span className="name">{ it.resource_name }</span>
              )}
            </FieldShowUpdate>
          ) : g.name }
        </div>
      </div>
      <StoryMapTableHeaderActivities activities={goalsData[g.pk].activities} goal={g} {...rest} />
    </>
  );
}

export { EditGoalDropdown };
export default StoryMapGoal;
