import React, { useContext } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import AppContext from 'AppContext';

export default function PrivateRoute({ children, ...rest }) {
  const { user } = useContext(AppContext);
  const location = useLocation();
  if (!user) {
    return (
      <Redirect
        to={{
          pathname: '/auth/login',
          state: { from: location }
        }}
      />
    );
  }
  if ('component' in rest) {
    return (
      <Route
        {...rest} />
    );
  }
  return (
    <Route
      {...rest}
      render={() => children} />
  );
}
