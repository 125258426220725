import React, { useContext } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';
import Icon from 'components/ui/Icon';
import OrganizationContext from 'components/utils/OrganizationContext';
import AdminPage from 'components/page/AdminPage';
import NotFoundPage from 'pages/NotFoundPage';
import GitLab from './gitlab/Manifest';
import GitLabPage from './gitlab/GitLabPage';
import GitLabWebhook from './gitlab_webhook/Manifest';
import GitLabWebHookPage from './gitlab_webhook/GitLabWebHookPage';
import Helpscout from './helpscout/Manifest';
import HelpscoutPage from './helpscout/HelpscoutPage';
import Slack from './slack/Manifest';
import SlackPage from './slack/SlackPage';

const integrations = {
  gitlab: GitLab,
  gitlab_webhook: GitLabWebhook,
  helpscout: Helpscout,
  slack: Slack
};

function Integration(props) {
  const { organization, integration, beta } = props;

  return (
    <Card className="integration integration__teaser" key="0">
      <Card.Body>
        <img src={integration.logo} alt={integration.name} width="24" height="24" className="icon"/>
        <div className="card-content">
          <Link to={`/${organization.slug}/integrations/${integration.slug}`} className="btn btn-sm stretched-link float-end">
            <Icon name="pencil"/>
          </Link>
          <Card.Title>{ integration.name }</Card.Title>
          <div>{ beta && (<span className="badge bg-warning me-2"><Trans>BETA</Trans></span>) }{ integration.summary }</div>
        </div>
      </Card.Body>
    </Card>
  );
}

function IntegrationList(props) {
  const { organization } = props;
  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Trans key="2">Integrations</Trans>
  ];

  const installed = organization.integrations.filter(oi => oi.installed && oi.slug in integrations);
  const available = organization.integrations.filter(oi => !oi.installed && oi.slug in integrations);

  return (
    <AdminPage breadcrumb={breadcrumb} name="welcome-page" title={t`Welcome to Optera.io`}>
      <div className="content-block-header">
        <h3><Trans>Installed</Trans></h3>
      </div>
      {installed.length > 0 ? (
        <div className="integration-list columns-4">
          {installed.map(integration => (
            <Integration
              key={integration.slug}
              organization={organization}
              integration={integrations[integration.slug]}
              beta={integration.beta}/>
          ))}
        </div>
      ) : (
        <div className="mb-4"><Trans>No installed integrations.</Trans></div>
      )}
      <div className="content-block-header">
        <h3><Trans>Available</Trans></h3>
      </div>
      {available.length > 0 ? (
        <div className="integration-list columns-4">
          {available.map(integration => (
            <Integration
              key={integration.slug}
              organization={organization}
              integration={integrations[integration.slug]}
              beta={integration.beta}/>
          ))}
        </div>
      ) : (
        <div className="mb-4"><Trans>No available integrations.</Trans></div>
      )}
    </AdminPage>
  );
}

export default function OrganizationIntegrationPage(props) {
  const organization = useContext(OrganizationContext);
  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/integrations`}><Trans>Integrations</Trans></Link>
  ];

  return (
    <Switch>
      <Route exact path="/:orgSlug/integrations">
        <IntegrationList organization={organization}/>
      </Route>
      <Route path="/:orgSlug/integrations/gitlab_webhook" component={GitLabWebHookPage} />
      <Route path="/:orgSlug/integrations/gitlab" component={GitLabPage} />
      <Route path="/:orgSlug/integrations/helpscout" component={HelpscoutPage} />
      <Route path="/:orgSlug/integrations/slack" component={SlackPage} />
      <Route>
        <NotFoundPage breadcrumb={breadcrumb} />
      </Route>
    </Switch>
  );
}
