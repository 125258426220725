/* eslint-disable react-hooks/exhaustive-deps */
import { t } from '@lingui/macro';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import PersistentSocket from 'components/utils/PersistentSocket';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { setStateItem } from 'utils/Item';

const StoryMapContext = React.createContext(null);

function itemsLoaderCallback(api, setter, params, error, editItems) {
  return (newItems) => {
    if (typeof newItems !== 'undefined') {
      editItems.current[api] = true;
      setter(newItems);
    }
    else {
      axios.get('/' + api + '?' + params).then(response => {
        for (const item of response.data.items) {
          setStateItem(api + ':' + item.pk, item, { isFull: true });
        }
        setter(response.data.items.map(i => i.pk));
      }).catch(err => {
        addMessage('load-' + api, t`Unknown error`, error);
      });
    }
  };
}

export function StoryMapContextProvider(props) {
  const { storyMap } = props;

  const [personas, setPersonas] = useState(null);
  const [goals, setGoals] = useState(null);
  const [activities, setActivities] = useState(null);
  const [tasks, setTasks] = useState(null);
  const [storyMapReleases, setStoryMapReleases] = useState(null);
  const [showHiddenStoryMapReleases, setShowHiddenStoryMapReleases] = useState(false);
  const [showHiddenPersonas, setShowHiddenPersonas] = useState(false);
  const [showHiddenGoals, setShowHiddenGoals] = useState(false);
  const [showHiddenActivities, setShowHiddenActivities] = useState(false);
  const [socketReady, setSocketReady] = useState(false);
  const [socketDisconnected, setsocketDisconnected] = useState(false);
  const [connectCount, setConnectCount] = useState(0);

  const editItems = useRef({
    personas: false,
    goals: false,
    activities: false,
    'story-map-releases': false,
    tasks: false
  });

  const loadPersonas = useCallback(
    itemsLoaderCallback(
      'personas',
      setPersonas,
      'order=order&story_map=' + storyMap.pk + (showHiddenPersonas ? '' : '&hidden=false'),
      t`Impossible to load personas`,
      editItems
    ),
    [storyMap.pk, showHiddenPersonas]
  );
  const loadGoals = useCallback(
    itemsLoaderCallback(
      'goals',
      setGoals,
      'order=order&story_map=' + storyMap.pk + (showHiddenGoals ? '' : '&hidden=false'),
      t`Impossible to load goals`,
      editItems
    ),
    [storyMap.pk, showHiddenGoals]
  );
  const loadActivities = useCallback(
    itemsLoaderCallback(
      'activities',
      setActivities,
      'order=order&story_map=' + storyMap.pk + (showHiddenActivities ? '' : '&hidden=false'),
      t`Impossible to load activities`,
      editItems
    ),
    [storyMap.pk, showHiddenActivities]
  );

  const loadTasks = useCallback((cb) => {
    if (cb) {
      setTasks(cb);
    }
    else {
      axios.get('/tasks?story_map=' + storyMap.pk).then(response => {
        for (const item of response.data.items) {
          setStateItem('tasks:' + item.pk, item, { isFull: true });
        }
        setTasks(response.data.items.map(i => i.pk));
      }).catch(err => {
        addMessage('load-tasks', t`Unknown error`, t`Impossible to load tasks`);
      });
    }
    
  }, [storyMap.pk]);

  const loadStoryMapReleases = useCallback(
    itemsLoaderCallback(
      'story-map-releases',
      setStoryMapReleases,
      'story_map=' + storyMap.pk + (showHiddenStoryMapReleases ? '' : '&hidden=false'),
      t`Impossible to load releases`,
      editItems
    ),
    [storyMap.pk, showHiddenStoryMapReleases]
  );

  useEffect(() => {
    loadTasks();
  }, [loadTasks]);

  useEffect(() => {
    loadActivities();
  }, [loadActivities]);

  useEffect(() => {
    loadGoals();
  }, [loadGoals]);
  
  useEffect(() => {
    loadPersonas();
  }, [loadPersonas]);

  useEffect(() => {
    loadStoryMapReleases();
  }, [loadStoryMapReleases]);

  useEffect(() => {
    let mounted = true;
    const socket = new PersistentSocket(
      `${process.env.REACT_APP_WS_URL}/ws/story_maps/${storyMap.pk}`,
      {
        onopen: () => {
          setSocketReady(true);
          setsocketDisconnected(false);
        },
        onfail: () => {
          if (mounted) {
            setSocketReady(true);
            setsocketDisconnected(true);
          }
        },
        onmessage: e => {
          if (e.data) {
            const data = JSON.parse(e.data).message;
            const check = (field, fieldOrder, api, loader) => {
              for (let i = 0; i < data[field].length; i++) {
                setStateItem(api + ':' + data[field][i].pk, data[field][i], { isFull: true });
              }
              if (data[fieldOrder] && !editItems.current[field]) {
                loader();
              }
              editItems.current[api] = false;
            };
            check('activities', 'activities_order_changed', 'activities', loadActivities);
            check('personas', 'personas_order_changed', 'personas', loadPersonas);
            check('goals', 'goals_order_changed', 'goals', loadGoals);
            check('releases', 'releases_order_changed', 'story-map-releases', loadStoryMapReleases);
            check('tasks', 'tasks_order_changed', 'tasks', loadTasks);
          }
        },
        onwakeup: () => {
          // this.load();
        }
      }
    );

    return () => {
      mounted = false;
      socket.close();
    };
  }, [storyMap.pk, connectCount]);

  const reconnectWebSocket = useCallback(() => {
    loadTasks();
    loadActivities();
    loadGoals();
    loadPersonas();
    loadStoryMapReleases();
    setConnectCount(c => c + 1);
  }, []);

  const contextValue = useMemo(() => ({
    tasks,
    personas,
    goals,
    activities,
    storyMapReleases,
    loadActivities,
    loadGoals,
    loadPersonas,
    loadStoryMapReleases,
    loadTasks,
    showHiddenActivities,
    showHiddenGoals,
    showHiddenPersonas,
    showHiddenStoryMapReleases,
    setShowHiddenActivities,
    setShowHiddenGoals,
    setShowHiddenPersonas,
    setShowHiddenStoryMapReleases,
    socketReady,
    socketDisconnected,
    reconnectWebSocket
  }), [tasks, personas, goals, activities, storyMapReleases,
    loadActivities, loadGoals, loadPersonas, loadStoryMapReleases, loadTasks,
    showHiddenActivities, showHiddenGoals, showHiddenPersonas, showHiddenStoryMapReleases,
    setShowHiddenActivities, setShowHiddenGoals, setShowHiddenPersonas, setShowHiddenStoryMapReleases,
    socketReady, socketDisconnected, reconnectWebSocket]);

  return (
    <StoryMapContext.Provider
      value={contextValue}>
      {props.children}
    </StoryMapContext.Provider>
  );
}

export default StoryMapContext;
