/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-lonely-if */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Dropdown, FormCheck, FormSelect } from 'react-bootstrap';
import { useLocation, useHistory } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import Icon from 'components/ui/Icon';
import QueryParams from 'utils/QueryParams';
import SelectSearch from 'components/ui/SelectSearch';
import Autocomplete from 'components/ui/Autocomplete';

function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

function useParamsFilters() {
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState();
  const [search, setSearch] = useState();

  const getFilters = useCallback(() => {
    const params = new URLSearchParams(location.search);
    const newFilters = {};
    let searchval = null;
    let newPage = null;
    params.forEach(function(value, key) {
      let val = value;
      if (value === 'true') val = true;
      if (value === 'false') val = false;
      if (value === 'null') val = 'NONE';
      if (key === 'page') {
        newPage = val;
      }
      else if (key === 'search') {
        searchval = val;
      }
      else {
        if (key in newFilters) {
          newFilters[key].push(val);
        } else {
          newFilters[key] = [val];
        }
      }
    });
    return { newFilters, searchval, newPage };
  }, [location.search]);

  const filterData = getFilters();
  const [filters, setFilters] = useState(filterData.newFilters);

  useEffect(() => {
    const { newFilters, searchval, newPage } = getFilters();
    if (newPage) setPage(newPage);
    setSearch(searchval);
    setFilters(oldFilters => (
      shallowEqual(oldFilters, newFilters) ? oldFilters : newFilters)
    );
  }, [getFilters]);
  return { filters, page, search, history };
}

const normalizeFilters = (filters) => {
  if (!('dropdown' in filters)) {
    filters.dropdown = [];
  }
  if ('base' in filters) {
    filters.base = {
      extra: [],
      autocomplete: [],
      check: [],
      checkboxes: [],
      total: 0,
      ...filters.base
    };
  }
  if ('more' in filters) {
    filters.more = {
      extra: [],
      autocomplete: [],
      check: [],
      checkboxes: [],
      total: 0,
      ...filters.more
    };
  }
  return filters;
};

function FiltersDropdown(props) {
  const { availableFilters } = props;
  const { filters, history } = useParamsFilters();
  const [showMore, setShowMore] = useState(false);

  const handleFilterClick = (field, value, exclude, inverse) => {
    const params = new QueryParams();
    if (value === null || (!inverse && value === false)) {
      params.delete(field);
    }
    else if (Array.isArray(value)) {
      for (const val of value) {
        if (exclude) {
          params.set(field, val);
        }
        else {
          params.appendOrIgnore(field, val);
        }
      }
    }
    else if (exclude) {
      params.set(field, value === 'NONE' ? null : value);
    }
    else {
      params.appendOrDelete(field, value === 'NONE' ? null : value);
    }
    history.push({ search: params.toString() });
  };

  useEffect(() => {
    const close = () => {
      setShowMore(false);
    };
    document.addEventListener('click', close);
    return () => {
      document.removeEventListener('click', close);
    };
  }, []);

  return (
    <>
      { availableFilters.more && availableFilters.more.total > 0 && (
        <button
          type="button"
          title={showMore ? t`Less filters` : t`More filters`}
          className="btn btn-outline-dark me-2"
          data-cy="more-btn"
          onClick={(e) => { e.stopPropagation(); setShowMore(v => !v); }}>
          <Icon name="funnel"/>
          <Icon name={showMore ? 'dash' : 'plus'}/>
        </button>
      ) }
      { availableFilters.dropdown.length > 0 && (
        <Dropdown className="filters-basic">
          <Dropdown.Toggle variant="light">
            <span className="d-md-none me-2">
              <Trans>Filters</Trans>
            </span>
            <Icon name="filter"/>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            { availableFilters.dropdown.map(group => (
              <React.Fragment key={group.name}>
                { group.name !== 'default' && (
                  <Dropdown.Header>{group.label}</Dropdown.Header>
                )}
                { group.items.map(item => (
                  <Dropdown.Item
                    key={item.field + '-' + item.value}
                    active={((item.field in filters) &&
                      filters[item.field].indexOf(item.value) !== -1)}
                    onClick={() => handleFilterClick(item.field, item.value)}>
                    {item.label}
                  </Dropdown.Item>
                )) }
              </React.Fragment>
            )) }
          </Dropdown.Menu>
        </Dropdown>
      ) }
      <FiltersTypes availableFilters={availableFilters.base} filters={filters} handleFilterClick={handleFilterClick} />
      { showMore && (
        <div className="filters-more" tabIndex="-1" onClick={e => { e.stopPropagation(); }}>
          <FiltersTypes
            parent=".filters-more"
            availableFilters={availableFilters.more}
            filters={filters}
            handleFilterClick={handleFilterClick} />
          <div className="filters d-none d-lg-block">
            <ActiveFilters availableFilters={availableFilters} />
          </div>
        </div>
      ) }
    </>
  );
}

function FiltersTypes(props) {
  const { availableFilters, filters, handleFilterClick, parent } = props;
  return (
    <>
      { availableFilters.checkboxes && availableFilters.checkboxes.length > 0 && (
        <FiltersCheckboxes
          filters={filters}
          checkFilters={availableFilters.checkboxes}
          filterChoose={handleFilterClick} />
      ) }
      { availableFilters.extra.length > 0 && (
        <FiltersExtra
          parent={parent}
          filters={filters}
          extraFilters={availableFilters.extra}
          filterChoose={handleFilterClick} />
      ) }
      { availableFilters.autocomplete && availableFilters.autocomplete.length > 0 && (
        <FiltersAutocomplete
          filters={filters}
          autocompleteFilters={availableFilters.autocomplete}
          filterChoose={handleFilterClick} />
      ) }
      { availableFilters.check && availableFilters.check.length > 0 && (
        <FiltersCheck
          filters={filters}
          checkFilters={availableFilters.check}
          filterChoose={handleFilterClick} />
      ) }
    </>
  );
}

function FilterSelect(props) {
  const { filter, handleSelect, value, optionNone, ...rest } = props;
  const select = useRef();
  useEffect(() => {
    if (select.current) select.current.value = value || '';
  }, [value]);
  return (
    <FormSelect
      ref={select}
      defaultValue={value}
      onChange={handleSelect}
      as="select"
      {...rest}>
      <option value="">{ t`All` }</option>
      { optionNone && (
        <option value="NONE">{ t`None` }</option>
      ) }
      { filter.data.map(item => (
        <option key={item.pk} value={item.pk}>{item.resource_name}</option>
      )) }
    </FormSelect>
  );
}

function FiltersExtra(props) {
  const { filters, extraFilters, filterChoose, parent } = props;
  return extraFilters.map(filter => (
    <div className="d-inline-block filter-foreign-key filter-labelled" key={filter.name}>
      <label htmlFor={'filter-' + filter.name}>
        { filter.label }
      </label>
      { 'api' in filter && (
        <SelectSearch
          value={filter.name in filters ? filters[filter.name][0] : null}
          onValueNotFound={() => { filterChoose(filter.name, null); }}
          validation={false}
          searchApi={filter.api}
          handleSelect={option => filterChoose(filter.name, option.pk, true)}
          id={'filter-' + filter.name}
          optionNone={filter.empty_value}
          parent={parent}
          optionAll/>
      ) }
      { 'data' in filter && (
        <FilterSelect
          value={filter.name in filters ? filters[filter.name][0] : null}
          filter={filter}
          handleSelect={e => {
            const { name, value } = e.target;
            filterChoose(name, value || null, true);
          }}
          name={filter.name}
          id={'filter-' + filter.name}
          optionNone={filter.empty_value} />
      ) }

    </div>
  ));
}

function FiltersCheck(props) {
  const { filters, checkFilters, filterChoose } = props;
  return checkFilters.map(filter => {
    const inverse = 'inverse' in filter ? filter.inverse : false;
    return (
      <div className="d-inline-block filter-checkbox" key={filter.name}>
        <FormCheck
          id={'filter-' + filter.name}
          checked={filter.name in filters ? filters[filter.name][0] : inverse}
          onChange={e => filterChoose(filter.name, e.target.checked, true, inverse)}
          label={filter.label}
          className="me-2 d-inline-block" />
      </div>
    );
  });
}

function FiltersCheckboxes(props) {
  const { filters, checkFilters, filterChoose } = props;
  return checkFilters.map(filter => (
    <div className="d-inline-block filter-checkboxes" key={filter.name}>
      <div className="label">{ filter.label }</div>
      <FiltersCheckboxesCheck
        filterChoose={filterChoose}
        filters={filters}
        checkFilters={filter} />
    </div>
  ));
}

function FiltersCheckboxesCheck(props) {
  const { filters, checkFilters, filterChoose } = props;
  const activeList = checkFilters.name in filters ? filters[checkFilters.name] : [];
  return checkFilters.items.map(opt => (
    <FormCheck
      key={opt.value}
      id={'filter-' + checkFilters.name + '-' + opt.value}
      checked={activeList.includes(opt.value)}
      onChange={e => filterChoose(checkFilters.name, opt.value)}
      label={opt.label}
      className="mb-1" />
  ));
}

function FiltersAutocomplete(props) {
  const { filters, autocompleteFilters, filterChoose } = props;

  return autocompleteFilters.map(filter => {
    let list = [];
    if (filter.field in filters) {
      if (filter.field === 'tags_and') {
        for (const str of filters[filter.field]) {
          list.push({ pk: str, resource_name: str });
        }
      }
      else {
        list = filters[filter.field];
      }
      
    }
    return (
      <div className="d-inline-block filter-autocomplete filter-labelled" key={filter.field}>
        <Autocomplete
          id={'filter-auto-' + filter.field}
          searchApi={filter.api}
          handleSelect={(items, input) => {
            const values = [];
            for (const item of items) {
              values.push(item.pk);
            }
            filterChoose(filter.field, values);
            input.value = '';
          }}
          inputLabel={filter.label}
          items={list}
          hideItems/>
      </div>
    );
  });
}

function ActiveFilters(props) {
  const { filters, history, search } = useParamsFilters();
  const { availableFilters } = props;
  const removeFilter = (name, value) => {
    const params = new QueryParams();
    if (!value) {
      params.delete(name);
    }
    else {
      params.deleteNameValue(name, value);
    }
    history.push({ search: params.toString() });
  };
  const clearSearch = () => {
    const params = new QueryParams();
    for (const filter in filters) {
      params.delete(filter);
    }
    params.delete('search');
    history.push({ search: params.toString() });
  };
  const buttons = [];
  let extra = 0;

  for (const field in filters) {
    const defaultGr = availableFilters.dropdown.find(g => g.name === 'default');
    const gr = availableFilters.dropdown.find(g => g.name === field) || 
      (('more' in availableFilters) && availableFilters.more.checkboxes.find(g => g.name === field));
    if (gr) {
      for (let i = 0; i < filters[field].length; i++) {
        const item = gr.items.find(it => it.value === filters[field][i]);
        if (item) {
          buttons.push(
            <button
              type="button"
              key={`${field}_${filters[field][i]}`}
              className="btn btn-outline-secondary"
              onClick={() => { removeFilter(field, filters[field][i]); }}>
              { item.label } <Icon name="x-circle"/>
            </button>
          );
        }
      }
      for (let i = 0; i < gr.items.length; i++) {
        if ('choices' in gr.items[i]) {
          for (let j = 0; j < filters[field].length; j++) {
            const item = gr.items[i].choices.find(it => it.value === filters[field][j]);
            if (item) {
              buttons.push(
                <button
                  type="button"
                  key={`${field}_${item.value}`}
                  className="btn btn-outline-secondary"
                  onClick={() => { removeFilter(field, item.value); }}>
                  { item.label } <Icon name="x-circle"/>
                </button>
              );
            }
          }
        }
      }
    }
    else if (defaultGr) {
      for (let i = 0; i < filters[field].length; i++) {
        const item = defaultGr.items.find(it => it.field === field);
        if (item) {
          buttons.push(
            <button
              type="button"
              key={`default_${field}}`}
              className="btn btn-outline-secondary"
              onClick={() => { removeFilter(field, filters[field][i]); }}>
              { item.label } <Icon name="x-circle"/>
            </button>
          );
        }
      }
    }
    const autoFilter = availableFilters.base.autocomplete.find(g => g.field === field) ||
      (availableFilters.more && availableFilters.more.autocomplete.find(g => g.field === field));
    if (autoFilter) {
      for (let i = 0; i < filters[field].length; i++) {
        buttons.push(
          <button
            type="button"
            key={`${field}_${filters[field][i]}`}
            className="btn btn-outline-secondary"
            onClick={() => { removeFilter(field, filters[field][i]); }}>
            { filters[field][i] } <Icon name="x-circle"/>
          </button>
        );
      }
    }
    const checkFilter = availableFilters.base.check.find(g => g.name === field) ||
      (availableFilters.more && availableFilters.more.check.find(g => g.name === field));
    if (checkFilter) {
      for (let i = 0; i < filters[field].length; i++) {
        buttons.push(
          <button
            type="button"
            key={`${field}_${filters[field][i]}`}
            className="btn btn-outline-secondary"
            onClick={() => { removeFilter(field, filters[field][i]); }}>
            { checkFilter.label } <Icon name="x-circle"/>
          </button>
        );
      }
    }
    const restFilters = [
      ...availableFilters.base.extra,
      ...(availableFilters.more ? availableFilters.more.extra : [])
    ];
    for (const f in filters) {
      if (restFilters.find(e => e.name === f)) {
        extra += 1;
      }
    }
  }
  if (search) {
    buttons.push(
      <button
        type="button"
        key="search"
        className="btn btn-outline-secondary"
        onClick={() => { removeFilter('search'); }}>
        { search } <Icon name="x-circle"/>
      </button>
    );
  }

  if (buttons.length < 1 && extra < 1) return null;
  return (
    <span className="active-filters">
      { buttons }
      <button type="button" className="btn btn-link" onClick={clearSearch}><Trans>Clear filters</Trans> <Icon name="x-circle"/></button>
    </span>
  );
}

export { useParamsFilters, FiltersExtra, FiltersDropdown, ActiveFilters, normalizeFilters };
