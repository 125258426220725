import React from 'react';
import SidebarUser from 'components/sidebar/SidebarUser';
import Page from 'components/page/Page';

export default function UserPage(props) {
  return (
    <Page PageSidebar={SidebarUser} {...props}>
      {props.children}
    </Page>
  );
}
