import React, { useCallback, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import Autocomplete from 'components/ui/Autocomplete';
import Icon from 'components/ui/Icon';

function keydownHandler (event) {
  // space key
  if (event.keyCode === 32) {
    event.preventDefault();
  }
  // enter key
  else if (event.keyCode === 13) {
    event.preventDefault();
    event.target.click();
  }
}

function keyupHandler (event) {
  // space key
  if (event.keyCode === 32) {
    event.preventDefault();
    event.target.click();
  }
}

function BacklogItemTags(props) {
  const { organization, item, readonly } = props;
  const [isEdit, setIsEdit] = useState(false);

  const onChange = useCallback(items => {
    const tags = items.map(it => it.pk);
    item.tags = tags;
    item.saveField('tags');
  }, [item]);

  if (isEdit) {
    const items = [];
    for (const tag of item.tags) {
      items.push({
        pk: tag,
        resource_name: tag
      });
    }
    return (
      <div className="field-value field-tags">
        <div className="text-secondary mb-1"><Trans>Tags</Trans></div>
        <Autocomplete
          items={items}
          searchApi={`/tags/${organization.slug}`}
          onChange={onChange}
          id="backlog-tags-edit"
          inputLabel={t`Search or add tag`}
          autocreate
          autofocus
          mode="continuous">
          <button
            type="button"
            className="bt-close-tags btn btn-outline-secondary text-small"
            onClick={() => { setIsEdit(false); }}>
            <Icon name="x-circle" title={t`Close`} />
          </button>
        </Autocomplete>
      </div>
    );
  }
  return (
    <div className="hstack field-value field-tags">
      <span className="text-secondary"><Trans>Tags</Trans></span>
      <div className="ms-auto">
        { readonly ? (
          <span className="bt-edit-tags">
            { item.tags.map(tag => (
              <span className="badge bg-light text-dark tag me-1" key={tag}>
                { tag }
              </span>
            )) }
          </span>
        ) : (
          <span
            className="bt-edit-tags"
            role="button"
            tabIndex="0"
            onKeyDown={keydownHandler}
            onKeyUp={keyupHandler}
            onClick={() => { setIsEdit(true); }}>
            { item.tags.map(tag => (
              <span className="badge bg-light text-dark tag me-1" key={tag}>
                { tag }
              </span>
            )) }
            { item.tags.length < 1 && (
              <span className="text-secondary me-3"><Trans>Add tags</Trans></span>
            ) }
            <Icon name="pencil"/>
          </span>
        )}

      </div>
    </div>

  );

}

export default BacklogItemTags;
