import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import ReleaseForm from 'components/forms/ReleaseForm';

export default function ReleaseModal(props) {
  const { title, onClose, onSave, modalTitle, show, product, release, hideButton, variant } = props;
  let formRelease = release;
  if (!formRelease) {
    formRelease = {};
    if (product) formRelease.product = { pk: product.pk };
  }
  const [showModal, setShowModal] = useState(show);
  const handleClose = () => {
    if (onClose) onClose();
    setShowModal(false);
  };
  const handleSave = (data) => {
    if (onSave) onSave(data);
    setShowModal(false);
  };
  const handleShow = (e) => {
    setShowModal(true);
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <ModalForm
      variant={variant}
      show={showModal}
      handleClose={handleClose}
      handleShow={handleShow}
      title={modalTitle || t`New release`}
      buttonTitle={title || t`Add release`}
      hideButton={hideButton}>
      <ReleaseForm
        release={formRelease}
        onSave={handleSave}
        onCancel={handleClose}>
      </ReleaseForm>
    </ModalForm>
  );
}
