import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import { Redirect } from 'react-router';
import { ForgottenPassword } from 'components/auth';
import HomePage from 'components/page/HomePage';
import AppContext from 'AppContext';

export default function ForgottenPasswordPage() {
  const { user } = useContext(AppContext);
  if (user) {
    return <Redirect to="/"/>;
  }

  return (
    <HomePage name="forgotten-form-page" title={t`Reset Password`}>
      <ForgottenPassword/>
    </HomePage>
  );
}
