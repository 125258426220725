/* eslint-disable react/jsx-no-target-blank */
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';
import { Form, FormContext, FormContextProvider } from 'components/form';
import { websiteUrl } from 'components/utils/urls';

function prefixify(str) {
  const normStr = str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toUpperCase()
    .replace(/[^A-Z ]/g, '')
    .replace(/\s{2,}/g, ' ')
    .trim();

  const arr = normStr.split(' ');
  let prefix = '';
  for (let i = 0; i < 3 && i < arr.length; i++) {
    prefix += arr[i].substring(0, 3);
  }
  return prefix;
}

function ProductFinalForm(props) {
  const { onCancel, onSave, organization } = props;
  const { item } = useContext(FormContext);
  const [prefixEdit, setPrefixEdit] = useState(item && item.pk);
  const [org, setOrg] = useState(organization);

  const onInput = (e) => {
    if (!prefixEdit) item.prefix = prefixify(e.target.value);
  };

  return (
    <Form
      name="product-add-form"
      noValidate
      onSave={onSave}>
      { !org && !item.pk && (
        <Form.Many2One
          id="organization"
          name="organization"
          api="/organizations"
          onOptionsChange={(options) => {
            if (options.length === 1) {
              // eslint-disable-next-line prefer-destructuring
              item.organization = options[0];
              setOrg(options[0]);
            }
          }}
          required />
      ) }
      <Form.Field className="full-w" id="name" name="name" onInput={onInput} required/>
      { item.pk ? (
        <>
          <em><Trans>Prefix</Trans>: </em>
          {item.prefix}
        </>
      ) : (
        prefixEdit ? (
          <Form.Field
            id="prefix"
            name="prefix"
            pattern="^[A-Z][A-Z0-9_]{0,15}$"
            help={t`The prefix must contain only uppercase letters, numbers, and underscores and begin with a letter.`}
            required />
        ) : (
          <>
            <em><Trans>Prefix</Trans>: </em>
            <Form.Field type="hidden" id="prefix" name="prefix" required />
            { item.prefix}
            <button
              data-cy="bt-edit-prefix"
              className="btn btn-outline-secondary ms-2"
              type="button"
              onClick={() => { setPrefixEdit(true); }}>
              <Trans>Edit</Trans>
            </button>
          </>
        )
      )}
      <Form.Field className="full-w" as="textarea" id="product_vision" name="product_vision"/>
      {!item.pk && (
        <Form.Select
          id="mode"
          name="mode"
          help={(
            <Trans>
              To understand the differences, please <a
                href={websiteUrl('/docs/core-concept/product/#working-method')}
                target="_blank">read the documentation</a>
            </Trans>
          )}
          required />
      )}
      <Form.Select id="estimate_mode" name="estimate_mode" required/>
      <Form.Check id="team_restricted" name="team_restricted"/>
      <div className="actions">
        <Button variant="primary" type="submit"><Trans>Save</Trans></Button>
        <Button variant="light" type="button" onClick={onCancel}><Trans>Cancel</Trans></Button>
      </div>
    </Form>
  );
}

export default function ProductForm(props) {
  const { product, organization, ...rest } = props;

  if (!('estimate_mode' in product)) {
    product.estimate_mode = {
      label: 'Points',
      value: 'points'
    };
  }
  return (
    <FormContextProvider item={product} api="/products">
      <ProductFinalForm
        organization={organization}
        {...rest} />
    </FormContextProvider>
  );
}
