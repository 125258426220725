import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import axios from 'axios';
import OrganizationContext from 'components/utils/OrganizationContext';
import Icon from 'components/ui/Icon';
import ProductPage from 'components/page/ProductPage';
import ProductContext from 'components/utils/ProductContext';
import ItemStatusModal from 'components/modal/ItemStatusModal';
import { statusNames } from 'components/utils/constants';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import { ConfirmButton } from 'components/utils';
import getSensor from 'mocks/cypressSensor';

const apiContext = { product: null };
const moveStatus = (item, action, target) => {
  const params = { move: action };
  if (action === 'above') params.object = target;
  const queryParams = new URLSearchParams(params).toString();
  return axios.put(`/item-status/${item.pk}?${queryParams}`, {});
};

function StatusList(props) {
  const { status, statusType, loading, setLoading, load, product, setRefresh, setStatus, setEditItem } = props;
  const [statusList, setStatusList] = useState(null);

  useEffect(() => {
    if (status) setStatusList(status.filter(s => s.status_type.value === statusType.value));
  }, [status, statusType.value]);

  if (status === null || statusList === null) {
    return (
      <LoaderContainer height="3"/>
    );
  }

  const onDelete = (item) => {
    setLoading(true);
    axios.delete(`item-status/${item.pk}`)
      .then(() => {
        load();
        product.reload();
      })
      .catch((error) => addMessage('delete-error', t`Unknown error`, error.response.data.message));
  };
  const onDragend = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination || source.index === destination.index) {
      return;
    }
    const pk = parseInt(draggableId, 10);
    let action = null;

    const item = statusList.find(i => i.pk === pk);

    let target = null;
    if (action === null) {
      action = 'bottom';
    }
    if (destination.index < (statusList.length - 1)) {
      action = 'above';
      target = statusList[destination.index].pk;
    }
    setLoading(true);
    moveStatus(item, action, target).then(() => {
      setLoading(false);
    }).catch(err => {
      addMessage('backlog-move-' + item.pk,
        t`Impossible to change the order of the status`,
        t`Reloading the status`);
      setStatus(null);
      setRefresh(r => r + 1);
    });
    const copiedItems = [...statusList];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setStatusList(copiedItems);
    product.reload();
  };

  const onAdd = () => {
    setLoading(true);
    product.reload();
    load();
  };

  const sensors = [];
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    sensors.push(getSensor('.item-status-list__' + statusType.value.replace('_', '-')));
  }

  const classes = ['relative', 'item-status-list'];
  classes.push('item-status-list__' + statusType.value.replace('_', '-'));
  return (
    <>
      <DragDropContext sensors={sensors} onDragEnd={onDragend}>
        <Droppable droppableId="col">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={`${classes.join(' ')}${snapshot.isDraggingOver ? ' is-drag-over' : ''}`}>
              { loading && (
                <LoaderContainer />
              ) }
              { statusList.length === 0 && (
                <div className="alert alert-primary" role="alert">
                  { statusType.value === 'in_progress' ? (
                    <Trans>You currently have no “in progress” status.
                      Please use the button below to add a new column to your kanban.</Trans>
                  ) : (
                    <Trans>You currently have no “backlog” status.
                      Please use the button below to add a new column to your product backlog.</Trans>
                  )}
                </div>
              ) }
              {statusList.map((item, index) => (
                <Draggable
                  key={item.pk}
                  draggableId={String(item.pk)}
                  index={index}>
                  {(provided2, snapshot2) => (
                    <div
                      ref={provided2.innerRef}
                      key={item.pk}
                      className={
                        `card item-status-card mb-2 ${snapshot2.isDragging ? ' is-draging' : ''}`
                      }
                      {...provided2.draggableProps}
                      {...provided2.dragHandleProps}>
                      <div className="card-body">
                        <div className="hstack">
                          <div><Icon name="arrows-move" size="16"/></div>
                          <div className="flex-grow-1">
                            <h5 className="card-title mb-0 ms-2 d-inline-block">{ item.name }</h5>
                            { item.auto_assign && (
                              <div className="d-inline-block ms-4">
                                <Icon name="check2-square" size="16"/>
                                <span className="ms-2"><Trans>Auto assign</Trans></span>
                              </div>
                            ) }
                          </div>

                          <div>
                            <button type="button" className="btn btn-outline-secondary me-2" onClick={() => { setEditItem(item); }}>
                              <Trans>Edit</Trans> <Icon name="pen"/>
                            </button>
                            <ConfirmButton
                              message={t`Are you sure you want to delete this element?`}
                              variant="outline-secondary"
                              onConfirm={() => onDelete(item)}>
                              <Trans>Delete</Trans> <Icon name="trash"/>
                            </ConfirmButton>
                          </div>
                        </div>
                      </div>
                    </div>

                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      { product.can_add_item_type && (
        <div className={`text-center my-2 actions-create-${statusType.value.replace('_', '-')}`}>
          <ItemStatusModal
            statusType={statusType}
            title={(
              <>
                <Icon name="plus"/>
                { statusType.value === 'in_progress' ? t`Add another in progress status` : t`Add another backlog status`}
              </>
            )}
            onSave={onAdd}
            product={product}
            key="item-type-add-modal" />
        </div>
      ) }
    </>
  );
}

export default function ItemStatusList() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const [editItem, setEditItem] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Item types`
  ];

  const onEditClose = () => {
    setEditItem(null);
  };
  const onSave = () => {
    setEditItem(null);
    setLoading(true);
    product.reload();
    load();
  };
  const load = useCallback(() => {
    axios.get('/item-status?product=' + product.pk + '&status_type=in_progress&status_type=pre_backlog').then((res) => {
      setStatus(res.data.items);
      setLoading(false);
    }).catch((error) => {
      addMessage('load-status', t`Unknown error`, t`Impossible to load status list`);
    });
  }, [product.pk]);
  apiContext.product = product.pk;

  useEffect(() => {
    load();
  }, [load, refresh]);
  return (
    <ProductPage
      name="item-types-list-page"
      title={t`Item types`}
      breadcrumb={breadcrumb}>
      <ItemStatusModal
        itemStatus={editItem}
        product={product}
        onClose={onEditClose}
        onSave={onSave}
        show={!!editItem}
        modalTitle={t`Edit status`}
        hideButton />

      <div className="">
        { product.enable_backlog && (
          <>
            <StatusList
              status={status}
              statusType={{ value: 'pre_backlog' }}
              loading={loading}
              setLoading={setLoading}
              load={load}
              product={product}
              setRefresh={setRefresh}
              setStatus={setStatus}
              setEditItem={setEditItem} />
            <div className="card bg-light mb-2">
              <div className="card-body">
                <div className="hstack">
                  <h5 className="card-title mb-0 me-3">{statusNames.backlog}</h5>
                  <p className="card-text text-secondary"><Trans>locked</Trans></p>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="card bg-light mb-2">
          <div className="card-body">
            <div className="hstack">
              <h5 className="card-title mb-0 me-3">{ statusNames.todo }</h5>
              <p className="card-text text-secondary"><Trans>locked</Trans></p>
            </div>
          </div>
        </div>
        <StatusList
          status={status}
          statusType={{ value: 'in_progress' }}
          loading={loading}
          setLoading={setLoading}
          load={load}
          product={product}
          setRefresh={setRefresh}
          setStatus={setStatus}
          setEditItem={setEditItem} />
        <div className="card bg-light mb-2">
          <div className="card-body">
            <div className="hstack">
              <h5 className="card-title mb-0 me-3">{ statusNames.done }</h5>
              <p className="card-text text-secondary"><Trans>locked</Trans></p>
            </div>
          </div>
        </div>
        <div className="card bg-light mb-2">
          <div className="card-body">
            <div className="hstack">
              <h5 className="card-title mb-0 me-3">{ statusNames.closed }</h5>
              <p className="card-text text-secondary"><Trans>locked</Trans></p>
            </div>
          </div>
        </div>
      </div>
    </ProductPage>
  );
}
