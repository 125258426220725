import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import Icon from './Icon';

function fillArray(arr, from, to) {
  for (let i = from; i <= to; i++) arr.push(i);
}

function getPagesArray(page, count, ellipsisSize, ellipsis) {
  const pages = [];
  
  if (ellipsis && ellipsisSize > 0) {
    if (page <= (ellipsisSize + 3)) {
      const max = Math.min(page + ellipsisSize, count);
      const diff = count - max;
      fillArray(pages, 1, max);
      if (diff > 0) {
        if (diff < 3) pages.push(max + 1);
        if (diff === 2) pages.push(max + 2);
        if (diff > 2) {
          pages.push('ellip1');
          pages.push(count);
        }
      }
    }
    else {
      pages.push(1);
      pages.push('ellip1');
      const noEllipEnd = page >= count - (ellipsisSize + 2);
      const end = noEllipEnd ? count : page + ellipsisSize;
      fillArray(pages, page - ellipsisSize, end);
      if (!noEllipEnd) {
        pages.push('ellip2');
        pages.push(count);
      }
    }
  }
  return pages;
}

function PaginationMini(props) {
  const { page, count, onNav } = props;
  return (
    <>
      { page > 2 && (
        <li>
          <button type="button" className="page-link prevnext" onClick={() => onNav(1)}>
            <Icon name="chevron-double-left" title={t`Go to start`} />
          </button>
        </li>
      ) }
      { page > 1 && (
        <li>
          <button type="button" className="page-link prevnext" onClick={() => onNav(page - 1)}>
            <Icon name="chevron-left" title={t`Go to previous`} />
          </button>
        </li>
      ) }
      { page < count && (
        <li>
          <button type="button" className="page-link prevnext" onClick={() => onNav(page + 1)}>
            <Icon name="chevron-right" title={t`Go to next`} />
          </button>
        </li>
      ) }
      { page < count - 1 && (
        <li>
          <button type="button" className="page-link prevnext" onClick={() => onNav(count)}>
            <Icon name="chevron-double-right" title={t`Go to end`} />
          </button>
        </li>
      ) }
    </>
  );
}
function PaginationDefault(props) {
  const { page, count, ellipsisSize, ellipsis, activeLink, onNav } = props;
  const [pages, setPages] = useState([]);

  useEffect(() => {
    setPages(getPagesArray(parseInt(page, 10), parseInt(count, 10), ellipsisSize, ellipsis));
  }, [page, count, ellipsisSize, ellipsis]);
  
  return pages.map((item) => {
    if (String(item).substring(0, 5) === 'ellip') {
      return <li key={page + '-' + item} className="page-item ellipsis"><span className="page-link">…</span></li>;
    }
    const liclasses = ['page-item'];
    if (activeLink && item === parseInt(page, 10)) liclasses.push('active');
    return (
      <li key={page + '-' + item} className={liclasses.join(' ')}>
        <button type="button" className="page-link" onClick={() => onNav(item)}>{ item }</button>
      </li>
    );
  });
}

function Pagination(props) {
  const { type, ...rest } = props;
  const { count } = rest;

  if (count < 2) return null;

  const ulClasses = 'pagination pagination--' + type + (('className' in props) ? ' ' + props.className : '');
  return (
    <ul className={ulClasses}>
      { type === 'mini' ? (
        <PaginationMini {...rest} />
      ) : (
        <PaginationDefault {...rest} />
      )}
    </ul>
  );
}

Pagination.defaultProps = {
  type: 'default',
  ellipsisSize: 2,
  ellipsis: true,
  activeLink: true
};

export default Pagination;
