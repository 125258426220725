import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormContext } from './FormContext';
import BaseField from './BaseField';

export default function Field({ children, ...props }) {
  const { item, version, setItem, errors } = useContext(FormContext);
  // eslint-disable-next-line no-unused-vars
  const { label, id, changeHandler, containerClassName, ...fieldProps } = props;
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setValidated(false);
  }, [version]);

  const handleChange = e => {
    const { name, value } = e.target;
    setValidated(true);
    item[name] = value;
    if (changeHandler) changeHandler(name, value, item);
    setItem(item);
  };

  const getValue = () => item[props.name];

  const groupClasses = ['form-group'];
  if (props.required) groupClasses.push('required');
  if (validated && !errors[props.name]) groupClasses.push('was-validated');

  if ('type' in fieldProps && fieldProps.type === 'hidden') {
    return (
      <Form.Control
        value={getValue() || ''}
        id={id}
        {...fieldProps}>{ children }</Form.Control>
    );
  }
  return (
    <BaseField
      {...props}
      className={groupClasses.join(' ')}
      label={label}
      humanValue={getValue()}>
      <Form.Control
        value={getValue() || ''}
        onChange={handleChange}
        isInvalid={!!errors[props.name]}
        id={id}
        {...fieldProps}>{ children }</Form.Control>
    </BaseField>
  );
}
Field.defaultProps = {
  type: 'text',
};
