import React, { useContext, useEffect, useState } from 'react';
import { Link, Route, Switch, useHistory } from 'react-router-dom';
import { Button, Form, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import { t, Trans } from '@lingui/macro';
import Icon from 'components/ui/Icon';
import OrganizationContext from 'components/utils/OrganizationContext';
import { ConfirmButton } from 'components/utils';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import AdminPage from 'components/page/AdminPage';
import copyTextToClipboard from 'components/utils/copyTextToClipboard';
import NotFoundPage from 'pages/NotFoundPage';

function GitLabWebhookSettings(props) {
  const history = useHistory();
  const { organization } = props;
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(null);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/integrations`}><Trans key="2">Integrations</Trans></Link>,
    <span key="3">GitLab</span>
  ];

  useEffect(() => {
    axios.get(`/integrations/gitlab_webhook/${organization.slug}/settings`).then(res => {
      setSettings(res.data);
    }).catch(err => {
      addMessage('load-gitlab-settings', t`Unknown error`, t`Can't load settings`);
    });
    setLoading(false);
  }, [organization.slug]);

  const renewToken = () => axios.post(`/integrations/gitlab_webhook/${organization.slug}/renew-token`);

  const handleRenewToken = e => {
    e.preventDefault();
    renewToken().then(res => {
      setSettings(res.data);
    }).catch(error => {
      addMessage(
        'gitlab-renew-app-token-failed', t`GitLab renew app token`, t`Secret token generation failed.`);
    });
  };

  const copyToken = () => {
    copyTextToClipboard(settings.secret_token);
    addMessage(
      'gitlab-copy-token', t`GitLab`, t`Secret token copied in your clipboard.`);
  };

  const handleInstall = e => {
    e.preventDefault();
    setLoading(true);
    renewToken().then(res => {
      setSettings(res.data);
      organization.reload().then(() => {
        setLoading(false);
      }).catch((err) => {
        addMessage('org-load', t`Unknown error`, t`Impossible to load organization`);
        setLoading(false);
      });
    }).catch(error => {
      addMessage(
        'gitlab-renew-secret-token-failed', t`GitLab renew secret token`, t`Secret token generation failed.`);
    });
  };

  const handleUninstall = () => {
    setLoading(true);
    axios.delete(`/integrations/gitlab_webhook/${organization.slug}`).then(res => {
      addMessage(
        'gitlab-uninstall-success', t`GitLab uninstall`, t`GitLab successfully uninstalled.`);
      organization.reload().then(() => {
        setLoading(false);
        history.push(`/${organization.slug}/integrations`);
      }).catch((err) => {
        addMessage('org-load', t`Unknown error`, t`Impossible to load organization`);
      });
    }).catch(error => {
      addMessage(
        'gitlab-uninstall-failed', t`GitLab uninstall`, t`GitLab uninstallation failed.`);
    });
  };

  const WEBHOOK_URL = `${process.env.REACT_APP_API_URL}/api/integrations/gitlab_webhook/${organization.slug}/webhook`;

  return (
    <AdminPage breadcrumb={breadcrumb} name="gitlab-page gitlab-page-config" title={t`GitLab integration`}>
      { settings === null ? (
        <LoaderContainer height="4" />
      ) : (
        <div className="relative">
          { loading && (
            <LoaderContainer />
          ) }
          {settings.secret_token ? (
            <>
              <div className="content-block-header">
                <h3>GitLab Webhooks</h3>
                <Trans>
                  In GitLab, activate webhooks on a project or on a group
                  (Settings → Webhooks), with following settings:
                  <ul>
                    <li>URL: <code>{WEBHOOK_URL}</code></li>
                    <li>Secret token: <i>The following secret token.</i></li>
                    <li>Checked triggers: Push events (All branches) / Merge request events / Pipeline events.</li>
                    <li>Enable SSL verification: Checked.</li>
                  </ul>
                </Trans>
              </div>
              <Form.Label htmlFor="secret_key"><Trans>Optera.io secret token</Trans></Form.Label>
              <InputGroup className="mb-3 input-group-secret-token">
                <Form.Control
                  required
                  name="secret_token"
                  id="secret_token"
                  autoComplete="new-password"
                  value={settings.secret_token}
                  disabled/>
                <Button
                  variant="primary"
                  id="button-addon2"
                  onClick={copyToken}>
                  <Icon name="clipboard"/>
                </Button>
                <Button variant="secondary" id="button-renew-token" onClick={handleRenewToken}>
                  <Trans>Renew</Trans>
                </Button>
              </InputGroup>
              <div className="gitlab-settings-actions mt-4">
                <ConfirmButton
                  variant="danger"
                  message={t`Are you sure you want to uninstall GitLab integration?`}
                  confirmLabel={t`Uninstall`}
                  confirmVariant="danger"
                  onConfirm={handleUninstall}>
                  <Trans>Uninstall GitLab integration</Trans>
                </ConfirmButton>
              </div>
            </>
          ) : (
            <div className="gitlab-settings-actions">
              <Button
                variant="primary"
                onClick={handleInstall}>
                <Trans>Install GitLab integration</Trans>
              </Button>
            </div>
          )}
        </div>
      ) }
    </AdminPage>
  );
}

export default function GitLabPage(props) {
  const organization = useContext(OrganizationContext);
  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>
  ];

  return (
    <Switch>
      <Route exact path="/:orgSlug/integrations/gitlab_webhook">
        <GitLabWebhookSettings organization={organization}/>
      </Route>
      <Route>
        <NotFoundPage breadcrumb={breadcrumb} />
      </Route>
    </Switch>
  );
}
