import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import UserPage from 'components/page/UserPage';
import OrganizationList from 'components/organization/OrganizationList';
import Icon from 'components/ui/Icon';

export default function OrganizationsListPage(props) {
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    t`My organizations`
  ];
  return (
    <UserPage
      name="organizations-list-page"
      title={t`My organizations`}
      breadcrumb={breadcrumb}>
      <div className="mb-3">
        <Link className="btn btn-primary" to="organizations/add"><Icon name="plus-circle"/> <Trans>Add organization</Trans></Link>
      </div>
      <OrganizationList columns="4" />
    </UserPage>
  );
}
