import React, { useCallback, useMemo, useState } from 'react';

const BacklogModalContext = React.createContext(null);

export function BacklogModalContextProvider(props) {
  const [item, setItem] = useState(null);
  const [show, setShow] = useState('show' in props ? props.show : null);
  const [isPrevented, setIsPrevented] = useState(false);
  const [preventCloseList, setPreventCloseList] = useState([]);

  const showModal = useCallback((el, opts) => {
    if (opts) {
      const newItem = { ...el };
      if ('parent' in opts) newItem._parent = opts.parent;
      if ('isModal' in opts) newItem._isModal = opts.isModal;
      if ('onClose' in opts) newItem._onClose = opts.onClose;
      setPreventCloseList([]);
      setItem(newItem);
    }
    else {
      setPreventCloseList([]);
      setItem(el);
    }
    setShow('view');
  }, []);

  const hideModal = useCallback((force) => {
    if (!force) {
      if (preventCloseList.length < 1) {
        if (item._onClose) item._onClose();
        setShow(false);
      }
      else {
        setIsPrevented(true);
      }
    }
    else {
      setPreventCloseList([]);
      setShow(false);
    }
  }, [item, preventCloseList]);

  const addPreventClose = useCallback(id => {
    if (preventCloseList.includes(id)) return;
    setPreventCloseList([...preventCloseList, id]);
  }, [preventCloseList]);
  
  const removePreventClose = useCallback(id => {
    if (!preventCloseList.includes(id)) return;
    setPreventCloseList(preventCloseList.filter(el => el !== id));
  }, [preventCloseList]);

  const contextValue = useMemo(() => ({
    show,
    item,
    showModal,
    hideModal,
    addPreventClose,
    removePreventClose,
    isPrevented,
    setIsPrevented
  }), [show, item, showModal, hideModal, addPreventClose, removePreventClose, isPrevented]);

  return (
    <BacklogModalContext.Provider
      value={contextValue}>
      {props.children}
    </BacklogModalContext.Provider>
  );
}

export default BacklogModalContext;
