import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import { setStateItem } from 'utils/Item';
import { LoaderContainer } from 'components/ui/Loader';
import FieldShowUpdate from 'components/form/FieldShowUpdate';

function slugify(str, separator = '-', size = 8) {
  const s = str
    .toString()
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .trim()
    .replace(/\s+/g, separator);
  if (str.length > size) {
    const parts = s.split(separator);
    if (parts.length === 1) {
      return s.substr(0, size);
    }
    let final = parts[0];
    const finalCheck = (fstring) => {
      if (fstring.length > (size + 12)) {
        return fstring.substr(0, size);
      }
      return fstring;
    };
    for (let i = 1; i < parts.length; i++) {
      if (final.length > size) {
        return finalCheck(final);
      }
      final += '-' + parts[i];
    }
    return finalCheck(final);
  }
  return s;
}

const nameSize = 20;
export const gitReplacements = {
  '(TYPE)': {
    desc: t`Item type reference: feature, bug, …`,
    process: vals => vals.backlogItem.item_type.git_checkout_prefix || slugify(vals.backlogItem.item_type.resource_name)
  },
  '(REFERENCE)': {
    desc: t`Item reference: MYPROD-234`,
    process: vals => vals.backlogItem.resource_reference
  },
  '(NAME)': {
    desc: <Trans>Item name: sort-items-by-name, limited to {nameSize} characters.</Trans>,
    process: vals => slugify(vals.backlogItem.resource_name, '-', nameSize)
  },
};

export const defaultGitPattern = '(TYPE)-(REFERENCE)-(NAME)';

export function generateGitBranch(backlogItem, product) {
  const params = { backlogItem, product };
  let branchName = product.git_checkout_pattern;
  for (const replace in gitReplacements) {
    branchName = branchName.replace(replace, gitReplacements[replace].process(params));
  }
  return branchName;
}

function GitPatternFields(props) {
  const { entity, defaultPattern, isOrg } = props;
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const activate = () => {
    setLoading(true);
    axios.put(entity._genericApi + '/' + entity.pk, { git_checkout_pattern: defaultPattern }).then(res => {
      setStateItem(entity._getStateKey(), res.data, { isFull: true });
      setLoading(false);
    }).catch(err => {
      addMessage('product-update-gitpattern', t`Unknown error`, t`Impossible to update product`);
      setLoading(false);
    });
  };

  if (!entity.git_checkout_pattern) {
    return (
      <>
        <div>
          <button type="button" className="btn btn-light" onClick={activate}>
            { isOrg ? t`Set template` : t`Enable`}
            
          </button>
          { (loading) && (
            <LoaderContainer size="tiny"/>
          ) }
        </div>
        <p className="text-small">
          { isOrg ? (
            <Trans>Click to define a default pattern for your products.</Trans>
          ) : (
            <Trans>Click enable to allow the git checkout command to be copied from the backlog item.</Trans>
          ) }
          
        </p>
      </>
    );
  }
  return (
    <>
      <FieldShowUpdate
        className="fill-width"
        stateKey={entity._getStateKey()}
        pk={entity.pk}
        api={entity._genericApi}
        field="git_checkout_pattern"
        label={t`Git checkout pattern`}
        attributes={{ 'data-cy': 'bt-git-checkout-pattern' }}
        onStateChange={setIsEdit}>
        { (ent) => (
          <span className="text-small d-inline-block my-2">
            { ent.git_checkout_pattern }
          </span>
        ) }
      </FieldShowUpdate>
      { isEdit && (
        <>
          <h5 className="h6 my-2"><Trans>Available variables:</Trans></h5>
          <ul>
            { Object.entries(gitReplacements).map(([key, value]) => (
              <li key={key}>{key}: {value.desc}</li>
            )) }
          </ul>
        </>
      ) }
    </>
  );
}
GitPatternFields.defaultProps = {
  defaultPattern: defaultGitPattern,
  isOrg: false
};
export { GitPatternFields };
