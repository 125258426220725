import React, { useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import { LoaderContainer } from 'components/ui/Loader';
import uploadFile from 'components/utils/uploadFiles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function BacklogItemImportModalContent(props) {
  const { onCancel, product, organization } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const handleSelectFile = e => {
    setLoading(true);
    uploadFile('backlog-item-imports', e.target.files[0], {
      onEditUpload: data => {
        setLoading(false);
        history.push(`/${organization.slug}/${product.slug}/import/${data.pk}`);
      },
      onError: err => setLoading(false),
      data: {
        product: { pk: product.pk }
      }
    });
  };
  return (
    <div className="relative">
      { loading && (
        <LoaderContainer />
      ) }
      <div className="form-group">
        <label htmlFor="import-file" className="form-label">{ t`File` }</label>
        <input
          type="file"
          className="form-control"
          id="import-file"
          accept=".csv,.xlsx,.ods"
          onChange={(e) => { handleSelectFile(e); }} />
        <div className="form-text">
          <Trans>Import compatible file: csv, xlsx</Trans>
        </div>
      </div>
      <button className="btn btn-outline-secondary" type="button" onClick={() => onCancel()}><Trans>Cancel</Trans></button>
    </div>
  );
}

function BacklogItemImportModal(props) {
  const { product, organization, isDropDown, show, onClose, hideButton, buttonTitle } = props;
  const [isShown, setShow] = useState(!!show);
  const handleCancel = () => {
    if (onClose) onClose(false);
    setShow(false);
  };
  const handleShow = (e) => {
    setShow(true);
  };
  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <ModalForm
      show={isShown}
      handleClose={handleCancel}
      handleShow={handleShow}
      title={t`Import items`}
      variant={props.variant || ''}
      isDropDown={isDropDown}
      buttonTitle={buttonTitle || t`Import items`}
      className="modal-about"
      hideButton={hideButton}>
      <BacklogItemImportModalContent
        onCancel={handleCancel}
        product={product}
        organization={organization}>
      </BacklogItemImportModalContent>
    </ModalForm>
  );
}
BacklogItemImportModal.defaultProps = {
  isDropDown: false
};

export default BacklogItemImportModal;
