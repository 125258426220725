import React, { useContext, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import { Form, FormContext, FormContextProvider } from 'components/form';
import { Alert, Button } from 'react-bootstrap';
import Icon from 'components/ui/Icon';

function MemberAddFinalForm(props) {
  const { onCancel, onSave, organization } = props;
  const { item } = useContext(FormContext);

  const exclude_roles = [];
  if ((item.role ? item.role.value === 'role_guest' : true) &&
      organization.is_members_limit_reached) {
    exclude_roles.push('role_member', 'role_account_manager', 'role_organization_manager');
  }
  if ((item.role ? item.role.value !== 'role_guest' : true) &&
      organization.is_guests_limit_reached) {
    exclude_roles.push('role_guest');
  }

  return (
    <Form
      name="member-add-form"
      noValidate
      onSave={onSave}>
      <Form.Field
        id="email"
        name="email"
        label={t`Emails`}
        type="text"
        as="textarea"
        rows={5}
        className="w-100"
        placeholder="user1@example.com&#10;user2@example.com"/>
      {exclude_roles.includes('role_member') && (
        <Alert variant="warning"><Trans>
          You cannot add members because the organization’s membership limit has been reached.
        </Trans></Alert>
      )}
      {exclude_roles.includes('role_guest') && (
        <Alert variant="warning"><Trans>
          You cannot add guests because the organization’s guests number limit has been reached.
        </Trans></Alert>
      )}
      <Form.Select id="role" name="role" excludes={exclude_roles} required/>
      <div className="actions">
        <Button variant="primary" type="submit"><Trans>Save</Trans></Button>
        <Button variant="light" type="button" onClick={onCancel}><Trans>Cancel</Trans></Button>
      </div>
    </Form>
  );
}

function MemberAddForm(props) {
  const { organization, ...rest } = props;
  return (
    <FormContextProvider item={{ organization: organization }} api="/members">
      <MemberAddFinalForm
        organization={organization}
        {...rest} />
    </FormContextProvider>
  );
}

export default function MemberAddModal(props) {
  const { title, onClose, onSave, modalTitle, show, organization, hideButton, variant } = props;
  const [showModal, setShowModal] = useState(show);
  const handleClose = () => {
    if (onClose) onClose();
    setShowModal(false);
  };
  const handleSave = (data) => {
    if (onSave) onSave(data);
    setShowModal(false);
  };
  const handleShow = (e) => {
    setShowModal(true);
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <ModalForm
      variant={variant}
      show={showModal}
      handleClose={handleClose}
      handleShow={handleShow}
      title={modalTitle || t`Invite new members`}
      buttonTitle={title || (
        <><Icon name="plus"/><Trans>Invite new members</Trans></>
      )}
      hideButton={hideButton}>
      <MemberAddForm
        onSave={handleSave}
        onCancel={handleClose}
        organization={organization}>
      </MemberAddForm>
    </ModalForm>
  );
}
