import { Trans, t } from '@lingui/macro';
import React, { useState } from 'react';
import Icon from 'components/ui/Icon';
import badgeColor from './badgeColor';

function CustomAccordion(props) {
  const { body, header, opened } = props;
  const [isOpened, setIsOpened] = useState(!!opened);
  return (
    <div className="custom-accordion">
      <div className="custom-accordion__header d-flex align-items-center justify-content-between py-2">
        <div className="header">
          { header }
        </div>
        <button
          type="button"
          className="btn btn-sm align-middle btn-outline-secondary"
          onClick={() => { setIsOpened(!isOpened); }}>
          { isOpened ? t`Hide` : t`View more` }
          {' '}
          <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} />
        </button>
      </div>
      { isOpened && (
        <div className="custom-accordion__body">
          { body }
        </div>
      ) }
    </div>
  );
}
export default function BacklogItemGitLab(props) {
  const { data } = props;

  return (
    <div className="git-tab-data row">
      <div className="col-12 col-md-6">
        {data.merge_requests.length > 0 && (
          <div>
            <h4 className="block-title"><Trans>Merge requests</Trans></h4>
            <ul className="list-group mb-4">
              { data.merge_requests.slice(0).reverse().map((mr) => (
                <li key={mr.id} className="list-group-item">
                  <a href={mr.url}>!{mr.iid}</a>{' '}
                  {mr.title}
                  <span className={`badge border ${badgeColor(mr.state)} ms-2`}>
                    {mr.state.replaceAll('_', ' ')}
                  </span>
                  <div className="text-secondary">{mr.source_branch}</div>
                </li>
              )) }
            </ul>
          </div>
        )}
        {data.branches.length > 0 && (
          <div>
            <h4 className="block-title"><Trans>Branches</Trans></h4>
            <ul className="list-group mb-4">
              { data.branches.map((branch) => (
                <li key={branch.name} className="list-group-item">
                  <a href={`${branch.project_url}/-/tree/${branch.name}`}>{branch.name}</a>
                </li>
              )) }
              { data.branches.map((branch) => (
                <li key={branch.name} className="list-group-item">
                  <a href={`${branch.project_url}/-/tree/${branch.name}`}>{branch.name}</a>
                </li>
              )) }
            </ul>
          </div>
        )}
        {data.commits.length > 0 && (
          <div>
            <h4 className="block-title"><Trans>Commits</Trans></h4>
            <ul className="list-group mb-4">
              { data.commits.map((commit) => (
                <li key={commit.id} className="list-group-item">
                  <a href={commit.url}>{commit.id}</a><br/>
                  {commit.title}
                </li>
              )) }
            </ul>
          </div>
        )}
      </div>
      <div className="col-12 col-md-6">
        {data.pipelines.length > 0 && (
          <div>
            <h4 className="block-title"><Trans>Pipelines</Trans></h4>
            { data.pipelines.slice(0).reverse().map((pipe) => (
              <CustomAccordion
                key={pipe.id}
                header={(
                  <>
                    <a href={`${pipe.project_url}/-/pipelines/${pipe.id}`}>#{pipe.id}</a>
                    <span className={`badge border ${badgeColor(pipe.status)} ms-2`}>
                      {pipe.status.replaceAll('_', ' ')}
                    </span>
                  </>
                )}
                body={(
                  <table className="table auto">
                    { pipe.jobs.map((job) => (
                      <tr key={job.id}>
                        <th>{job.stage}</th>
                        <td className="text-secondary">{job.name}</td>
                        <td>
                          <span className={`badge big border ${badgeColor(job.status)} ms-2`}>
                            {job.status.replaceAll('_', ' ')}
                          </span>
                        </td>
                      </tr>
                    )) }
                  </table>
                )}>
              </CustomAccordion>
            )) }
          </div>
        )}
      </div>
    </div>
  );
}
