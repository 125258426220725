import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import TriggerConditionForm from 'components/forms/TriggerConditionForm';
import Icon from 'components/ui/Icon';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import React, { useCallback, useEffect, useState } from 'react';

function TriggerConditionsList(props) {
  const { items, setEditCondition, loadConditions } = props;
  if (items.length < 1) return null;
  const removeItem = item => {
    axios.delete('/trigger-conditions/' + item.pk)
      .then(() => {
        loadConditions();
      })
      .catch(() => {
        addMessage('trigger-condition-delete-' + item.pk, t`Unknown error`, t`Impossible to remove condition`);
      });
    
  };

  return (
    <div>
      { items.map((item) => (
        <div key={item.pk} className="hstack gap-3 mb-1">
          { item.resource_name }
          <div className="actions ms-auto">
            
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary"
              onClick={() => { setEditCondition(item); }} >
              <Icon name="pencil" /> <Trans>Edit</Trans>
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-secondary ms-2"
              onClick={() => { removeItem(item); }} >
              <Icon name="x-circle"/> <Trans>Remove</Trans>
            </button>
          </div>
        </div>
      )) }
    </div>
  );
}
function TriggerConditions(props) {
  const { trigger } = props;
  const [editCondition, setEditCondition] = useState(null);
  const [addCondition, setAddCondition] = useState(null);
  const [conditions, setConditions] = useState(null);
 
  const loadConditions = useCallback(() => {
    setConditions(null);
    axios.get('/trigger-conditions?trigger=' + trigger.pk)
      .then(res => {
        setConditions(res.data);
      });
  }, [trigger.pk]);

  useEffect(() => {
    loadConditions();
  }, [loadConditions]);
  
  const onConditionSave = (data) => {
    setAddCondition(null);
    setEditCondition(null);
    loadConditions();
  };
  return (
    <>
      { conditions === null ? (
        <LoaderContainer height="2" />
      ) : (
        <TriggerConditionsList loadConditions={loadConditions} items={conditions.items} setEditCondition={setEditCondition} />
      ) }
      <div></div>
      { editCondition ? (
        <TriggerConditionForm
          trigger={trigger}
          onSave={onConditionSave}
          triggerCondition={editCondition}
          onCancel={() => setEditCondition(null)} />
      ) : (
        addCondition ? (
          <TriggerConditionForm trigger={trigger} onSave={onConditionSave} onCancel={() => setAddCondition(false)} />
        ) : (
          <button type="button" className="btn btn-primary" onClick={() => { setAddCondition(v => !v); }}>
            <Trans>Add a condition</Trans>
          </button>
        )
      )}
    </>
  );
}

export default TriggerConditions;
