import React, { useContext, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { Trans, t } from '@lingui/macro';
import StoryMapTaskModal from 'components/modal/StoryMapTaskModal';
import Icon from 'components/ui/Icon';
import StoryMapContext from './StoryMapContext';
import StoryMapTask from './StoryMapTask';

function StoryMapCell(props) {
  const { rows, storyMapRelease, activityPk, emptyActivity } = props;
  const { loadTasks } = useContext(StoryMapContext);
  const [showAll, setShowAll] = useState(false);

  const classes = ['story-map-cell'];
  classes.push((activityPk !== null || emptyActivity) ? 'activity' : 'no-activity');
  const onSaveTask = () => {
    loadTasks();
  };
  const row = storyMapRelease ? rows[storyMapRelease.pk] : rows.NONE;
  if (!emptyActivity && activityPk === null) {
    return (
      <div className={classes.join(' ')}>

      </div>
    );
  }
  const garbageCell = emptyActivity || storyMapRelease === null;
  const taskLength = row.columns[activityPk].length;
  const droppableId = 'cell-' + (storyMapRelease ? storyMapRelease.pk : 'NONE') + '-' + activityPk;
  return (
    <div className={classes.join(' ')} data-colid={droppableId}>
      <Droppable droppableId={droppableId} direction="vertical" type="tasks">
        {(provided, snapshot) => (
          <div
            className={'story-map-tasks droppable-container' + (snapshot.isDraggingOver ? ' is-drag-over' : '')}
            ref={provided.innerRef}>
            { garbageCell && taskLength > 2 && (
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => { setShowAll(s => !s); }}>
                { showAll ? t`Hide tasks` : (
                  <>
                    <Trans>Show tasks</Trans>
                    <span className="badge bg-light ms-2">{ taskLength }</span>
                  </>
                )}
              </button>
            ) }
            { (!garbageCell || taskLength < 3 || showAll) && row.columns[activityPk].map((taskPk, index) => (
              <Draggable
                key={taskPk}
                draggableId={'t-' + String(taskPk)}
                index={index}>
                {(provided2, snapshot2) => (
                  <StoryMapTask key={'t-' + taskPk} isDragging={snapshot2.isDragging} pk={taskPk} provided={provided2} />
                )}
              </Draggable>
            )) }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      { !garbageCell && (
        <StoryMapTaskModal
          api="tasks"
          variant="outline-secondary add-btn"
          title={<Icon name="plus" title={t`New task`} />}
          modalTitle={t`Add task`}
          storyMap={storyMapRelease.story_map}
          storyMapRelease={storyMapRelease}
          onSave={onSaveTask}
          activity={{ pk: activityPk }} />
      ) }
      
    </div>
  );
}
StoryMapCell.defaultProps = {
  emptyActivity: false
};

export default StoryMapCell;
