import React from 'react';

function LoaderContainer(props) {
  const classes = ['loader-container'];
  if (props.height) classes.push('height-' + props.height);
  if (props.position) classes.push(props.position);
  if (props.static) classes.push('static');
  if (props.fullpage) classes.push('fullpage');
  return (
    <div className={classes.join(' ')}>
      <Loader size={props.size}/>
    </div>
  );
}

function Loader(props) {
  const classes = ['loader'];
  if (props.size) classes.push(props.size);
  if (props.className) classes.push(props.className);
  return (
    <div className={classes.join(' ')} data-testid="loader"><div></div><div></div><div></div><div></div></div>
  );
}

export { Loader, LoaderContainer };
