/* eslint-disable react/jsx-no-constructed-context-values */
/* @TODO, fix no-constructed-context-values error
  https://app.optera.io/opteraio/opteraio/item/OPT-867 */

import React, { Component } from 'react';
import axios from 'axios';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { t } from '@lingui/macro';
import * as Sentry from '@sentry/react';
import { dynamicActivate, defaultLocale } from 'utils/i18n';
import User from 'entity/User';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import HomePage from 'components/page/HomePage';

const AppContext = React.createContext();

export class AppContextProvider extends Component {
  constructor(props) {
    super(props);
    const defaults = 'defaults' in props ? props.defaults : {};
    this.state = {
      user: null,
      appLoaded: false,
      languages: [],
      lang: {
        pk: localStorage.getItem('lang_pk'),
        name: localStorage.getItem('lang_name')
      },
      loading: false,
      ...defaults
    };
    this.urlLang = null;
  }

  logout() {
    this.setState({ loading: true });
    axios.get('/auth/logout').then(res => {
      this.setState({
        loading: false,
        user: null,
      });
    }).catch((e) => {
      if (e.response && e.response.status === 401) {
        this.setState({
          loading: false,
          user: null,
        });
      }
      else {
        this.setState({
          loading: false,
        });
        addMessage('logout', t`Unknown error`, t`Impossible to logout`);
      }
    });

  }

  setUser(user) {
    const userInstance = new User();
    userInstance.loadFromObject(user);
    this.setState({
      user: userInstance
    });
  }

  storeLang(lang) {
    localStorage.setItem('lang_pk', lang.pk);
    localStorage.setItem('lang_name', lang.name);
  }

  setLang(lang) {
    this.storeLang(lang);
    this.setState({
      lang: {
        pk: lang.pk,
        name: lang.name,
      }
    });
    dynamicActivate(lang.pk);
  }

  getLanguages() {
    return new Promise((resolve, reject) => {
      axios.get('/locales/languages').then(res => {
        if (this.urlLang || !this.state.lang.pk) {
          // If no language was previously selected, select it from API response.
          // The content-language of API response is determined by browser
          // Accept-Language but limited to API available languages.
          this.setLang(res.data.items.find(
            l => l.pk === res.headers['content-language'].toLowerCase()));
        }
        this.setState({
          languages: [...res.data.items]
        }, () => {
          resolve();
        });
      }).catch((e) => {
        reject(e);
      });
    });
  }

  componentDidMount() {
    if (this.state.appLoaded) return;

    const params = new URLSearchParams(window.location.search);
    if (params.get('lang')) {
      this.urlLang = params.get('lang');
    }
    axios.interceptors.request.use(
      request => {
        request.baseURL = `${process.env.REACT_APP_API_URL}/api`;
        request.withCredentials = true;
        request.headers['X-Custom-Language'] = this.urlLang || this.state.lang.pk;
        return request;
      },
      error => Promise.reject(error)
    );
    axios.interceptors.response.use(response => response, error => {
      if (error.response && error.response.status === 401) {
        if (error.response.config.url !== '/users/profile' && error.response.config.url !== '/auth/logout') {
          this.logout();
        }
      }
      if (error.response && error.response.status === 403) {
        error.response.data.message = t`You don't have the permission to perform this action`;
      }
      if (error.response && error.response.status === 500) {
        Sentry.captureException(error);
      }
      return Promise.reject(error);
    });

    const loaded = {
      languages: false,
      user: false
    };

    const check = () => {
      if (loaded.languages && loaded.user) {
        this.setState({ appLoaded: true });
      }
    };

    axios.get('/users/profile').then(res => {
      loaded.user = true;
      const user = new User();
      user.loadFromObject(res.data);
      this.setState({ user: user });
      check();
    }).catch(err => {
      loaded.user = true;
      check();
    });
    this.getLanguages().then(() => {
      dynamicActivate(this.state.lang.pk || defaultLocale).then(() => {
        loaded.languages = true;
        check();
      });
    }).catch(() => {
      dynamicActivate(defaultLocale).then(() => {
        loaded.languages = true;
        check();
      });
    });
  }

  render() {
    const { children } = this.props;
    if (!this.state.appLoaded) {
      return (
        <>
          <LoaderContainer position="bg-transparent" fullpage />
          <HomePage name="page-loading">
            
          </HomePage>
        </>
      );
    }

    return (
      <I18nProvider i18n={i18n}>
        <AppContext.Provider
          value={{
            ...this.state,
            logout: this.logout.bind(this),
            setUser: this.setUser.bind(this),
            setLang: this.setLang.bind(this),
          }}>
          { this.state.loading && (
            <LoaderContainer fullpage />
          ) }
          {children}
        </AppContext.Provider>
      </I18nProvider>
    );
  }
}

export default AppContext;
