import { i18n } from '@lingui/core';
import { en, fr } from 'make-plural/plurals';

export const locales = {
  en: 'English',
  fr: 'Français',
};

i18n.loadLocaleData({
  en: { plurals: en },
  fr: { plurals: fr },
})

export const defaultLocale = 'en';

/**
* We do a dynamic import of just the catalog that we need
* @param locale any locale string
*/
export function dynamicActivate(locale) {
  return new Promise((resolve, reject) => {
    ((() => {
      if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ) {
        if (locale === 'fr') {
          import("messages.js!=!@lingui/loader!locales/fr/messages.po");
        } else {
          import("messages.js!=!@lingui/loader!locales/en/messages.po");
        }
      }
      return import(`locales/${locale}/messages`);
    })()).then(res => {
      i18n.load(locale, res.messages);
      i18n.activate(locale);
      resolve();
    }).catch(err => {
      console.error(err);
      window.location.reload();
    });
  });
}
