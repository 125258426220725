import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import { FormCheck } from 'react-bootstrap';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import ProductPage from 'components/page/ProductPage';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import StoryMapContext, { StoryMapContextProvider } from 'components/storymap/StoryMapContext';
import StoryMapTable from 'components/storymap/StoryMapTable';
import BacklogItemStorymapModal from 'components/modal/BacklogItemStorymapModal';

function StoryMapFilters(props) {
  const { showHiddenActivities, showHiddenGoals, showHiddenPersonas, showHiddenStoryMapReleases,
    setShowHiddenActivities, setShowHiddenGoals, setShowHiddenPersonas, setShowHiddenStoryMapReleases } = useContext(StoryMapContext);

  return (
    <div>
      <span className="me-2"><Trans>Show</Trans>:</span>
      <FormCheck
        type="checkbox"
        id="hidden_releases"
        label={t`Hidden releases`}
        value={showHiddenStoryMapReleases}
        onChange={e => { setShowHiddenStoryMapReleases(e.target.checked); }}/>
      <FormCheck
        type="checkbox"
        id="hidden_personas"
        label={t`Hidden personas`}
        value={showHiddenPersonas}
        onChange={e => { setShowHiddenPersonas(e.target.checked); }}/>
      <FormCheck
        type="checkbox"
        id="hidden_goals"
        label={t`Hidden goals`}
        value={showHiddenGoals}
        onChange={e => { setShowHiddenGoals(e.target.checked); }}/>
      <FormCheck
        type="checkbox"
        id="hidden_activities"
        label={t`Hidden activities`}
        value={showHiddenActivities}
        onChange={e => { setShowHiddenActivities(e.target.checked); }}/>
    </div>
  );
}
function StoryMapPageContent(props) {
  const { rightSidebar, setRightSidebar, storyMap } = props;
  const { goals, tasks, activities, personas, storyMapReleases } = useContext(StoryMapContext);
  
  if (goals === null || tasks === null || activities === null || personas === null || storyMapReleases === null) {
    return <LoaderContainer height="4" />;
  }
  return (
    <>
      
      <div className="storymap-filters d-flex justify-content-between align-items-center mb-2">
        <div className="pe-2">
          <StoryMapFilters />
        </div>
        <BacklogItemStorymapModal
          status="backlog"
          onClose={() => {}}/>
      </div>
      <StoryMapTable
        storyMap={storyMap}
        rightSidebar={rightSidebar}
        setRightSidebar={setRightSidebar} />
    </>

  );
}

export default function StoryMapPagePage(props) {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const { storyMapPk } = useParams();
  const [storyMap, setStoryMap] = useState(null);
  const [rightSidebar, setRightSidebar] = useState(false);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}/storymap`}><Trans>Story maps</Trans></Link>,
    storyMap ? storyMap.name : t`Story map`
  ];

  const extra = {};
  if (rightSidebar) extra.className = 'sidebar-right';

  useEffect(() => {
    axios.get('/story-maps/' + storyMapPk).then(response => {
      setStoryMap(response.data);
    }).catch(e => {
      addMessage('story-map-load', t`Unknown error`, t`Impossible to load story map`);
    });
  }, [storyMapPk]);

  return (
    <ProductPage
      name="storymap-page"
      title={storyMap ? storyMap.name : t`Story map`}
      breadcrumb={breadcrumb}
      {...extra}>
      { storyMap ? (
        <StoryMapContextProvider storyMap={storyMap}>
          <StoryMapPageContent
            storyMap={storyMap}
            rightSidebar={rightSidebar}
            setRightSidebar={setRightSidebar} />
        </StoryMapContextProvider>
      ) : (
        <LoaderContainer height="3" />
      ) }
      
    </ProductPage>
  );

}
