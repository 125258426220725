import React, { useCallback, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';
import { Form, FormContext, FormContextProvider } from 'components/form';
import { addMessage } from 'components/ui/Messages';

function slugify(str, separator = '-', size = 8) {
  return str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .trim()
    .substr(0, size)
    .replace(/\s+/g, separator);
}

function EpicFinalForm(props) {
  const { onCancel, onSave, product, organization } = props;
  const { item } = useContext(FormContext);
  const [shortNameEdit, setShortNameEdit] = useState(item && item.pk);

  const onNameChange = (name, value, formItem) => {
    if (!shortNameEdit) formItem.short_name = slugify(value);
  };

  const productField = product ? '' : (
    <Form.Many2One id="product" name="product" api={'/products?organization=' + organization.pk} required />
  );

  const onDelete = useCallback(function () {
    item.delete().then(() => {
      addMessage(
        'delete-epic-' + item.pk,
        t`Item removed`,
        t`${item.name} has been removed.`
      );
      onSave();
    }).catch(err => {
      addMessage(
        'delete-epic-error-' + item.pk,
        t`Unknown error`,
        t`Impossible to remove this epic.`
      );
      onCancel();
    });
  }, [item, onCancel, onSave]);

  return (
    <Form
      name="backlogitem-add-form"
      noValidate
      onSave={onSave}>
      {productField}
      <Form.Field id="name" name="name" changeHandler={onNameChange} required />
      { shortNameEdit ? (
        <Form.Field
          id="short_name"
          name="short_name"
          pattern="[a-zA-Z0-9_\-]{0,15}"
          help={t`The prefix must contain only letters, numbers, hyphens and underscores.`}
          required />
      ) : (
        <Form.ErrorCollector field="short_name" className="mt-1 mb-2">
          <em><Trans>Short name</Trans>: </em>
          { item.short_name}
          <button className="btn btn-outline-secondary ms-2" type="button" onClick={() => { setShortNameEdit(true); }}>
            <Trans>Edit</Trans>
          </button>
        </Form.ErrorCollector>
      )}
      <Form.Color name="color" id="color" required/>
      <div className="actions">
        <Button variant="primary" type="submit"><Trans>Save</Trans></Button>
        {item.pk && (
          <Button variant="outline-secondary" type="button" onClick={onDelete}><Trans>Delete</Trans></Button>
        )}
        <Button variant="outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></Button>
      </div>
    </Form>
  );
}

export default function EpicForm(props) {
  const { organization, epic, ...rest } = props;

  if (props.product && epic) {
    epic.product = props.product;
  }

  return (
    <FormContextProvider item={epic} api="/epics">
      <EpicFinalForm organization={organization} {...rest} />
    </FormContextProvider>
  );
}
