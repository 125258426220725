/* eslint-disable prefer-destructuring */
import React from 'react';
import { Trans } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';
import RichContent from 'components/form/RichContent';

function SprintReviewFinalForm(props) {
  const { product, onSave, onBeforeSave, field } = props;
  return (
    <Form
      name="sprint-review-form"
      noValidate
      onBeforeSave={onBeforeSave}
      onSave={onSave}>
      <RichContent id={field} product={product} name={field} nolabel />
      <div className="actions">
        <button className="btn btn-primary" type="submit"><Trans>Save</Trans></button>
      </div>
    </Form>
  );
}

const SprintReviewForm = function(props) {
  const { onSave, onBeforeSave, product, sprint, field } = props;

  return (
    <FormContextProvider item={sprint} api="/sprints">
      <SprintReviewFinalForm
        product={product}
        onBeforeSave={onBeforeSave}
        onSave={onSave}
        field={field} />
    </FormContextProvider>
  );
};
SprintReviewForm.defaultProps = {
  onBeforeSave: () => {}
};

export default SprintReviewForm;
