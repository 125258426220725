import React, { useContext, useEffect, useState } from 'react';
import * as BS from 'react-bootstrap';
import axios from 'axios';
import { useHistory } from 'react-router';
import { t } from '@lingui/macro';
import { LoaderContainer } from 'components/ui/Loader';
import { FormContext } from './FormContext';
import Field from './Field';
import CustomField from './CustomField';
import Check from './Check';
import Select from './Select';
import Many2One from './Many2One';
import Color from './Color';
import ErrorCollector from './ErrorCollector';

const manageFormErrors = function(fields, error, mergeFieldsErrors) {
  const lErrors = [];
  const fErrors = {};
  if (!error.response) {
    lErrors.push({ key: 'unknown-error', error: t`Unknown error` });
    return {
      globalErrors: lErrors,
      fieldsErrors: fErrors
    };
  }
  if (error.response.status === 422) {
    // Display a global error message for each validation errors
    // not linked to a specific field:
    for (const k in error.response.data) {
      if (k === '__all__') {
        if (Array.isArray(error.response.data.__all__)) {
          error.response.data.__all__.forEach(
            (m, i) => { lErrors.push({ key: 'global-' + i, error: m }); });
        } else {
          lErrors.push({ key: 'global', error: error.response.data.__all__ });
        }
      }
      // Display validation errors for unregistered fields
      // as global error message:
      else {
        if ((fields && !fields.includes(k) && k !== 'message') || mergeFieldsErrors) {
          lErrors.push({ key: 'error-' + k, error: error.response.data[k] });
        }
        fErrors[k] = error.response.data[k];
      }
    }
  }
  if (error.response.data.message) {
    lErrors.push({ key: 'error-message', error: error.response.data.message });
  }
  return {
    globalErrors: lErrors,
    fieldsErrors: fErrors
  };
};

const FormGlobalErrors = function(props) {
  const { errors } = props;
  if (errors.length < 1) return null;
  return (
    <div className="mb-2">
      { errors.map((e) => (
        <BS.Alert variant="danger" key={e.key}>{e.error}</BS.Alert>
      )) }
    </div>
  );
};

function Form(props) {
  const state = useContext(FormContext);
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const { onSave, onBeforeSave, onCancel, staticContext, name, isNotReady, ...formProps } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isMetaLoaded, setIsMetaLoaded] = useState(false);
  const [localErrors, setLocalErrors] = useState([]);

  const save = () => {
    if (!state.api) {
      return new Promise((resolve, reject) => {
        resolve({ data: state.item });
      });
    }
    if (state.item.pk) {
      return axios.put(`${state.api}/${state.item.pk}`, state.item);
    }
    return axios.post(state.api, state.item);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setLocalErrors([]);
    if (onBeforeSave) {
      onBeforeSave(state.item);
    }

    ((typeof state.item.save === 'function') ? state.item.save(state.item) : save())
      .then(res => {
        setIsLoading(false);
        state.setErrors({});
        pushOrCall(onSave, res.data, !state.item.pk, e.nativeEvent.submitter);
      })
      .catch(error => {
        const { globalErrors, fieldsErrors } = manageFormErrors([...state.fields, ...state.errorFields], error);
        state.setErrors(fieldsErrors);
        if (globalErrors.length) setLocalErrors(globalErrors);
        setIsLoading(false);
      });
  };

  const pushOrCall = (handle, data, created, submitter) => {
    if (handle.call && handle.apply) {
      handle(data, created, submitter);
    } else {
      history.push(handle);
    }
  };

  useEffect(() => {
    if (state.meta) setIsMetaLoaded(true);
  }, [state.meta]);

  if (!isMetaLoaded) {
    return (
      <div className="relative">
        <LoaderContainer height="2"/>
      </div>
    );
  }

  const isLoaderShown = isNotReady || isLoading;

  return (
    <BS.Form className="relative" onSubmit={handleSubmit} {...formProps}>
      {isLoaderShown && (
        <LoaderContainer/>
      )}
      <FormGlobalErrors errors={localErrors} />
      { props.children }
    </BS.Form>
  );
}

export { manageFormErrors, FormGlobalErrors };

export default Object.assign(Form, {
  Field: Field,
  CustomField: CustomField,
  Check: Check,
  Select: Select,
  Many2One: Many2One,
  Color: Color,
  ErrorCollector: ErrorCollector
});
