import React, { useContext, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { t, Trans } from '@lingui/macro';
import AppContext from 'AppContext';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import HomePage from 'components/page/HomePage';

export default function SlackInstall(props) {
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [slug, setSlug] = useState(null);

  const handleInstall = e => {
    e.preventDefault();
    setLoading(true);
    axios.put(`/integrations/slack/${slug}/settings`).then(res => {
      window.location.href = res.data.url;
    }).catch(error => {
      setLoading(false);
      addMessage(
        'slack-install-failed', t`Slack install`, t`Slack installation failed.`);
    });
  };
  const members = user.memberships.filter(
    m => m.roles.some(r => r[0] === 'role_account_manager'));

  return (
    <HomePage name="slack-page slack-page-install" title={t`Slack integration`}>
      <div className="relative">
        { loading && (
          <LoaderContainer />
        ) }
        { members.length === 0 ? (
          <Trans>No organization available. You must be an account manager
            on an organization to install this integration.</Trans>
        ) : (
          <>
            <Form.Group className="form-group">
              <Form.Label><Trans>Organization</Trans></Form.Label>
              <select
                className="form-select d-inline-block"
                required
                onInput={(e) => {
                  setSlug(e.target.value);
                }}>
                { members.map(m => (
                  <option key={m.organization.pk} value={m.organization.resource_slug}>
                    { m.organization.resource_name }</option>
                )) }
              </select>
            </Form.Group>
            <Button
              variant="primary"
              onClick={handleInstall}
              disabled={slug === null}>
              <Trans>Install Slack integration</Trans>
            </Button>
          </>
        ) }
      </div>
    </HomePage>
  );
}
