import React, { useCallback, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { LoaderContainer } from 'components/ui/Loader';
import getBacklogItems from 'entity/BacklogItems';
import ContentBlock from 'components/content/ContentBlock';
import Icon from 'components/ui/Icon';
import BacklogItem from 'entity/BacklogItem';
import { addMessage } from 'components/ui/Messages';
import BacklogItemForm from 'components/forms/BacklogItemForm';
import { setStateItem } from 'utils/Item';
import BacklogItemTeaser from 'components/product/BacklogItemTeaser';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import axios from 'axios';
import getSensor from 'mocks/cypressSensor';

function BacklogItemFormContainer(props) {
  const { backlogItem, ...rest } = props;

  const newItem = {
    parent: backlogItem,
    product: props.product.pk,
    status: backlogItem.status,
    item_type: backlogItem.item_type
  };
  if (backlogItem.sprint) {
    rest.sprint = backlogItem.sprint;
  }

  return (
    <BacklogItemForm showCreateMessage={false} backlogitem={newItem} {...rest} />
  );
}

function SubtasksListReorder(props) {
  const { setSubtasks, subset, backlogItem, isModal, product } = props;
  const items = [...subset];

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (!destination || destination.index === source.index) {
      return;
    }
    const pk = parseInt(draggableId, 10);
    const item = items.find(i => i.pk === pk);

    const params = {
      move_child: 'top'
    };
    const obj = {};

    if (destination.index > 0) {
      params.move_child = 'below';
      if (destination.index > source.index) {
        params.object = items[destination.index].pk;
      }
      else {
        params.object = items[destination.index - 1].pk;
      }
      
    }
    axios.put(backlogItem._genericApi + '/' + item.pk + '?' + new URLSearchParams(params).toString(), obj).catch(err => {
      addMessage('subtasks-move-' + item.pk,
        t`Unknown error`,
        t`Impossible to change the order of the backlog items`);
    });

    setSubtasks(oldTasks => {
      const newTasks = [...oldTasks];
      const [removed] = newTasks.splice(source.index, 1);
      newTasks.splice(destination.index, 0, removed);
      return newTasks;
    });
  };

  const sensors = [];
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') sensors.push(getSensor('.subtasks__list'));

  return (
    <DragDropContext sensors={sensors} onDragEnd={onDragEnd}>
      <Droppable droppableId="subtasks-order">
        {(provided, snapshot) => (
          <div className={'subtasks__list' + (snapshot.isDraggingOver ? ' is-drag-over' : '')} ref={provided.innerRef}>
            { items.map((subtask, index) => (
              <Draggable
                key={subtask.pk}
                draggableId={String(subtask.pk)}
                index={index}>
                {(provided2, snapshot2) => (
                  <div
                    ref={provided2.innerRef}
                    id={'bl-subtask-' + subtask.pk}
                    className={
                      'card flex-grow-1 backlog-item-teaser backlog-item-subtask mb-1 is-child' +
                      (snapshot2.isDragging ? ' is-draging' : '') +
                      (subtask.pk === backlogItem.pk ? ' is-current' : '')
                    }
                    key={subtask.pk}
                    {...provided2.draggableProps}
                    {...provided2.dragHandleProps}>
                    <BacklogItemTeaser
                      parent={backlogItem}
                      isModal={isModal}
                      layout="subtask"
                      hideMore={subtask.pk === backlogItem.pk}
                      backlogItem={subtask}
                      product={product}
                      showStatus />
                  </div>
                )}
              </Draggable>
            )) }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function SubtasksList(props) {
  const { subset, backlogItem, isModal, product } = props;

  if (backlogItem.subtasks_count > 0 && subset.length > 0) {
    return <SubtasksListReorder {...props} />;
  }

  return (
    <div className="subtasks__list">
      { subset.map(subtask => (
        <div
          key={subtask.pk}
          className={'card flex-grow-1 backlog-item-teaser backlog-item-subtask mb-1 ' + (subtask.parent ? 'is-child' : 'is-parent')
            + (subtask.pk === backlogItem.pk ? ' is-current' : '')}>
          <BacklogItemTeaser
            parent={backlogItem}
            isModal={isModal}
            layout="subtask"
            hideMore={subtask.pk === backlogItem.pk}
            backlogItem={subtask}
            product={product}
            showStatus />
        </div>
      )) }
    </div>
  );
}

export default function BacklogItemSubtasks(props) {
  const { backlogItem, readonly, product, isModal, onEdit, onEditClose, ...rest } = props;
  const [loading, setLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [subtasks, setSubtasks] = useState(null);
  const [viewMore, setViewMore] = useState(false);

  const setShowAddFormHandler = useCallback((show) => {
    if (show) {
      if (onEdit) onEdit('addsubtask-' + backlogItem.pk);
    }
    else if (onEditClose) {
      onEditClose('addsubtask-' + backlogItem.pk);
    }
    setShowAddForm(show);
  }, [onEdit, onEditClose, backlogItem.pk]);

  const loadSubtasks = useCallback(() => {
    if (backlogItem.subtasks_count) {
      setLoading(true);
      getBacklogItems(backlogItem.product.pk, { params: [['parent', backlogItem.pk], ['order', 'child_item']] }).then((response) => {
        setSubtasks(response);
        setLoading(false);
      });
    }
    else if (backlogItem.parent) {
      setLoading(true);
      const blitem = new BacklogItem();
      let parent = null;
      let children = null;
      const check = () => {
        if (parent !== null && children !== null) {
          setSubtasks([parent, ...children]);
          setLoading(false);
        }
      };
      blitem.load(backlogItem.parent.pk).then(() => {
        parent = blitem;
        check();
      }).catch(() => {
        addMessage('load-parent-' + backlogItem.parent.pk, t`Unknown error`, t`Impossible to load parent item`);
      });
      getBacklogItems(backlogItem.product.pk, { params: [['parent', backlogItem.parent.pk], ['order', 'child_item']] }).then((response) => {
        children = response;
        check();
      });
    }
  }, [backlogItem.pk, backlogItem.subtasks_count, backlogItem.parent, backlogItem.product.pk]);

  const check_estimate_refresh = 'c-' + (backlogItem.hours_estimate ? backlogItem.hours_estimate.minutes : '0') +
    '-' + (backlogItem.points_estimate ? backlogItem.points_estimate : '0');

  useEffect(() => {
    loadSubtasks();
  }, [loadSubtasks, check_estimate_refresh, backlogItem.item_type.pk]);

  const needViewMore = subtasks && subtasks.length > 5;
  const subset = (needViewMore && !viewMore) ? subtasks.slice(0, 5) : subtasks;

  const actions = [];
  if (!(showAddForm || readonly || backlogItem.parent)) {
    actions.push(
      <button key="add-link" type="button" className="btn btn-outline-secondary" onClick={() => { setShowAddFormHandler(true); }}>
        <Icon name="plus"/> <Trans>Add subtask</Trans>
      </button>
    );
  }

  const onSubtaskSaved = (data) => {
    setStateItem('backlog-items:' + backlogItem.pk, { subtasks_count: backlogItem.subtasks_count + 1 });
    const blitem = new BacklogItem();
    blitem.load(backlogItem.pk).then(() => {
      setStateItem('backlog-items:' + backlogItem.pk, blitem);
    }).catch(() => {
      addMessage('reload-item-' + backlogItem.pk, t`Unknown error`, t`Impossible to reload item`);
    });
    setShowAddFormHandler(false);
  };

  return (
    <div className="content-block subtasks">
      <ContentBlock
        title={backlogItem.parent ? t`Parent task` : t`Subtasks`}
        className="subtasks bordered small-head mt-2 mb-2 relative"
        actions={actions}>
        { loading && (
          <LoaderContainer />
        ) }
        { showAddForm && (
          <BacklogItemFormContainer
            backlogItem={backlogItem}
            product={product}
            onSave={onSubtaskSaved}
            onCancel={() => {
              setShowAddFormHandler(false);
            }}
            {...rest}/>
        ) }
        { subset && !showAddForm && (
          <SubtasksList setSubtasks={setSubtasks} subset={subset} product={product} backlogItem={backlogItem} isModal={isModal} />
        ) }
        { needViewMore && (
          <div className="mt-2 text-center">
            <button type="button" className="btn btn-outline-secondary" onClick={() => { setViewMore(v => !v); }}>
              { viewMore ? t`View less` : t`View more` }
            </button>
          </div>
        ) }

      </ContentBlock>
    </div>
  );
}
