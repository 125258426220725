import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { t, Trans } from '@lingui/macro';
import { useHistory } from 'react-router-dom';
import useQuery from 'utils/hooks';
import { addMessage } from 'components/ui/Messages';
import HomePage from 'components/page/HomePage';
import { LoaderContainer } from 'components/ui/Loader';

export default function ValidateEmailPage(props) {
  const { uidb64, token } = useQuery();
  const [response, setResponse] = useState();
  const history = useHistory();

  useEffect(() => {
    axios.get(`/auth/validate-email/${uidb64}/${token}`).then((res) => {
      addMessage(
        'email-validated',
        t`Email validated`,
        t`Your email has been successfully changed.`);
      history.push('/');
    }).catch(err => {
      setResponse(t`Impossible to validate email address`);
    });
  }, [uidb64, token, history]);

  return (
    <HomePage name="validate-email-page" title={t`Validate email`}>
      { response ? (
        <>
          <h4><Trans>Validation error</Trans></h4>
          <div className="alert alert-error" role="alert">
            { response }
          </div>
        </>
      ) : (
        <>
          <h4><Trans>Validating…</Trans></h4>
          <div className="relative">
            <LoaderContainer height="2"/>
          </div>
        </>
      ) }
    </HomePage>
  );
}
