import React, { useContext, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router';
import { Dropdown, Modal } from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';
import Icon from 'components/ui/Icon';
import { ConfirmButton } from 'components/utils';
import BacklogItemParentForm from 'components/forms/BacklogItemParentForm';
import ProductContext from 'components/utils/ProductContext';
import { addMessage } from 'components/ui/Messages';
import BacklogModalContext from 'components/modal/BacklogModalContext';
import { setStateItem } from 'utils/Item';
import BacklogItem from 'entity/BacklogItem';
import BulkPrintModal from 'components/modal/BulkPrintModal';
import BulkChangeProductModal from 'components/modal/BulkChangeProductModal';

function ChooseParentBtn(props) {
  const { backlogItem, contextItem } = props;
  const [show, setShow] = useState(false);
  return (
    <>
      <Dropdown.Item as="button" onClick={() => { setShow(true); }}>
        <Trans>Set parent task</Trans>
      </Dropdown.Item>
      <Modal
        show={show}
        onHide={() => { setShow(false); }}
        animation={false}
        className="upper-modal"
        backdropClassName="upper-modal"
        centered>
        <Modal.Body>
          <BacklogItemParentForm
            backlogItem={backlogItem}
            onSave={(data) => {
              setStateItem('backlog-items:' + backlogItem.pk, { parent: data.parent });
              if (contextItem && ('_parent' in contextItem)) {
                const blitem = new BacklogItem();
                blitem.load(contextItem._parent.pk).then(() => {
                  setStateItem('backlog-items:' + contextItem._parent.pk, blitem);
                }).catch(() => {
                  addMessage('reload-item-' + contextItem._parent.pk, t`Unknown error`, t`Impossible to reload item`);
                });
              }
              setShow(false);
            }}
            onBeforeSave={(item) => { item._events = {}; }}
            onCancel={() => { setShow(false); }}/>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default function BacklogItemActions(props) {
  const { backlogItem, isModal, contextItem } = props;
  const product = useContext(ProductContext);
  const backlogModalContext = useContext(BacklogModalContext);
  const history = useHistory();
  const onClose = isModal ? backlogModalContext.hideModal : () => {
    history.push('/' + product.organization.resource_slug + '/' + product.slug);
  };

  const actionsList = [];
  if (backlogItem) {
    if (product.can_delete_item) {
      actionsList.push({
        key: 'delete',
        content: t`Delete`,
        as: ConfirmButton,
        attrs: {
          variant: 'danger',
          confirmLabel: t`Delete`,
          message: (
            <Trans>
              <p>Are you sure you want to delete this backlog item?</p>
              <p>Deleting a backlog item will definitively delete all comments, link, attachments and worklogs.</p>
            </Trans>
          ),
          onConfirm: () => {
            axios.delete(`${backlogItem._genericApi}/${backlogItem.pk}`)
              .then(() => {
                addMessage(
                  'item-delete-success' + backlogItem.pk,
                  t`Item deleted`,
                  (<Trans>«<em>{ backlogItem.title }</em>» has been deleted</Trans>)
                );
                if (contextItem && ('_parent' in contextItem)) {
                  if (contextItem._isModal) {
                    backlogModalContext.showModal(contextItem._parent);
                  }
                  else {
                    const blitem = new BacklogItem();
                    blitem.load(contextItem._parent.pk).then(() => {
                      setStateItem('backlog-items:' + contextItem._parent.pk, blitem);
                    }).catch(() => {
                      addMessage('reload-item-' + contextItem._parent.pk, t`Unknown error`, t`Impossible to reload item`);
                    });
                    onClose();
                  }
                }
                else {
                  onClose();
                }
              })
              .catch((err) => {
                console.error(err);
                addMessage('item-delete-' + backlogItem.pk, t`Unknown error`, t`Impossible to delete item`);
              });
          }
        }
      });
    }
    if (product.can_edit_item && backlogItem.parent) {
      actionsList.push({
        key: 'unlink',
        content: t`Convert into a main task`,
        as: ConfirmButton,
        attrs: {
          variant: 'danger',
          confirmLabel: t`Convert into a main task`,
          message: (
            <Trans>
              <p>Are you sure you want to convert this sub backlog item into a main backlog item?</p>
            </Trans>
          ),
          onConfirm: () => {
            backlogItem.parent = null;
            backlogItem.saveField('parent').then(() => {
              if (contextItem && ('_parent' in contextItem)) {
                const blitem = new BacklogItem();
                blitem.load(contextItem._parent.pk).then(() => {
                  setStateItem('backlog-items:' + contextItem._parent.pk, blitem);
                }).catch(() => {
                  addMessage('reload-item-' + contextItem._parent.pk, t`Unknown error`, t`Impossible to reload item`);
                });
              }
            });
          }
        }
      });

    }
    if (product.can_edit_item && backlogItem.subtasks_count < 1) {
      actionsList.push({
        button: <ChooseParentBtn contextItem={contextItem} backlogItem={backlogItem} key="ChooseParent" />
      });
    }
    if (product.can_print_items) {
      actionsList.push({
        button: <BulkPrintModal dropdownItem pks={[backlogItem.pk]} key="print"/>
      });
    }
    if (product.can_edit_item) {
      actionsList.push({
        button: <BulkChangeProductModal dropdownItem pks={[backlogItem.pk]} key="change-product"/>
      });
    }
  }

  if (actionsList.length < 1) return null;
  return (
    <Dropdown className="backlogitem-actions-dropdown">
      <Dropdown.Toggle variant="white">
        <Icon name="three-dots" />
      </Dropdown.Toggle>
      <Dropdown.Menu align="end">
        { actionsList.map(bt => {
          if (bt.button) return bt.button;
          const attrs = bt.attrs || {};
          if (bt.onClick) attrs.onClick = bt.onClick;
          if (!bt.as) {
            attrs.className = 'btn btn-outline-secondary';
          }
          return (
            <Dropdown.Item as={bt.as || 'button'} key={bt.key} onClick={bt.onClick} {...attrs}>
              { bt.content }
            </Dropdown.Item>
          );
        }) }
      </Dropdown.Menu>
    </Dropdown>
  );
}
