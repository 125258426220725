import { useCallback, useEffect, useRef, useState } from 'react';

const useStickyHeader = (defaultSticky = false) => {
  const [isSticky, setIsSticky] = useState(defaultSticky);
  const [stickyTop, setStickyTop] = useState(0);
  const tableRef = useRef(null);

  const handleScroll = useCallback(({ top, bottom, y }) => {
    if (top <= 0 && bottom > 2 * 68) {
      if (!isSticky) setIsSticky(true);
      setStickyTop(Math.round(-1 * y));
    } else if (isSticky) {
      setIsSticky(false);
    }
  }, [isSticky]);

  useEffect(() => {
    const handleScroll2 = () => {
      handleScroll(tableRef.current.getBoundingClientRect());
    };
    window.addEventListener('scroll', handleScroll2);

    return () => {
      window.removeEventListener('scroll', handleScroll2);
    };
  }, [handleScroll]);

  return { tableRef, isSticky, stickyTop };
};

export default useStickyHeader;
