import React, { useState, useRef } from 'react';
import * as BS from 'react-bootstrap';
import { Trans, t } from '@lingui/macro';

export default function ConfirmButton({
  message, children,
  confirmLabel, confirmVariant,
  onConfirm, onCancel, confirmValue,
  confirmValueLabel,
  noButton,
  ...buttonProps }) {
  const [show, setShow] = useState(!!noButton);
  const [confirmInvalid, setConfirmInvalid] = useState(false);
  const confirmRef = useRef(null);

  const handleShow = () => setShow(true);

  const handleCancel = () => {
    setShow(false);
    if (onCancel && onCancel.call && onCancel.apply) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    if (confirmValue && confirmRef.current.value !== confirmValue) {
      setConfirmInvalid(true);
      return;
    }
    setShow(false);
    if (onConfirm && onConfirm.call && onConfirm.apply) {
      onConfirm();
    }
  };

  return (
    <>
      {/* Animations is buggy because of strict mode, need a future version
      of Transition in react-bootstrp dependencies to work */}
      <BS.Modal
        show={show}
        onHide={handleCancel}
        animation={false}
        className="upper-modal"
        backdropClassName="upper-modal"
        centered>
        <BS.Modal.Body>
          <div>{message}</div>
          {confirmValue && (
            <>
              <BS.Form.Label htmlFor="confirm-value">{confirmValueLabel || t`Confirm Value`}</BS.Form.Label>
              <BS.FormControl
                ref={confirmRef}
                className={`form-control w-100 ${confirmInvalid ? 'is-invalid' : ''}`}
                type="text"
                aria-label="Confirm Value"
                id="confirm-value"/>
              { confirmInvalid && (
                <BS.Form.Control.Feedback type="invalid">
                  <Trans>Wrong value, you should type <em>{ confirmValue }</em></Trans>
                </BS.Form.Control.Feedback>
              ) }
            </>
          )}
        </BS.Modal.Body>
        <BS.Modal.Footer>
          <BS.Button variant="secondary" onClick={handleCancel}>
            <Trans>Cancel</Trans>
          </BS.Button>
          <BS.Button variant={confirmVariant || 'primary'} data-cy="bt-confirm-modal" onClick={handleConfirm}>
            {confirmLabel || (<Trans>Confirm</Trans>)}
          </BS.Button>
        </BS.Modal.Footer>
      </BS.Modal>
      {!noButton && (
        <BS.Button {...buttonProps} onClick={handleShow}>{children}</BS.Button>
      )}
    </>
  );
}
