import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

import * as serviceWorker from 'serviceWorkerRegistration';

// t('A new version is available!')
// t('Please update the app.')
// t('Update')
const texts = {
  en: {
    title: 'A new version is available!',
    txt: 'Please update the app.',
    reload: 'Update',
  },
  fr: {
    title: 'Une nouvelle version est disponible !',
    txt: "Merci de mettre à jour l'application.",
    reload: 'Mettre à jour',

  }
};

let swRegistration;

export function getSWRegistration() {
  return swRegistration;
}

function ServiceWorkerLoader() {
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);
  const ln = navigator.language.substring(0, 2);
  const textsI18n = (ln in texts) ? texts[ln] : texts.en;

  const onSWUpdate = (registration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  const onRegister = (registration) => {
    swRegistration = registration;
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate, onRegister: onRegister });
  }, []);

  const reloadPage = () => {
    waitingWorker.postMessage({ type: 'SKIP_WAITING' });
    setShowReload(false);
    window.location.reload(true);
  };
  if (!showReload) return null;
  return (
    <Modal show centered backdrop="static">
      <Modal.Header>
        <Modal.Title>{textsI18n.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{textsI18n.txt}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={reloadPage}>{ textsI18n.reload }</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceWorkerLoader;
