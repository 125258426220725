import axios from 'axios';

const getBacklogItemFeedConfig = product => ({
  marker: '#',
  feed: getFeedItems(product),
  itemRenderer: customItemRenderer
});

function MentionCustomization(editor) {
  editor.conversion.for('upcast').elementToAttribute({
    view: {
      name: 'a',
      key: 'data-mention',
      classes: 'mention',
      attributes: {
        href: true,
        'data-backlog-id': true
      }
    },
    model: {
      key: 'mention',
      value: viewItem => {
        const mentionAttribute = editor.plugins.get('Mention').toMentionAttribute(viewItem, {
          href: null,
          link: viewItem.getAttribute('href'),
          id: viewItem.getAttribute('data-mention'),
          pk: viewItem.getAttribute('data-backlog-id'),
        });

        return mentionAttribute;
      }
    },
    converterPriority: 'high'
  });

  editor.conversion.for('downcast').attributeToElement({
    model: 'mention',
    view: (modelAttributeValue, { writer }) => {
      if (!modelAttributeValue || !modelAttributeValue.link) {
        return;
      }
      // eslint-disable-next-line consistent-return
      return writer.createAttributeElement('a', {
        class: 'mention btn btn-slug',
        'data-mention': modelAttributeValue.id,
        'data-backlog-id': modelAttributeValue.pk,
        href: modelAttributeValue.link
      }, {
        priority: 20,
        id: modelAttributeValue.pk
      });
    },
    converterPriority: 'high'
  });
}

function getFeedItems(product) {
  return function(queryText) {
    // @TODO workaround for bug https://github.com/ckeditor/ckeditor5/issues/11541
    // https://app.optera.io/opteraio/opteraio/item/OPT-869
    if ((queryText.match(/\s/g) || []).length > 0) return [];
    return new Promise((resolve, reject) => {
      axios.get('backlog-items?product=' + product.pk + '&page_size=5&search=' + encodeURIComponent(queryText))
        .then(response => {
          const items = [];
          for (const bl of response.data.items) {
            items.push({
              id: '#' + bl.resource_reference + ' ' + bl.resource_name,
              pk: bl.pk,
              name: bl.resource_reference + ' ' + bl.resource_name,
              link: '/' + product.organization.resource_slug + '/' +
                product.resource_slug + '/item/' + bl.resource_reference
            });
          }
          resolve(items);
        })
        .catch(err => {
          console.error('Impossible to find backlog-items');
          reject();
        });
    });
  };
}

function customItemRenderer(item) {
  const itemElement = document.createElement('div');
  itemElement.classList.add('pe-4');

  itemElement.classList.add('backlog-item-link');
  itemElement.id = `backlog-item-link-id-${item.pk}`;
  itemElement.textContent = item.name;

  return itemElement;
}

export { getBacklogItemFeedConfig, MentionCustomization };
