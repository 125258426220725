import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import AccountForm from 'components/forms/AccountForm';

function AccountModal(props) {
  const { title, modalTitle, hideButton, onClose, show, variant, ...rest } = props;
  const [showModal, setShowModal] = useState(!!show);
  const handleClose = () => {
    if (onClose) onClose();
    setShowModal(false);
  };
  const handleShow = (e) => {
    setShowModal(true);
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <ModalForm
      variant={variant}
      show={showModal}
      handleClose={handleClose}
      handleShow={handleShow}
      title={modalTitle || t`New account`}
      buttonTitle={title || t`Add account`}
      hideButton={hideButton}>
      <AccountForm {...rest} onSave={handleClose} onCancel={handleClose}></AccountForm>
    </ModalForm>
  );
}
AccountModal.defaultProps = {
  account: { name: '', slug: '', customer: null, notification_threshold: 60 }
};

export default AccountModal;
