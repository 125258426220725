import React, { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Form, Modal } from 'react-bootstrap';
import Icon from 'components/ui/Icon';

function ColumnsModal(props) {
  const { columnsList, setColumns, columnsKey, mobile } = props;
  const [show, setShow] = useState(false);

  const onClose = () => {
    setShow(false);
  };
  const setColumnItem = (name, checked) => {
    columnsList.find(c => c.name === name).active = checked;
    localStorage.setItem(columnsKey, JSON.stringify(columnsList.filter(c => c.active).map(c => c.name)));
    setColumns([...columnsList]);
  };

  const groups = [];
  for (let i = 0; i < columnsList.length; i++) {
    const group = groups.find(g => g.name === columnsList[i].group);
    if (!group) {
      groups.push({ name: columnsList[i].group, items: [columnsList[i]] });
    }
    else {
      group.items.push(columnsList[i]);
    }
  }

  return (
    <>
      <button className={'btn' + (mobile ? ' btn-primary' : ' btn-simplelink')} type="button" onClick={() => { setShow(true); }}>
        <Icon name="grid-fill" title={t`Columns`} />
        { mobile && (' ' + t`Columns`) }
      </button>
      <Modal size="lg" show={show} onHide={onClose} centered>
        <Modal.Header closeButton>
          <Modal.Title><Trans>Show columns</Trans></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            { groups.map(g => (
              <div key={g.name} className="col-12 col-lg-4 col-md-6 group">
                <h5>{ g.name !== 'DEFAULT' ? g.name : <>&nbsp;</> }</h5>
                { g.items.map(col => !col.locked && (
                  <Form.Check
                    id={'column-' + col.name}
                    key={col.name}
                    checked={col.active}
                    onChange={(e) => {
                      setColumnItem(col.name, e.target.checked);
                    }}
                    label={col.label}
                    className="ml-2 mb-3" />
                )) }
                
              </div>
            )) }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
ColumnsModal.defaultProps = {
  mobile: false
};
export default ColumnsModal;
