/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-loop-func */
import React, { useCallback, useContext, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import RichContentEditable from 'components/richcontent/RichContentEditable';
import OrganizationContext from 'components/utils/OrganizationContext';
import BacklogModalContext from 'components/modal/BacklogModalContext';
import BacklogHistory from 'components/product/history/BacklogHistory';
import BacklogItemLinks from 'components/product/backlogitem/BacklogItemLinks';
import BacklogItemAttachments from 'components/product/backlogitem/BacklogItemAttachments';
import ProductContext from 'components/utils/ProductContext';
import { getOptionsFromAPI } from 'components/form/DropdownEditable';
import CheckboxEditable from 'components/form/CheckboxEditable';
import BacklogItemHelpscoutLinks from 'integrations/helpscout/BacklogItemHelpscoutLinks';
import BacklogItemGitLabLinks from 'integrations/gitlab/BacklogItemGitLabLinks';
import BacklogItemTags from 'components/product/backlogitem/BacklogItemTags';
import BacklogItemSubtasks from 'components/product/backlogitem/BacklogItemSubtasks';
import * as BlFields from 'components/product/backlogitem/BacklogItemFields';
import { FieldEstimates } from './backlogitem/FieldEstimate';

function FieldGroupItem(props) {
  const { visible } = props;
  if (!visible) return null;
  return props.children;
}
FieldGroupItem.defaultProps = {
  visible: true
};

function FieldGroup(props) {
  const { name, title, visible, opened } = props;
  let defaulOpened = opened ? 'is-open' : 'is-close';
  const storedOpen = localStorage.getItem('fieldgroup-' + name);
  if (name && storedOpen) defaulOpened = storedOpen;
  const [isOpen, setIsOpen] = useState(defaulOpened);

  const changeOpened = () => {
    const newVal = isOpen === 'is-open' ? 'is-close' : 'is-open';
    setIsOpen(newVal);
    if (name) localStorage.setItem('fieldgroup-' + name, newVal);
  };
  let children = false;
  React.Children.map(props.children, child => {
    if (typeof child === 'string') {
      if (child.length > 0) children = true;
    }
    else if (React.isValidElement(child)) {
      if ('visible' in child.props) {
        if (child.props.visible) children = true;
      }
      else {
        children = true;
      }
    }
    else {
      children = true;
    }
  });

  if (!visible || !children) return null;
  return (
    <div className={'field-group ' + isOpen}>
      <button type="button" className="btn-simplelink field-group-title" onClick={changeOpened}>
        { title }
      </button>
      <div className="field-group-content">
        { props.children }
      </div>
    </div>
  );
}
FieldGroup.defaultProps = {
  visible: true,
  opened: true
};

export default function BacklogItem(props) {
  const { backlogItem, isModal } = props;
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const backlogModalContext = useContext(BacklogModalContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAssigneeOptions = useCallback(
    getOptionsFromAPI(
      'org-assignee-' + organization.pk,
      '/members?mode=select&organization=' + organization.pk
    ), [organization]);
  const cantEditItem = !product.can_edit_item;
  const modalAttrs = {};
  if (isModal && backlogModalContext) {
    const { addPreventClose, removePreventClose } = backlogModalContext;
    modalAttrs.onEdit = (id) => {
      addPreventClose(id);
    };
    modalAttrs.onEditClose = (id) => {
      removePreventClose(id);
    };
  }
  return (
    <>
      <div className="row">
        <div className="col-sm-8">
          <RichContentEditable
            content={backlogItem.description}
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            backlogItem={backlogItem}
            mention
            placeholder={t`Please provide a description`}
            field="description"
            saveButtons
            readonly={cantEditItem}
            product={product}
            editButton
            {...modalAttrs} />
          <BacklogItemSubtasks
            key={backlogItem.parent ? backlogItem.parent.pk : 'none'}
            backlogItem={backlogItem}
            product={product}
            organization={organization}
            readonly={cantEditItem}
            isModal={isModal}
            {...modalAttrs} />
          <BacklogItemLinks
            organization={organization}
            backlogItem={backlogItem}
            readonly={cantEditItem}
            {...modalAttrs} />
          <BacklogItemAttachments
            backlogItem={backlogItem}
            readonly={cantEditItem}/>
        </div>
        <div className="col-sm-4">
          <BlFields.Type backlogItem={backlogItem} readonly={cantEditItem} product={product} />
          <BlFields.Status backlogItem={backlogItem} readonly={cantEditItem} product={product} />
          { ['done', 'closed'].includes(backlogItem.status.status_type.value) && (
            <BlFields.Resolution backlogItem={backlogItem} readonly={cantEditItem} product={product} />
          ) }
          <BlFields.Assignee backlogItem={backlogItem} readonly={cantEditItem} getAssigneeOptions={getAssigneeOptions} />
          <BlFields.Reporter backlogItem={backlogItem} readonly={cantEditItem} getAssigneeOptions={getAssigneeOptions} />
          <BlFields.Epic backlogItem={backlogItem} readonly={cantEditItem} product={product} />
          {backlogItem.sprint && (
            <div className="hstack field-value item_sprint">
              <span className="text-secondary"><Trans>Sprint</Trans></span>
              <div className="ms-auto">
                { backlogItem.sprint.resource_name }
              </div>
            </div>
          )}
          <BacklogItemTags
            organization={organization}
            item={backlogItem}
            readonly={cantEditItem}
          />
          { product.can_see_helpscout_links && backlogItem.integrations_detail.helpscout && (
            <BacklogItemHelpscoutLinks
              organization={organization}
              item={backlogItem}
              readonly={cantEditItem}
            />
          )}
          { product.enable_release && (
            <>
              <BlFields.Version
                field="fix_versions"
                title={t`Fix versions`}
                backlogItem={backlogItem}
                product={product}
                readonly={cantEditItem} />
              <BlFields.Version
                field="affects_versions"
                title={t`Affects versions`}
                backlogItem={backlogItem}
                product={product}
                readonly={cantEditItem} />
            </>
          ) }
          <FieldGroup
            name="versioning"
            title={t`Versioning`}
            opened={false}
            visible={product.can_see_versioning}>
            <FieldGroupItem visible={!!product.git_checkout_pattern}>
              <BlFields.GitCheckout backlogItem={backlogItem} product={product} />
            </FieldGroupItem>
            <FieldGroupItem visible={!!backlogItem.integrations_detail.gitlab}>
              <BacklogItemGitLabLinks
                organization={organization}
                data={backlogItem.integrations_detail.gitlab}
                readonly={cantEditItem}
              />
            </FieldGroupItem>
            <FieldGroupItem visible={!!backlogItem.integrations_detail.gitlab_webhook}>
              <BacklogItemGitLabLinks
                organization={organization}
                data={backlogItem.integrations_detail.gitlab_webhook}
                readonly={cantEditItem}
              />
            </FieldGroupItem>
          </FieldGroup>
          <FieldGroup
            name="work"
            title={t`Work`}
            opened={false}>
            <FieldGroupItem visible={product.can_see_estimates}>
              <FieldEstimates product={product} backlogItem={backlogItem} readonly={cantEditItem} />
              { backlogItem.parent === null && !cantEditItem && (
                <div className="field-value text-end">
                  <CheckboxEditable
                    pk={backlogItem.pk}
                    api={backlogItem._genericApi}
                    field="need_estimate"
                    label={t`Need estimate`} />
                </div>
              ) }
            </FieldGroupItem>
            <FieldGroupItem visible={product.can_see_worklogs}>
              <BlFields.TotalWork backlogItem={backlogItem} product={product} />
              <BlFields.DefaultAccount
                backlogItem={backlogItem}
                product={product}
                organization={organization}
                readonly={cantEditItem} />
            </FieldGroupItem>
          </FieldGroup>
          <FieldGroup name="value" title={t`Value`} opened={false}>
            <BlFields.Priority backlogItem={backlogItem} readonly={cantEditItem} />
            <BlFields.Deadline backlogItem={backlogItem} readonly={cantEditItem} />
            { !backlogItem.parent && (
              <BlFields.BusinessValue backlogItem={backlogItem} readonly={cantEditItem} />
            ) }
          </FieldGroup>
        </div>
      </div>
      <BacklogHistory
        backlogItem={backlogItem}
        product={product} />
    </>
  );
}
