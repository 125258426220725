/* eslint-disable react/no-unescaped-entities */

import React, { useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Alert, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import AppContext from 'AppContext';
import Product from 'entity/Product';
import { LoaderContainer } from 'components/ui/Loader';
import ProductModal from 'components/modal/ProductModal';
import Icon from 'components/ui/Icon';

function ProductList(props) {
  const { user } = useContext(AppContext);
  const [products, setProducts] = useState(null);
  const [error, setError] = useState(null);
  const { refresh, organization, columns, onAddProduct, type, limit } = props;

  let api = '/products?order=most_relevant&subset=offset&limit=' + limit;
  if (organization) api += '&organization=' + organization.pk;
  useEffect(() => {
    axios.get(api).then((response) => {
      const prods = [];
      for (let i = 0; i < response.data.items.length; i++) {
        const prod = new Product();
        prod.loadFromObject(response.data.items[i]);
        prods.push(prod);
      }
      setProducts(prods);
    }).catch((err) => { setError(err); });
  }, [refresh, api]);

  if (error) {
    return (
      <Alert>
        <p><Trans>Impossible to load Products</Trans></p>
      </Alert>
    );
  }
  if (products === null) {
    return (
      <Card className="products-list relative">
        <Card.Body>
          <LoaderContainer height="3"/>
        </Card.Body>
      </Card>
    );
  }
  if (products.length < 1) {
    return (
      <Card className="products-list products-list__empty">
        <Card.Body>
          <p><Trans>You don’t have a product yet</Trans></p>
          { user.can_add_product && (
            <ProductModal onSave={onAddProduct} />
          )}
        </Card.Body>
      </Card>
    );
  }
  const classes = ['products-list'];
  if (columns) classes.push('columns-' + columns);
  return (
    <div className={classes.join(' ')}>
      {products.map((prod) => (
        <Card className="product product__teaser" key={prod.slug}>
          <Card.Body>
            <Icon name="stack" />
            <div className="card-content">
              <Card.Title>
                { type === 'small' ? (
                  <NavLink to={`/${prod.organization.resource_slug}/${prod.slug}`}>
                    { prod.name }
                  </NavLink>
                ) : prod.name }
              </Card.Title>
              <div>
                {prod.organization.resource_name}
              </div>
              { type !== 'small' && (
                <div>
                  <NavLink className="btn btn-slug mt-2" to={`/${prod.organization.resource_slug}/${prod.slug}`}>{prod.slug}</NavLink>
                </div>
              ) }
              
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}
ProductList.defaultProps = {
  type: 'default',
  limit: 6
};

export default ProductList;
