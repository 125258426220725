/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Button } from 'react-bootstrap';
import { Trans } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';

function ReleaseFinalForm(props) {
  const { onCancel, onSave } = props;

  return (
    <Form
      name="release-add-form"
      noValidate
      onSave={onSave}>
      
      <Form.Field className="full-w" id="name" name="name" required/>
      <div className="row">
        <div className="col-md-6">
          <Form.Field type="date" id="start_at" name="start_at"/>
        </div>
        <div className="col-md-6">
          <Form.Field type="date" id="released_at" name="released_at"/>
        </div>
      </div>
      
      <Form.Check id="released" name="released"/>
      <Form.Check id="archived" name="archived"/>
      <div className="actions">
        <Button variant="primary" type="submit"><Trans>Save</Trans></Button>
        <Button variant="light" type="button" onClick={onCancel}><Trans>Cancel</Trans></Button>
      </div>
    </Form>
  );
}

export default function ReleaseForm(props) {
  const { release, ...rest } = props;

  return (
    <FormContextProvider item={release} api="/releases">
      <ReleaseFinalForm
        {...rest} />
    </FormContextProvider>
  );
}
