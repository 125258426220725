import React, { useState, useEffect, useContext } from 'react';
import * as BS from 'react-bootstrap';
import axios from 'axios';
import { Trans, t } from '@lingui/macro';
import useQuery from 'utils/hooks';
import AppContext from 'AppContext';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import SigninForm from './SigninForm';

export default function SetPasswordForm(props) {
  const appContext = useContext(AppContext);
  const [checkPassword, setCheckPassword] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(null);
  const [invalidLink, setInvalidLink] = useState();
  const { uidb64, token } = useQuery();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios.post(
      '/auth/validate-user-token', {
        token: token,
        uidb64: uidb64,
      }).then(response => {
      setInvalidLink(false);
      setLoading(false);
    }).catch(e => {
      setInvalidLink(true);
      setLoading(false);
    });
  }, [token, uidb64]);

  const handleSubmit = async e => {
    e.preventDefault();
    setError(null);
    if (password && password !== checkPassword) {
      setError(t`Passwords don’t match`);
      return;
    }
    axios.post(
      '/auth/set-password', {
        check_password: checkPassword,
        password: password,
        token: token,
        uidb64: uidb64,
        autologin: 'cookie'
      }
    ).then(function ({ data }) {
      addMessage(
        'password-changed', t`Password changed`, t`Your password has been successfully changed.`);
      appContext.setUser(data.user);
    }).catch(function (err) {
      if (err.response && err.response.data && err.response.data.password) {
        if (err.response.data.password.length === 1) {
          setError(err.response.data.password[0]);
        }
        else {
          setError((
            <ul>
              { err.response.data.password.map(mess => (
                <li key={mess}>{ mess }</li>
              )) }
            </ul>
          ));
        }
      }
      else {
        setError(t`Unable to change password!`);
      }
    });
  };

  if (loading) {
    return <LoaderContainer height="3" static/>;
  }

  if (invalidLink) {
    const forgottenPasswordPath = '/auth/forgotten-password';
    return (
      <>
        <h2><Trans>Your link is invalid.</Trans></h2>
        <p>
          <Trans>
            Use this <a href={forgottenPasswordPath}>form</a> to request a new link.
          </Trans>
        </p>
      </>
    );
  }

  return (
    <SigninForm title={t`Please define your password`} onSubmit={handleSubmit}>
      <BS.Form.Group className="form-group required">
        <BS.Form.Label htmlFor="password" className="sr-only"><Trans>New Password</Trans></BS.Form.Label>
        <BS.Form.Control
          type="password"
          name="password"
          id="password"
          placeholder={t`New Password`}
          onChange={e => setPassword(e.target.value)}/>
      </BS.Form.Group>
      <BS.Form.Group className="form-group required">
        <BS.Form.Label htmlFor="check_password" className="sr-only"><Trans>Confirm Password</Trans></BS.Form.Label>
        <BS.Form.Control
          type="password"
          name="check password"
          id="check_password"
          placeholder={t`Confirm Password`}
          onChange={e => setCheckPassword(e.target.value)}/>
        { error && (
          <div className="mb-2 mt-3">
            <BS.Alert variant="danger">{error}</BS.Alert>
          </div>
        )}
      </BS.Form.Group>
      <div className="mt-3 mb-3">
        <BS.Button
          className="btn btn-cta"
          type="submit"><Trans>Set password</Trans></BS.Button>
      </div>
    </SigninForm>
  );
}
