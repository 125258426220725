import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import * as BS from 'react-bootstrap';
import axios from 'axios';
import { Trans, t } from '@lingui/macro';
import AppContext from 'AppContext';
import AboutModal from 'components/modal/AboutModal';
import { CodeInputs } from 'components/form/CodeField';
import RequestCodeByEmail from 'components/forms/RequestCodeByEmail';
import SigninForm from './SigninForm';

function LoginOtp(props) {
  const appContext = useContext(AppContext);
  const { userToken } = props;
  const [otpCode, setOtpCode] = useState();
  const [error, setError] = useState(null);
  const [validated, setValidated] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setError(null);
    axios.post('/auth/otp/login?mode=cookie', {
      token: userToken,
      otp_code: otpCode }
    ).then(function ({ data }) {
      appContext.setUser(data.user);
    }).catch(function (err) {
      if (err.response) {
        if (err.response.status === 429) {
          setError(err.response.data.message);
        }
        else {
          setError(t`Wrong OTP code !`);
        }
      }
      else if (err.request) {
        console.error(err);
        setError(t`Service currently unavailable.`);
      }
      else {
        console.error(err);
        setError(t`An error occured, impossible to log you in.`);
      }
    });
  };

  if (appContext.user) {
    return <Redirect to={props.from}/>;
  }

  const onInvalid = () => {
    setValidated(true);
  };

  return (
    <>
      <h1 className="h2 mb-3 font-weight-normal">{ t`Enter your OTP code` }</h1>
      <SigninForm onSubmit={handleSubmit} isOtp onInvalid={onInvalid}>
        <BS.FormGroup className={'form-group' + (validated ? ' was-validated' : '')}>
          <BS.Form.Label htmlFor="otp_code">
            <Trans>Use your authenticator to get a fresh OTP code.</Trans>
          </BS.Form.Label>
          <CodeInputs
            size="6"
            error={!!error}
            id="otp_code"
            onChange={v => setOtpCode(v)}
            autofocus />
        </BS.FormGroup>
        { error && (
          <div className="mb-2">
            <BS.Alert variant="danger">{error}</BS.Alert>
          </div>
        )}
        <RequestCodeByEmail token={userToken} />
        <div className="mb-3 mt-3">
          <button
            className="btn btn-cta"
            type="submit">Login</button>
        </div>
        <div>
          <hr />
          <AboutModal variant="simplelink" />
        </div>
      </SigninForm>
    </>
  );
}
export default LoginOtp;
