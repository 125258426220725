import React from 'react';
import * as BS from 'react-bootstrap';
import { Trans } from '@lingui/macro';

export default function ConfirmDialog({
  message, confirmLabel, confirmVariant,
  onConfirm, onCancel, show }) {

  const handleCancel = () => {
    if (onCancel && onCancel.call && onCancel.apply) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    if (onConfirm && onConfirm.call && onConfirm.apply) {
      onConfirm();
    }
  };

  return (
    <>
      {/* Animations is buggy because of strict mode, need a future version
      of Transition in react-bootstrp dependencies to work */}
      <BS.Modal
        show={show}
        onHide={handleCancel}
        animation={false}
        className="upper-modal"
        backdropClassName="upper-modal"
        centered>
        <BS.Modal.Body>
          <div>{message}</div>
        </BS.Modal.Body>
        <BS.Modal.Footer>
          <BS.Button variant="secondary" onClick={handleCancel}>
            <Trans>Cancel</Trans>
          </BS.Button>
          <BS.Button variant={confirmVariant || 'primary'} data-cy="bt-confirm-modal" onClick={handleConfirm}>
            {confirmLabel || (<Trans>Confirm</Trans>)}
          </BS.Button>
        </BS.Modal.Footer>
      </BS.Modal>
    </>
  );
}
