import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import { Form, FormContext, FormContextProvider } from 'components/form';
import { addMessage } from 'components/ui/Messages';

function StoryMapTaskFinalForm(props) {
  const { onCancel, onSave, api } = props;
  const { item } = useContext(FormContext);

  const onDelete = useCallback(function () {
    item.delete().then(() => {
      addMessage(
        'delete-story-task-' + item.pk,
        t`Task removed`,
        t`${item.name} has been removed.`
      );
      onSave();
    }).catch(err => {
      addMessage(
        'delete-story-task-error-' + item.pk,
        t`Unknown error`,
        t`Impossible to remove task.`
      );
      onCancel();
    });
  }, [item, onCancel, onSave]);

  return (
    <Form
      name={api + '-add-form'}
      noValidate
      onSave={onSave}>
      <Form.Field id="name" name="name" className="w-100" required />
    
      <div className="actions">
        <button type="submit" className="btn btn-primary"><Trans>Save</Trans></button>
        {item.pk && (
          <button type="button" className="btn btn-outline-secondary" onClick={onDelete}><Trans>Delete</Trans></button>
        )}
        <button type="button" className="btn btn-outline-secondary" onClick={onCancel}><Trans>Cancel</Trans></button>
      </div>
    </Form>
  );
}

function StoryMapTaskForm(props) {
  const { organization, item, api, storyMap, storyMapRelease, activity, ...rest } = props;

  const newItem = item === null ? {} : item;
  if (item === null) {
    if (storyMap) newItem.story_map = storyMap;
    if (storyMapRelease) newItem.release = storyMapRelease;
    if (activity) newItem.activity = activity;
  }

  return (
    <FormContextProvider item={newItem} api={api}>
      <StoryMapTaskFinalForm organization={organization} api={api} {...rest} />
    </FormContextProvider>
  );
}

function StoryMapTaskModal(props) {
  const { title, modalTitle, onClose, onSave, hideButton, show, variant, ...rest } = props;
  const [isShown, setShow] = useState(!!show);
  const handleCancel = () => {
    if (onClose) onClose(false);
    setShow(false);
  };
  const handleSave = (data, created) => {
    if (onSave) onSave(data, created);
    setShow(false);
  };
  const handleShow = (e) => {
    setShow(true);
  };

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <ModalForm
      show={isShown}
      handleClose={handleCancel}
      handleShow={handleShow}
      title={modalTitle || title || t`New epic`}
      buttonTitle={title || t`Add epic`}
      hideButton={hideButton}
      variant={variant}>
      <StoryMapTaskForm
        {...rest}
        onSave={handleSave}
        onCancel={handleCancel}>
        
      </StoryMapTaskForm>
    </ModalForm>
  );
}
StoryMapTaskModal.defaultProps = {
  item: null
};

export default StoryMapTaskModal;
