import React from 'react';
import svgFile from 'bootstrap-icons/bootstrap-icons.svg';

function Icon(props) {
  const { title, size, className } = props;
  return (
    <svg className={'icon' + (className ? (' ' + className) : '')} width={size} height={size} fill="currentColor">
      { title && (
        <title>{ title }</title>
      ) }
      <use xlinkHref={`${svgFile}#${props.name}`}/>
    </svg>
  );
}
Icon.defaultProps = {
  size: 24
};

export default Icon;
