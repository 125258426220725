import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Icon from 'components/ui/Icon';
import { LoaderContainer } from 'components/ui/Loader';
import AppContext from 'AppContext';
import ProductContext from 'components/utils/ProductContext';
import BacklogModalContext from 'components/modal/BacklogModalContext';
import { addMessage } from 'components/ui/Messages';
import DateBlock from 'components/ui/DateBlock';
import RichContentEditable, { RichContent } from 'components/richcontent/RichContentEditable';
import Avatar from 'components/ui/Avatar';
import BacklogItemCommentsAdd from './BacklogItemCommentsAdd';

function getComments(pk, size) {
  return new Promise(resolve => {
    axios.get('/item-comments?subset=offset&limit=5&offset=' + size + '&item=' + pk)
      .then(results => {
        resolve(results);
      })
      .catch(err => {
        addMessage(
          `comments-${pk}-${size}`,
          t`An error occured`,
          t`Impossible to load comments`
        );
      });
  });
}

function deleteComment(comment) {
  return new Promise(resolve => {
    axios.delete('/item-comments/' + comment.pk)
      .then(results => {
        addMessage(
          'delete-comment-' + comment.pk,
          t`Comment removed`,
          t`Comment has been removed.`
        );
        resolve(results);
      })
      .catch(err => {
        addMessage(
          'delete-comment-error-' + comment.pk,
          t`Unknown error`,
          t`Impossible to remove this comment.`
        );
      });
  });
}

function Comment(props) {
  const { comment, setRefresh, commentlist, product, backlogItem, highlighted } = props;
  const { user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const onRemove = useCallback(com => {
    setLoading(true);
    deleteComment(com).then(() => {
      commentlist.current.items.splice(
        commentlist.current.items.findIndex(c => c.pk === com.pk),
        1
      );
      commentlist.current.meta.subset.count -= 1;
      setLoading(false);
      setRefresh(oldVal => oldVal + 1);
    });
  }, [commentlist, setRefresh]);

  const onEditUpdate = useCallback(data => {
    if (data !== false) {
      comment.comment = data;
    }
    setEditing(false);
  }, [comment]);

  return (
    <div className={'comment' + (highlighted ? ' highlighted' : '')}>
      { loading && (
        <LoaderContainer/>
      ) }
      { (product.can_edit_comment(comment, user) || product.can_delete_comment(comment, user)) && (
        <DropdownButton
          title={<Icon name="three-dots-vertical" title={t`Menu`}/>}
          className="contextual-menu-dropdown no-carret"
          variant="outligned-light"
          align="end">
          {product.can_edit_comment(comment, user) && (
            <Dropdown.Item onClick={() => { setEditing(true); }} as="button"><Trans>Edit</Trans></Dropdown.Item>
          )}
          {product.can_delete_comment(comment, user) && (
            <Dropdown.Item onClick={() => { onRemove(comment); }} as="button"><Trans>Delete</Trans></Dropdown.Item>
          )}
        </DropdownButton>
      ) }
      <div>
        <div className="comment-avatar">
          <Avatar user={(comment.author && comment.author.user) ? comment.author.user : null}/>
        </div>

        <div className="comment-body">
          <div className="comment-header">
            <h5 className="mb-0">
              { comment.author.resource_name }
            </h5>
            <DateBlock id={`comment-${comment.pk}`} datestring={comment.created_at} />
          </div>
          { editing ? (
            <RichContentEditable
              content={comment.comment}
              pk={comment.pk}
              editMode
              api="/item-comments"
              backlogItem={backlogItem}
              mention
              onUpdate={onEditUpdate}
              onBlur={() => { setEditing(false); }}
              field="comment"
              product={product}/>
          ) : (
            <RichContent data={comment.comment}/>
          ) }
          { comment.updated_by && comment.updated_at && (
            <div className="comment-footer">
              <DateBlock id={`comment-updated-${comment.pk}`} datestring={comment.updated_at} />
              <h5 className="mb-0">
                <Trans>Modified by { comment.updated_by.resource_name }</Trans>
              </h5>
            </div>
          ) }
        </div>
      </div>
    </div>
  );
}

export default function BacklogItemComments(props) {
  const product = useContext(ProductContext);
  const backlogModalContext = useContext(BacklogModalContext);
  const { backlogItem } = props;
  const [, setRefresh] = useState(0);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const comments = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const comment = parseInt(params.get('comment'), 10);

  const modalAttrs = {};
  if (backlogModalContext) {
    const { addPreventClose, removePreventClose } = backlogModalContext;
    modalAttrs.onEdit = (id) => {
      addPreventClose(id);
    };
    modalAttrs.onEditClose = (id) => {
      removePreventClose(id);
    };
  }

  useEffect(() => {
    let isMount = true;
    getComments(backlogItem.pk, 0).then((response) => {
      if (isMount) {
        comments.current = {
          meta: response.data.meta,
          items: response.data.items
        };
        setRefresh(oldVal => oldVal + 1);
      }
    });
    return () => {
      isMount = false;
    };
  }, [backlogItem]);

  const onLoadMore = () => {
    setCommentsLoading(true);
    getComments(backlogItem.pk, comments.current.items.length).then((response) => {
      comments.current = {
        meta: response.data.meta,
        items: [...comments.current.items, ...response.data.items]
      };
      setCommentsLoading(false);
    });
  };

  const onAddComment = useCallback(com => {
    comments.current.items = [com, ...comments.current.items];
    comments.current.meta.subset.count += 1;
    setRefresh(oldVal => oldVal + 1);
  }, []);

  if (comments.current === null) {
    return (
      <div className="comments">
        <LoaderContainer height="3" />
      </div>
    );
  }

  return (
    <div className="comments">
      {product.can_add_comment && (
        <BacklogItemCommentsAdd backlogItem={backlogItem} onAddComment={onAddComment} {...modalAttrs} />
      )}
      <div className="comments__list mb-2 relative">
        { comments.current.items.map(com => (
          <Comment
            comment={com}
            key={com.pk}
            setRefresh={setRefresh}
            commentlist={comments}
            product={product}
            highlighted={com.pk === comment}
            backlogItem={backlogItem}/>
        )) }
        { commentsLoading && (
          <LoaderContainer height="2"/>
        ) }
      </div>
      { comments.current.meta.subset.count > comments.current.items.length && (
        <div className="mb-2 text-center">
          <button type="button" className="btn btn-outline-dark" onClick={onLoadMore}>
            <Trans>Load more</Trans>
          </button>
        </div>
      ) }
    </div>
  );
}
