import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import { Form, FormContext, FormContextProvider } from 'components/form';
import { addMessage } from 'components/ui/Messages';
import { useStateItem } from 'utils/Item';
import { LoaderContainer } from 'components/ui/Loader';

function StoryMapItemFinalForm(props) {
  const { onCancel, onSave, api, parent, parentApi, parentContext } = props;
  const { item } = useContext(FormContext);

  const parentField = (parent === 'story_map' || parent === 'activity') ? '' : (
    <Form.Many2One id={parent} name={parent} api={parentApi + '?' + parentContext} required />
  );

  const onDelete = useCallback(function () {
    item.delete().then(() => {
      addMessage(
        'delete-story-' + api + '-' + item.pk,
        t`Item removed`,
        t`${item.name} has been removed.`
      );
      onSave();
    }).catch(err => {
      addMessage(
        'delete-story-' + api + '-error-' + item.pk,
        t`Unknown error`,
        t`Impossible to remove.`
      );
      onCancel();
    });
  }, [item, onCancel, onSave, api]);

  return (
    <Form
      name={api + '-add-form'}
      noValidate
      onSave={onSave}>
      {parentField}
      <Form.Field id="name" name="name" className="w-100" required inputRef={ref => { ref.current.focus(); }} />
    
      <div className="actions">
        <button type="submit" className="btn btn-primary"><Trans>Save</Trans></button>
        {item.pk && (
          <button type="button" className="btn btn-outline-secondary" onClick={onDelete}><Trans>Delete</Trans></button>
        )}
        <button type="button" className="btn btn-outline-secondary" onClick={onCancel}><Trans>Cancel</Trans></button>
      </div>
    </Form>
  );
}

function StoryMapItemForm(props) {
  const { organization, item, api, parent, parentItem, ...rest } = props;
  const meta = useStateItem('meta:' + api);

  if (!meta) {
    return <LoaderContainer height="3" />;
  }

  const newItem = item === null ? {} : item;
  if (parentItem && item === null) {
    newItem[parent] = parentItem;
  }

  return (
    <FormContextProvider item={newItem} api={api} meta={meta}>
      <StoryMapItemFinalForm organization={organization} api={api} parent={parent} {...rest} />
    </FormContextProvider>
  );
}

function StoryMapItemModal(props) {
  const { title, modalTitle, onClose, onSave, hideButton, show, variant, ...rest } = props;
  const [isShown, setShow] = useState(!!show);
  const handleCancel = () => {
    if (onClose) onClose(false);
    setShow(false);
  };
  const handleSave = (data, created) => {
    if (onSave) onSave(data, created);
    if (!hideButton) setShow(false);
  };
  const handleShow = (e) => {
    setShow(true);
  };

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <ModalForm
      show={isShown}
      handleClose={handleCancel}
      handleShow={handleShow}
      title={modalTitle || title || t`New epic`}
      buttonTitle={title || t`Add epic`}
      hideButton={hideButton}
      variant={variant}>
      <StoryMapItemForm
        {...rest}
        onSave={handleSave}
        onCancel={handleCancel}>
        
      </StoryMapItemForm>
    </ModalForm>
  );
}
StoryMapItemModal.defaultProps = {
  item: null
};

export default StoryMapItemModal;
