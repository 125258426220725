import React from 'react';

export default function SigninForm({ children, title, ...props }) {
  const onInvalid = () => {
    if ('onInvalid' in props) {
      props.onInvalid();
    }
  };
  return (
    <form onInvalid={onInvalid} className={'form-login' + (props.isOtp ? '-otp' : '')} onSubmit={props.onSubmit}>
      { title && (
        <p>{ title }</p>
      ) }
      {children}
    </form>
  );
}
