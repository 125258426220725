import React, { useContext } from 'react';
import { NotificationsContext } from 'components/utils/NotificationsObserver';
import { Trans } from '@lingui/macro';
import AppContext from 'AppContext';

function NotificationsDisabled(props) {
  const { isActive, socket } = useContext(NotificationsContext);
  const { user } = useContext(AppContext);

  if (!user || isActive || isActive === null) {
    return null;
  }

  return (
    <div className="alert alert-warning alert--notifications-disabled">
      <Trans>You are no longer connected to notifications</Trans>
      <button
        type="button"
        className="btn btn-small btn-outline-secondary ms-2"
        onClick={e => {
          e.preventDefault();
          socket.reconnect(true);
        }}>
        <Trans>reconnect</Trans>
      </button>
    </div>
  );
}

export default NotificationsDisabled;
