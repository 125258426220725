import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import NotFoundPage from 'pages/NotFoundPage';
import SlackInstall from './slack/SlackInstall';

export default function IntegrationPage(props) {
  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
  ];

  return (
    <Switch>
      <Route path="/organizations/integrations/slack" component={SlackInstall} />
      <Route>
        <NotFoundPage breadcrumb={breadcrumb} />
      </Route>
    </Switch>
  );
}
