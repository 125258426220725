import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';

function keydownHandler (event) {
  // space key
  if (event.keyCode === 32) {
    event.preventDefault();
  }
  // enter key
  else if (event.keyCode === 13) {
    event.preventDefault();
    event.target.click();
  }
}

function keyupHandler (event) {
  // space key
  if (event.keyCode === 32) {
    event.preventDefault();
    event.target.click();
  }
}

export default function BacklogItemHelpscoutLinks(props) {
  const { item, readonly } = props;
  const [, setRefresh] = useState(0);

  const { conversations } = item.integrations_detail.helpscout;
  if (!conversations || conversations.length === 0) {
    return '';
  }

  const unlinkClickHandler = (conversation) => {
    axios.put(`/integrations/helpscout/item/${item.pk}/unlink`, {
      ticket: conversation.id
    }).then(
      response => {
        item.integrations_detail.helpscout = response.data.helpscout;
        setRefresh(current => current + 1);
      }
    ).catch(err => {
      addMessage(
        'unlink-helpscout-item-' + item.pk,
        t`Unknown error`,
        t`Impossible to remove this helpscout link.`
      );
    });
  };

  return (
    <div className="hstack field-value field-helpscout-links align-items-start">
      <div className="text-secondary"><Trans>Helpscout conversations</Trans></div>
      <div className="ms-auto">
        { conversations.map(conv => (
          <div key={conv.id}>
            <a href={conv.web_url}>#{ conv.id }</a> {conv.subject}
            {!readonly && (
              <span
                tabIndex={0}
                role="button"
                onKeyDown={keydownHandler}
                onKeyUp={keyupHandler}
                onClick={(e) => unlinkClickHandler(conv)}><Icon name="x"/></span>
            )}
          </div>
        )) }
      </div>
    </div>
  );

}
