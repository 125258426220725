/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Card } from 'react-bootstrap';
import Icon from 'components/ui/Icon';
import ProductPage from 'components/page/ProductPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import SprintModal from 'components/modal/SprintModal';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';
import DateFormat from 'components/ui/DateFormat';
import { ConfirmButton } from 'components/utils';
import { setStateItem, useStateItem } from 'utils/Item';
import Pagination from 'components/ui/Pagination';
import SimpleModal from 'components/modal/SimpleModal';
import { websiteUrl } from 'components/utils/urls';

function Effort(props) {
  const { product, sprint } = props;
  const effort = {};
  if (product.estimate_mode.value === 'hours'
      && sprint.hours_effort
      && sprint.total_hours_effort) {
    effort.total = sprint.hours_effort.minutes;
    effort.sum = sprint.total_hours_effort.minutes;
    effort.display = `${sprint.total_hours_effort.human_format} / ${sprint.hours_effort.human_format}`;
  }
  else if (product.estimate_mode.value === 'points'
      && sprint.points_effort
      && sprint.total_points_effort) {
    effort.total = sprint.points_effort;
    effort.sum = Math.round(sprint.total_points_effort * 100) / 100;
    effort.display = `${effort.sum} / ${effort.total}`;
  }
  else {
    return null;
  }
  return (
    <div className="text-md-end px-0 px-md-3 py-2 py-md-0">
      <Trans>Effort</Trans>:{' '}
      <span className={'badge ' + (effort.sum > effort.total ? 'bg-danger' : 'bg-secondary')}>
        { effort.display }
      </span>
    </div>
  );
}

function ReviewsLinks(props) {
  const { product, sprintLink, sprint } = props;
  return product.can_edit_sprint ? (
    <>
      <Link 
        className="btn btn-simplelink"
        to={sprintLink + '/review'}>
        { sprint.review ? t`Review` : t`Add review` }
      </Link>
      <Link 
        className="btn btn-simplelink"
        to={sprintLink + '/retrospective'}>
        { sprint.retrospective ? t`Retrospective` : t`Add retrospective` }
      </Link>
    </>
  ) : (
    <>
      { sprint.review && (
        <SimpleModal
          title={t`Sprint review`}
          variant="simplelink"
          buttonTitle={t`Review`}
          className="modal-sprint-review modal-lg">
          <div
            className="rich-content"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sprint.review }}/>
        </SimpleModal>
      ) }
      { sprint.retrospective && (
        <SimpleModal
          title={t`Sprint retrospective`}
          variant="simplelink"
          buttonTitle={t`Retrospective`}
          className="modal-sprint-retrospective modal-lg">
          <div
            className="rich-content"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: sprint.retrospective }}/>
        </SimpleModal>
      ) }
    </>
  );
}

function SprintItem(props) {
  const { pk, setEditItem, hasActive, organization, product } = props;
  const sprint = useStateItem('sprints:' + pk);

  const sprintname = sprint.name;
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const manageError = err => {
    if (err.response.status === 400) {
      setError(err.response.data.message);
    }
    else {
      addMessage(err.response.config.url, t`Unknown error`, t`Impossible to modify sprint`);
    }
    setLoading(false);
  };

  const updateSprint = res => {
    setStateItem('sprints:' + sprint.pk, res.data, {
      isFull: true
    });
  };

  const activate = spk => {
    axios.put('/sprints/' + spk + '/activate').then(updateSprint).catch(manageError);
  };
  const close = spk => {
    axios.put('/sprints/' + spk + '/close').then(updateSprint).catch(manageError);
  };
  const inactivate = spk => {
    axios.put('/sprints/' + spk + '/inactivate').then(updateSprint).catch(manageError);
  };
  const deleteSprint = spr => {
    setLoading(true);
    axios.delete('/sprints/' + spr.pk).then(response => {
      updateSprint({ data: null });
    }).catch(manageError);
  };

  const classes = ['sprint', 'relative'];
  if (sprint.is_current) classes.push('sprint__active');
  if (sprint.status.value === 'closed') classes.push('sprint__closed');
  const sprintLink = '/' + organization.slug + '/' + product.slug + '/sprints/' + sprint.pk;
  return (
    <div className={classes.join(' ')}>
      { loading && (
        <LoaderContainer/>
      ) }
      { error && (
        <div className="alert alert-danger text-center" role="alert">
          { error }
        </div>
      ) }
      <div className="d-md-flex flex-wrap w-100 justify-content-between align-items-center">
        <div>
          <h5 className="mb-0 align-middle d-inline-block">
            <Link
              to={sprintLink}>
              { sprint.name }
            </Link>
          </h5>
          { sprint.is_current && (
            <span className="badge rounded-pill bg-primary ms-2 middle"><Trans>active</Trans></span>
          ) }
          { sprint.status.value === 'closed' && (
            <span className="badge rounded-pill bg-secondary ms-2 middle"><Trans>closed</Trans></span>
          ) }

          <div className="d-inline-block text-small ms-3">
            <DateFormat
              datestring={sprint.date_from}
              day="numeric"
              month="long"
              year="numeric"/>
            {' '}
            <Icon name="arrow-right"/>
            {' '}
            <DateFormat
              datestring={sprint.date_to}
              day="numeric"
              month="long"
              year="numeric"/>
          </div>
          <div className="mt-2 links">
            { product.can_edit_sprint && sprint.status.value === 'open' && (
              <Link
                className="btn btn-simplelink"
                to={sprintLink}>
                <Trans>Sprint planning</Trans>
              </Link>
            ) }
            { (sprint.is_current || sprint.status.value === 'closed') && (
              <ReviewsLinks product={product} sprintLink={sprintLink} sprint={sprint} />
            ) }
          </div>
        </div>
        { product.can_see_worklogs && (
          <Effort product={product} sprint={sprint} />
        ) }
        <div className="text-md-end">
          <div className="btn-group btn-group-vertical-mobile my-2 my-md-0" role="group">
            { product.can_edit_sprint && sprint.is_current && (
              <>
                <button
                  onClick={e => inactivate(sprint.pk)}
                  type="button"
                  className="btn btn-outline-secondary">
                  <Trans>Inactivate</Trans>
                </button>
                {' '}
                <ConfirmButton
                  message={(
                    <Trans>Are you sure you want to close the sprint <em>{sprintname}</em>?</Trans>
                  )}
                  variant="outline-secondary"
                  onConfirm={e => close(sprint.pk)}>
                  <Trans>Close</Trans>
                </ConfirmButton>
              </>
            ) }
            { product.can_edit_sprint && !sprint.is_current && !hasActive && sprint.status.value === 'open' && (
              <button
                onClick={e => activate(sprint.pk)}
                type="button"
                className="btn btn-outline-secondary">
                <Trans>Start sprint</Trans>
              </button>
            ) }
            { product.can_edit_sprint && (
              <button
                onClick={e => setEditItem(sprint)}
                type="button"
                className="btn btn-outline-secondary">
                <Icon name="pencil"/> <Trans>Edit</Trans>
              </button>
            ) }
            { product.can_delete_sprint && !sprint.is_current && (
              <ConfirmButton
                message={
                  <Trans>Are you sure you want to delete the sprint <em>{sprintname}</em>?</Trans>}
                variant="outline-secondary"
                onConfirm={() => deleteSprint(sprint)}>
                <Trans>Delete</Trans>
              </ConfirmButton>
            ) }
          </div>
        </div>
      </div>
    </div>
  );
}

function SprintsNoContent() {
  const doc_url = websiteUrl('/docs/core-concept/product/sprint'); 
  return (
    <Card className="sprints-list mt-3">
      <Card.Body>
        <h2><Trans>You don't have any sprint</Trans></h2>
        <p>
          <a className="btn btn-light" href={doc_url} rel="noreferrer" target="_blank">
            <Icon name="info-circle-fill" className="text-primary" />
            <span className="ms-2"><Trans>Read the documentation about sprints</Trans></span>
          </a>
        </p>
      </Card.Body>
    </Card>
  );
}

function SprintsPageContent(props) {
  const { organization, product } = props;
  const [editItem, setEditItem] = useState(null);
  const [sprints, setSprints] = useState(null);
  const [page, setPage] = useState(1);
  const refresh = useStateItem('sprints');
  const limit = 10;

  useEffect(() => {
    let url = '/sprints?subset=offset&order=-date_from&limit=' + limit + '&product=' + product.pk;
    if (page > 1) {
      url += '&offset=' + (limit * (page - 1));
    }
    axios.get(url).then(response => {
      const sprintList = response.data.items;
      for (const sprint of sprintList) {
        setStateItem('sprints:' + sprint.pk, sprint, {
          isFull: true,
          refreshParent: false
        });
      }
      setSprints({
        meta: response.data.meta,
        list: sprintList
      });
    }).catch(e => {
      addMessage('sprints-load', t`Unknown error`, t`Impossible to load sprints`);
    });
  }, [refresh, product.pk, page]);

  const activeSprint = (sprints && sprints.list) ? sprints.list.find(spr => spr.is_current) : null;

  return sprints === null ? (
    <LoaderContainer height="3"/>
  ) : (
    <>
      <SprintModal
        product={product}
        organization={organization}
        pk={editItem ? editItem.pk : null}
        show={!!editItem}
        modalTitle={t`Edit sprint`}
        hideButton />
      { sprints.list.length < 1 && (
        <SprintsNoContent />
      ) }
      { sprints.meta.subset.count > limit && (
        <Pagination
          className="justify-content-end"
          count={sprints.meta.subset.page_count}
          page={page}
          onNav={setPage} />
      )}
      <div className="sprints-list">
        { sprints.list.map(sprint => (
          <SprintItem
            key={sprint.pk}
            pk={sprint.pk}
            setEditItem={setEditItem}
            hasActive={!!activeSprint}
            setSprints={setSprints}
            organization={organization}
            product={product} />
        ))}
      </div>
      { sprints.meta.subset.count > limit && (
        <Pagination
          className="justify-content-end mt-3"
          count={sprints.meta.subset.page_count}
          page={page}
          onNav={setPage} />
      )}

    </>
  );
}
export default function SprintsPage() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Sprints`
  ];
  const sprintCreate = (
    <SprintModal
      variant="primary"
      key="1"
      product={product}
      title={(<><Icon name="plus-circle"/> <Trans>Add sprint</Trans></>)}
      organization={organization}/>
  );

  return (
    <ProductPage
      name="sprints-page"
      title={t`Sprints`}
      breadcrumb={breadcrumb}>
      { product.can_add_sprint && (
        sprintCreate
      ) }
      <SprintsPageContent organization={organization} product={product} />
    </ProductPage>
  );
}
