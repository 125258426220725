/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import Datatable from 'components/datatable/Datatable';
import Icon from 'components/ui/Icon';
import OrganizationContext from 'components/utils/OrganizationContext';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import ProductContext from 'components/utils/ProductContext';
import ProductPage from 'components/page/ProductPage';
import TriggerModal from 'components/modal/TriggerModal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Product from 'entity/Product';

const toProduct = (item) => {
  const product = new Product();
  product.loadFromObject(item);
  return product;
};

function TriggerListContent(props) {
  const { organization, product } = props;
  const [, setRefresh] = useState(0);

  const apiContext = {};
  if (product) {
    apiContext.product = product.pk;
  }

  const onEnable = item => {
    axios.put(`/triggers/${item.pk}`, { active: true })
      .then((response) => {
        addMessage(
          'enable-success', t`Success`, response.data.meta.message);
        setRefresh(current => current + 1);
      }).catch((error) => addMessage(
        'enable-error', t`Server error`, error.response.data.message));
  };
  const onDisable = item => {
    axios.put(`/triggers/${item.pk}`, { active: false })
      .then((response) => {
        addMessage(
          'disable-success', t`Success`, response.data.meta.message);
        setRefresh(current => current + 1);
      }).catch((error) => addMessage(
        'disable-error', t`Server error`, error.response.data.message));
  };
  const getActions = item => {
    if (!product.can_manage_triggers) return [];
    if (!item.active) {
      return [
        {
          key: 'onenable',
          onClick: () => onEnable(item),
          content: <Trans>Enable</Trans>
        }
      ];
    }
    return [
      {
        key: 'ondisable',
        onClick: () => onDisable(item),
        content: <Trans>Disable</Trans>
      }
    ];
  };

  return (
    <Datatable
      api="triggers"
      canEdit={() => false}
      canDelete={item => toProduct(item).can_manage_triggers}
      apiContext={apiContext}
      rowClasses={(it, classes) => {
        if (it.error) {
          classes.push('table-danger');
        }
      }}
      actions={getActions}>
      <Datatable.Column
        name="name"
        label={t`Name`}>
        {(it) => (
          <Link to={`/${organization.slug}/${product.slug}/triggers/${it.pk}`}>
            { it.name }
          </Link>
        )}
      </Datatable.Column>
      <Datatable.Column
        name="when"
        label={t`When`}>
        {(it) => it.when.label}
      </Datatable.Column>
      <Datatable.Column
        name="error"
        label={t`Error`}>
        { (it) => (it.error ? (
          <>
            <Icon name="exclamation-triangle" />{' '}
            { it.error_message }
          </>
          
        ) : null) }
      </Datatable.Column>
      <Datatable.Column
        name="active"
        label={t`Active`}>
        { (it) => (!it.active ? (
          <Icon name="x-circle" />
          
        ) : null) }
      </Datatable.Column>
    </Datatable>
  );
}
export default function TriggerListPage() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const history = useHistory();

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Triggers`
  ];

  return (
    <ProductPage
      name="trigger-list-page"
      title={t`Triggers`}
      breadcrumb={breadcrumb}>
      { product.can_manage_triggers && (
        <TriggerModal
          variant="primary"
          onSave={(data) => history.push(`/${organization.slug}/${product.slug}/triggers/${data.pk}`)}
          product={product}
          title={(<><Icon name="plus-circle"/> <Trans>Add trigger</Trans></>)} />
      ) }
      <TriggerListContent organization={organization} product={product} />
    </ProductPage>
  );
}
