/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import OrganizationContext from 'components/utils/OrganizationContext';
import AdminPage from 'components/page/AdminPage';
import ProductJiraImport from 'components/organization/import/ProductJiraImport';

export default function ProductImport() {
  const organization = useContext(OrganizationContext);
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/manage/products`}>{ t`Products` }</Link>,
    t`Import`
  ];
  const actions = [];
  const sources = [{ name: 'Jira', value: 'jira' }];
  const [selectedSource, setSelectedSource] = useState(
    sources.length ? sources[0].value : null);

  const subPage = () => {
    switch (selectedSource) {
      case 'jira':
        return <ProductJiraImport/>;
      default:
        return <p><Trans>Select a source for import.</Trans></p>;
    }
  };

  return (
    <AdminPage
      name="product-import-page"
      title={t`Import products`}
      breadcrumb={breadcrumb}
      actions={actions}>
      { sources.length > 1 && (
        <ul className="nav nav-tabs mb-3">
          {sources.map((source, idx) => (
            <li className="nav-item" key={source.value}>
              <button
                id={`radio-${idx}`}
                type="button"
                className={'nav-link' + (selectedSource === source.value ? ' active' : '')}
                value={source.value}
                onClick={(e) => setSelectedSource(source.value)}>
                {source.name}
              </button>
            </li>

          ))}
        </ul>
      )}
      {subPage()}
    </AdminPage>
  );
}
