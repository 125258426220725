import React from 'react';
import { useLocation } from 'react-router-dom';
import { t } from '@lingui/macro';
import { Helmet } from 'react-helmet-async';
import { Login } from 'components/auth';
import HomePage from 'components/page/HomePage';

export default function LoginPage(props) {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };
  return (
    <HomePage name="page-login">
      <div className="login-block">
        <Helmet>
          <title>{t`Optera.io`}</title>
        </Helmet>
        <Login from={from}/>
      </div>
    </HomePage>
  );
}
