/* eslint-disable max-len */
import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import axios from 'axios';
import { DragDropContext } from 'react-beautiful-dnd';
import { addMessage } from 'components/ui/Messages';
import ProductContext from 'components/utils/ProductContext';
import { setStateItem } from 'utils/Item';
import getSensor from 'mocks/cypressSensor';
import StoryMapContext from './StoryMapContext';
import { StoryMapTableHeaderPersonas } from './StoryMapPersona';

function moveItems(source, destination, parentData, pk, loader, parentItems, entityName, parentName) {
  const params = {
    move: 'bottom'
  };
  const item = { pk: pk };
  const parentPk = parseInt(destination.droppableId.split('-')[1], 10);
  const destItems = parentData ? [...parentData[parentPk][entityName]] : [...parentItems];
  let sourceParentPk = null;
  let sourceItems = null;
  
  const destItemsFiltered = destItems.filter(i => i !== pk);
  if (destination.index < destItemsFiltered.length) {
    params.move = 'above';
    params.object = destItemsFiltered[destination.index];
  }
  if (source.droppableId === destination.droppableId) {
    if (source.index === destination.index) {
      return;
    }
    const [removed] = destItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
  }
  else {
    item[parentName] = { pk: parentPk };
    sourceParentPk = parseInt(source.droppableId.split('-')[1], 10);
    sourceItems = [...parentData[sourceParentPk][entityName]];
    const [removed] = sourceItems.splice(source.index, 1);
    const newItem = {
      pk: removed
    };
    newItem[parentName] = { pk: parentPk };
    setStateItem(entityName + ':' + removed, newItem);
    destItems.splice(destination.index, 0, removed);
  }
  if (parentData) {
    const newItems = [];
    parentItems.forEach(p => {
      if (sourceParentPk === p) {
        newItems.push(...sourceItems);
      }
      else if (parentPk === p) {
        newItems.push(...destItems);
      }
      else {
        newItems.push(...parentData[p][entityName]);
      }
    });
    loader(newItems);
  }
  else {
    loader(destItems);
  }
  
  const queryString = new URLSearchParams(params).toString();
  axios.put(`${entityName}/${pk}?${queryString}`, item).then((response) => {
  }).catch((error) => {
    addMessage('update-' + entityName, t`Unknown error`, t`Impossible to update item`);
    loader();
  });
}

export default function StoryMapTableHeader(props) {
  const product = useContext(ProductContext);
  const { loadActivities, loadGoals, loadPersonas, goals, personas } = useContext(StoryMapContext);
  const { goalsData, personasData } = props;
  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (destination === null) return;
    const [type, pk] = draggableId.split('-');
    const intPk = parseInt(pk, 10);
    if (type === 'a') {
      moveItems(source, destination, goalsData, intPk, loadActivities, goals, 'activities', 'goal');
    }
    else if (type === 'g') {
      moveItems(source, destination, personasData, intPk, loadGoals, personas, 'goals', 'persona');
    }
    else if (type === 'p') {
      moveItems(source, destination, null, intPk, loadPersonas, personas, 'personas', null);
    }
  };
  const sensors = [];
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') sensors.push(getSensor('.story-map-head'));
  return (
    <DragDropContext onDragEnd={onDragEnd} sensors={sensors}>
      <div className="story-map-head">
        <StoryMapTableHeaderPersonas product={product} {...props} />
      </div>
    </DragDropContext>
  );
}
