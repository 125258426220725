import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DateFormat from 'components/ui/DateFormat';
import { LoaderContainer } from 'components/ui/Loader';
import Icon from 'components/ui/Icon';
import { D, SubMenu } from './SprintBurndown';

const SvgEl = function(props) {
  const { title, w, h, data, max } = props;

  if (!data) {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}></svg>;
  }

  const xMargin = 1;
  const yMargin = 10;
  const insideMargin = 20;
  const axeD = new D(xMargin, 0);
  axeD.lineTo(0, h - yMargin);
  axeD.lineTo(w - (xMargin + 1), 0);

  const vertX = [];
  const diff = (w - (xMargin + 2 * insideMargin + 1)) / (data.labels.length - 1);
  for (let d = 0; d <= data.labels.length; d++) {
    vertX.push(xMargin + insideMargin + (Math.round(100 * d * diff) / 100));
  }

  const drawLine = function(dataset) {
    const availH = h - (yMargin + 2 * insideMargin);
    let y = availH * (max - dataset[0]) / max;
    const graphD = new D(
      xMargin + insideMargin, insideMargin + Math.round(100 * y) / 100);
    for (let d = 1; d <= dataset.length; d++) {
      y = availH * (max - dataset[d]) / max;
      graphD.lineToAbs(
        xMargin + insideMargin + (Math.round(100 * d * diff) / 100),
        insideMargin + Math.round(100 * y) / 100
      );
    }
    return graphD;
  };

  const graphScope = drawLine(data.scope);
  const graphTodo = data.todo && drawLine(data.todo);
  const graphDone = drawLine(data.done);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
      <title>{ title }</title>
      <path className="st1" d={axeD.toString()}/>
      { vertX.map(vx => (
        <line key={vx} x1={vx} y1={h - yMargin} x2={vx} y2={h} className="st1" />
      )) }
      <line
        x1={xMargin + insideMargin}
        y1={h - (yMargin + insideMargin)}
        x2={w - insideMargin}
        y2={insideMargin + Math.round(100 * (h - (yMargin + 2 * insideMargin)) * (max - data.scope.slice(-1)) / max) / 100}
        strokeDasharray="3"
        className="st2" />
      {graphTodo && (
        <path className="st-todo" d={graphTodo.toString()}/>
      )}
      <path className="st-done" d={graphDone.toString()}/>
      <path className="st-scope" d={graphScope.toString()}/>
    </svg>
  );
};

export default function ProductBurnup(props) {
  const { product, sprint, noTodos, nosettings } = props;
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [fields, setFields] = useState(false);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(function() {
    setError(null);
    let apiUrl = null;
    if (sprint) {
      apiUrl = `/sprints/${sprint.pk}/burnup?sum=${product.sprint_burnup_sum.value}`;
    } else {
      apiUrl = `/products/${product.pk}/burnup?sum=${product.product_burnup_sum.value}` +
        `&group_by=${product.product_burnup_group_by.value}&limit=${product.product_burnup_limit}`;
    }
    axios.get(apiUrl).then(res => {
      setData(res.data);
      setLoading(false);
    }).catch(err => {
      setData(false);
      setError(t`Impossible to show graph`);
      setLoading(false);
    });
  }, [product, sprint]);

  useEffect(() => {
    loadData();
    if (product && product.pk) {
      product.loadMeta().then((d) => {
        setFields(d.fields);
      }).catch(err => {
        setFields(null);
        setError(t`Metas can't be loaded`);
      });
    }
  }, [loadData, product, sprint]);

  const updateProduct = (field, value) => {
    product[field] = value;
    setLoading(true);
    product.saveField(field).then(() => {
      product.reload().then(() => {
        if (field !== 'sprint_graph') {
          loadData();
        }
      }).catch(err => {
        setError(t`Product can't be loaded`);
      });
    }).catch(err => {
      setError(t`Product can't be loaded`);
    });
  };

  if (loading) {
    return (
      <LoaderContainer height="1"/>
    );
  }
  return (
    <div className="graph graph-burnup">
      {product.can_edit && !nosettings && (
        <Dropdown className="d-inline-block float-end" align="end">
          <Dropdown.Toggle className="btn" variant="link">
            <Icon name="three-dots-vertical" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="p-2">
            {sprint ? (
              <>
                <Dropdown className="" drop="start">
                  <Dropdown.Toggle as={SubMenu}>
                    <Trans>Graph type</Trans>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {fields && fields.sprint_graph.choices.map(o => (
                      <button
                        key={`group_by_${o.value}`}
                        type="button"
                        className={`btn btn-outline-dark dropdown-item ${product.sprint_graph.value === o.value ? 'active' : ''}`}
                        value={o.value}
                        onClick={(e) => { updateProduct('sprint_graph', e.target.value); }}>
                        {o.label}
                      </button>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="" drop="start">
                  <Dropdown.Toggle as={SubMenu}>
                    <Trans>Sum</Trans>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {fields && fields.sprint_burnup_sum.choices.map(o => (
                      <button
                        key={`group_by_${o.value}`}
                        type="button"
                        className={`btn btn-outline-dark dropdown-item ${product.sprint_burnup_sum.value === o.value ? 'active' : ''}`}
                        value={o.value}
                        onClick={(e) => { updateProduct('sprint_burnup_sum', e.target.value); }}>
                        {o.label}
                      </button>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <Dropdown className="" drop="start">
                  <Dropdown.Toggle as={SubMenu}>
                    <Trans>Sum</Trans>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {fields && fields.product_burnup_sum.choices.map(o => (
                      <button
                        key={`sum_${o.value}`}
                        type="button"
                        className={`btn btn-outline-dark dropdown-item ${product.product_burnup_sum.value === o.value ? 'active' : ''}`}
                        value={o.value}
                        onClick={(e) => { updateProduct('product_burnup_sum', e.target.value); }}>
                        {o.label}
                      </button>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="" drop="start">
                  <Dropdown.Toggle as={SubMenu}>
                    <Trans>Granularity</Trans>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {fields && fields.product_burnup_group_by.choices.map(o => (
                      product.mode.value === 'scrum' || o.value !== 'sprint') && (
                      <button
                        key={`group_by_${o.value}`}
                        type="button"
                        className={`btn btn-outline-dark dropdown-item
                          ${product.product_burnup_group_by.value === o.value ? 'active' : ''}`}
                        value={o.value}
                        onClick={(e) => { updateProduct('product_burnup_group_by', e.target.value); }}>
                        {o.label}
                      </button>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="" drop="start">
                  <Dropdown.Toggle as={SubMenu}>
                    <Trans>Number of values</Trans>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {fields && [5, 10, 15, 20].map(n => (
                      <button
                        key={`limit_${n}`}
                        type="button"
                        className={`btn btn-outline-dark dropdown-item ${product.product_burnup_limit === n ? 'active' : ''}`}
                        value={n}
                        onClick={(e) => { updateProduct('product_burnup_limit', e.target.value); }}>
                        {n}
                      </button>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      )}
      <h3 className="fs-5 text-center">
        {sprint ? t`Sprint burnup` : t`Product burnup`}
      </h3>
      <div className="graph__legend">
        <span className="bar bar-scope"></span> <Trans>Scope</Trans>
        {noTodos || (
          <><span className="bar bar-todo ms-3"></span> <Trans>Todo</Trans></>
        )}
        <span className="bar bar-done ms-3"></span> <Trans>Done</Trans>
      </div>
      <div className="graph__inside">
        { data && (
          <div className="graph__graph">
            <SvgEl w="600" h="240" title={t`Scope`} data={data} max={data.max_value} />
          </div>
        ) }
        <div className="graph__yaxis">
          { (sprint ? product.sprint_burnup_sum : product.product_burnup_sum).value === 'nb_items' ? (
            <Trans>Backlog items</Trans>
          ) : (
            product.estimate_mode.value === 'hours' ? (
              <Trans>Hours</Trans>
            ) : (
              <Trans>Points</Trans>
            )
          )}
        </div>
        <div className="graph__xaxis">
          { data && (
            product.product_burnup_group_by.value === 'sprint' && !sprint ? (
              <span>{data.labels[0]} - {data.labels[data.labels.length - 1]}</span>
            ) : (
              <>
                <DateFormat month="long" year="numeric" day="numeric" datestring={data.labels[0]}/> -{' '}
                <DateFormat month="long" year="numeric" day="numeric" datestring={data.labels[data.labels.length - 1]}/>
              </>
            )
          ) }
        </div>
        { error && (
          <div className="graph__error alert alert-danger">{ error }</div>
        ) }
      </div>

    </div>
  );
}
