import { t } from '@lingui/macro';
import AppContext from 'AppContext';
import { addMessage } from 'components/ui/Messages';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PersistentSocket from './PersistentSocket';

export const NotificationsContext = createContext();

function NotificationsProvider(props) {
  const { user } = useContext(AppContext);
  const [notificationsOpened, setNotificationsOpened] = useState(null);
  const [socket, setSocket] = useState(false);
  const [needRefresh, setNeedRefresh] = useState(0);

  useEffect(() => {
    if (!user) {
      return () => {};
    }
    const userSocket = new PersistentSocket(`${process.env.REACT_APP_WS_URL}/ws/user`, {
      onopen: () => {
        setNotificationsOpened(true);
      },
      onmessage: (e) => {
        const data = JSON.parse(e.data);
        const event = new CustomEvent('notification', { detail: data });
        document.dispatchEvent(event);
      },
      onfail: () => {
        setNotificationsOpened(false);
        addMessage(
          'socket-closed-' + Date.now(),
          t`Connection problem`,
          t`Unable to connect to notification server due to unknown problem.`
        );
      },
      onwakeup: () => {
        setNeedRefresh(v => v + 1);
      }
    });
    setSocket(userSocket);

    return () => {
      userSocket.close();
      setSocket(null);
    };
  }, [user]);

  const ret = useMemo(() => ({
    isActive: notificationsOpened,
    socket: socket,
    needRefresh: needRefresh
  }), [notificationsOpened, socket, needRefresh]);
  return (
    <NotificationsContext.Provider value={ret}>
      { props.children }
    </NotificationsContext.Provider>
  );
}
export default NotificationsProvider;
