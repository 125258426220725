/* eslint-disable max-len */
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import optera from 'assets/optera.svg';

class HomePage extends React.Component {
  componentDidMount() {
    document.body.classList.add('body-home');
  }

  componentWillUnmount() {
    document.body.classList.remove('body-home');
  }

  render() {
    const classes = ['page', 'home-page'];
    const { name, title, ...rest } = this.props;
    if (name) classes.push(this.props.name);
    return (
      <div className={classes.join(' ')} {...rest}>
        <div className="bg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.24 99.03">
            <defs>
              <linearGradient id="MyGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="5%" stopColor="#28121e"/>
                <stop offset="95%" stopColor="#54102f"/>
              </linearGradient>
            </defs>
            <path fill="url(#MyGradient)" d="M949.36,568.15c-11.15,9.35-26.2,6-33.61-9.68s-6-33.58-6-33.58,27.33,11.35,36.07,13S957.17,526.19,949,524s-39.23-9.28-39.23-9.28l1-7.18s41.4,9.67,50.33,2.6,3.35-14.31-3.49-14.31H920.46a9,9,0,0,0-16.73-6.63A9,9,0,0,0,887,495.79H849.59c-6.84,0-12.42,7.24-3.49,14.31s50.33-2.6,50.33-2.6l1,7.18s-31,7.05-39.24,9.28-5.58,15.63,3.17,13.95,36.07-13,36.07-13-1.56,19.44,4,33.58,21.65,29.09,38.62,23.35c18.14-6.14,26.41-15.88,28.47-19C973.91,554.75,960,559.22,949.36,568.15Zm-53.75-68.58a6.5,6.5,0,1,1,6.5-6.49A6.49,6.49,0,0,1,895.61,499.57Zm9.75-6.49a6.5,6.5,0,1,1,6.49,6.49A6.49,6.49,0,0,1,905.36,493.08Z" transform="translate(-841.48 -484.05)"/>
          </svg>
        </div>
        <main>
          <div className="content-container">
            <div className="icon-container">
              { name === 'page-loading' ? (
                <img src={optera} alt="Optera.io"/>
              ) : (
                <Link to="/">
                  <img src={optera} alt="Optera.io"/>
                </Link>
              ) }

            </div>

            { title && (
              <>
                <Helmet>
                  <title>{title}</title>
                </Helmet>
                <h1 className="main-title">{ title }</h1>
              </>
            ) }
            { this.props.children }
          </div>
        </main>
      </div>
    );
  }
}

export default HomePage;
