import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import axios from 'axios';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { LoaderContainer } from 'components/ui/Loader';
import { setStateItem } from 'utils/Item';
import { manageFormErrors } from './Form';

export default function BacklogItemReopen(props) {
  const { pk } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const reopen = () => {
    setLoading(true);
    axios.put(`/backlog-items/reopen/${pk}`).then(res => {
      setStateItem(`backlog-items:${pk}`, res.data, { isFull: true });
      setLoading(false);
    }).catch(err => {
      const { globalErrors } = manageFormErrors(null, err, true);
      setError(globalErrors.map(e => e.error).join(<br/>));
      setLoading(false);
    });
  };

  return (
    loading ? (
      <LoaderContainer size="tiny"/>
    ) : (
      <OverlayTrigger
        show={error !== null}
        placement="right"
        overlay={(
          <Popover id="dropdown-item-button-popover">
            <Popover.Body>
              { error }
            </Popover.Body>
          </Popover>
        )}>
        {({ ref, ...triggerHandler }) => (
          <button className="btn btn-sm btn-outline-secondary ms-2" type="button" onClick={reopen} {...triggerHandler} ref={ref}>
            <Trans>Reopen</Trans>
          </button>
        )}
      </OverlayTrigger>
    )
  );
}
