const _cache = {};

const cache = {
  get(cid, defaultValue, seconds) {
    if (seconds && cid in _cache) {
      if (seconds < ((Date.now() - _cache[cid].time) / 1000)) {
        return defaultValue || null;
      }
    }
    if (cid in _cache) return _cache[cid].value;
    if (defaultValue) return defaultValue;
    return null;
  },
  set(cid, value) {
    _cache[cid] = {
      value: value,
      time: Date.now()
    };
  },
  clear(cid) {
    if (cid in _cache) {
      delete _cache[cid];
    }
  }
};
export default cache;
