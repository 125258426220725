/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import axios from 'axios';
import { Form, FormContextProvider } from 'components/form';

function SprintFinalForm(props) {
  const { product, onSave, onCancel, isNotReady } = props;
  return (
    <Form
      name="sprint-add-form"
      noValidate
      onSave={onSave}
      isNotReady={isNotReady}>
      <Form.Field id="name" name="name" required />
      <Form.Field id="goal" name="goal" as="textarea"/>
      <div className="d-flex flex-row">
        <div><Form.Field type="date" id="date_from" name="date_from" required /></div>
        <div className="ms-2"><Form.Field type="date" id="date_to" name="date_to" required/></div>
      </div>
      { product.can_see_estimates && (
        product.estimate_mode.value === 'hours' ? (
          <Form.Field id="hours_effort" name="hours_effort" placeholder="100h" />
        ) : (
          <Form.Field type="number" id="points_effort" name="points_effort" />
        )
      ) }
      <div className="actions">
        <button className="btn btn-primary" type="submit"><Trans>Save</Trans></button>
        <button className="btn btn-outline-secondary" type="button" onClick={e => { onCancel(); }}><Trans>Cancel</Trans></button>
      </div>
    </Form>
  );
}

const SprintForm = function(props) {
  const { onSave, onCancel, product, sprint } = props;
  const isEdit = sprint && !!sprint.pk;
  const [isNotReady, setIsNotReady] = useState(true);
  const [sprintItem, setSprintItem] = useState(sprint);
  useEffect(() => {
    if (!isEdit) {
      setIsNotReady(true);
      sprint.product = { pk: product.pk };
      axios.get('/sprints?order=-date_from&subset=offset&limit=1&product=' + product.pk).then(res => {
        if (res.data.items.length > 0) {
          const lastSprint = res.data.items[0];
          const start = new Date(lastSprint.date_from + 'T12:00');
          const end = new Date(lastSprint.date_to + 'T12:00');
          const newStart = new Date(lastSprint.date_to + 'T12:00');
          while (newStart.getDay() !== 1) {
            newStart.setTime(newStart.getTime() + 1000 * 60 * 60 * 24);
          }
          const newEnd = new Date(newStart.getTime() + (end.getTime() - start.getTime()));
          sprint.date_from = newStart.toISOString().split('T')[0];
          sprint.date_to = newEnd.toISOString().split('T')[0];
        }
        setSprintItem({ ...sprint });
        setIsNotReady(false);
      }).catch(err => {
        console.error('Unable to load last sprint');
        setSprintItem({ ...sprint });
        setIsNotReady(false);
      });
    }
    else {
      setIsNotReady(false);
    }
  }, [isEdit, product, sprint]);

  return (
    <FormContextProvider item={sprintItem} api="/sprints">
      <SprintFinalForm
        isEdit={isEdit}
        product={product}
        onSave={onSave}
        onCancel={onCancel}
        isNotReady={isNotReady} />
    </FormContextProvider>
  );
};

export default SprintForm;
