/* eslint-disable
  no-lonely-if,
  max-classes-per-file,
  jsx-a11y/no-noninteractive-element-interactions,
  jsx-a11y/no-static-element-interactions,
  jsx-a11y/click-events-have-key-events */
import { t, Trans } from '@lingui/macro';
import React, { useEffect, useRef } from 'react';
import { createPopper } from '@popperjs/core';
import getSearchResults from 'entity/SearchResults';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Loader } from './Loader';
import Icon from './Icon';

class FakeEntity {
  constructor(data) {
    Object.assign(this, data);
  }

  getPath() {
    return this.path;
  }
}

function stringify(str) {
  return str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
}

const resourceName = function(obj) {
  if (obj.resource_reference) {
    return obj.resource_reference + ': ' + obj.resource_name;
  }
  return obj.resource_name;
};

function ItemGroup(props) {
  const { type, data, focusedItem, preselection, changeSelected, validateSelect, searchVal } = props;
  const titles = {
    orgs: t`Organizations`,
    products: t`Products`,
    items: t`Backlog items`
  };
  if (data.length < 1) return null;

  return (
    <>
      <h3 className="h5">{ titles[type] }</h3>
      <div className={'btn-group-vertical group-' + type}>
        { data.map(entity => (
          <Item
            key={entity.pk}
            focusedItem={focusedItem}
            entity={entity}
            preselection={entity.pk === preselection}
            changeSelected={changeSelected}
            validate={validateSelect}
            searchVal={searchVal}/>
        )) }
      </div>
    </>
  );
}
function Item(props) {
  const { entity, searchVal, preselection, changeSelected, validate, focusedItem } = props;
  const ref = useRef();

  useEffect(() => {
    if (preselection && focusedItem === entity.pk) ref.current.focus();
  }, [preselection, focusedItem, entity.pk]);

  const mouseEnter = () => {
    changeSelected(entity.pk);
  };

  const resName = resourceName(entity);
  let finalText = resName;
  if (searchVal) {
    const re = new RegExp(stringify(searchVal), 'g');
    const array1 = re.exec(resName);
    if (array1 !== null) {
      finalText = (
        <>
          { resName.substring(0, re.lastIndex - searchVal.length) }
          <mark>{ resName.substring(re.lastIndex - searchVal.length, re.lastIndex) }</mark>
          { resName.substring(re.lastIndex) }
        </>
      );
    }
  }
  return (
    <button
      className={'btn ' + (preselection ? 'btn-primary' : 'btn-outline-secondary')}
      onMouseEnter={mouseEnter}
      onClick={validate}
      ref={ref}
      type="button"
      tabIndex="-1">
      { finalText }
    </button>
  );
}

/**
 * props:
 *  nullText: Text shown for null value (opt | default to t'None')
 *  value: Actual field value (opt, object with pk and resource_name)
 *  required
 */
class SearchSuggestions extends React.Component {
  constructor(props) {
    super(props);
    this.query = 0;
    this.state = {
      selectedValue: props.value || null,
      opened: false,
      data: null,
      preselection: props.value && props.value.pk,
      focusedItem: null,
      loading: false,
      isValid: null
    };

    this.buttonRef = React.createRef();
    this.popinRef = React.createRef();
    this.inputRef = React.createRef();

    this.toggleOpened = this.toggleOpened.bind(this);
    this.close = this.close.bind(this);
    this.handleBtnKeyDown = this.handleBtnKeyDown.bind(this);
    this.handlePopinKeyDown = this.handlePopinKeyDown.bind(this);
    this.handleInputKeyDown = this.handleInputKeyDown.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.changeSelected = this.changeSelected.bind(this);
    this.validate = this.validate.bind(this);
    this.validateSelect = this.validateSelect.bind(this);
    this.isMount = true;
  }

  handleBtnKeyDown(e) {
    if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      if (!this.state.opened) {
        this.setState({ opened: true });
      }
      else {
        this.handlePopinKeyDown(e);
      }
      e.preventDefault();
    }
    else if (e.code === 'Escape') {
      this.close();
    }
  }

  handlePopinKeyDown(e) {
    if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
      this.setState(prevState => {
        if (!prevState.data || prevState.data.list.length < 1) return {};
        const index = prevState.data.list.findIndex(el => el.pk === this.state.preselection);
        if (index !== -1) {
          if (e.code === 'ArrowDown') {
            if (index >= prevState.data.list.length - 1) {
              return { preselection: prevState.data.list[0].pk };
            }
            return { preselection: prevState.data.list[index + 1].pk };
          }
          if (index <= 0) {
            return { preselection: null };
          }
          return { preselection: prevState.data.list[index - 1].pk };
        }
        return e.code === 'ArrowDown' ? {
          preselection: prevState.data.list[0].pk
        } : {
          preselection: prevState.data.list[prevState.data.list.length - 1].pk
        };
      }, () => {
        if (this.state.preselection === null) {
          this.inputRef.current.focus();
        }
        else {
          this.setState(prevState => ({ focusedItem: prevState.preselection }));
        }
        
      });

      e.preventDefault();
    }
    else if (e.code === 'Enter' || e.code === 'Space') {
      this.validateSelect();
      e.stopPropagation();
    }
    else if (e.code === 'Escape') {
      this.close();
    }
  }

  validateSelect() {
    if (this.state.preselection !== false) {
      if (this.props.onValidate) {
        this.setState(
          this.props.onValidate,
          () => {
            if (this.props.handleSelect) {
              this.props.handleSelect(this.state.selectedValue, this.state, this.inputRef.current.value);
              this.inputRef.current.value = '';
            }
          });
      }
      else {
        this.validate();
      }
    }
  }

  validate() {
    if (this.state.preselection !== false) {
      this.setState(prevState => {
        const selectedOption = prevState.data ? prevState.data.list.find(el => el.pk === prevState.preselection) : null;
        const newState = {
          opened: false,
          isValid: true
        };
        if (selectedOption) {
          newState.selectedValue = selectedOption;
        }
        else if (!this.props.required) {
          newState.selectedValue = null;
        }
        return newState;
      }, () => {
        if (this.props.handleSelect) {
          this.props.handleSelect(this.state.selectedValue, this.state, this.inputRef.current.value);
          this.inputRef.current.value = '';
        }
      });
    }

  }

  handleInputKeyDown(e) {
    if (e.code === 'ArrowDown' || e.code === 'ArrowUp') {
      this.handlePopinKeyDown(e);
      e.preventDefault();
      e.stopPropagation();
    }
    else if (e.code === 'Escape') {
      this.close();
    }
    else if (e.code === 'Enter') {
      this.validateSelect();
      e.stopPropagation();
    }
  }

  onOpen() {
    if (!this.props.noPopper) {
      this.popperInstance = createPopper(this.buttonRef.current, this.popinRef.current, {
        placement: 'bottom-start',
      });
    }
    const el = this.props.parent ? document.querySelector(this.props.parent) : document;
    el.addEventListener('click', this.close);
    this.inputRef.current.focus();
  }

  onClose() {
    if (this.popperInstance) {
      this.popperInstance.destroy();
      this.popperInstance = null;
    }
    const el = this.props.parent ? document.querySelector(this.props.parent) : document;
    el.removeEventListener('click', this.close);
  }

  close() {
    this.setState({ opened: false, focusedItem: null });
  }

  toggleOpened() {
    this.setState(prevState => ({
      opened: !prevState.opened
    }));
  }

  handleSearchChange(e) {
    this.query += 1;
    const q = this.query;
    this.setState({
      loading: true
    });
    getSearchResults({
      search: this.inputRef.current.value
    }).then(res => {
      if (this.isMount && this.query === q) {
        res.list = [];
        for (const type of res.order) {
          if (type !== 'items' && res[type].length === 1) {
            const extraLinks = res[type][0].getLinks();
            for (let j = 0; j < extraLinks.length; j++) {
              res[type].push(new FakeEntity({
                pk: type + '-' + extraLinks[j].name,
                resource_name: '> ' + extraLinks[j].title,
                path: extraLinks[j].path
              }));
            }
          }
          for (let i = 0; i < res[type].length; i++) {
            res.list.push(res[type][i]);
          }
        }
        this.setState({ 
          data: { ...res },
          loading: false,
          opened: res.list.length > 0
        
        });
      }
    }).catch(err => {
      if (this.isMount) {
        console.error('Impossible to show search results', err);
      }
    });
  }

  changeSelected(value) {
    this.setState({ preselection: value, focusedItem: value });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.opened !== prevState.opened) {
      if (this.state.opened) {
        this.onOpen();
      }
      else {
        this.onClose();
      }
    }
    if (this.props.forceFocus !== prevProps.forceFocus && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  componentWillUnmount() {
    const el = this.props.parent ? document.querySelector(this.props.parent) : document;
    el.removeEventListener('click', this.close);
    this.isMount = false;
  }

  render() {

    const searchVal = (this.inputRef.current) ?
      this.inputRef.current.value :
      null;

    const baseClass = ['search-suggestions', 'dropdown'];
    if (this.props.className) baseClass.push(this.props.className);

    const attributes = {};
    if ('id' in this.props) {
      attributes.id = this.props.id;
    }

    const dMenuClass = ['dropdown-menu'];
    if (this.state.opened) {
      dMenuClass.push('show');
    }
    if ('dMenu' in this.props && this.props.dMenu) {
      dMenuClass.push(this.props.dMenu);
    }

    return (
      <div
        {...attributes}
        className={baseClass.join(' ')}
        role="listbox"
        tabIndex="-1"
        onClick={e => { e.stopPropagation(); }}>
        <Form.Floating className="sidebar-search">
          <Form.Control
            ref={this.inputRef}
            type="text"
            id="search"
            placeholder={t`search`}
            onChange={this.handleSearchChange}
            autoComplete="off"
            onKeyDown={this.handleInputKeyDown} />
          <Form.Label htmlFor="search"><Trans>Search</Trans></Form.Label>
          <button className="btn" type="button" onClick={this.validateSelect}>
            <Icon name="search" title={t`Search`}/>
          </button>
          { this.state.loading && (
            <Loader size="tiny" />
          ) }
          <OverlayTrigger
            placement="bottom"
            overlay={(
              <Tooltip id="tooltip-search" className="tooltip-search">
                <Trans>Type <span className="badge with-border key-info">/</span> to search</Trans>
              </Tooltip>
            )}>
            <span className="badge with-border key-info">/</span>
          </OverlayTrigger>
        </Form.Floating>
        { this.state.data && (
          <div
            className={dMenuClass.join(' ')}
            ref={this.popinRef}
            onKeyDown={this.handlePopinKeyDown}>
            
            { this.state.data.order.map(type => (
              <ItemGroup
                key={type}
                type={type}
                data={this.state.data[type]}
                focusedItem={this.state.focusedItem}
                preselection={this.state.preselection}
                changeSelected={this.changeSelected}
                validateSelect={this.validateSelect}
                searchVal={searchVal} />
            )) }
          </div>
        ) }
      </div>
    );
  }
}
SearchSuggestions.defaultProps = {
  parent: null,
  required: false,
  forceFocus: 0
};

export default SearchSuggestions;
