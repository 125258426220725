import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { t } from '@lingui/macro';
import Icon from 'components/ui/Icon';
import { FormContext } from './FormContext';
import BaseField from './BaseField';

function CodeInput(props) {
  const { value, handleChange, index, error, id, refs, autofocus } = props;
  const ref = useRef();
  useEffect(() => {
    refs.current[index] = ref;
    if (autofocus && ref.current) {
      ref.current.focus();
    }
  }, [index, refs, autofocus]);

  return (
    <Form.Control
      ref={ref}
      value={value}
      onInput={(e) => {
        handleChange(index, e.target.value, e);
      }}
      isInvalid={error}
      id={id}
      required />
  );
}

function CodeInputs(props) {
  const { size, value, error, id, onChange, autofocus } = props;
  const refs = useRef([]);
  const [parts, setParts] = useState(value.split(''));
  const handleChange = (index, val, e) => {
    const subparts = val.split('');
    if (subparts.length === 2) {
      setParts(old => {
        const newParts = [...old];
        // eslint-disable-next-line prefer-destructuring
        newParts[index] = (e.nativeEvent && e.nativeEvent.data && e.nativeEvent.data.length === 1) ? e.nativeEvent.data : subparts[1];
        if (index + 1 < size) {
          refs.current[index + 1].current.focus();
          refs.current[index + 1].current.select();
        }
        return newParts;
      });
    }
    else if (subparts.length > 1) {
      setParts(old => {
        const newParts = [...old];
        for (let i = 0; i < subparts.length; i++) {
          if (i + index < size) {
            newParts[i + index] = subparts[i];
          }
          if (i === subparts.length - 1 && (i + index + 1) < size) {
            refs.current[i + index + 1].current.focus();
            refs.current[i + index + 1].current.select();
          }
        }
        return newParts;
      });
    }
    else {
      setParts(old => {
        const newParts = [...old];
        newParts[index] = val;
        if (index + 1 < size && val.length > 0) {
          refs.current[index + 1].current.focus();
          refs.current[index + 1].current.select();
        }
        return newParts;
      });
    }
  };

  const onClear = () => {
    setParts([]);
    refs.current[0].current.focus();
  };

  useEffect(() => {
    onChange(parts.join(''));
  }, [parts, onChange]);

  const contents = [];
  for (let i = 0; i < size; i++) {
    contents.push(
      <CodeInput
        key={'code-' + i}
        index={i}
        error={error}
        refs={refs}
        handleChange={handleChange}
        id={i === 0 ? id : (id + '_' + i)}
        value={parts.length > i ? parts[i] : ''}
        autofocus={autofocus && i === 0} />
    );
  }
  return (
    <div className={'code-group' + (error ? ' is-invalid' : '')}>
      { contents }
      <button type="button" className="btn btn-clear" onClick={onClear}>
        <Icon name="x-circle" title={t`Clear`} />
      </button>
    </div>
  );
}
CodeInputs.defaultProps = {
  value: ''
};

function CodeField({ children, ...props }) {
  const { item, setItem, meta, errors } = useContext(FormContext);
  const { label, size, id, autofocus } = props;

  const handleChange = useCallback((value) => {
    item[props.name] = value;
    setItem(item);
  }, [item, setItem, props.name]);

  const groupClasses = ['form-group'];
  if (props.required) groupClasses.push('required');
  if (props.validated) groupClasses.push('was-validated');

  return (
    <BaseField
      {...props}
      label={label || meta && meta.fields && meta.fields[props.name] && meta.fields[props.name].name}
      className={groupClasses.join(' ')}>
      <CodeInputs
        size={size}
        error={!!errors[props.name]}
        value={item[props.name]}
        id={id}
        onChange={handleChange}
        autofocus={autofocus} />
    </BaseField>
  );
}
CodeField.defaultProps = {
  autofocus: false,
  size: 4
};

export default CodeField;
export { CodeInputs };
