import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import BacklogModalContext from 'components/modal/BacklogModalContext';
import DateFormat from 'components/ui/DateFormat';
import DateBlock from 'components/ui/DateBlock';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import Pagination from 'components/ui/Pagination';
import { ColorSticker } from 'components/ui/ColorSticker';
import Icon from 'components/ui/Icon';

function FieldValue(props) {
  const { field, value, className } = props;
  if (!field.type) {
    return null;
  }
  if (value === null) {
    return <em className={`small ${className}`}>{t`(empty)`}</em>;
  }
  switch (field.type) {
    case 'ModelObject':
      if (field.model) {
        switch (field.model) {
          case 'products.ItemStatus':
            return (
              <span className={`badge border text-dark status ${className}`}>{ value.resource_name }</span>
            );
          case 'products.ItemType':
            return (
              <span className={className}>
                <ColorSticker color={value.color} />{' '}
                {value.resource_name}
              </span>
            );
          case 'products.Epic':
            return (
              <span className={`badge bg-col-${value.color || 'none'} epic ${className}`}>
                { value.resource_name }</span>
            );
          case 'organizations.Member':
            return <span className={`badge rounded-pill bg-light text-dark ${className}`}>{value.resource_name}</span>;
          default:
        }
      }
      return <span className={className}>{ (value.resource_reference ? `${value.resource_reference} ` : '') + value.resource_name }</span>;
    case 'CharField':
      if (field.choices === true) {
        if (field.field === 'priority') {
          return (
            <span className={`badge border badge-priority-${value.value} priority ${className}`}>
              { value.label }
            </span>
          );
        }
        if (value.value === '__deleted__') {
          return (
            <span className={`badge border bg-none ${className}`}>
              { value.label }</span>
          );
        }
        return (
          <span className={`badge border text-dark ${className}`}>
            { value.label }</span>
        );
      }
      break;
    case 'BooleanField':
      return (
        <span className={`badge border text-dark ${className}`}>
          { value ? t`Yes` : t`No` }
        </span>
      );
    case 'MinutesDurationField':
      return <span className={className}>{ value.human_format }</span>;
    case 'DateField':
      return <span className={className}><DateFormat datestring={value}/></span>;
    case 'DateTimeField':
      return <span className={className}><DateBlock datestring={value}/></span>;
    default:
  }
  if (typeof value === 'object') {
    return <span className={className}><Trans>(invalid value)</Trans></span>;
  }
  return <span className={className}>{ value }</span>;
}

function Field({ field, noLabel }) {
  if (!field) {
    return null;
  }
  return (
    <>
      { !noLabel && field.label && (
        <span className="text-secondary small">{ field.label }:</span>
      )}
      { field.type === 'TextField' ? (
        field.updated ? <span className="ms-3"><Trans>(updated)</Trans></span> : null
      ) : (
        field.updated ? (
          <>
            <FieldValue key="old-value small" field={field} value={field.old} className="ms-3 me-2"/>
            <Icon name="arrow-right"/>
            <FieldValue key="new-value small" field={field} value={field.new} className="ms-2"/>
          </>
        ) : <FieldValue key="value" field={field} value={field.value} className="ms-3"/>
      )}
    </>
  );
}
Field.defaultProps = {
  noLabel: false
};

function backlogItemContent(item) {
  if (item.operation.value === 'create') {
    return (
      <div className="history-item-summary">
        <span className="text-secondary small me-3"><Trans>Item created</Trans>:</span>
        {item.item.resource_reference} {item.item.resource_name}
        <Field field={item.changes.item_type} noLabel/>
        <Field field={item.changes.status} noLabel/>
      </div>
    );
  }

  const changes = [];
  for (const field in item.changes) {
    changes.push(<div className="history-item-value"><Field key={field} field={item.changes[field]}/></div>);
  }
  return changes;
}

function otherModelContent(item) {
  const models = {
    ItemLink: {
      heads: ['type', 'linked_item'],
      title: {
        create: <Trans>Link added</Trans>,
        update: <Trans>Link updated</Trans>,
        delete: <Trans>Link removed</Trans>
      }
    },
    Worklog: {
      heads: ['date', 'worker'],
      title: {
        create: <Trans>Worklog added</Trans>,
        update: <Trans>Worklog updated</Trans>,
        delete: <Trans>Worklog removed</Trans>
      }
    }
  };
  if (!(item.model in models)) {
    return null;
  }
  const changes = [];
  const heads = models[item.model].heads.map(field => {
    if (!(field in item.changes) || item.changes[field].value === null) {
      // The header values should be required values.
      // But in some cases we don't have the value in the history
      // (if the value wasn't saved or wasn't required).
      // We avoid writing (empty) in the header.
      return null;
    }
    return <Field key={field} field={item.changes[field]} noLabel/>;
  });
  for (const field in item.changes) {
    if (
      field !== 'item' && item.changes[field].value &&
      !models[item.model].heads.includes(field)) {
      changes.push(<div key={field} className="history-item-value"><Field field={item.changes[field]}/></div>);
    }
  }
  if (item.model === 'Worklog' && item.changes.worker && item.modified_by) {
    if ((!item.changes.worker.updated &&
      item.changes.worker.type === 'ModelObject' &&
      item.changes.worker.model === 'organizations.Member' &&
      item.changes.worker.value &&
      item.changes.worker.value.pk !== item.modified_by.pk) ||
      item.changes.worker.updated) {
      changes.push((
        <div key="modified_by" className="history-item-value">
          <span className="text-secondary small"><Trans>Modified by</Trans>:</span>
          <span className="badge rounded-pill bg-light text-dark ms-3">
            {item.modified_by.resource_name}</span>
        </div>
      ));
    }
  }
  return (
    <>
      <div className="history-item-summary">
        <span className="text-secondary small">{models[item.model].title[item.operation.value]}:</span>
        {heads}
      </div>
      {changes}
    </>
  );
}

function History(props) {
  const { item, backlogItem, showModal } = props;

  let content = '';
  if (item.model === 'BacklogItem') {
    content = backlogItemContent(item);
  } else {
    content = otherModelContent(item);
  }
  if (!content) return null;

  return (
    <div className={`history-item history-item__${item.model.toLowerCase()}`}>
      <div className="history-item__header d-flex justify-content-between">
        { !backlogItem && (
          <div><a
            href={item.item.path}
            className="me-2"
            onClick={(e) => {
              e.preventDefault();
              showModal(item.item);
            }} >
            { item.item.resource_reference }
          </a>{ item.item.resource_name }</div>
        )}
        <DateBlock id={`comment-${item.pk}`} datestring={item.modified_at} />
        { backlogItem && (
          <div>
            <DateBlock id={`comment-${item.pk}`} datestring={item.modified_at} />
            <span className="badge rounded-pill bg-light text-dark ms-3">
              { item.modified_by ? item.modified_by.resource_name : t`Anonymous author` }
            </span>
          </div>
        )}
      </div>
      { content }
    </div>

  );
}

function HistoryList(props) {
  const [items, setItems] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [page, setPage] = useState(1);
  const { show, showModal } = useContext(BacklogModalContext);
  const { product, backlogItem, model, hidePagination } = props;
  const limit = 10;

  useEffect(() => {
    if (!show) setRefresh(oldVal => oldVal + 1);
  }, [show]);

  useEffect(() => {
    const params = [];
    if (product) params.push('product=' + product.pk);
    if (backlogItem) params.push('item=' + backlogItem.pk);
    if (model) {
      const arr = model.split(' ');
      for (let i = 0; i < arr.length; i++) {
        params.push('model=' + arr[i]);
      }
    }
    params.push('offset=' + ((page - 1) * limit));
    params.push('limit=' + limit);
    axios.get('/item-history?subset=offset&order=-modified_at&' + params.join('&'))
      .then(bl => {
        setItems(bl.data);
      })
      .catch(e => {
        addMessage('last-bl-history', 'Unknown error', 'Impossible to load backlog history.');
      });
  }, [refresh, product, model, backlogItem, page]);

  if (items === null) {
    return (
      <LoaderContainer height="2"/>
    );
  }

  if (items.items.length === 0) {
    return null;
  }

  return (
    <>
      { !hidePagination && items.meta.subset.page_count > 1 && (
        <Pagination
          count={items.meta.subset.page_count}
          page={page}
          onNav={setPage} />
      ) }
      { items.items.map(item => (
        <History
          product={product}
          backlogItem={backlogItem}
          showModal={showModal}
          key={item.pk}
          item={item} />
      ))}
    </>
  );
}
HistoryList.defaultProps = {
  hidePagination: false
};

export default HistoryList;
