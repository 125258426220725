import React, { useCallback, useEffect, useRef, useState } from 'react';
import { t } from '@lingui/macro';
import axios from 'axios';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as Sentry from '@sentry/react';
import { Loader } from 'components/ui/Loader';
import Icon from 'components/ui/Icon';
import { getStateItem, setStateItem } from 'utils/Item';
import { manageFormErrors } from './Form';

function keydownHandler (event) {
  // space key
  if (event.keyCode === 32) {
    event.preventDefault();
  }
  // enter key
  else if (event.keyCode === 13) {
    event.preventDefault();
    event.target.click();
  }
}

function keyupHandler (event) {
  // space key
  if (event.keyCode === 32) {
    event.preventDefault();
    event.target.click();
  }
}

function InputEdit(props) {
  const { content, label, field, pk, api, onSuccess, onCancel, placeholder, type } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(content);
  const inputRef = useRef();

  const onSubmit = useCallback((event) => {
    event.preventDefault();
    let finalValue = value;
    if (type === 'date' && value.length < 1) {
      finalValue = null;
    }
    const obj = {};
    obj[field] = finalValue;
    setLoading(true);
    setError(null);
    const url = api + (pk && '/' + pk);
    axios.put(url, obj).then((response) => {
      if (onSuccess) onSuccess(response.data[field], response.data);
    }).catch((err) => {
      if (err.response || err.request) {
        const { globalErrors } = manageFormErrors(null, err, true);
        setError(globalErrors.map(e => e.error).join(<br/>));
        setLoading(false);
      }
      else {
        setError(t`Unknown error`);
        Sentry.captureException(err);
        setLoading(false);
      }
    });
  }, [value, api, field, pk, onSuccess, type]);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const allowedTypes = ['text', 'number', 'email', 'password', 'tel', 'time', 'date', 'url', 'textarea'];
  const finalType = allowedTypes.indexOf(type) === -1 ? 'text' : type;

  const id = 'field-' + field;
  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        <span className="input-wrapper">
          <label className="visually-hidden" htmlFor={id}>{ label }</label>
          <OverlayTrigger
            show={error !== null}
            placement="top"
            overlay={(
              <Popover id={`${id}-popover`}>
                <Popover.Body>
                  { error }
                </Popover.Body>
              </Popover>
            )}>
            {({ ref, ...triggerHandler }) => (
              <span {...triggerHandler} ref={ref}>
                { finalType === 'textarea' ? (
                  <textarea
                    ref={inputRef}
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    className={'form-control' + (error ? ' is-invalid' : '')}
                    placeholder={placeholder}
                    id={id}
                    name={id}/>
                ) : (
                  <input
                    ref={inputRef}
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                    className={'form-control' + (error ? ' is-invalid' : '')}
                    type={finalType}
                    placeholder={placeholder}
                    id={id}
                    name={id}/>
                ) }
              </span>
            )}

          </OverlayTrigger>
          { loading && (
            <Loader size="tiny"/>
          )}
        </span>
        <button type="submit" className="btn btn-transparent">
          <Icon name="check-circle" title={t`Save`}/>
        </button>
        <button type="button" onClick={onCancel} className="btn btn-transparent">
          <Icon name="x-circle" title={t`Cancel`}/>
        </button>
      </div>
    </form>
  );
}

export default function FieldShowUpdate(props) {
  const { field, onSave, onStateChange, className, description, getValue, stateKey, attributes, onEdit, onEditClose } = props;
  const [isEdit, setIsEdit] = useState(false);
  const finalStateKey = stateKey || `${props.api}:${props.pk}`;
  const item = getStateItem(finalStateKey);

  const setIsEditHandler = useCallback((show) => {
    if (show) {
      if (onEdit) onEdit('showupdate-' + props.api + '-' + props.pk);
    }
    else if (onEditClose) {
      onEditClose('showupdate-' + props.api + '-' + props.pk);
    }
    setIsEdit(show);
  }, [onEdit, onEditClose, props.api, props.pk]);

  const onEditSuccess = useCallback((value, it) => {
    if (onSave) onSave(field, value, it);
    setStateItem(finalStateKey, it, { isFull: true });
    setIsEditHandler(false);
  }, [onSave, field, finalStateKey, setIsEditHandler]);

  const onCancel = useCallback(() => {
    setIsEditHandler(false);
  }, [setIsEditHandler]);

  useEffect(() => {
    if (onStateChange) onStateChange(isEdit);
  }, [isEdit, onStateChange]);

  if (!item) return null;

  let attrs = attributes || {};
  if (!isEdit) {
    attrs = {
      ...attrs,
      tabIndex: '0',
      role: 'button',
      onKeyDown: keydownHandler,
      onKeyUp: keyupHandler,
      onClick: () => { setIsEditHandler(true); }
    };
  }
  const spanClasses = ['field-update'];
  spanClasses.push(isEdit ? 'edit' : 'view');
  if (className) spanClasses.push(className);
  if (props.type === 'textarea') spanClasses.push('field-update__textarea');
  return (
    <>
      <span
        className={spanClasses.join(' ')}
        {...attrs}>
        { !isEdit && (
          <>
            { typeof props.children === 'function' ? props.children(item) : item[field] }
            <Icon name="pencil" />
          </>
        ) }
        { isEdit && (
          <>
            <span className="field-value" aria-hidden="true">
              { typeof props.children === 'function' ? props.children(item) : item[field] }
            </span>
            <InputEdit content={getValue ? getValue(item) : item[field]} onSuccess={onEditSuccess} onCancel={onCancel} {...props} />
          </>
        ) }
      </span>
      { isEdit && description }
    </>
  );
}
