/* eslint-disable react/no-unescaped-entities */

import React, { useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Alert, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import getBacklogItems from 'entity/BacklogItems';
import { LoaderContainer } from 'components/ui/Loader';
import ContentBlock from 'components/content/ContentBlock';
import BacklogItemTeaser from './BacklogItemTeaser';

function LastItemListContent(props) {
  const { error, items, columns, product, limit } = props;
  if (error) {
    return (
      <Alert>
        <p><Trans>Impossible to load Items</Trans></p>
      </Alert>
    );
  }
  if (items === null) {
    return (
      <Card className="products-list relative">
        <Card.Body>
          <LoaderContainer height="3"/>
        </Card.Body>
      </Card>
    );
  }
  if (items.length < 1) {
    return (
      <Card className="products-list products-list__empty">
        <Card.Body>
          <p><Trans>You have not yet worked on any items</Trans></p>
        </Card.Body>
      </Card>
    );
  }
  const classes = ['backlog-items-list'];
  if (columns) classes.push('columns-' + columns);
  if (product) {
    return (
      <div className={classes.join(' ')}>
        {items.slice(0, limit).map((item) => (
          <div
            key={item.pk}
            className="card backlog-item-teaser">
            <BacklogItemTeaser showStatus layout="link" backlogItem={item} />
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className={classes.join(' ')}>
      {items.slice(0, limit).map((item) => (
        <Link
          key={item.pk}
          className="card backlog-item-teaser"
          to={item.path}>
          <BacklogItemTeaser showStatus layout="link" backlogItem={item} hideMore />
        </Link>
      ))}
    </div>
  );
}
function LastItemList(props) {
  const [items, setItems] = useState(null);
  const [error, setError] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const { refresh, columns, limit, product, user, assignee, title, actions, limitLess } = props;

  useEffect(() => {
    const settings = { subset: 'offset', limit: limit, params: [] };

    if (user) {
      settings.params.push(['user_updated', user.pk]);
    } else if (assignee) {
      settings.params.push(['status_type', 'backlog']);
      settings.params.push(['status_type', 'todo']);
      settings.params.push(['status_type', 'in_progress']);
      settings.params.push(['assigned_user', assignee.pk]);
    } else if (product) {
      settings.params.push(['product_updated', product.pk]);
    }
    settings.params.push(['order', 'last_updated']);
    getBacklogItems(
      product ? product.pk : null,
      settings
    ).then((response) => {
      setItems(response);
    }).catch((err) => { setError(err); });
  }, [refresh, user, product, assignee, limit]);

  if (title || (actions.length > 0) || limitLess) {
    const act = [...actions];
    if (limitLess && (items && items.length > parseInt(limitLess, 10))) {
      act.push(
        <button
          key="view-more"
          type="button"
          className="btn btn-outline-secondary"
          onClick={() => setViewMore(!viewMore)}>
          { viewMore ? t`Hide` : t`View more` }
        </button>
      );
    }
    return (
      <ContentBlock
        className="p-3"
        title={title || ''}
        actions={act}>
        <LastItemListContent
          items={items}
          columns={columns}
          limit={limitLess ? (viewMore ? limit : limitLess) : limit}
          error={error}/>
      </ContentBlock>
    );
  }

  return (
    <LastItemListContent
      items={items}
      columns={columns}
      limit={limit}
      error={error}/>
  );
}
LastItemList.defaultProps = {
  actions: []
};

export default LastItemList;
