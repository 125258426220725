import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { addMessage } from 'components/ui/Messages';

function StatBlock(props) {
  const { api, value, link, linkName, title } = props;
  const [count, setCount] = useState(typeof value !== 'undefined' ? value : null);

  useEffect(() => {
    if (!api) return;
    axios.get(api).then((response) => {
      setCount(response.data.meta.count);
    }).catch((err) => { addMessage('error-' + title, title, t`Can't retrieve data`); });
    // Won't rerun for error management
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(() => {
    setCount(value);
  }, [value]);

  return (
    <div className="stat card text-white mb-3">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <div>
            <h4>{ title }</h4>
            { link && (
              <>
                <hr />
                <Link className="link" to={link}>{ linkName || t`Manage` }</Link>
              </>
            ) }
          </div>
          <span className="count">{ count }</span>
        </div>
      </div>
    </div>
  );
}

export default StatBlock;
