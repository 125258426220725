import React, { useContext } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import UserPage from 'components/page/UserPage';
import WorklogsContent from 'components/worklogs/WorklogsContent';
import AppContext from 'AppContext';

export default function UserWorklog(props) {
  const { user } = useContext(AppContext);
  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    t`My work`
  ];

  return (
    <UserPage
      name="worklogs-page worklogs-page-user"
      title={t`My work`}
      breadcrumb={breadcrumb}>
      <WorklogsContent user={user} hideExport />
    </UserPage>
  );
}
