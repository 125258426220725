import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Trans, t } from '@lingui/macro';
import OrganizationContext from 'components/utils/OrganizationContext';
import { addMessage } from 'components/ui/Messages';
import { Form, FormContextProvider } from 'components/form';
import { Loader, LoaderContainer } from 'components/ui/Loader';
import Icon from 'components/ui/Icon';
import ImportProductModal from 'components/modal/ImportProductModal';

const STATUS_QUEUED = 'queued';
const STATUS_STARTED = 'started';
const STATUS_DONE = 'done';
const STATUS_FAILED = 'failed';

function ProjectStatus(props) {
  const { project } = props;
  const badgeClasses = ['badge me-2 normal-size'];

  if (project.status.value === STATUS_DONE) {
    badgeClasses.push('bg-success');
  }
  else if (project.status.value === STATUS_FAILED) {
    badgeClasses.push('bg-danger');
  }
  else {
    badgeClasses.push('bg-warning text-dark');
  }
  return (
    <>
      <span className={badgeClasses.join(' ')}>{project.status.label}</span>
      { project.status.value === STATUS_STARTED && (
        <Loader size="tiny"/>
      )}
      { project.attachments != null && project.attachments.total > 0 && (
        <span className="">
          <Trans>Attachments:</Trans>{' '}
          {project.attachments.done} / {project.attachments.total}
        </span>
      ) }
    </>
  );
}

function JiraTokenForm(props) {
  const { setIsEdit, onSave } = props;
  return (
    <Form
      name="jira-token-form"
      className="mt-4 mb-4"
      onSave={onSave}>
      <div className="row mb-1">
        <div className="col-4">
          <Form.Field className="full-w" id="jira_url" htmlSize="20" name="jira_url"/>
        </div>
        <div className="col-4">
          <Form.Field className="full-w" id="atlassian_username" htmlSize="20" name="atlassian_username"/>
        </div>
        <div className="col-4">
          <Form.Field className="full-w" id="atlassian_api_token" htmlSize="20" name="atlassian_api_token"/>
        </div>
      </div>
      <div className="actions">
        <input className="btn btn-primary" type="submit" value={t`Save`}/>
        <button className="btn btn-light" type="button" onClick={() => setIsEdit(false)}>
          <Trans>Cancel</Trans>
        </button>
      </div>
    </Form>
  );
}

function JiraToken(props) {
  const [isEdit, setIsEdit] = useState(false);
  const [meta, setMeta] = useState(null);
  const { jiraToken, setJiraToken } = props;
  const onSave = (data) => {
    setJiraToken(data);
    setIsEdit(false);
  };
  const onMetaLoaded = useCallback(d => {
    setMeta(d);
  }, []);
  return (
    <div className="relative">
      <FormContextProvider
        onMetaLoaded={onMetaLoaded}
        item={jiraToken}
        api="/members/atlassian-token">
        { meta ? (isEdit ? (
          <JiraTokenForm onSave={onSave} setIsEdit={setIsEdit} organization={props.organization}/>
        ) : (
          <div className="row mb-2">
            <div className="col-4">
              <div className="text-secondary">{ meta.fields.atlassian_username.name }</div>
              { jiraToken.atlassian_username ? jiraToken.atlassian_username : (
                <span className="text-red"><Trans>Missing</Trans></span>
              )}
            </div>
            <div className="col-4">
              <div className="text-secondary">{ meta.fields.jira_url.name }</div>
              { jiraToken.jira_url ? jiraToken.jira_url : (
                <span className="text-red"><Trans>Missing</Trans></span>
              )}
            </div>
            <div className="col-3">
              <div className="text-secondary">{ meta.fields.atlassian_api_token.name }</div>
              { jiraToken.atlassian_api_token ? (
                <Icon name="check-circle" />
              ) : (
                <span className="text-red"><Icon name="x-circle" /></span>
              ) }
            </div>
            <div className="col-1">
              <button type="button" onClick={() => setIsEdit(true)} className="btn btn-outline-secondary">
                <Trans>Edit</Trans>
              </button>
            </div>
          </div>
        )) : (
          <LoaderContainer height="2"/>
        ) }
      </FormContextProvider>
      <hr />
    </div>
  );
}

function ImportList(props) {
  const { formsMeta, organization, jiraToken } = props;
  const [projects, setProjects] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const load = useCallback(() => {
    axios.get(`/product-imports/jira-projects/${organization.member.pk}`).then((res) => {
      setProjects(res.data.items);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      setError(true);
    });
  }, [organization.member.pk]);

  const onImportSave = useCallback(data => {
    setProjects(old => old.map((project) => {
      if (project.jira_key === data.source_product_key) {
        return {
          ...project,
          status: {
            value: STATUS_QUEUED,
            label: t`Queued`
          }
        };
      }
      return project;
    }));
  }, []);

  useEffect(() => {
    let processing = 0;
    let timeout = null;
    if (projects) {
      for (const it of projects) {
        if (it.status != null && it.status.value !== STATUS_DONE && it.status.value !== STATUS_FAILED) {
          processing += 1;
        }
      }
    }
    if (processing > 0) {
      timeout = setTimeout(load, 3000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [projects, load]);

  return (
    <>
      <button
        type="button"
        className="btn btn-primary"
        id="button-cancel"
        onClick={() => {
          setLoading(true);
          setError(false);
          load();
        }}>
        <Trans>Load products</Trans>
      </button>
      { error && (
        <div className="mt-3 alert alert-danger" role="alert">
          <Trans>
            Unable to retrieve the product list. Please check your login information and try again.
          </Trans>
        </div>
      ) }
      { loading && (
        <LoaderContainer height="2"/>
      ) }
      { !loading && formsMeta && projects !== null && (
        projects.length < 1 ? (
          <div className="mt-3 alert alert-info" role="alert">
            <Trans>
              Empty product list. Please check your login information.
            </Trans>
          </div>
        ) : (
          <table className="table align-middle">
            <thead>
              <tr>
                <th><Trans>Jira key</Trans></th>
                <th><Trans>Original name</Trans></th>
                <th><Trans>Prefix</Trans></th>
                <th><Trans>Name</Trans></th>
                <th><Trans>Operations</Trans></th>
              </tr>
            </thead>
            <tbody>
              { projects.map(project => (
                <tr key={project.jira_key}>
                  <td>{ project.jira_key }</td>
                  <td>{ project.jira_name }</td>
                  <td>{ project.prefix }</td>
                  <td>{ project.name }</td>
                  <td>
                    { project.status != null && (
                      <ProjectStatus project={project}/>
                    )}
                    { (project.status == null || project.status.value === 'failed') && (
                      <ImportProductModal
                        meta={formsMeta}
                        jiraToken={jiraToken}
                        organization={organization}
                        title={project.status == null ? t`Import product` : t`Retry product import`}
                        buttonTitle={project.status == null ? t`Import` : t`Retry`}
                        project={project}
                        onSave={onImportSave}
                        variant="outline-secondary"/>
                    )}
                  </td>
                </tr>
              )) }
            </tbody>
          </table>
        )
      ) }
    </>
  );
}

export default function ProductJiraImport(props) {
  const organization = useContext(OrganizationContext);
  const [jiraToken, setJiraToken] = useState(null);
  const [formsMeta, setFormsMeta] = useState(null);

  useEffect(() => {
    axios.get(`/members/atlassian-token/${organization.member.pk}`).then((res) => {
      setJiraToken(res.data);
    }).catch(err => {
      addMessage('get-jira-token', t`Unknown error`, t`Cannot check token.`);
    });
  }, [organization.member.pk]);

  useEffect(() => {
    axios.get('/product-imports/meta').then((res) => {
      setFormsMeta(res.data);
    }).catch(err => {
      addMessage('get-jira-projects-meta', t`Unknown error`, t`Cannot get Jira projects meta informations.`);
    });
  }, []);

  return (
    <>
      <h3><Trans>Import products from Jira</Trans></h3>
      { jiraToken && (
        <JiraToken setJiraToken={setJiraToken} organization={organization} jiraToken={jiraToken}/>
      ) }
      <ImportList formsMeta={formsMeta} organization={organization} jiraToken={jiraToken}/>
    </>
  );
}
