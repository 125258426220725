import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link, useParams } from 'react-router-dom';
import ProductPage from 'components/page/ProductPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import { LoaderContainer } from 'components/ui/Loader';
import ContentBlock from 'components/content/ContentBlock';
import { addMessage } from 'components/ui/Messages';
import Sprint from 'entity/Sprint';
import { useStateItem } from 'utils/Item';
import SprintReviewForm from 'components/forms/SprintReviewForm';
import NotFoundPage from './NotFoundPage';

function SprintRetrospectivePageContent(props) {
  const { sprint, product } = props;
  const [message, setMessage] = useState(null);

  const onSave = () => {
    setMessage(t`Sprint review saved`);
  };
  const onBeforeSave = () => {
    setMessage(null);
  };

  return (
    <ContentBlock className="p-3" title={t`Sprint retrospective`}>
      { message && (
        <div className="alert alert-info mb-3">
          { message }
        </div>
      ) }
      <SprintReviewForm field="retrospective" product={product} sprint={sprint} onBeforeSave={onBeforeSave} onSave={onSave} />
    </ContentBlock>
  );
}

export default function SprintRetrospectivePage() {
  const { sprintPk } = useParams();
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const [error, setError] = useState();
  const sprint = useStateItem('sprints:' + sprintPk, true);

  useEffect(() => {
    const s = new Sprint();
    s.load(sprintPk).catch(err => {
      if (err.response && err.response.status === 404) {
        setError(404);
      }
      else {
        addMessage('load-sprint', t`Unknown error`, t`Can't load sprint`);
      }
    });
  }, [product.pk, sprintPk]);

  if (error === 404) {
    return <NotFoundPage breadcrumb={breadcrumb}/>;
  }

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    <Link key="4" to={`/${organization.slug}/${product.slug}/sprints`}><Trans>Sprints</Trans></Link>,
  ];
  if (sprint) breadcrumb.push(<Link key="5" to={`/${organization.slug}/${product.slug}/sprints/${sprint.pk}`}>{ sprint.name }</Link>);
  breadcrumb.push(t`Retrospective`);

  return (
    <ProductPage
      name="sprint-retrospective-page"
      title={sprint ? (t`Sprint retrospective` + ' -  ' + sprint.name) : t`Sprint retrospective`}
      breadcrumb={breadcrumb}
      nocontent>
      { sprint ? (
        <SprintRetrospectivePageContent sprint={sprint} product={product} />
      ) : (
        <ContentBlock className="p-3 kanban-top-content">
          <LoaderContainer height="3" static/>
        </ContentBlock>
      ) }
    </ProductPage>
  );
}
