export default function badgeColor(status) {
  switch (status) {
    case 'warning':
      return 'text-orange';
    case 'success':
      return 'text-green';
    case 'failed':
      return 'text-red';
    case 'skipped':
    case 'canceled':
      return 'text-gray';
    default:
      return 'text-yellow';
  }
}
