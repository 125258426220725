import React, { useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import { Loader } from 'components/ui/Loader';
import { websiteUrl } from 'components/utils/urls';
import SimpleModal from './SimpleModal';

function AboutModal(props) {
  const [backVersion, setBackVersion] = useState(null);
  useEffect(() => {
    axios.get('status/version').then(res => {
      setBackVersion(res.data.version);
    });
  }, []);
  const { isDropDown } = props;
  return (
    <SimpleModal
      title={t`About Optera.io`}
      variant={props.variant || ''}
      isDropDown={isDropDown}
      buttonTitle={t`About Optera.io`}
      className="modal-about">
      <p>
        <Trans>Optera.io is published by Bluestar Solutions Sàrl</Trans>
      </p>
      <p>
        <a className="me-3" href={websiteUrl('')}><Trans>More about Optera.io</Trans></a>
        <a className="me-3" href={websiteUrl('terms')}><Trans>Terms of service</Trans></a>
        <a href={websiteUrl('docs/getting_started')}><Trans>Documentation</Trans></a>
      </p>

      <ul className="list-group">
        <li className="list-group-item d-flex justify-content-between align-items-center">
          <Trans>Environment</Trans>
          <strong>{ process.env.REACT_APP_ENVIRONMENT }</strong>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center">
          <Trans>App version</Trans>
          <strong>{ process.env.REACT_APP_VERSION }</strong>
        </li>
        <li className="list-group-item d-flex justify-content-between align-items-center">
          <Trans>API version</Trans>
          { backVersion === null ? (
            <Loader />
          ) : (
            <strong>{ backVersion }</strong>
          ) }

        </li>
      </ul>
    </SimpleModal>
  );
}
export default AboutModal;
