import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import { manageFormErrors } from 'components/form/Form';
import { LoaderContainer } from 'components/ui/Loader';
import { CodeInputs } from 'components/form/CodeField';

function ChangeEmailForm(props) {
  const { onCancel, onSave, user, email, setUser } = props;
  const [errors, setErrors] = useState({});
  const [error, setError] = useState();
  const [newEmail, setNewEmail] = useState(email);
  const [otpCode, setOtpCode] = useState();
  const [password, setPassword] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const fields = ['new_email', 'password', 'new_password_conf'];

  const onSaveHandler = (e) => {
    e.preventDefault();
    setErrors({});
    setIsLoading(true);
    const data = {
      new_email: newEmail
    };
    if (user.is_otp) {
      data.otp_code = otpCode;
    }
    else {
      data.password = password;
    }
    axios.put('/users/change-email', data).then((res) => {
      setIsLoading(false);
      if (onSave) onSave();
      setUser(res.data);
      addMessage('email-changed', t`Email`, t`Your email has been updated`);
    }).catch(err => {
      const { globalErrors, fieldsErrors } = manageFormErrors(fields, err);
      setIsLoading(false);
      setErrors(fieldsErrors);
      setError(globalErrors.map(er => er.error).join('<br/>'));
    });
  };

  const handleChangeEmail = e => {
    const { value } = e.target;
    setNewEmail(value);
  };
  const handleChangePassword = e => {
    const { value } = e.target;
    setPassword(value);
  };
  const onInvalid = () => {
    setValidated(true);
  };

  return (
    <form
      className="relative"
      method="POST"
      id="user-change-email"
      onInvalid={onInvalid}
      onSubmit={onSaveHandler}>
      { isLoading && (
        <LoaderContainer />
      ) }
      <div className="alert alert-warning mt-3">
        <Trans>
          Once your address has been modified and validated,{' '}
          <strong>it will become your new login.</strong>
        </Trans>
      </div>
      <div className={'form-group required' + (validated ? ' needs-validation' : '')}>
        <label className="form-label" htmlFor="email">
          <Trans>New email</Trans>
        </label>
        <input
          onInput={handleChangeEmail}
          type="email"
          id="new_email"
          name="email"
          className={'form-control' + (errors.email ? ' is-invalid' : '')}
          defaultValue={email}
          required />
        { errors.email && (
          <div className="invalid-feedback">{ errors.email.join(' ') }</div>
        ) }
      </div>
      <div className={'form-group required' + (validated ? ' needs-validation' : '')}>
        { user.is_otp ? (
          <>
            <label htmlFor="otp_code" className="mb-2">
              <Trans>Use your authenticator to get a fresh OTP code.</Trans>
            </label>
            <CodeInputs
              size="6"
              error={!!errors.otp_code}
              id="otp_code"
              onChange={v => setOtpCode(v)}
              required />
            { errors.otp_code && (
              <div className="invalid-feedback">{ errors.otp_code.join(' ') }</div>
            ) }
          </>
        ) : (
          <>
            <label htmlFor="password">
              <Trans>Password</Trans>
            </label>
            <input
              onInput={handleChangePassword}
              type="password"
              id="password"
              name="password"
              className={'form-control' + (errors.password ? ' is-invalid' : '')}
              required />
            { errors.password && (
              <div className="invalid-feedback">{ errors.password.join(' ') }</div>
            ) }
          </>
        ) }
      </div>
      { error && (
        <div className="alert alert-danger mb-2" role="alert">
          { error }
        </div>
      ) }
      <div className="actions">
        <Button variant="primary" type="submit"><Trans>Change email</Trans></Button>
        <Button variant="outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></Button>
      </div>
    </form>
  );
}

function ChangeEmailToggle(props) {
  const [isOpened, setIsOpened] = useState(false);
  if (!isOpened) {
    return (
      <button type="button" className="btn btn-outline-secondary" onClick={() => { setIsOpened(true); }}>
        <Trans>Change email</Trans>
      </button>
    );
  }
  return (
    <ChangeEmailForm
      {...props}
      onSave={() => { setIsOpened(false); }}
      onCancel={() => { setIsOpened(false); }} />
  );
}

export default ChangeEmailForm;
export { ChangeEmailToggle };
