import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import EpicForm from 'components/forms/EpicForm';

export default function EpicModal(props) {
  const { title, modalTitle, onClose, onSave, hideButton, show, variant, ...rest } = props;
  const [isShown, setShow] = useState(!!show);
  const handleCancel = () => {
    if (onClose) onClose(false);
    setShow(false);
  };
  const handleSave = (data, created) => {
    if (onSave) onSave(data, created);
    setShow(false);
  };
  const handleShow = (e) => {
    setShow(true);
  };

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <ModalForm
      show={isShown}
      handleClose={handleCancel}
      handleShow={handleShow}
      title={modalTitle || t`New epic`}
      buttonTitle={title || t`Add epic`}
      hideButton={hideButton}
      variant={variant}>
      <EpicForm {...rest} onSave={handleSave} onCancel={handleCancel}></EpicForm>
    </ModalForm>
  );
}
EpicModal.defaultProps = {
  epic: { product: null }
};
