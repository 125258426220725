import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Trans } from '@lingui/macro';
import { Form, FormContext, FormContextProvider } from 'components/form';

function BacklogItemParentFinalForm(props) {
  const { onSave, onCancel, ...rest } = props;
  const { item } = useContext(FormContext);

  return (
    <Form
      name="backlogitem-choose-parent-form"
      noValidate
      onSave={onSave}
      {...rest}>
      <Form.Many2One
        api={'/backlog-items?subtasks=-children&product=' + item.product.pk}
        id="parent"
        name="parent"
        required />
      <div className="actions">
        <Button variant="primary" type="submit">
          <Trans>Save</Trans>
        </Button>
        <Button
          variant="light"
          type="button"
          onClick={onCancel}>
          <Trans>Cancel</Trans>
        </Button>
      </div>
    </Form>
  );
}

export default function BacklogItemParentForm(props) {
  const { backlogItem, ...rest } = props;

  return (
    <FormContextProvider item={backlogItem} api="/backlog-items">
      <BacklogItemParentFinalForm {...rest} />
    </FormContextProvider>
  );
}
