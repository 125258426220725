import React from 'react';
import { Badge, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function OrganizationTeaser(props) {
  const { organization } = props;
  return (
    <Card className="organization organization__teaser">
      <Card.Body>
        <Card.Title>{ organization.name }</Card.Title>
        <Card.Text>
          { organization.subscription.label }
        </Card.Text>
        <p>
          {organization.member.roles.map(([key, name]) => (
            <Badge className="big spaced" pill bg="light" key={key} variant="primary">{name}</Badge>
          ))}
        </p>
        <NavLink className="btn btn-slug" to={`/${organization.slug}`}>
          {organization.slug}
        </NavLink>
      </Card.Body>
    </Card>
  );
}
