import React, { useContext } from 'react';
import { t, Trans } from '@lingui/macro';
import { Form, FormContext, FormContextProvider } from 'components/form';

function ItemStatusFinalForm(props) {
  const { onSave, onCancel, isEdit } = props;
  const { item } = useContext(FormContext);
  return (
    <Form
      name="account-add-form"
      noValidate
      onSave={onSave}>
      <Form.Field id="name" name="name" required />
      { item.status_type.value === 'in_progress' && (
        <Form.Check
          id="auto_assign"
          name="auto_assign"
          help={t`Auto assign: By moving the ticket in this column, it will be automatically assigned to the person moving it.`} />
      ) }
      <div className="actions mt-3">
        <button className="btn btn-primary" type="submit">
          { isEdit ? t`Save` : t`Add status` }
        </button>
        <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
      </div>
    </Form>
  );
}

const ItemStatusForm = function(props) {
  const { onSave, onCancel, product, itemStatus, statusType } = props;
  if (!itemStatus) return null;
  if (!itemStatus.pk) {
    itemStatus.product = { pk: product.pk };
    itemStatus.status_type = statusType;
  }
  return (
    <FormContextProvider item={itemStatus} api="/item-status">
      <ItemStatusFinalForm
        isEdit={itemStatus && !!itemStatus.pk}
        product={product}
        onSave={onSave}
        onCancel={onCancel} />
    </FormContextProvider>
  );
};

export default ItemStatusForm;
