import React, { useCallback, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { t, Trans } from '@lingui/macro';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from 'components/ui/Icon';
import DateFormat from 'components/ui/DateFormat';
import { NotificationsContext } from 'components/utils/NotificationsObserver';

function Notification(props) {
  const { notification, setNotifications } = props;
  const notifProps = { className: 'card mb-2' };
  if (notification.read) {
    notifProps.className += ' text-muted';
  }
  const readNotif = () => {
    notification.read = true;
    axios.put('/notifications/' + notification.pk, { read: true }).then(() => {
      setNotifications(cur => {
        const newItems = [...cur.items];
        const index = newItems.findIndex(c => c.pk === notification.pk);
        if (index !== -1) {
          newItems.splice(
            newItems.findIndex(c => c.pk === notification.pk),
            1
          );
          return {
            meta: cur.meta,
            items: newItems
          };
        }
        return cur;
        
      });
    }).catch((err) => {
      console.error('Impossible to update notification');
    });
  };

  if (notification.read) return null;
  const links = [];
  for (const link of notification.links) {
    if (typeof link === 'object') {
      links.push(<Link key={link.path} to={link.path}>{ link.title }</Link>);
    }
  }

  return (
    <div {...notifProps}>
      <div className="card-body py-2">
        <small>
          <DateFormat
            month="short"
            year="numeric"
            day="numeric"
            hour="numeric"
            minute="numeric"
            datestring={notification.created_at}/>
        </small>
        <div className="hstack">
          { notification.message }{' '}
          <button type="button" className="ms-auto btn btn-simplelink" onClick={readNotif}>
            <Icon name="x-circle" title={t`Delete`}/>
          </button>
        </div>
        { links }
      </div>
    </div>
  );
}

function Notifications() {
  const [notifications, setNotifications] = useState(null);
  const { isActive } = useContext(NotificationsContext);
  const baseSize = 2;

  const loadNotifications = useCallback((size, offset, append) => {
    axios.get(`/notifications?max_days=15&subset=offset&limit=${size}&offset=${offset}&read=false`).then(res => {
      setNotifications(current => {
        if (current === null || !append) return res.data;
        return {
          meta: res.data.meta,
          items: [...current.items, ...res.data.items]
        };
      });
    }).catch((err) => {
      console.error('Impossible to load notifications');
    });
  }, []);

  const markAllAsRead = () => {
    axios.put('/notifications/mark-all-as-read').then(() => {
      setNotifications(null);
    }).catch((err) => {
      console.error('Impossible to update notification');
    });
  };

  const onLoadMore = () => {
    loadNotifications(baseSize, notifications.items.length, true);
  };

  const notificationListener = useCallback((e) => {
    if (e.detail.message.model === 'Notification') {
      if (e.detail.message.event === 'new_notification') {
        setNotifications(current => {
          if (current === null) {
            return {
              meta: { subset: { count: 1, offset: 0 } },
              items: [e.detail.message.item]
            };
          }
          return {
            meta: { subset: { ...current.meta.subset, count: current.meta.subset.count + 1 } },
            items: [e.detail.message.item, ...current.items]
          };
        });
      }
      else if (e.detail.message.event === 'is_read') {
        setNotifications(current => {
          if (current === null) {
            return current;
          }
          const newItems = [...current.items];
          const existingIndex = newItems.findIndex(i => i.pk === e.detail.message.item.pk);
          if (existingIndex !== -1) {
            newItems.splice(existingIndex, 1);
            return { 
              meta: { subset: { ...current.meta.subset, count: current.meta.subset.count - 1 } },
              items: newItems
            };
          }
          return current;
        });
      }
      
    }
  }, []);

  useEffect(() => {
    document.addEventListener('notification', notificationListener);
    return () => {
      document.removeEventListener('notification', notificationListener);
    };
  }, [notificationListener]);

  useEffect(() => {
    if (notifications === null || (notifications.items.length < baseSize && notifications.meta.subset.count > notifications.items.length)) {
      loadNotifications(baseSize, 0);
    }
  }, [loadNotifications, notifications, baseSize]);

  if (isActive === false || notifications === null || notifications.items.length < 1) return null;

  const unread = notifications.meta.subset.count;
  return (
    <Dropdown className="notifications-dropdown">
      <Dropdown.Toggle id="notifications-dropdown" className="nav-link" variant="link">
        <Icon name="envelope" title={t`Notifications`} />
        <span className="d-none d-md-inline ms-2">
          <Trans>Notifications</Trans>
        </span>
        { unread && (
          <span className="ms-2 badge bg-theme rounded-pill">{ unread }</span>
        ) }
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-2" align="end">
        {notifications.items.length && notifications.items.map((n) => (
          <Dropdown.Item
            as={Notification}
            key={n.pk}
            notification={n}
            setNotifications={setNotifications}>
          </Dropdown.Item>
        ))}
        <div className="text-center">
          { unread > notifications.items.length && (
            <button type="button" className="btn bg-white btn-outline-secondary me-2" onClick={onLoadMore}>
              <Trans>Load more</Trans>
            </button>
          )}
          <button type="button" className="btn bg-white btn-outline-secondary" onClick={() => markAllAsRead()}>
            <Trans>Mark all as read</Trans>
          </button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default Notifications;
