import React, { useContext, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { FormSelect } from 'react-bootstrap';
import axios from 'axios';
import { LoaderContainer } from 'components/ui/Loader';
import BaseField from 'components/form/BaseField';
import SelectSearch from 'components/ui/SelectSearch';
import { modelsApi } from 'components/utils/constants';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import { manageFormErrors } from 'components/form/Form';

function TriggerActionFinalForm(props) {
  const { meta, triggerAction, onSave, onCancel, trigger } = props;
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const item = {};
  if (triggerAction) {
    item.action = triggerAction.action.value;
    if (item.action && (item.action in meta.fields.value.actions)) {
      const actionMeta = meta.fields.value.actions[item.action];
      if (actionMeta.use_value) {
        if ('options' in actionMeta && actionMeta.options.length > 0) {
          item.value_select = triggerAction.value.type === 'option' ? triggerAction.value.value : 'model';
        }
        item.value = triggerAction.value ? triggerAction.value.value : null;
      }
    }
  }
  const [state, setState] = useState({ action: null, value_select: null, value: null, ...item });
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);

  // eslint-disable-next-line arrow-body-style
  const handleChange = (field) => {
    return e => {
      setState(old => {
        old[field] = ('target' in e) ? e.target.value : e;
        if (field === 'action') {
          old.value_select = null;
          old.value = null;
        }
        return { ...old };
      });
    };
  };

  let needValue = false;
  let needValueChoice = false;
  let valueField = null;
  const finalErrors = { ...errors };
  if (state.action && (state.action in meta.fields.value.actions)) {
    const actionMeta = meta.fields.value.actions[state.action];
    needValue = true;
    if (!actionMeta.use_value) {
      needValue = false;
    }
    else if ('options' in actionMeta && actionMeta.options.length > 0) {
      needValueChoice = true;
    }
    if (needValue && ('type' in actionMeta) && (!needValueChoice || state.value_select === 'model')) {
      if (actionMeta.type === 'ModelObject') {
        const [parent, model] = actionMeta.model.split('.');
        if (model in modelsApi) {
          let { api } = modelsApi[model];
          if (parent === 'organizations') {
            api += `?organization=${organization.pk}`;
          }
          else if (parent === 'products') {
            api += `?product=${product.pk}`;
          }
          
          valueField = (
            <BaseField
              label={modelsApi[model].name}
              name="value"
              className="form-group"
              humanValue={state.value ? state.value.resource_name : ''}
              fieldErrors={errors}>
              <SelectSearch
                value={state.value}
                handleSelect={handleChange('value')}
                className={errors.value ? 'is-invalid' : ''}
                searchApi={api}
                isInvalid={!!errors.value}
                isValid={state.value !== null}/>
            </BaseField>
          );
        }
        
      }
    }
    else if (needValueChoice && errors.value) {
      finalErrors.value_select = [t`You need to choose a value`];
    }
  }

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const action = {
      trigger: { pk: trigger.pk },
      action: state.action
    };
    if (needValue) {
      action.value = {
        type: 'value',
        value: state.value
      };
    }
    if (needValueChoice && state.value_select !== 'model') {
      action.value = {
        type: 'option',
        value: state.value_select
      };
    }
    if (triggerAction) {
      action.pk = triggerAction.pk;
      axios.put('trigger-actions/' + triggerAction.pk, action).then(res => {
        setIsLoading(false);
        onSave(res.data);
      }).catch(err => {
        const { globalErrors, fieldsErrors } = manageFormErrors(['action', 'value'], err);
        setErrors(fieldsErrors);
        if (globalErrors.length) setError(globalErrors.map(er => er.error).join(' '));
        setIsLoading(false);
      });
    }
    else {
      axios.post('trigger-actions', action).then(res => {
        setIsLoading(false);
        onSave(res.data);
      }).catch(err => {
        const { globalErrors, fieldsErrors } = manageFormErrors(['action', 'value'], err);
        setErrors(fieldsErrors);
        if (globalErrors.length) setError(globalErrors.map(er => er.error).join(' '));
        setIsLoading(false);
      });
    }
  };

  return (
    <form
      className="relative"
      method="POST"
      id="trigger-action-form"
      noValidate
      onSubmit={onSubmit}>
      { isLoading && (
        <LoaderContainer />
      ) }
      { error && (
        <div className="alert alert-danger mb-2" role="alert">
          { error }
        </div>
      ) }
      <BaseField
        className="form-group"
        label={meta.fields.action.name}
        fieldErrors={errors}
        name="action">
        <FormSelect
          value={state.action || ''}
          onChange={handleChange('action')}
          isInvalid={!!errors.action}
          isValid={state.action !== null}
          as="select"
          id="value_select"
          required>
          { !state.action && (
            <option>- { t`Please choose a value`} -</option>
          ) }
          {meta && meta.fields.action.choices.map((it) => (
            <option key={it.value} value={it.value}>{it.label}</option>
          ))}
        </FormSelect>
      </BaseField>
      { needValue && needValueChoice && (
        <BaseField
          className="form-group"
          label={t`Value`}
          fieldErrors={finalErrors}
          name="value_select">
          <FormSelect
            value={state.value_select || ''}
            onChange={handleChange('value_select')}
            isInvalid={!!finalErrors.value_select}
            isValid={state.value_select !== null}
            as="select"
            id="value_select"
            required>
            { !state.value_select && (
              <option>- { t`Please choose a value`} -</option>
            ) }
            <option value="model">{ t`Value`}</option>
            {meta.fields.value.actions[state.action].options.map((it) => (
              <option key={it.value} value={it.value}>{it.label}</option>
            ))}
          </FormSelect>
        </BaseField>
      ) }
      { valueField }
      <div className="actions">
        <button type="submit" className="btn btn-primary">{ triggerAction ? t`Save` : t`Add action`}</button>
        <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
      </div>
    </form>
  );
}

const TriggerActionForm = function(props) {
  const { onSave, onCancel, trigger, triggerAction } = props;
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    axios.get('/trigger-actions/meta').then(res => {
      setMeta(res.data);
    });
  }, []);

  if (!trigger) return null;
  if (!meta) {
    return (
      <LoaderContainer height="3" />
    );
  }
  return (
    <div className="card mt-3">
      <div className="card-body">
        <TriggerActionFinalForm
          meta={meta}
          trigger={trigger}
          isEdit={triggerAction && !!triggerAction.pk}
          onSave={onSave}
          onCancel={onCancel}
          triggerAction={triggerAction} />
      </div>
    </div>
  );
};

export default TriggerActionForm;
