export default class QueryParams extends URLSearchParams {
  constructor(search) {
    super(search || window.location.search);
  }

  getAllValues(name) {
    const vals = this.getAll(name);
    return vals.map((v) => {
      if (v === 'true') return true;
      if (v === 'false') return false;
      return v;
    });
  }
 
  hasNameValue(name, value) {
    const values = this.getAllValues(name);
    return values && values.indexOf(value) !== -1;
  }

  deleteNameValue(name, value) {
    const values = this.getAllValues(name);
    if (this.hasNameValue(name, value)) {
      this.delete(name);
      for (let i = 0; i < values.length; i++) {
        if (values[i] !== value) this.append(name, values[i]);
      }
    }
    else {
      this.delete(name);
    }
    return this;
  }

  appendOrDelete(name, value) {
    if (this.has(name) && this.hasNameValue(name, value)) {
      return this.deleteNameValue(name, value);
    }
    return this.append(name, value);
  }
  
  appendOrIgnore(name, value) {
    if (this.has(name) && this.hasNameValue(name, value)) {
      return this;
    }
    return this.append(name, value);
  }
}
