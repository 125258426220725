import { modelsApi } from 'components/utils/constants';
import Entity from 'entity/Entity';

export default class Sprint extends Entity {
  constructor() {
    super(['pk'], modelsApi.Sprint.api + '/:pk', modelsApi.Sprint.api, [
      'pk',
      'product',
      'date_from',
      'date_to',
      'status',
      'name',
      'is_current',
      'hours_effort',
      'points_effort',
      'goal',
      'review',
      'retrospective',
    ]);
  }
}
