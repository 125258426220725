import React, { useContext } from 'react';
import * as BS from 'react-bootstrap';
import AppContext from 'AppContext';

function LanguageSelector() {
  const appContext = useContext(AppContext);
  const { lang, languages } = appContext;
  return (
    <BS.NavDropdown
      key="lang"
      title={lang.pk}
      className="language-dropdown"
      id="basic-nav-dropdown">
      {languages.length && languages.map((l) => (
        <BS.NavDropdown.Item
          as="button"
          key={l.pk}
          onClick={e => appContext.setLang(l)}>{l.name}
        </BS.NavDropdown.Item>
      ))}
    </BS.NavDropdown>
  );
}

export default LanguageSelector;
