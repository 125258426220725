/* eslint-disable no-bitwise */
import React from 'react';

const avatars = {};

export default function Avatar(props) {
  const { user } = props;

  if (!user) {
    return (
      <div className="avatar anonymous">
      </div>
    );
  }

  const name = user.resource_name;
  if (!(name in avatars)) {
    let hash = 0;
    let hash2 = 0;
    for (let i = 0; i < name.length; i++) {
      const chr = name.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    for (let i = 0; i < name.length / 3; i++) {
      const chr = name.charCodeAt(i);
      hash2 = ((hash2 << 5) - hash) + chr;
      hash2 |= 0;
    }
    avatars[name] = {
      color: 'hsl(' + Math.abs(hash) % 360 + ', 70%, ' + (60 + Math.abs(hash2) % 32) + '%)',
      initials: name.split(' ').reduce((prev, cur) => prev + cur.charAt(0), '')
    };
  }
  if (user.gravatar) {
    return <img className="avatar-img" width="60" height="60" src={user.avatar} alt="" />;
  }
  return (
    <div className="avatar" style={{ backgroundColor: avatars[name].color }}>
      { avatars[name].initials }
    </div>
  );
}
