import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link, useParams } from 'react-router-dom';
import ProductPage from 'components/page/ProductPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import { LoaderContainer } from 'components/ui/Loader';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import BacklogItemModel from 'entity/BacklogItem';
import BacklogItem from 'components/product/BacklogItem';
import { addMessage } from 'components/ui/Messages';
import BacklogItemActions from 'components/product/backlogitem/BacklogItemActions';
import { useStateItem } from 'utils/Item';
import NotFoundPage from './NotFoundPage';

function BacklogItemFull(props) {
  const backlogItem = useStateItem('backlog-items:' + props.item.pk);
  const product = useContext(ProductContext);
  if (!backlogItem) return null;
  return (
    <div className="backlog-item-full relative">
      <div className="backlog-title d-flex mb-2">
        <div className="flex-grow-1">
          <span className="h4">{ backlogItem.reference }</span>
          { product.can_edit_item ? (
            <FieldShowUpdate
              className="h4 title-element"
              pk={backlogItem.pk}
              api={backlogItem._genericApi}
              field="title"
              label={t`title`}>
            </FieldShowUpdate>
          ) : (
            <div className="h4 title-element">{ backlogItem.title }</div>
          )}
        </div>
        <BacklogItemActions backlogItem={backlogItem} />
      </div>
      <BacklogItem
        backlogItem={backlogItem} />
    </div>
  );
}

function BacklogItemError() {
  return (
    <div className="alert alert-danger" role="alert">
      <Trans>This backlog item cannot be viewed in this product.</Trans>
    </div>
  );
}

export default function BacklogItemPage() {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const { reference } = useParams();
  const [item, setItem] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const blitem = new BacklogItemModel();
    setLoading(true);
    blitem.load_by_reference(organization.slug, reference, true).then(() => {
      if (blitem.product.pk !== product.pk) {
        setError(<BacklogItemError/>);
      }
      else {
        setItem(blitem);
      }
      setLoading(false);
    }).catch(err => {
      if (err.response && err.response.status === 404) {
        setItem(null);
      }
      else {
        addMessage('backlog-item-load', t`Unknown error`, t`Can't load backlog item`);
      }
    });
  }, [reference, organization.slug, product.pk]);

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    <Link key="4" to={`/${organization.slug}/${product.slug}/items`}><Trans>Backlog items</Trans></Link>,
    item ? item.title : t`Backlog item`
  ];

  if (item === null) {
    return <NotFoundPage breadcrumb={breadcrumb} />;
  }
  if (error) {
    return (
      <ProductPage
        name="backlog-item-page"
        title={t`Error`}
        breadcrumb={breadcrumb}>
        { error }
      </ProductPage>
    );
  }

  return (
    <ProductPage
      name="backlog-item-page"
      title={item ? item.title : t`Backlog item`}
      breadcrumb={breadcrumb}>
      { !loading ? (
        <BacklogItemFull item={item} product={product} />
      ) : (
        <div className="relative">
          <LoaderContainer height="4"/>
        </div>
      ) }

    </ProductPage>
  );
}
