import { t } from '@lingui/macro';
import ConfirmDialog from 'components/utils/ConfirmDialog';
import React, { useState } from 'react';
import { Button, CloseButton, Modal } from 'react-bootstrap';

export default function ModalForm(props) {
  const { hideButton, buttonTitle, title, show, handleClose, handleShow, variant, confirmClose } = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const onHide = () => {
    if (confirmClose) {
      setShowConfirm(true);
    }
    else {
      handleClose();
    }
  };
  return (
    <>
      { !hideButton && (
        <Button variant={variant || 'outline-secondary'} onClick={handleShow}>
          { buttonTitle }
        </Button>
      ) }

      <Modal show={show} onHide={onHide} centered>
        <Modal.Header>
          <Modal.Title>{ title }</Modal.Title>
          <CloseButton onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <ConfirmDialog
            show={showConfirm}
            variant="transparent"
            message={t`You have unsaved changes, are you sure you want to close this modal?`}
            confirmLabel={t`Close`}
            onCancel={() => {
              setShowConfirm(false);
            }}
            onConfirm={() => {
              setShowConfirm(false);
              handleClose();
            }}>
          </ConfirmDialog>
          { props.children }
        </Modal.Body>
      </Modal>
    </>
  );
}
