import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { D } from './SprintBurndown';

const SvgEl = function(props) {
  const { title, w, h, data } = props;

  if (!data) {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}></svg>;
  }

  const xMargin = 1;
  const yMargin = 30;
  const insideMargin = 20;
  const axeD = new D(xMargin, 0);
  axeD.lineTo(0, h - yMargin);
  axeD.lineTo(w - (xMargin + 1), 0);

  const values = [];
  const diffX = (w - xMargin + insideMargin + 1) / data.meta.count;
  const maxH = 0.85 * (h - 2 * yMargin + 2 * insideMargin);
  const pointH = maxH / data.meta.max_value;
  const barWidth = diffX * 0.25;

  for (let d = 0; d < data.meta.count; d++) {
    const item = data.items[d];
    values.push({
      item: item,
      x: xMargin + (Math.round(100 * d * diffX) / 100) + diffX / 2
    });
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${w} ${h}`}>
      <title>{ title }</title>
      <path className="st1" d={axeD.toString()}/>
      { values.map(e => {
        let deliveredSum = 0;
        return (
          <g key={e.item.sprint.pk}>
            <foreignObject
              x={e.x - diffX / 2}
              y={h - yMargin}
              width={diffX}
              height={yMargin}>
              <div className="xlabel" title={e.item.sprint.resource_name}>{ e.item.sprint.resource_name }</div>
            </foreignObject>

            <rect
              x={e.x - barWidth}
              y={h - yMargin - e.item.commitment * pointH}
              width={barWidth}
              height={(e.item.commitment * pointH)}
              className="chart-rect-col-grey"/>
            {e.item.commitment > 0 && (
              <text
                x={e.x - barWidth / 2}
                y={h - yMargin - e.item.commitment * pointH - 10}
                className="yvalue">{ Math.round(e.item.commitment) }</text>
            )}
            { e.item.delivered.items.map(de => {
              deliveredSum += de.value;
              return (
                <g key={de.item_type.pk}>
                  <rect
                    x={e.x}
                    y={h - yMargin - deliveredSum * pointH}
                    width={barWidth}
                    height={de.value * pointH}
                    className={`chart-rect-col-${de.item_type.color || 'white'}`}/>
                </g>
              );
            })}
            { deliveredSum > 0 && (
              <text
                x={e.x + barWidth / 2}
                y={h - yMargin - deliveredSum * pointH - 10}
                className="yvalue">{ Math.round(deliveredSum) }</text>
            ) }
          </g>
        );
      }) }
    </svg>
  );
};

export default function ScrumVelocity(props) {
  const { product, sprints } = props;
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const loadData = useCallback(() => {
    axios.get(`/sprints/${product.pk}/velocity?sprints=${sprints}`).then(res => {
      setData(res.data);
    }).catch(err => {
      setData(false);
      setError(t`Impossible to show graph`);
    });
  }, [product.pk, sprints]);

  useEffect(() => {
    loadData();
  }, [product.pk, loadData, sprints]);

  if (!data || data.items.length < 1) {
    return null;
  }
  if (error) {
    return (
      <div className="graph graph-velocity">
        <h3 className="fs-5 text-center"><Trans>Team velocity</Trans></h3>
        <div className="alert alert-danger">{ error }</div>
      </div>
    );
  }
  return (
    <div className="graph graph-velocity">
      <h3 className="fs-5 text-center"><Trans>Team velocity</Trans></h3>
      <div className="graph__legend">
        <span className="bar-square bg-col-grey"></span> <Trans>Commitment</Trans>
        {data && data.items.length > 0 && data.meta.item_types.map(it => (
          <span key={it.pk} className="ms-3">
            <span className={`bar-square bg-col-${it.color || 'white'}`}></span> <Trans>{ it.resource_name }</Trans>
          </span>
        ))}
      </div>
      <div className="graph__inside">
        <div className="graph__graph">
          <SvgEl w="600" h="240" title={t`Team velocity`} data={data} />
        </div>
        <div className="graph__yaxis">
          { product.estimate_mode.value === 'hours' ? (
            <Trans>Hours</Trans>
          ) : (
            <Trans>Points</Trans>
          )}
        </div>
      </div>
    </div>
  );
}
