/* eslint-disable jsx-a11y/click-events-have-key-events,
                  jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Card, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Icon from 'components/ui/Icon';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import AppContext from 'AppContext';
import BacklogItemTeaser from 'components/product/BacklogItemTeaser';
import getSearchResults from 'entity/SearchResults';
import { setStateItem, useStateItem } from 'utils/Item';
import SearchSuggestions from 'components/ui/SearchSuggestions';

function SearchSidebar(props) {
  const { user } = useContext(AppContext);
  const search = useStateItem('search:string');
  const searchOpened = useStateItem('search:opened');

  if (!user || !searchOpened) return null;

  return (
    <SearchSidebarContent search={searchOpened === 'string' ? search : ''} searchOpened={searchOpened} />
  );
}
function SearchSidebarContent(props) {
  const { search, searchOpened } = props;

  const history = useHistory();
  const [term, setTerm] = useState(search);
  const [loading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState(null);
  const inputRef = useRef();
  const onSubmit = e => {
    e.preventDefault();
    setTerm(inputRef.current.value);
  };

  const close = () => {
    setStateItem('search:opened', false);
  };

  const doSearch = useCallback(() => {
    setLoading(true);
    if (!term) {
      setLoading(false);
      inputRef.current.focus();
      return;
    }
    getSearchResults({ orgs_limit: 2, products_limit: 4, items_limit: 12, search: term }).then(res => {
      const compare = term.toLowerCase();
      for (const item of res.items) {
        if (item.reference.toLowerCase() === compare) {
          setStateItem('search:opened', false);
          history.push(item.path);
          return;
        }
      }
      setSearchResults(res);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      addMessage('search-impossible', t`Unknown error`, t`Impossible to access search`);
    });
  }, [term, history]);

  useEffect(() => {
    document.addEventListener('click', close);
    return () => {
      document.removeEventListener('click', close);
    };
  }, []);

  useEffect(() => {
    doSearch();
  }, [doSearch]);

  useEffect(() => {
    setTerm(search);
  }, [search]);

  if (!searchOpened) return null;
  
  return (
    <nav
      className="sidebar sidebar-search-overlay"
      tabIndex="-1"
      onClick={e => { e.stopPropagation(); }}>
      <button type="button" onClick={close} className="btn-close" aria-label={t`Close`}></button>
      <div className="head">
        <form onSubmit={onSubmit}>
          <h3 className="pt-2">
            <Form.Label htmlFor="search"><Trans>Search</Trans></Form.Label>
          </h3>
          <div className="sidebar-search">
            <Form.Control
              ref={inputRef}
              type="text"
              id="sidebar-search"
              placeholder="search"
              defaultValue={search} />
            <button className="btn" type="submit">
              <Icon name="search" title={t`Search`}/>
            </button>
          </div>
        </form>
      </div>
      <div className="sidebar-content">
        { loading ? (
          <LoaderContainer/>
        ) : (searchResults && (
          <>
            { searchResults.orgs.length === 0 && searchResults.products.length === 0 && searchResults.items.length === 0 && (
              <div className="alert alert-secondary" role="alert">
                <Trans>There are no results matching your search</Trans>
              </div>
            ) }
            { searchResults.orgs.length === 0 && searchResults.products.length === 0 ? (
              <div className="items">
                { searchResults.items.length > 0 && (
                  <>
                    <h5><Trans>Backlog items</Trans></h5>
                    <div className="list-items">
                      { searchResults.items.map(item => (
                        <Link
                          key={item.pk}
                          className="card backlog-item-teaser mb-1"
                          to={item.path}
                          onClick={close}>
                          <BacklogItemTeaser showStatus layout="link" backlogItem={item} hideMore />
                        </Link>
                      ))}
                    </div>
                  </>
                ) }
              </div>
            ) : (
              <div className="row">
                <div className="col-12 col-md-4">
                  { searchResults.orgs.length > 0 && (
                    <>
                      <h5><Trans>Organizations</Trans></h5>
                      <div className="list-group list-orgs mb-3">
                        { searchResults.orgs.map(org => (
                          <div
                            key={org.pk}
                            className="card organization__teaser mb-2">
                            <Card.Body>
                              <Icon name="building" />
                              <div className="card-content">
                                <Card.Title>
                                  <Link
                                    to={`/${org.resource_slug}`}
                                    onClick={close}>
                                    { org.resource_name }
                                  </Link>
                                </Card.Title>
                                { searchResults.orgs.length === 1 && (
                                  <div className="extra-links">
                                    { org.getLinks().map(link => (
                                      <Link key={link.name} to={link.path} onClick={close}>
                                        { link.title }
                                      </Link>
                                    )) }
                                  </div>
                                ) }
                              </div>
                            </Card.Body>
                          </div>
                        ))}
                      </div>
                    </>
                  ) }
                  { searchResults.products.length > 0 && (
                    <>
                      <h5><Trans>Products</Trans></h5>
                      <div className="list-products mb-3">
                        { searchResults.products.map(prod => (
                          <div
                            key={prod.pk}
                            className="card product__teaser mb-2">
                            <Card.Body>
                              <Icon name="stack" />
                              <div className="card-content">
                                <Card.Title>
                                  <Link
                                    to={`/${prod.organization.resource_slug}/${prod.resource_slug}`}
                                    onClick={close}>
                                    { prod.resource_name }
                                  </Link>
                                </Card.Title>
                                { prod.organization.resource_name }
                                { searchResults.products.length === 1 && (
                                  <div className="extra-links">
                                    { prod.getLinks().map(link => (
                                      <Link key={link.name} to={link.path} onClick={close}>
                                        { link.title }
                                      </Link>
                                    )) }
                                  </div>
                                ) }
                              </div>
                            </Card.Body>
                          </div>
                        ))}
                      </div>
                    </>
                  ) }
                </div>
                <div className="col-12 col-md-8">
                  { searchResults.items.length > 0 && (
                    <>
                      <h5><Trans>Backlog items</Trans></h5>
                      <div className="list-items">
                        { searchResults.items.map(item => (
                          <Link
                            key={item.pk}
                            className="card backlog-item-teaser mb-2"
                            to={item.path}
                            onClick={close}>
                            <BacklogItemTeaser showStatus layout="link" backlogItem={item} hideMore />
                          </Link>
                        ))}
                      </div>
                    </>
                  ) }
                </div>
              </div>
            ) }
          </>
        )) }
      </div>
    </nav>
  );
}

function Search(props) {
  const history = useHistory();
  const [forceFocus, setForceFocus] = useState(0);

  const handleSelect = (value, state, search) => {
    if (value === null) {
      setStateItem('search:string', search);
      if (search) {
        setStateItem('search:opened', 'string');
      }
    }
    else {
      history.push(value.getPath());
    }
  };

  useEffect(() => {
    const onkeydown = (ev) => {
      if (ev.key === '/' && !ev.isComposing && ev.keyCode !== 229) {
        if (!/^(?:input|textarea|select)$/i.test(ev.target.tagName) && !ev.target.isContentEditable) {
          ev.preventDefault();
          if (document.querySelector('.hide-reduced').checkVisibility()) {
            setForceFocus(v => v + 1);
          }
          else {
            setStateItem('search:opened', true);
          }
        }
      }
    };
    document.addEventListener('keydown', onkeydown);
    return () => {
      document.removeEventListener('keydown', onkeydown);
    };
  }, []);

  return (
    <>
      <button
        className="btn mini-link show-reduced"
        type="button"
        onClick={(e) => { e.stopPropagation(); setStateItem('search:opened', true); }}>
        <Icon name="search"/>
        <span><Trans>Search</Trans></span>
      </button>
      <div className="hide-reduced">
        <SearchSuggestions handleSelect={handleSelect} forceFocus={forceFocus} />
      </div>
    </>
  );
}

export { SearchSidebar };
export default Search;
