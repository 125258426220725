/* eslint-disable react/no-unstable-nested-components */
import React, { useCallback, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import DropdownEditable, { getOptionsFromAPI } from 'components/form/DropdownEditable';
import SelectSearchEditable from 'components/form/SelectSearchEditable';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { ColorSticker } from 'components/ui/ColorSticker';
import HoursDuration from 'components/ui/HoursDuration';
import Icon from 'components/ui/Icon';
import BacklogItemReopen from 'components/form/BacklogItemReopen';
import copyTextToClipboard from 'components/utils/copyTextToClipboard';
import { generateGitBranch } from 'components/utils/gitcheckout';
import Autocomplete from 'components/ui/Autocomplete';
import DateFormat from 'components/ui/DateFormat';
import { Button, Form, Modal } from 'react-bootstrap';
import BaseField from 'components/form/BaseField';
import { LoaderContainer } from 'components/ui/Loader';

export function Type(props) {
  const { backlogItem, readonly, product } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getIssueTypeOptions = useCallback(
    getOptionsFromAPI(
      'prod-issue-type-' + product.pk,
      '/item-types?mode=select&product=' + product.pk
    ), [product]);

  return (
    <div className="hstack field-value">
      <span className="text-secondary"><Trans>Type</Trans></span>
      <div className="ms-auto">
        { (readonly || backlogItem.parent) ? (
          backlogItem.item_type && (
            <>
              <ColorSticker color={backlogItem.item_type.color}/>{' '}
              { backlogItem.item_type.resource_name }
            </>
          )
        ) : (
          <DropdownEditable
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            getOptions={getIssueTypeOptions}
            variant="light rounded-pill"
            field="item_type"
            fieldName={t`Type`}
            fieldValue="pk"
            fieldLabel="resource_name"
            required
            overlay="left"
            viewOption={opt => (
              <>
                { 'item' in opt && (
                  <ColorSticker color={opt.item.color}/>
                ) }{' '}
                { opt.label }
              </>
            )}/>
        )}
      </div>
    </div>
  );
}

function StatusModal(props) {
  const { confirmPopin, setConfirmPopin } = props;
  const [opts, setOpts] = useState(null);

  useEffect(() => {
    getOptionsFromAPI('backlog-resolution-options', '/backlog-items/meta', 'resolution')().then(response => {
      setOpts(response);
    });
  }, []);

  const onClose = () => {
    confirmPopin.setLoading(false);
    confirmPopin.setConfirm(false);
    setConfirmPopin(false);
  };
  return (
    <Modal
      show
      onHide={onClose}
      animation={false}
      className="upper-modal"
      backdropClassName="upper-modal"
      centered>
      <Modal.Header closeButton>
        <Trans>Please choose a resolution</Trans>
      </Modal.Header>
      <Modal.Body>
        { opts === null ? (
          <LoaderContainer height="3" />
        ) : (
          <BaseField
            className="form-group"
            label={t`Resolution`}
            id="confirm-resolution">
            <Form.Select
              onChange={e => {
                confirmPopin.setConfirm(conf => {
                  const data = { ...conf.obj };
                  data.resolution = e.target.value;
                  return {
                    message: conf.message,
                    obj: data,
                    confirmed: true
                  };
                });
                setConfirmPopin(false);
              }}
              as="select"
              id="confirm-resolution">
              <option>{ t`Please choose a value`}</option>
              { opts.filter(it => it.value !== 'undefined' && it.value !== null).map((it) => (
                <option key={it.value} value={it.value}>{it.label}</option>
              ))}
            </Form.Select>
          </BaseField>
        ) }
        <div className="actions">
          <Button variant="light" type="button" onClick={onClose}><Trans>Cancel</Trans></Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export function Status(props) {
  const { backlogItem, readonly, product } = props;
  const [confirmPopin, setConfirmPopin] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getItemStatusOptions = useCallback(
    getOptionsFromAPI(
      'prod-issue-status-' + product.pk,
      '/item-status?mode=select&product=' + product.pk
    ), [product]);

  const onBeforeUpdateStatus = useCallback((val, obj) => {
    const status = product.getStatus(val);
    if (product.mode.value === 'scrum') {
      if (status && status.status_type.value === 'backlog' && backlogItem.status.pk !== status.pk) {
        return {
          message: t`Are you sure you want to remove this backlog item from its sprint and put it back in the product backlog?`,
          value: val,
          obj: { ...obj, sprint: null }
        };
      }
    }
    if (status && status.status_type.value === 'closed' && backlogItem.status.status_type.value !== 'done') {
      return {
        callback: (setConfirm, setLoading) => {
          setConfirmPopin({ setConfirm: setConfirm, setLoading: setLoading });
        },
        value: val,
        obj: { ...obj }
      };
    }
    return false;
  }, [product, backlogItem.status.pk, backlogItem.status.status_type.value]);

  return (
    <div className="hstack field-value">
      { confirmPopin !== false && (
        <StatusModal setConfirmPopin={setConfirmPopin} confirmPopin={confirmPopin} />
      ) }
      <span className="text-secondary"><Trans>Status</Trans></span>
      <div className="ms-auto">
        { readonly || backlogItem.status.status_type.value === 'closed' ? (
          <div className="d-flex align-items-center">
            <div className="btn-theme badge normal-size rounded-pill">
              {backlogItem.status.resource_name}</div>
            { backlogItem.status.status_type.value === 'closed' && (
              <BacklogItemReopen pk={backlogItem.pk}/>
            )}
          </div>
        ) : (
          <DropdownEditable
            filterOptions={opts => opts.filter(opt => {
              if (!('item' in opt) || opt.item.status_type.value !== 'pre_backlog') return true;
              if (['todo', 'in_progress', 'done'].includes(backlogItem.status.status_type.value)) return false;
              return true;
            })}
            onBeforeUpdate={onBeforeUpdateStatus}
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            getOptions={getItemStatusOptions}
            variant="theme rounded-pill"
            field="status"
            fieldName={t`Status`}
            fieldValue="pk"
            fieldLabel="resource_name"
            overlay="left"
            required />
        )}
      </div>
    </div>
  );
}

export function Resolution(props) {
  const { backlogItem, readonly } = props;
  return (
    <div className="hstack field-value sep">
      <span className="text-secondary"><Trans>Resolution</Trans></span>
      <div className="ms-auto">
        { readonly ? (
          <div className="dropdown">
            <div className="btn-outline-secondary badge rounded-pill">{backlogItem.resolution.label}</div>
          </div>
        ) : (
          <DropdownEditable
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            getOptions={
              getOptionsFromAPI('backlog-resolution-options', '/backlog-items/meta', 'resolution')
            }
            variant="light rounded-pill"
            field="resolution"
            fieldName={t`Resolution`}
            filterOptions={opts => opts.filter(o => o.value !== 'undefined')}
            fieldValue="value"
            fieldLabel="label"
            overlay="left"
            required />
        )}
      </div>
    </div>
  );
}

export function Assignee(props) {
  const { backlogItem, readonly, getAssigneeOptions } = props;

  return (
    <div className="hstack field-value">
      <span className="text-secondary"><Trans>Assignee</Trans></span>
      <div className="ms-auto">
        { backlogItem.assignee && (
          <Icon name="person-circle"/>
        ) }
        { readonly ? (
          <div className="d-inline-block relative">
            <div className="dropdown">
              <div className="light rounded-pill">
                {backlogItem.assignee && backlogItem.assignee.resource_name}
              </div>
            </div>
          </div>
        ) : (
          <DropdownEditable
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            getOptions={getAssigneeOptions}
            variant="light rounded-pill"
            field="assignee"
            fieldName={t`Assignee`}
            fieldValue="pk"
            overlay="left"
            fieldLabel="resource_name" />
        )}
      </div>
    </div>
  );
}

export function Reporter(props) {
  const { backlogItem, readonly, getAssigneeOptions } = props;

  return (
    <div className="hstack field-value">
      <span className="text-secondary"><Trans>Reporter</Trans></span>
      <div className="ms-auto">
        { backlogItem.reporter && (
          <Icon name="person-circle"/>
        ) }
        { readonly ? (
          <div className="d-inline-block relative">
            <div className="dropdown">
              <div className="light rounded-pill">
                {backlogItem.reporter && backlogItem.reporter.resource_name}
              </div>
            </div>
          </div>
        ) : (
          <DropdownEditable
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            getOptions={getAssigneeOptions}
            variant="light rounded-pill"
            field="reporter"
            fieldName={t`Reporter`}
            fieldValue="pk"
            overlay="left"
            fieldLabel="resource_name" />
        )}
      </div>
    </div>
  );
}

export function Epic(props) {
  const { backlogItem, readonly, product } = props;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getEpicOptions = useCallback(
    getOptionsFromAPI(
      'prod-epic-' + product.pk,
      '/epics?mode=select&product=' + product.pk
    ), [product]);

  return (
    <div className="hstack field-value">
      <span className="text-secondary"><Trans>Epic</Trans></span>
      <div className="ms-auto">
        { (readonly || backlogItem.parent) ? (
          backlogItem.epic && (
            <>
              <ColorSticker color={backlogItem.epic.color}/>{' '}
              { backlogItem.epic.resource_name }
            </>
          )
        ) : (
          <DropdownEditable
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            getOptions={getEpicOptions}
            variant="light rounded-pill"
            field="epic"
            fieldName={t`Epic`}
            fieldValue="pk"
            fieldLabel="resource_name"
            overlay="left"
            viewOption={opt => (
              <>
                { 'item' in opt && (
                  <ColorSticker color={opt.item.color}/>
                ) }{' '}
                { opt.label }
              </>
            )}/>
        )}
      </div>
    </div>
  );
}

export function TotalWork(props) {
  const { backlogItem, product } = props;
  const { total_work } = backlogItem;
  const remaining_time =
    (backlogItem.hours_estimate ? backlogItem.hours_estimate.minutes : 0) -
    (total_work ? total_work.minutes : 0);

  return (
    <div className="hstack field-value total-work">
      <span className="text-secondary"><Trans>Total Work</Trans></span>
      <div className="ms-auto total-work-value">
        { total_work ? total_work.human_format : '0h' }
        { product.can_see_estimates &&
          product.estimate_mode.value === 'hours' &&
          backlogItem.hasEstimate('hours') && (
          <>
            {' ('}
            {remaining_time > 0 ? (
              <HoursDuration
                value={remaining_time}
                display={time => t`${time} remaining`}/>
            ) : t`no time remaining`}
            )
          </>
        ) }
      </div>
    </div>
  );
}

export function DefaultAccount(props) {
  const { backlogItem, readonly, product, organization } = props;

  return (
    <div className="hstack field-value default-account">
      <span className="text-secondary"><Trans>Default Account</Trans></span>
      <div className="ms-auto">
        { readonly || backlogItem.parent ? (
          <div className="dropdown">
            <div className="light rounded-pill">
              {backlogItem.default_account && backlogItem.default_account.resource_name ||
                product.default_account && t`${product.default_account.resource_name} (default)` }
            </div>
          </div>
        ) : (
          <SelectSearchEditable
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            searchApi={'/accounts?organization=' + organization.pk + '&product=' + product.pk}
            variant="light rounded-pill"
            field="default_account"
            fieldName={t`Default account`}
            fieldLabel="resource_name"
            overlay="left"
            emptyLabel={(product.default_account && t`${product.default_account.resource_name} (default)`)}
            dropRight />
        )}
      </div>
    </div>
  );
}
export function Priority(props) {
  const { backlogItem, readonly } = props;
  return (
    <div className="hstack field-value sep">
      <span className="text-secondary"><Trans>Priority</Trans></span>
      <div className="ms-auto">
        { readonly ? (
          <div className="dropdown">
            <div className="btn-outline-secondary badge rounded-pill">{backlogItem.priority.label}</div>
          </div>
        ) : (
          <DropdownEditable
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            getOptions={
              getOptionsFromAPI('backlog-priority-options', '/backlog-items/meta', 'priority')
            }
            variant="light rounded-pill"
            field="priority"
            fieldName={t`Priority`}
            fieldValue="value"
            fieldLabel="label"
            overlay="left"
            required />
        )}
      </div>
    </div>
  );
}

export function Deadline(props) {
  const { backlogItem, readonly } = props;
  return (
    <div className="field-deadline hstack field-value">
      <span className="text-secondary"><Trans>Deadline</Trans></span>
      <div className="ms-auto relative">
        { readonly ? backlogItem.deadline : (
          <FieldShowUpdate
            type="date"
            className="ms-auto"
            getValue={bl => (bl.deadline === null ? '' : bl.deadline)}
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            field="deadline"
            label={t`Deadline`}>
            { bl => (
              bl.deadline === null ? (
                <span className="text-secondary"><Trans>Add deadline</Trans></span>
              ) : (
                <DateFormat datestring={bl.deadline} />
              )
            ) }
          </FieldShowUpdate>
        )}
      </div>
    </div>
  );
}

export function BusinessValue(props) {
  const { backlogItem, readonly } = props;
  return (
    <div className="field-business-value hstack field-value">
      <span className="text-secondary"><Trans>Business Value</Trans></span>
      <div className="ms-auto relative">
        { readonly ? backlogItem.business_value : (
          <FieldShowUpdate
            className="ms-auto"
            getValue={bl => (bl.business_value === null ? '' : bl.business_value)}
            pk={backlogItem.pk}
            api={backlogItem._genericApi}
            field="business_value"
            label={t`Business Value`}>
            { bl => (
              bl.business_value === null ? (
                <span className="text-secondary"><Trans>Add business value</Trans></span>
              ) : bl.business_value
            ) }
          </FieldShowUpdate>
        )}
      </div>
    </div>
  );
}

export function GitCheckout(props) {
  const { backlogItem, product } = props;
  const copyCode = () => {
    copyTextToClipboard(
      'git checkout -b ' + generateGitBranch(backlogItem, product)
    );
  };
  return (
    <>
      <div className="hstack field-value">
        <span className="text-secondary"><Trans>Branch name</Trans></span>
        <div className="ms-auto relative text-end">
          <em><code className="text-secondary" data-cy="git-branch-name">{ generateGitBranch(backlogItem, product) }</code></em>
        </div>
      </div>
      <div className="hstack field-value">
        <span className="text-secondary"><Trans>Checkout</Trans></span>
        <div className="ms-auto relative text-end">
          <button type="button" onClick={copyCode} className="btn btn-light btn-small">
            <Trans>Copy to clipboard</Trans>
          </button>
        </div>
      </div>
    </>
  );
}

function keydownHandler (event) {
  // space key
  if (event.keyCode === 32) {
    event.preventDefault();
  }
  // enter key
  else if (event.keyCode === 13) {
    event.preventDefault();
    event.target.click();
  }
}

function keyupHandler (event) {
  // space key
  if (event.keyCode === 32) {
    event.preventDefault();
    event.target.click();
  }
}
export function Version(props) {
  const { backlogItem, product, field, title, readonly } = props;
  const [isEdit, setIsEdit] = useState(false);

  const onChange = useCallback(items => {
    backlogItem[field] = items;
    backlogItem.saveField(field);
  }, [backlogItem, field]);

  if (isEdit) {
    return (
      <div className={`field-value field-versions field-${field}`}>
        <div className="text-secondary mb-1">{ title }</div>
        <Autocomplete
          items={backlogItem[field]}
          searchApi={`/releases?product=${product.pk}`}
          onChange={onChange}
          id={'backlog-' + field + '-edit'}
          inputLabel={t`Search or add version`}
          autofocus
          mode="continuous">
          <button
            type="button"
            className="bt-close-versions btn btn-outline-secondary text-small"
            onClick={() => { setIsEdit(false); }}>
            <Icon name="x-circle" title={t`Close`} />
          </button>
        </Autocomplete>
      </div>
    );
  }
  return (
    <div className={`hstack field-value field-versions field-${field}`}>
      <span className="text-secondary">{ title }</span>
      <div className="ms-auto">
        { readonly ? (
          <span className="bt-edit-versions">
            { backlogItem[field].map(v => (
              <span className="badge bg-light text-dark tag me-1" key={v.pk}>
                { v.resource_name }
              </span>
            )) }
          </span>
        ) : (
          <span
            className="bt-edit-versions"
            role="button"
            tabIndex="0"
            onKeyDown={keydownHandler}
            onKeyUp={keyupHandler}
            onClick={() => { setIsEdit(true); }}>
            { backlogItem[field].map(v => (
              <span className="badge bg-light text-dark version me-1" key={v.pk}>
                { v.resource_name }
              </span>
            )) }
            { backlogItem[field].length < 1 && (
              <span className="text-secondary me-3"><Trans>Add versions</Trans></span>
            ) }
            <Icon name="pencil"/>
          </span>
        )}
      </div>
    </div>
  );
}
