import React, { useState, useEffect, useContext } from 'react';
import { t, Trans } from '@lingui/macro';
import { Dropdown, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BacklogItem from 'components/product/BacklogItem';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import BacklogItemModel from 'entity/BacklogItem';
import Icon from 'components/ui/Icon';
import copyTextToClipboard from 'components/utils/copyTextToClipboard';
import { getAbsoluteUrl } from 'components/utils/urls';
import BacklogItemActions from 'components/product/backlogitem/BacklogItemActions';
import { useStateItem } from 'utils/Item';
import ProductContext from 'components/utils/ProductContext';
import ConfirmDialog from 'components/utils/ConfirmDialog';
import BacklogModalContext from './BacklogModalContext';

function LinkSelector(props) {
  const { backlogItem } = props;
  const { hideModal } = useContext(BacklogModalContext);
  if (!backlogItem) return null;
  const handleCopy = () => {
    copyTextToClipboard(getAbsoluteUrl(backlogItem.path));
  };
  return (
    <Dropdown className="link-action">
      <Dropdown.Toggle variant={null}>
        <Icon name="link"/>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          as="button"
          onClick={handleCopy}>
          <Trans>Copy to clipboard</Trans>
        </Dropdown.Item>
        <Link to={backlogItem.path} onClick={() => { hideModal(); }} className="dropdown-item">
          <Trans>Go to backlog item page</Trans>
        </Link>
      </Dropdown.Menu>
    </Dropdown>
  );
}
export default function BacklogItemViewModal(props) {
  const { item } = useContext(BacklogModalContext);
  if (!item) return null;
  return (
    <BacklogItemViewModalFinal item={item} />
  );
}

export function BacklogItemViewModalFinal(props) {
  const { item } = props;
  const { 
    show,
    hideModal,
    item: contextItem,
    isPrevented,
    setIsPrevented,
    addPreventClose,
    removePreventClose
  } = useContext(BacklogModalContext);
  const product = useContext(ProductContext);
  const [loading, setLoading] = useState(false);
  const backlogItem = useStateItem('backlog-items:' + item.pk, true);
  useEffect(() => {
    const blitem = new BacklogItemModel();
    if (item) {
      setLoading(true);
      blitem.load(item.pk, false).then(() => {
        setLoading(false);
      }).catch(err => {
        addMessage('backlog-item-load', t`Unknown error`, t`Can't load backlog item`);
      });
    }
  }, [item]);

  if (show !== 'view' || !item) return null;

  return (
    <Modal
      enforceFocus={false}
      size="xl"
      show={show === 'view'}
      onHide={hideModal}
      className="backlog-item-modal backlog-item-full"
      centered>
      <Modal.Header closeButton>
        <LinkSelector backlogItem={backlogItem}/>
        <Modal.Title className="backlog-title">
          { backlogItem ? backlogItem.reference : item.reference }{' '}
          { (loading || !backlogItem) ? t`Loading…` : (
            product.can_edit_item ? (
              <FieldShowUpdate
                className="title-element"
                item={backlogItem}
                pk={backlogItem.pk}
                api={backlogItem._genericApi}
                field="title"
                label={t`title`}
                onEdit={addPreventClose}
                onEditClose={removePreventClose}>
              </FieldShowUpdate>
            ) : (
              <div className="title-element">{ backlogItem.title }</div>
            )
          )}
        </Modal.Title>
        <BacklogItemActions contextItem={contextItem} backlogItem={backlogItem} isModal />
      </Modal.Header>
      <Modal.Body>
        <ConfirmDialog
          show={isPrevented}
          variant="transparent"
          message={t`You have unsaved changes, are you sure you want to close this modal?`}
          confirmLabel={t`Close`}
          onCancel={() => {
            setIsPrevented(false);
          }}
          onConfirm={() => {
            setIsPrevented(false);
            hideModal(true);
          }}>
        </ConfirmDialog>
        { (loading || !backlogItem) ? (
          <LoaderContainer height="4"/>
        ) : (
          <BacklogItem
            backlogItem={backlogItem}
            readonly={props.readonly}
            isModal />
        )}
      </Modal.Body>
    </Modal>
  );
}
