/* eslint-disable react/no-unescaped-entities */

import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Alert, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { LoaderContainer } from 'components/ui/Loader';
import getOrganizations from 'entity/Organizations';
import Icon from 'components/ui/Icon';
import OrganizationTeaser from './OrganizationTeaser';

export default function OrganizationList(props) {
  const [organizations, setOrganizations] = useState(null);
  const [error, setError] = useState(null);
  const { columns } = props;

  useEffect(() => {
    getOrganizations().then((orgs) => {
      setOrganizations(orgs);
    }).catch((err) => { setError(err); });
  }, []);

  if (error) {
    return (
      <Alert>
        <p><Trans>Impossible to load organizations</Trans></p>
      </Alert>
    );
  }
  if (organizations === null) {
    return (
      <Card className="organizations-list relative">
        <Card.Body>
          <LoaderContainer height="3"/>
        </Card.Body>
      </Card>
    );
  }
  if (organizations.length < 1) {
    return (
      <Card className="organizations-list">
        <Card.Body>
          <p><Trans>You don't have any organization</Trans></p>
          <NavLink className="btn btn-secondary" to="organizations/add"><Trans>Create an organization</Trans></NavLink>
        </Card.Body>
      </Card>
    );
  }
  if (organizations.length === 1) {
    return <OrganizationTeaser organization={organizations[0]} />;
  }

  const classes = ['organization-list'];
  if (columns) classes.push('columns-' + columns);
  return (
    <div className={classes.join(' ')}>
      {organizations.map((org) => (
        <Card className="organization organization__teaser" key={org.slug}>
          <Card.Body>
            <Icon name="building" />
            <div className="card-content">
              <Card.Title>{ org.name }</Card.Title>
              <Card.Text>{ org.subscription.label }</Card.Text>
              <NavLink className="btn btn-slug" to={`/${org.slug}`}>{org.slug}</NavLink>
            </div>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}
