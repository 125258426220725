/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-lonely-if */
import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';
import { priceList, currencySymbol } from 'components/utils/constants';
import { getAbsoluteUrl, websiteUrl } from 'components/utils/urls';

function SubButton(props) {
  const { sub, setSub, btnClasses } = props;
  const classes = btnClasses ? ['btn', ...btnClasses] : ['btn'];
  return (
    <button
      type="button"
      className={classes.join(' ')}
      onClick={() => setSub(sub)}>
      { props.children }
    </button>
  );
}

function SimpleInput(props) {
  const { onEdit, label, ...rest } = props;
  const [isEdited, setIsEdited] = useState(false);
  let classes = 'form-group';
  if (props.required) classes += ' required';
  if (isEdited) classes += ' was-validated';
  return (
    <div className={classes}>
      <label htmlFor={props.id} className="form-label">
        { label }
      </label>
      <input
        onInput={(e) => {
          setIsEdited(true);
          onEdit(e);
        }}
        {...rest} />
    </div>
  );
}

function Periodicity(props) {
  const { periodicity, setPeriodicity } = props;
  return (
    <div className="form-group inline">
      <div className="form-label">{ t`Periodicity` }</div>
      <div className="btn-group" role="group">
        <button
          type="button"
          className={'btn ' + (periodicity === 'monthly' ? 'btn-primary' : 'btn-outline-secondary')}
          onClick={() => { setPeriodicity('monthly'); }}>
          { t`Monthly`}
        </button>
        <button
          type="button"
          className={'btn ' + (periodicity === 'yearly' ? 'btn-primary' : 'btn-outline-secondary')}
          onClick={() => { setPeriodicity('yearly'); }}>
          { t`Yearly`}
        </button>
      </div>
    </div>
  );
}
function CurrencySelect(props) {
  const { onEdit, label, ...rest } = props;
  const [isEdited, setIsEdited] = useState(false);
  let classes = 'form-group inline';
  if (props.required) classes += ' required';
  if (isEdited) classes += ' was-validated';
  return (
    <div className={classes}>
      <label htmlFor={props.id} className="form-label">
        { label }
      </label>
      <select
        className="form-select d-inline-block"
        onInput={(e) => {
          setIsEdited(true);
          onEdit(e);
        }}
        {...rest}>
        <option value="EUR">{ t`Euro (€)` }</option>
        <option value="USD">{ t`U.S. Dollar ($)` }</option>
        <option value="CHF">{ t`Swiss franc (CHF)` }</option>
      </select>
    </div>
  );
}

function SubscriptionForm(props) {
  const { onCancel, onSave, organization, isNew } = props;
  const [loading, setLoading] = useState(false);
  const [periodicity, setPeriodicity] = useState('monthly');
  const [sub, setSub] = useState(isNew ? null : (organization.subscription ? organization.subscription.value : null));
  const [users, setUsers] = useState(5);
  const [currency, setCurrency] = useState('EUR');

  const onSubmit = (e) => {
    e.preventDefault();
    if (isNew && sub === 'trial') {
      onSave();
    }
    else if (!organization.has_stripe_subscription) {
      setLoading(true);
      const path = organization.slug + '/manage/settings';
      axios.get(`/organizations/${organization.pk}/checkout-session?` +
          'success_url=' + encodeURIComponent(getAbsoluteUrl(path, [['payment', 'success']])) +
          '&cancel_url=' + encodeURIComponent(getAbsoluteUrl(path, [['payment', 'cancelled']])) +
          `&tariff=${sub}&tariff_mode=${periodicity}&currency=${currency}` +
          `&quantity=${sub === 'premium' ? users : 1}`).then((res) => {
        if (res.data.session_url) {
          window.location.href = res.data.session_url;
        } else {
          // Only in test
          window.location.reload();
        }
      }).catch(err => {
        setLoading(false);
        addMessage('checkout-session', 'Unknown error', 'Impossible to open checkout');
      });
    } else {
      setLoading(true);
      axios.put(`/organizations/${organization.pk}/subscription?` +
          `tariff=${sub}&tariff_mode=${periodicity}&currency=${currency}` +
          `&quantity=${sub === 'premium' ? users : 1}`).then((res) => {
        organization.loadFromObject(res, true);
        setLoading(false);
        onSave();
      }).catch(err => {
        setLoading(false);
        addMessage('checkout-session', 'Unknown error', 'Impossible to update subscription');
      });
    }
  };

  const getPrice = () => {
    if (sub === 'starter') {
      return priceList.starter[periodicity][currency].toFixed(2);
    }
    if (sub === 'premium') {
      return (users * priceList.premium[periodicity][currency]).toFixed(2);
    }
    return '0.00';
  };

  let hasChanges = false;
  if (isNew) {
    hasChanges = !!sub;
  }
  else {
    if (organization.subscription.value !== sub) {
      hasChanges = true;
    }
    else {
      if (organization.subscription.value === 'starter' &&
        organization.payment_mode &&
        organization.payment_mode.value !== periodicity) {
        hasChanges = true;
      }
      else if (organization.subscription.value === 'premium' && (
        (organization.payment_mode &&
          organization.payment_mode.value !== periodicity) ||
        organization.max_users !== users)) {
        hasChanges = true;
      }
    }
  }

  const forceActive = (!sub) || (sub === 'trial' && !isNew);
  return (
    <form
      className="relative form-subscription"
      name="organization-add-form"
      onSubmit={onSubmit}>
      { loading && (
        <LoaderContainer />
      ) }
      <div className="sub-filters">
        <Periodicity periodicity={periodicity} setPeriodicity={setPeriodicity}/>
        { (!organization.pk || organization.subscription.value === 'trial') && (
          <CurrencySelect
            name="currency"
            id="currency"
            label={t`Currency`}
            required
            onEdit={(e) => setCurrency(e.target.value)} />
        ) }
      </div>
      <div className="d-sm-flex flex-wrap justify-content-between">
        <div className={'card card__sub card__starter ' + (forceActive || (sub === 'starter') ? 'active' : 'inactive')}>
          <div className="subtitle"><Trans>Our discovery package</Trans></div>
          <div className="card-body">
            <h3 className="card-title"><Trans>Starter</Trans></h3>
            <div className="">
              <Trans>
                Fixed price of
                <span className="price">{ priceList.starter[periodicity][currency].toFixed(2) } { currencySymbol[currency] }</span>
              </Trans><br />
              { periodicity === 'monthly' ? t`per month` : t`per year` }
            </div>
            <SubButton sub="starter" setSub={setSub}>
              <Trans>Choose</Trans>
            </SubButton>
            <p className="text-small">
              <Trans>5 users package.</Trans><br />
              <Trans>10 “read only” guests users.</Trans><br />
              <Trans>2GB of file storage.</Trans>
            </p>
          </div>
        </div>
        <div className={'card card__sub card__premium ' +
          (forceActive || (sub === 'premium') ? 'active' : 'inactive')}>
          <div className="subtitle"><Trans>Our standard package</Trans></div>
          <div className="card-body">
            <h3 className="card-title"><Trans>Premium</Trans></h3>
            <div className="">
              <Trans>
                <span className="price">
                  { priceList.premium[periodicity][currency].toFixed(2) } { currencySymbol[currency] }
                </span> / <Trans>user</Trans>
              </Trans><br />
              { periodicity === 'monthly' ? t`per month` : t`per year` }

            </div>
            <SubButton sub="premium" setSub={setSub}>
              <Trans>Choose</Trans>
            </SubButton>
            { sub === 'premium' && (
              <SimpleInput
                name="max_users"
                id="max_users"
                type="number"
                label={t`Number of users`}
                className="form-control d-inline-block ms-1"
                value={users}
                step="1"
                min="1"
                max="1000"
                size="3"
                required
                onEdit={(e) => setUsers(Math.min(parseInt(e.target.value, 10) || 1, 1000))} />
            ) }
            <p className="text-small">
              <Trans>Up to 250 users.</Trans><br />
              <Trans>20 “read only” guests users per user.</Trans><br />
              <Trans>2GB of file storage per user.</Trans>
            </p>
          </div>
        </div>
        <div className={'card card__sub card__entreprise ' + (forceActive ? 'active' : 'inactive')}>
          <div className="subtitle"><Trans>Our tailor-made offer</Trans></div>
          <div className="card-body">
            <h4 className="card-title"><Trans>Entreprise</Trans></h4>
            <div className="d-grid gap-2">
              <p>
                <Trans>
                  For more information on our enterprise subscriptions or any tailor-made offer,
                  please contact <a href="mailto:sales@optera.io">sales@optera.io</a>
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 row align-items-end mb-2">
        <div className="col-xl-4 col-sm-6 col-12">
          { (!organization.pk || organization.subscription.value === 'trial') && isNew && (
            <div className={'card card__sub card__trial ' + (!sub || (sub === 'trial') ? 'active' : 'inactive')}>
              <div className="card-body">
                <h4 className="card-title"><Trans>Trial</Trans></h4>
                <SubButton btnClasses={['btn-outline-secondary']} sub="trial" setSub={setSub}>
                  <Trans>One month free trial</Trans>
                </SubButton>
              </div>
            </div>
          ) }
        </div>
        <div className="col-xl-4 col-sm-6 col-12 text-end">
          <strong><Trans>Total</Trans>: </strong>
          <span className="fs-2">{ getPrice() } { currencySymbol[currency] }</span>
          { periodicity && (
            <span> / { periodicity === 'monthly' ? t`month` : t`year` }</span>
          ) }
        </div>
        <div className="col-xl-4 mt-3 mt-xl-0 col-12 text-end">
          <div className="d-grid gap-2">
            { hasChanges && (
              <button className="btn btn-primary" type="submit">
                { organization.has_stripe_subscription ? t`Save` :
                  (sub === 'trial' ? t`Start the demonstration` : t`Proceed to checkout`)}
              </button>
            ) }
            { !isNew && (
              <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
            ) }
          </div>
        </div>
      </div>
      <div className="text-end mb-3">
        <Trans>
          By proceeding with the purchase, you accept our <a target="_blank" href={websiteUrl('terms')}>terms and conditions</a>
        </Trans>
      </div>
    </form>
  );
}

export default SubscriptionForm;
