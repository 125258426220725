/* eslint-disable no-continue */
import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import BacklogItemForm from 'components/forms/BacklogItemForm';
import { getStateItem, setStateItem } from 'utils/Item';
import StoryMapContext from 'components/storymap/StoryMapContext';
import ProductContext from 'components/utils/ProductContext';
import OrganizationContext from 'components/utils/OrganizationContext';
import { LoaderContainer } from 'components/ui/Loader';
import { ProgressBar } from 'react-bootstrap';
import axios from 'axios';

function getDescription(task) {
  const fLLower = s => s.charAt(0).toLowerCase() + s.slice(1);
  const personaText = fLLower(task.persona.resource_name);
  const taskText = fLLower(task.resource_name);
  const activityText = fLLower(task.activity.resource_name);
  return '<p>' + t`As a ${personaText}, I want to ${taskText}, so that I can ${activityText}` + '</p>';
}

function BacklogItemStorymapModalContentProgress(props) {
  const { tasks, status, handleCancel, meta, ...rest } = props;
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const backlogitem = {};

  const task = tasks[step];
  
  backlogitem.description = getDescription(task);
  backlogitem.product = props.product.pk;
  backlogitem.title = task.resource_name;
  backlogitem.status = props.product.statuses.find(s => s.status_type.value === status);
  const [blitem, setBlitem] = useState(backlogitem);

  const next = (data) => {
    const b = {};
    const nt = tasks[step + 1];
    b.product = data.product.pk;
    b.status = data.status;
    b.sprint = data.sprint;
    b.item_type = data.item_type;
    b.title = nt.resource_name;
    b.description = getDescription(nt);
    setStep(s => s + 1);
    setBlitem(b);
  };

  const handleSave = (data, created, submitter) => {
    setStateItem('backlog-items', d => (d ? d + 1 : 1));
    setLoading(true);
    axios.put('/tasks/' + task.pk, {
      pk: task.pk,
      backlog_item: { pk: data.pk } 
    }).then((r) => {
      setStateItem('tasks:' + r.data.pk, r.data);
      if (submitter.classList.contains('bt-save-new')) {
        next(data);
        setLoading(false);
      }
      else {
        handleCancel();
      }
    });
  };

  const onSkip = () => {
    setLoading(true);
    next({ ...blitem });
    setLoading(false);
  };

  if (loading) {
    return <LoaderContainer height="4" />;
  }

  return (
    <div>
      { tasks.length > 1 && (
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="me-2">
            { step + 1 } / { tasks.length }
          </div>
          <div className="flex-grow-1">
            <ProgressBar now={100 * (step + 1) / tasks.length} />
          </div>
        </div>
      ) }
      
      <BacklogItemForm
        key={step}
        backlogitem={blitem}
        layout="storymap"
        meta={meta}
        {...rest}
        onSave={handleSave}
        onCancel={handleCancel}
        onSkip={onSkip}
        hasNext={(step + 1) < tasks.length}>
      </BacklogItemForm>
    </div>
    
  );
}
function BacklogItemStorymapModalContent(props) {
  const { handleCancel, filter, ...rest } = props;
  const { tasks, activities } = useContext(StoryMapContext);
  const [taskList, setTaskList] = useState(null);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    const l = [];
    for (let i = 0; i < tasks.length; i++) {
      const task = { ...getStateItem('tasks:' + tasks[i]) };
      if (!task.backlog_item && task.release && task.activity && activities.includes(task.activity.pk)) {
        const activity = getStateItem('activities:' + task.activity.pk);
        if (!activity || activity.hidden) continue;
        const goal = getStateItem('goals:' + activity.goal.pk);
        if (!goal || goal.hidden) continue;
        const persona = getStateItem('personas:' + goal.persona.pk);
        if (!persona || persona.hidden) continue;
        
        task.activity = activity;
        task.goal = goal;
        task.persona = persona;
        if (filter && filter(task)) {
          l.push(task);
        }
        else if (!filter) {
          l.push(task);
        }
      }
    }
    setTaskList(l);
  }, [tasks, filter, activities]);
  
  useEffect(() => {
    axios.get('/backlog-items/meta').then(res => {
      setMeta(res.data);
    });
  }, []);
  
  if (taskList === null || !meta) {
    return (
      <LoaderContainer height="3" />
    );
  }
  if (taskList.length < 1) {
    return (
      <p>
        <Trans>Ther is no compatible task to create backlog items</Trans>
      </p>
    );
  }

  return (
    <BacklogItemStorymapModalContentProgress
      {...rest}
      meta={meta}
      handleCancel={handleCancel}
      tasks={taskList}>
    </BacklogItemStorymapModalContentProgress>
  );
}
function BacklogItemStorymapModal(props) {
  const { title, modalTitle, onClose, hideButton,
    show, btnVariant, status, ...rest } = props;
  const product = useContext(ProductContext);
  const organization = useContext(OrganizationContext);
  const [isShown, setShow] = useState(!!show);

  const handleCancel = () => {
    if (onClose) onClose(false);
    setShow(false);
  };
  const handleShow = (e) => {
    setShow(true);
  };

  useEffect(() => {
    setShow(show);
  }, [show]);

  return (
    <ModalForm
      show={isShown}
      handleClose={handleCancel}
      handleShow={handleShow}
      title={modalTitle || t`New backlog item`}
      buttonTitle={title || t`Create backlog items from tasks`}
      hideButton={hideButton}
      variant={btnVariant}
      confirmClose>
      <BacklogItemStorymapModalContent
        handleCancel={handleCancel}
        organization={organization}
        product={product}
        status={status}
        {...rest} />
    </ModalForm>
  );
}
BacklogItemStorymapModal.defaultProps = {
  status: 'backlog'
};
export default BacklogItemStorymapModal;
