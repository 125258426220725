/* eslint-disable react/no-array-index-key */

import React from 'react';

function Actions(props) {
  const { links } = props;

  if (!links || links.length < 1) return null;

  return (
    <nav className="actions-container mt-2 mt-md-0">
      <div className="btn-group">
        { links.map((link, index) => {
          if ('type' in link && link.type.name === 'SimpleModal') {
            return link;
          }
          return React.cloneElement(link, {
            key: index,
            className: 'btn btn-outline-secondary'
          });
        }) }
      </div>
    </nav>
  );
}

export default Actions;
