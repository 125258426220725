import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import { Loader } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import React, { useState } from 'react';

export default function RequestCodeByEmail(props) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const { token } = props;
 
  const onClick = e => {
    setLoading(true);
    setMessage(false);
    let data = null;
    if (token) data = { token: token };
    axios.post('auth/email', data).then(() => {
      setLoading(false);
      setMessage(true);
    }).catch(() => {
      setLoading(false);
      addMessage('otp-email-fail', t`Unknown error`, t`Impossible to send OTP code by email.`);
    });
  };
  return (
    <div>
      <button
        type="button"
        className="btn-simplelink"
        onClick={onClick}>
        <Trans>Request a code by email</Trans>
      </button>
      { loading && (
        <Loader className="ms-2" size="tiny" />
      ) }
      { message && (
        <div className="alert alert-info mt-2" role="alert">
          <Trans>an email with a verification code was just sent to your email address.</Trans>
        </div>
      ) }
    </div>
  );
}
