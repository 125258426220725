import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function SimpleModal(props) {
  const { hideButton, buttonTitle, title, handleClose, variant, isDropDown, ...rest } = props;
  const [show, setShow] = useState(false);
  const onClose = () => {
    if (handleClose) handleClose();
    setShow(false);
  };
  return (
    <>
      { !hideButton && (isDropDown ? (
        <button type="button" className="dropdown-item" onClick={() => { setShow(true); }}>
          { buttonTitle }
        </button>
      ) : (
        <Button variant={variant || 'outline-secondary'} onClick={() => { setShow(true); }}>
          { buttonTitle }
        </Button>
      )) }

      <Modal show={show} onHide={onClose} centered {...rest}>
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { props.children }
        </Modal.Body>
      </Modal>
    </>
  );
}
