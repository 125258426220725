import React, { useContext } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ProductContext from 'components/utils/ProductContext';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import { getStateItem, setStateItem } from 'utils/Item';
import getSensor from 'mocks/cypressSensor';
import StoryMapContext from './StoryMapContext';
import StoryMapRelease from './StoryMapRelease';
import StoryMapCell from './StoryMapCell';

function moveReleases(source, destination, draggableId, pk, item, listItems, loadStoryMapReleases) {
  const params = {
    move: 'bottom'
  };
  const destItems = [...listItems];
  
  const destItemsFiltered = destItems.filter(i => i !== pk);
  if (destination.index < destItemsFiltered.length) {
    params.move = 'above';
    params.object = destItemsFiltered[destination.index];
  }
  if (source.index === destination.index) {
    return;
  }
  const [removed] = destItems.splice(source.index, 1);
  destItems.splice(destination.index, 0, removed);
  loadStoryMapReleases(destItems);
  
  const queryString = new URLSearchParams(params).toString();
  axios.put(`story-map-releases/${pk}?${queryString}`, item).then((response) => {
  }).catch((error) => {
    addMessage('update-story-map-release', t`Unknown error`, t`Impossible to update story map release`);
    loadStoryMapReleases();
  });
}

function moveTasks(source, destination, draggableId, pk, item, rows, loadTasks, storyMapReleases, columns) {
  if (source.droppableId === destination.droppableId && source.index === destination.index) {
    return;
  }
  const params = {
    move: 'bottom'
  };
  const destItems = [...rows[item.release ? item.release.pk : 'NONE'].columns[item.activity ? item.activity.pk : 'NONE']];
  
  let sourceStoryMapReleasePk = null;
  let sourceActivityPk = null;
  let sourceItems = null;
  
  const destItemsFiltered = destItems.filter(i => i !== pk);
  if (destination.index < destItemsFiltered.length) {
    params.move = 'above';
    params.object = destItemsFiltered[destination.index];
  }
  if (source.droppableId === destination.droppableId) {
    const [removed] = destItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
  }
  else {
    const sourceDropParts = source.droppableId.split('-');
    sourceStoryMapReleasePk = sourceDropParts[1] === 'NONE' ? 'NONE' : parseInt(sourceDropParts[1], 10);
    sourceActivityPk = sourceDropParts[2] === 'NONE' ? 'NONE' : parseInt(sourceDropParts[2], 10);
    sourceItems = [...rows[sourceStoryMapReleasePk].columns[sourceActivityPk]];
    const [removed] = sourceItems.splice(source.index, 1);
    const newActivity = item.activity ? getStateItem('activities:' + item.activity.pk) : null;
    const newRelease = item.release ? getStateItem('story-map-releases:' + item.release.pk) : null;
    const updateItem = {
      pk: removed,
      activity: newActivity ? {
        pk: item.activity.pk,
        resource_name: newActivity.resource_name
      } : null,
      release: newRelease ? {
        pk: item.release.pk,
        resource_name: newRelease.resource_name
      } : null
    };
    setStateItem('tasks:' + removed, updateItem);
    destItems.splice(destination.index, 0, removed);
  }
  loadTasks(oldTasks => {
    const newTasks = [];
    const releases = [...storyMapReleases, 'NONE'];
    const itemReleasePK = item.release ? item.release.pk : 'NONE';
    const itemActivityPK = item.activity ? item.activity.pk : 'NONE';
    for (let i = 0; i < releases.length; i++) {
      const finalCols = [...columns, 'NONE'];
      for (let j = 0; j < finalCols.length; j++) {
        if (finalCols[j] !== null) {
          
          if (releases[i] === itemReleasePK && finalCols[j] === itemActivityPK) {
            newTasks.push(...destItems);
          }
          else if (sourceActivityPk !== null && releases[i] === sourceStoryMapReleasePk && finalCols[j] === sourceActivityPk) {
            newTasks.push(...sourceItems);
          }
          else {
            newTasks.push(...rows[releases[i]].columns[finalCols[j]]);
          }
        }
      }
    }
    return newTasks;
  });
  
  const queryString = new URLSearchParams(params).toString();
  axios.put(`tasks/${pk}?${queryString}`, item).then((response) => {
  }).catch((error) => {
    addMessage('update-tasks', t`Unknown error`, t`Impossible to update task`);
    loadTasks();
  });
}

export default function StoryMapBody(props) {
  const { rows, columns } = props;
  const { storyMapReleases, loadStoryMapReleases, loadTasks } = useContext(StoryMapContext);
  const product = useContext(ProductContext);

  const onDragEnd = (result) => {
    const { source, destination, draggableId } = result;
    if (destination === null) return;
    const [type, pk] = draggableId.split('-');
    const intPk = parseInt(pk, 10);
    if (type === 'r') {
      moveReleases(source, destination, draggableId, intPk, { pk: pk }, storyMapReleases, loadStoryMapReleases);
    }
    else if (type === 't') {
      const dropParts = destination.droppableId.split('-');
      const storyMapReleasePk = dropParts[1] === 'NONE' ? 'NONE' : parseInt(dropParts[1], 10);
      const activityPk = dropParts[2] === 'NONE' ? 'NONE' : parseInt(dropParts[2], 10);
      moveTasks(source, destination, draggableId, intPk, {
        pk: pk,
        release: storyMapReleasePk !== 'NONE' ? { pk: storyMapReleasePk } : null,
        activity: activityPk !== 'NONE' ? { pk: activityPk } : null,
      }, rows, loadTasks, storyMapReleases, columns);
    }
  };
  const hasNoneRelease = Object.values(rows.NONE.columns).reduce(
    (acc, current) => acc + current.length,
    0,
  ) > 0;

  const sensors = [];
  if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') sensors.push(getSensor('.story-map-body'));

  return (
    <DragDropContext onDragEnd={onDragEnd} sensors={sensors}>
      <Droppable droppableId="releases" direction="vertical" type="releases">
        {(provided, snapshot) => (
          <div
            className={'story-map-body droppable-container' + (snapshot.isDraggingOver ? ' is-drag-over' : '')}
            ref={provided.innerRef}
            style={{ width: ((columns.length + 2) * 200) + 'px' }}>
            { storyMapReleases.map((storyMapReleasePk, index) => (
              <Draggable
                key={storyMapReleasePk}
                draggableId={'r-' + String(storyMapReleasePk)}
                index={index}>
                {(provided2, snapshot2) => (
                  <div
                    className={'story-map-release' + (snapshot2.isDragging ? ' is-draging' : '')}
                    ref={provided2.innerRef}
                    {...provided2.draggableProps}>
                    <StoryMapRelease
                      product={product}
                      dragHandleProps={provided2.dragHandleProps}
                      storyMapReleasePk={storyMapReleasePk}
                      isDragging={snapshot2.isDragging}
                      rows={rows}
                      columns={columns} />
                  </div>
                )}
              </Draggable>
            )) }
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      { hasNoneRelease && (
        <div className="story-map-release" style={{ width: ((columns.length + 2) * 200) + 'px' }}>
          <div className="story-map-release-header">
            <Trans>Without release</Trans>
          </div>
          { columns.map((activityPk, index2) => (
            <StoryMapCell
              key={activityPk !== null ? activityPk : ('ind-' + index2)}
              rows={rows}
              storyMapRelease={null}
              activityPk={activityPk} />
          )) }
          <StoryMapCell
            rows={rows}
            storyMapRelease={null}
            activityPk="NONE"
            emptyActivity />
        </div>
      ) }
    </DragDropContext>
  );
}
