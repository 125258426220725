import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ContentBlock from 'components/content/ContentBlock';
import Breadcrumb from 'components/content/Breadcrumb';
import Actions from 'components/content/Actions';
import Icon from 'components/ui/Icon';
import NotificationsDisabled from 'components/topnav/NotificationsDisabled';
import { t } from '@lingui/macro';

function Page(props) {
  const classes = ['page'];
  const storedReduced = localStorage.getItem('menu-reduced');
  const [reduced, setReduced] = useState(storedReduced === null ? false : (storedReduced === 'true'));
  const { name, PageSidebar, title, breadcrumb, actions, nocontent, className, ...rest } = props;
  if (name) classes.push(props.name);
  if (PageSidebar) classes.push('sidebar');
  if (reduced) classes.push('sidebar-reduced');
  if (nocontent) classes.push('nocontent');
  if (className) classes.push(className);

  const body = nocontent ? props.children : (
    <ContentBlock
      className="p-3"
      nocontent={nocontent}>
      {props.children}
    </ContentBlock>
  );

  const expandBtn = (
    <button
      type="button"
      className="btn-simplelink sidebar-expand"
      aria-controls="main-sidebar"
      aria-expanded={reduced ? 'false' : 'true'}
      onClick={() => { setReduced(v => !v); }}>
      <Icon name="chevron-left" title={reduced ? t`Expand` : t`Collapse`}/>
    </button>
  );

  useEffect(() => {
    window.localStorage.setItem('menu-reduced', reduced ? 'true' : 'false');
  }, [reduced]);

  return (
    <div className={classes.join(' ')} {...rest}>
      <NotificationsDisabled />
      <PageSidebar expandBtn={expandBtn} reduced={reduced} />
      <main className="flex-grow-1">
        <div className="px-2">
          { title && (
            <>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              <h1 className="main-title">{ title }</h1>
            </>
          ) }
          <div className="page-header">
            <Breadcrumb links={breadcrumb}/>
            { actions && (
              <Actions links={actions}/>
            ) }
          </div>
          { body }
        </div>
      </main>
    </div>
  );
}

export default Page;
