import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import SprintForm from 'components/forms/SprintForm';
import { setStateItem, useStateItem } from 'utils/Item';

function SprintModal(props) {
  const { pk } = props;
  if (pk) {
    return <SprintModalEdit {...props}/>;
  }
  return (
    <SprintModalContent sprint={{ product: null }} {...props}/>
  );
}

function SprintModalEdit(props) {
  const { pk } = props;
  const sprint = useStateItem('sprints:' + pk, true);
  if (!sprint) return null;
  return <SprintModalContent sprint={sprint} {...props}/>;
}

function SprintModalContent(props) {
  const { title, modalTitle, hideButton, variant,
    onClose, show, product, ...rest } = props;
  const [showModal, setShowModal] = useState(!!show);
  const handleClose = () => {
    if (onClose) onClose();
    setShowModal(false);
  };
  const handleSave = (spr) => {
    if (onClose) onClose(spr);
    setStateItem('sprints:' + spr.pk, spr, { isFull: true });
    setShowModal(false);
  };
  const handleShow = (e) => {
    setShowModal(true);
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <ModalForm
      variant={variant}
      show={showModal}
      handleClose={handleClose}
      handleShow={handleShow}
      title={modalTitle || t`New sprint`}
      buttonTitle={title || t`Add sprint`}
      hideButton={hideButton}>
      <SprintForm
        {...rest}
        product={product}
        onSave={handleSave}
        onCancel={handleClose}>
      </SprintForm>
    </ModalForm>
  );
}

export default SprintModal;
