import React from 'react';
import { t, Trans } from '@lingui/macro';
import { Helmet } from 'react-helmet-async';
import HomePage from 'components/page/HomePage';
import Breadcrumb from 'components/content/Breadcrumb';

export default function NotFoundPage(props) {
  const { breadcrumb } = props;
  return (
    <HomePage name="page-error">
      <Helmet>
        <title>{t`Not found`} – {t`Optera.io`}</title>
      </Helmet>
      <Breadcrumb links={breadcrumb}/>
      <h2 className="my-3"><Trans>Sorry, the content you are looking for cannot be found</Trans></h2>
      <p className="mt-3"><a href="/" className="btn btn-outline-light"><Trans>Go back to home</Trans></a></p>
    </HomePage>
  );
}
