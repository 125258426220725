import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link, useParams } from 'react-router-dom';
import ProductPage from 'components/page/ProductPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import { LoaderContainer } from 'components/ui/Loader';
import ContentBlock from 'components/content/ContentBlock';
import { addMessage } from 'components/ui/Messages';
import Sprint from 'entity/Sprint';
import { useStateItem } from 'utils/Item';
import getBacklogItems from 'entity/BacklogItems';
import BacklogItemTeaser from 'components/product/BacklogItemTeaser';
import SprintReviewForm from 'components/forms/SprintReviewForm';
import NotFoundPage from './NotFoundPage';

function SprintReviewPageContent(props) {
  const { sprint, product } = props;
  const [items, setItems] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    getBacklogItems(product.pk, {
      params: [
        ['subtasks', '-children'],
        ['sprint', sprint.pk],
        ['status', product.statuses.find(s => s.status_type.value === (sprint.status.value === 'closed' ? 'closed' : 'done')).pk],
      ]
    }).then((response) => {
      setItems(response);
    });
  }, [sprint.pk, product.pk, product.statuses, sprint.status]);

  const onSave = () => {
    setMessage(t`Sprint review saved`);
  };
  const onBeforeSave = () => {
    setMessage(null);
  };

  return (
    <div className="row">
      <div className="col-md-8">
        { message && (
          <div className="alert alert-info mb-3">
            { message }
          </div>
        ) }
        <ContentBlock className="p-3" title={t`Sprint review`}>
          <SprintReviewForm field="review" product={product} sprint={sprint} onBeforeSave={onBeforeSave} onSave={onSave} />
        </ContentBlock>
      </div>
      <div className="col-md-4">
        <ContentBlock className="p-3" title={t`Items done this sprint`}>
          { items === null ? (
            <LoaderContainer/>
          ) : items.map(item => (
            <div key={item.pk} className="card backlog-item backlog-item-teaser mb-2">
              <BacklogItemTeaser
                product={product}
                backlogItem={item}
                showStatus />
            </div>
          )) }
        </ContentBlock>

      </div>
    </div>
  );
}

export default function SprintReviewPage() {
  const { sprintPk } = useParams();
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);
  const [error, setError] = useState();
  const sprint = useStateItem('sprints:' + sprintPk, true);

  useEffect(() => {
    const s = new Sprint();
    s.load(sprintPk).catch(err => {
      if (err.response && err.response.status === 404) {
        setError(404);
      }
      else {
        addMessage('load-sprint', t`Unknown error`, t`Can't load sprint`);
      }
    });
  }, [product.pk, sprintPk]);

  if (error === 404) {
    return <NotFoundPage breadcrumb={breadcrumb}/>;
  }

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="3" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    <Link key="4" to={`/${organization.slug}/${product.slug}/sprints`}><Trans>Sprints</Trans></Link>,
  ];
  if (sprint) breadcrumb.push(<Link key="5" to={`/${organization.slug}/${product.slug}/sprints/${sprint.pk}`}>{ sprint.name }</Link>);
  breadcrumb.push(t`Review`);

  return (
    <ProductPage
      name="sprint-review-page"
      title={sprint ? (t`Sprint review` + ' -  ' + sprint.name) : t`Sprint review`}
      breadcrumb={breadcrumb}
      nocontent>
      { sprint ? (
        <SprintReviewPageContent sprint={sprint} product={product} />
      ) : (
        <ContentBlock className="p-3 kanban-top-content">
          <LoaderContainer height="3" static/>
        </ContentBlock>
      ) }
    </ProductPage>
  );
}
