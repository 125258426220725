import axios from 'axios';
import { setStateItem } from 'utils/Item';
import BacklogItem from './BacklogItem';

export default function getBacklogItems(productPk, settings) {
  const options = {
    state: true,
    subset: false,
    limit: 0,
    params: [],
    url: '/backlog-items',
    ...settings
  };

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams();
    for (let i = 0; i < options.params.length; i++) {
      params.append(options.params[i][0], options.params[i][1]);
    }
    if (productPk) {
      params.set('product', productPk);
    }
    if (options.subset) {
      params.set('subset', options.subset);
    } else if (options.limit) {
      params.set('subset', 'offset');
    }
    if (options.limit) {
      params.set('limit', options.limit);
    }
    const { url } = options;
    axios.get(url, { params: params }).then((response) => {
      const bl = [];
      for (let i = 0; i < response.data.items.length; i++) {
        const item = new BacklogItem();
        item.loadFromObject(response.data.items[i]);
        if (options.state) {
          setStateItem('backlog-items:' + item.pk, item, { refreshParent: false });
        }
        bl.push(item);
      }
      resolve(bl);
    }).catch((error) => { reject(error); });
  });
}
