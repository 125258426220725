import React, { useContext, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { Loader, LoaderContainer } from 'components/ui/Loader';
import Avatar from 'components/ui/Avatar';
import Icon from 'components/ui/Icon';
import DropdownEditable, { getOptionsFromAPI } from 'components/form/DropdownEditable';
import { addMessage } from 'components/ui/Messages';
import DateBlock from 'components/ui/DateBlock';
import { setStateItem } from 'utils/Item';
import AppContext from 'AppContext';

function MemberModalContent(props) {
  const { member, organization, user } = props;
  const [sendInvitLoading, setSendInvitLoading] = useState(false);
  const { lang } = useContext(AppContext);
  const fields = [
    { field: 'email', title: t`Email` },
    { field: 'private_phone', title: t`Private phone` },
    { field: 'professional_phone', title: t`Professional phone` },
    { field: 'mobile_phone', title: t`Mobile phone` },
  ];

  const onSendInvitation = () => {
    setSendInvitLoading(true);
    axios.put(`/members/${member.pk}/send-invitation-email`)
      .then((response) => {
        addMessage('send-invitation-success', t`Success`, response.data.meta.message);
        member.user.date_invited = response.data.user.date_invited;
        setSendInvitLoading(false);
      }).catch((error) => {
        addMessage('send-invitation-error', t`Server error`, error.response.data.message);
        setSendInvitLoading(false);
      });
  };

  return (
    <div className="row">
      <div className="col-12 col-sm-2">
        <Avatar user={member.user}/>
      </div>
      <div className="col-12 col-sm-10">
        <ul className="list-group list-group-flush">
          { member.user.is_active && (
            <li className="list-group-item px-0">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-4">
                  <strong><Trans>Date joined</Trans></strong>
                </div>
                <div className="col-12 col-md-7 col-lg-8 mt-2 mt-md-0">
                  <DateBlock datestring={member.date_joined}/>
                </div>
              </div>
            </li>
          ) }
          { fields.map(field => (member.user[field.field] ? (
            <li key={field.field} className="list-group-item px-0">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-4">
                  <strong>{ field.title }</strong>
                </div>
                <div className="col-12 col-md-7 col-lg-8 mt-2 mt-md-0">
                  { member.user[field.field] }
                </div>
              </div>
            </li>
          ) : null))}
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <strong><Trans>Role</Trans></strong>
              </div>
              <div className="col-12 col-md-7 col-lg-8 mt-2 mt-md-0">
                { organization.can_edit_member(member, user) ? (
                  <DropdownEditable
                    pk={member.pk}
                    api="members"
                    getOptions={
                      getOptionsFromAPI('member-roles-options-' + lang.pk, '/members/meta', 'role')
                    }
                    variant="light rounded-pill"
                    field="role"
                    fieldName={t`Role`}
                    fieldValue="value"
                    fieldLabel="label"
                    required />
                ) : (
                  <span className="badge bg-light me-1">{ member.role.label }</span>
                ) }
              </div>
            </div>
          </li>
          <li className="list-group-item px-0">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4">
                <strong></strong>
              </div>
              <div className="col-12 col-md-7 col-lg-8 mt-2 mt-md-0">
                { !member.user.is_active && (
                  <>
                    <p>
                      <Trans>Inactive</Trans>
                    </p>
                    <p>
                      <Trans>Date invited</Trans>: <DateBlock datestring={member.user.date_invited}/><br />
                      <button onClick={onSendInvitation} type="button" className="btn btn-outline-dark mt-2">
                        <Trans>Resend invitation</Trans> <Icon name="envelope"/>
                        { sendInvitLoading && (
                          <Loader size="tiny"/>
                        ) }
                      </button>
                    </p>
                  </>
                ) }
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default function MemberModal(props) {
  const { member, hideModal, ...rest } = props;
  const [apiMember, setApiMember] = useState(null);

  useEffect(() => {
    axios.get('/members/' + member.pk).then(res => {
      setStateItem('members:' + member.pk, res.data);
      setApiMember(res.data);
    }).catch(err => {
      addMessage('load-member', t`Unknown error`, t`Can't load member`);
    });
  }, [member.pk]);

  if (!member) return null;

  return (
    <Modal
      enforceFocus={false}
      size="lg"
      show
      onHide={hideModal}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>
          { member.resource_name }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { (!apiMember) ? (
          <LoaderContainer height="4"/>
        ) : (
          <MemberModalContent member={apiMember} {...rest} />
        )}
      </Modal.Body>
    </Modal>
  );
}
