import React, { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';

function slugify(str, separator = '-') {
  return str
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')
    .replace(/\s+/g, separator);
}
function ImportBindingFinalForm(props) {
  const { onSave, organization, product, fieldsNames, examples, isNotReady } = props;

  return (
    <Form
      name="items-import-binding-form"
      onSave={onSave}
      isNotReady={isNotReady}>
      { fieldsNames.map(name => (
        <div key={name} className="select-group">
          <Form.Select id={name} name={name} required />
          { (name in examples) && examples[name].map((ex) => (
            <div className="example" key={ex}>{ ex }{' '}</div>
          )) }
        </div>
      )) }
      <div id="form-group-jira-key" className="form-group">
        <input type="submit" className="btn btn-primary me-2" value={t`Save and import`} />
        <Link
          to={`/${organization.slug}/${product.slug}/items`}
          className="btn btn-outline-secondary"><Trans>Cancel</Trans></Link>
      </div>
    </Form>
  );
}

const ImportBindingForm = function(props) {
  const { binding, organization, product, importPk } = props;
  const [isNotReady, setIsNotReady] = useState(false);
  const history = useHistory();

  const fieldsNames = [];
  const getFieldName = (name) => {
    const slugPart = slugify(name, '_');
    let slugExtra = 0;
    while (fieldsNames.includes(slugPart + (slugExtra > 0 ? ('_' + slugExtra) : ''))) {
      slugExtra += 1;
    }
    const slug = slugPart + (slugExtra > 0 ? ('_' + slugExtra) : '');
    return slug;
  };
  const meta = {
    fields: {}
  };
  const opts = [
    { value: 'NONE', label: t`Ignored` },
    ...binding.fields.map(f => ({
      value: f.key,
      label: f.label
    }))
  ];
  const item = {};
  const examples = {};
  for (let i = 0; i < binding.binding.length; i++) {
    const name = getFieldName(binding.binding[i].value);
    fieldsNames.push(name);
    meta.fields[name] = {
      name: binding.binding[i].value,
      choices: opts
    };
    const ex = [];
    for (let j = 0; j < 3; j++) {
      if (j in binding.lines && i in binding.lines[j]) {
        ex.push(binding.lines[j][i]);
      }
      else {
        ex.push('');
      }
    }
    examples[name] = ex;
    item[name] = binding.binding[i].field ? opts.find(o => o.value === binding.binding[i].field.key) : { value: 'NONE', label: t`Ignored` };
  }
  const onSave = (data, created, submitter) => {
    setIsNotReady(true);
    const bind = [];
    for (const fieldName in meta.fields) {
      const row = data[fieldName];
      bind.push({
        value: meta.fields[fieldName].name,
        field: row.value === 'NONE' ? null : row.value
      });
    }
    axios.put(`backlog-item-imports/${importPk}/start`, bind).then((res) => {
      setIsNotReady(false);
      history.push(`/${organization.slug}/${product.slug}/items`);
    }).catch((err) => {
      setIsNotReady(false);
      addMessage('submit-binding-import', t`Unknown error`, t`Impossible to begin import`);
    });
  };
  return (
    <FormContextProvider meta={meta} item={item}>
      <ImportBindingFinalForm
        examples={examples}
        fieldsNames={fieldsNames}
        onSave={onSave}
        organization={organization}
        product={product}
        isNotReady={isNotReady} />
    </FormContextProvider>
  );
};

export default ImportBindingForm;
