import axios from 'axios';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import OrganizationContext from 'components/utils/OrganizationContext';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';
import ProductContext from 'components/utils/ProductContext';
import SelectSearch from 'components/ui/SelectSearch';
import ProductPage from 'components/page/ProductPage';
import { setStateItem } from 'utils/Item';
import { Loader, LoaderContainer } from 'components/ui/Loader';

function AccountRow(props) {
  const { product, account } = props;
  const { allowed_accounts } = product;
  const [loading, setLoading] = useState(false);

  const onDeleteClick = (e) => {
    e.preventDefault();
    setLoading(true);
    const accounts = allowed_accounts.filter(acc => acc.pk !== account.pk);
    axios.put(`${product._genericApi}/${product.pk}`, { allowed_accounts: accounts })
      .then(response => {
        setStateItem(product._getStateKey(), response.data, { isFull: true });
        setLoading(false);
      })
      .catch((error) => {
        addMessage('product-accounts', t`Unknown error`, t`Impossible to update product`);
      });
  };

  return (
    <tr>
      <td className="align-middle" data-cy="account-name">{ account.resource_name }</td>
      <td>
        <button
          onClick={onDeleteClick}
          type="button"
          className="btn">
          <Icon name="x-circle" title={t`Delete`}></Icon>
        </button>
        { loading && (
          <Loader size="tiny" />
        ) }
      </td>
    </tr>
  );
}
function AccountList(props) {
  const { product } = props;
  const { allowed_accounts } = product;

  if (!allowed_accounts || allowed_accounts.length < 1) {
    return (
      <div className="mt-4 alert alert-secondary" role="alert">
        <Trans>No accounts selected</Trans>
      </div>
    );
  }
  return (
    <table className="table">
      <tbody>
        { allowed_accounts.map(account => (
          <AccountRow key={account.pk} product={product} account={account} />
        ))}
      </tbody>
    </table>
  );
}
function ProductAccountListContent(props) {
  const { organization, product } = props;
  const [loading, setLoading] = useState(false);

  const onValidateSelect = (prevState) => ({
    opened: false,
    isValid: true,
  });
  const handleSelect = (selectedValue, state) => {
    const allowed_accounts = Array.isArray(product.allowed_accounts) ? [...product.allowed_accounts] : [];
    const account = state.options.find(el => el.pk === state.preselection);
    allowed_accounts.push(account);
    setLoading(true);
    axios.put(`${product._genericApi}/${product.pk}`, { allowed_accounts: allowed_accounts })
      .then(response => {
        setStateItem(product._getStateKey(), response.data, { isFull: true });
        setLoading(false);
      })
      .catch((error) => {
        addMessage('product-accounts', t`Unknown error`, t`Impossible to update product`);
      });
  };

  return (
    <div className="relative">
      { loading && (
        <LoaderContainer />
      ) }

      <div className="row">
        <div className="col col-12 col-md-4">
          <h4><Trans>Add an account</Trans></h4>
          <SelectSearch
            onValidate={onValidateSelect}
            handleSelect={handleSelect}
            refreshKey={product.allowed_accounts.map(a => a.pk).join('-')}
            searchApi={`/accounts?organization=${organization.pk}&already_on_product=${product.pk}`}
            validation={false}
            id="account-search"
            dMenu="w-260"
            autoSelect={false}
            required>
          </SelectSearch>
          <p className="mt-3">
            <Trans>
              Lists the active accounts for this product. If no account is selected, all accounts will be active.
            </Trans>
          </p>
        </div>
        <div className="col col-12 col-md-8">
          <AccountList product={product} />
        </div>
      </div>
    </div>

  );
}

export default function ProductAccountListPage(props) {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Accounts`
  ];

  return (
    <ProductPage
      name="product-accounts-page"
      title={t`Accounts`}
      breadcrumb={breadcrumb}>
      <ProductAccountListContent
        organization={organization}
        product={product} />
    </ProductPage>
  );
}
