/* eslint-disable no-lonely-if */
import { useEffect, useState } from 'react';

const statusCollection = {};

function mergeData(data, value, key) {
  let isModified = false;
  if (Array.isArray(value)) {
    if (value.length !== data[key].length) {
      data[key] = value;
      return true;
    }
    for (let i = 0; i < value.length; i++) {
      isModified = isModified || mergeData(data[key], value[i], i);
    }
  }
  else if (typeof value === 'object' && value !== null) {
    if (key && !data[key]) {
      data[key] = value;
      return true;
    }
    for (const attr in value) {
      if (key !== null) {
        if (!(attr in data[key])) {
          data[key][attr] = value[attr];
          isModified = true;
        }
        else {
          isModified = mergeData(data[key], value[attr], attr) || isModified;
        }
      }
      else {
        if (!(attr in data)) {
          data[attr] = value[attr];
          isModified = true;
        }
        else {
          isModified = mergeData(data, value[attr], attr) || isModified;
        }
      } 
    }
  }
  else {
    if (!(key in data) || data[key] !== value) {
      data[key] = value;
      return true;
    }
  }
  return isModified;
}

function refresh(key, refreshParent) {
  const [entity, pk] = key.split(':');
  for (const setV of statusCollection[key].refreshs) {
    setV(v => v + 1);
  }
  if (pk && (entity in statusCollection) && refreshParent) {
    statusCollection[entity].data += 1;
    for (const setV of statusCollection[entity].refreshs) {
      setV(v => v + 1);
    }
  }
}

function getStateItem(key, defaultValue, options) {
  if (!(key in statusCollection) || !('data' in statusCollection[key])) {
    return defaultValue;
  }
  if (options && ('isFull' in options)) {
    if (!statusCollection[key].isFull === options.isFull) {
      return defaultValue;
    }
  }
  return statusCollection[key].data;
}

function setStateItem(key, value, opts) {
  const defaults = { isFull: false, replace: false, refreshParent: true };
  const options = opts ? { ...defaults, ...opts } : defaults;
  const { isFull, replace, refreshParent } = options;
  if (!(key in statusCollection)) {
    register(key);
  }
  if (isFull) statusCollection[key].isFull = true;
  if (value === null) {
    unregister(key);
    refresh(key, refreshParent);
  }
  else if (!('data' in statusCollection[key]) || replace) {
    statusCollection[key].data = value.call ? value(null) : value;
    refresh(key, refreshParent);
  }
  else if (isFull && value.constructor.name !== 'Object') {
    statusCollection[key].data = value.call ? value(statusCollection[key].data) : value;
    refresh(key, refreshParent);
  }
  else {
    const val = value.call ? value(statusCollection[key].data) : value;
    if (typeof val === 'object') {
      const isModified = mergeData(statusCollection[key].data, val, null);
      if (isModified) {
        refresh(key, refreshParent);
      }
    }
    else {
      statusCollection[key].data = val;
      refresh(key, refreshParent);
    }
  }
  
}

function register(key, setRefresh) {
  // eslint-disable-next-line no-unused-vars
  const [entity, pk] = key.split(':');
  if (!(key in statusCollection)) {
    statusCollection[key] = {
      refreshs: []
    };
    if (!pk) {
      statusCollection[key].data = 1;
    }
  }
  if (setRefresh) statusCollection[key].refreshs.push(setRefresh);
}

function unregister(key, setRefresh) {
  if (!(key in statusCollection)) {
    return;
  }
  const ind = statusCollection[key].refreshs.findIndex(el => el === setRefresh);
  if (ind !== -1) statusCollection[key].refreshs.splice(ind, 1);

  if (statusCollection[key].refreshs.length < 1) {
    // @todo check if commenting next line doesn't create memory leak issues
    // delete statusCollection[key];
  }
}

function useStateItem(key, isFull) {
  const [, setRefresh] = useState(0);
  useEffect(() => {
    register(key, setRefresh);
    return () => {
      unregister(key, setRefresh);
    };
  }, [key]);

  if (!(key in statusCollection) || !('data' in statusCollection[key])) {
    const [, pk] = key.split(':');
    return pk ? null : 1;
  }
  if (isFull && (!('isFull' in statusCollection[key]) || !statusCollection[key].isFull)) {
    return null;
  }
  return statusCollection[key].data;
}

export { getStateItem, setStateItem, useStateItem };
