import { Trans } from '@lingui/macro';
import React, { useContext } from 'react';
import { Alert } from 'react-bootstrap';
import OrganizationContext from './OrganizationContext';

function ReadonlyWarning(props) {
  const organization = useContext(OrganizationContext);
  if (organization.is_active) return null;
  if (organization.inactive_reasons.includes('max_users_reached')) {
    return (
      <Alert variant="warning"><Trans>
        Your organization is read-only because the number of users
        is higher than the number allowed by your subscription.
      </Trans></Alert>
    );
  }
  if (organization.inactive_reasons.includes('max_guests_reached')) {
    return (
      <Alert variant="warning"><Trans>
        Your organization is read-only because the number of guests
        is higher than the number allowed by your subscription.
      </Trans></Alert>
    );
  }
  return (
    <Alert variant="warning"><Trans>
      This organization is readonly because it has no active subscription.
    </Trans></Alert>
  );
}

export default ReadonlyWarning;
