import { t } from '@lingui/macro';
import React from 'react';
import { ActiveFilters, FiltersDropdown, normalizeFilters } from 'components/filters/Filters';

function KanbanSearch(props) {
  const { product } = props;
  const filters = {
    dropdown: [],
    base: {
      extra: [
        {
          api: '/item-types?product=' + product.pk,
          name: 'item_type',
          label: t`Item type`
        },
        {
          api: '/members?organization=' + product.organization.pk,
          name: 'assignee',
          label: t`Assignee`
        },
        {
          api: '/epics?product=' + product.pk,
          name: 'epic',
          label: t`Epic`
        },
      ],
      check: [],
      autocomplete: [
        {
          is_group: false,
          label: t`Tags`,
          field: 'tags_and',
          type: 'autocomplete',
          api: '/tags/' + product.organization.resource_slug
        },
      ],
    }
  };
  if (product.can_see_estimates) {
    filters.base.check.push({
      name: 'show_eval',
      label: t`Show estimates`,
      inverse: true
    });
  }
  normalizeFilters(filters);

  return (
    <div className="kanban-search filters">
      <FiltersDropdown availableFilters={filters}/>
      <ActiveFilters availableFilters={filters} />
    </div>
  );
}

export default KanbanSearch;
