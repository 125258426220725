import React from 'react';
import * as BS from 'react-bootstrap';
import { Trans } from '@lingui/macro';

export default function NoDataAlert(props) {
  return (
    <BS.Alert className="mt-3" variant="secondary">
      <BS.Alert.Heading><Trans>No data found</Trans></BS.Alert.Heading>
      <ul>
        <li>
          {/* TODO: display only if there a search filter
              https://app.optera.io/opteraio/opteraio/item/OPT-868 */}
          <Trans>
            You can clear or change the search filter to see more data.
          </Trans>
        </li>
        {props.onCreate ? (
          <li>
            <Trans>
              You can create new data with the <strong>create</strong> button.
            </Trans>
          </li>
        ) : ''}
      </ul>
    </BS.Alert>
  );
}
