import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { t, Trans } from '@lingui/macro';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import BacklogItemTeaser from 'components/product/BacklogItemTeaser';
import getBacklogItems from 'entity/BacklogItems';
import Icon from 'components/ui/Icon';
import SelectSearch from 'components/ui/SelectSearch';
import BacklogItem from 'entity/BacklogItem';
import { ColorSticker } from 'components/ui/ColorSticker';

const Epic = function(props) {
  const { epic, product } = props;
  const [items, setItems] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const viewSetter = React.createRef();
  viewSetter.current = {
    show: null,
    onUpdate: () => {
      setRefresh(ref => ref + 1);
    }
  };

  useEffect(() => {
    getBacklogItems(product.pk, { params: [['epic', epic.pk]] }).then(response => {
      setItems(response);
    }).catch(err => {
      addMessage('load-epics-items', t`Unknown error`, t`Impossible to load backlog items`);
    });
  }, [epic, refresh, product]);

  if (items === null) {
    return (
      <LoaderContainer height="4"/>
    );
  }

  const handleSelect = (option) => {
    if (option.epic) {
      if (option.epic.pk === epic.pk) {
        setMessage(t`You can't add the same epic multiple times`);
      }
      else {
        setMessage(t`This backlog item already has an epic: ${option.epic.resource_name}`);
      }
    }
    else {
      setLoading(true);
      axios.put('/backlog-items/' + option.pk, { epic: epic }).then(response => {
        setMessage(null);
        setLoading(false);
        const bl = new BacklogItem();
        bl.loadFromObject(response.data);
        setItems(list => [...list, bl]);
      }).catch(err => {
        setMessage(t`Impossible to add backlog item`);
        setLoading(false);
      });
    }
  };

  const handleRemove = item => {
    setLoading(true);
    axios.put('/backlog-items/' + item.pk, { epic: null }).then(response => {
      setMessage(null);
      setLoading(false);
      setItems(items.filter(it => it.pk !== item.pk));
    }).catch(err => {
      setMessage(t`Impossible to remove backlog item`);
      setLoading(false);
    });
  };
  return (
    <div className="relative">
      { loading && (
        <LoaderContainer/>
      ) }
      { items.map(item => (
        <div className="hstack mb-1" key={item.pk}>
          <div className="card flex-grow-1 backlog-item-teaser backlog-item-normal">
            <BacklogItemTeaser
              product={product}
              layout="normal"
              backlogItem={item}
              hideMore />
          </div>
          { product.can_edit_item && (
            <button onClick={() => handleRemove(item)} type="button" className="ms-3 btn btn-outline-secondary">
              <Icon name="x-circle"/> <Trans>Remove</Trans>
            </button>
          ) }
        </div>

      )) }
      { message && (
        <div className="my-2 alert alert-warning" role="alert">
          { message }
        </div>
      ) }
      <div className="mt-3">
        { product.can_edit_item && (
          <SelectSearch
            value={null}
            handleSelect={handleSelect}
            searchApi={'/backlog-items?product=' + product.pk}
            validation={false}
            id="Add backlogitem"
            buttonText={t`Add another backlog item`}
            required />
        ) }
      </div>
    </div>
  );
};
export default function EpicViewModal(props) {
  const { epic, onClose, show, product, organization } = props;

  const handleClose = () => {
    if (onClose) onClose();
  };

  if (!epic) return null;
  return (
    <Modal enforceFocus={false} size="lg" show={show} onHide={handleClose} className="epic-modal epic-full">
      <Modal.Header closeButton>
        <Modal.Title className="backlog-title">
          <ColorSticker color={epic.color} />
          { epic.name }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Epic epic={epic} product={product} organization={organization}/>
      </Modal.Body>
    </Modal>
  );
}
