import React, { useState } from 'react';
import axios from 'axios';
import { t } from '@lingui/macro';
import { FormCheck, OverlayTrigger, Popover, Form } from 'react-bootstrap';
import { LoaderContainer } from 'components/ui/Loader';
import { getStateItem, setStateItem } from 'utils/Item';
import { manageFormErrors } from '../form/Form';

function Checkbox(props) {
  const {
    value,
    notification,
    type,
    label,
    onChange,
    ...rest
  } = props;

  const handleChange = event => {
    const { checked } = event.target;
    if (onChange) onChange(notification, type, checked);
  };

  return (
    <FormCheck
      id={`notification-${notification}-${type}`}
      checked={value}
      onChange={handleChange}
      label={label}
      className="me-2 d-inline-block"
      {...rest} />
  );
}

function CheckboxGroup(props) {
  const {
    user,
    notification,
    label,
    onChange,
  } = props;

  return (
    <div className="row">
      <div className="col-12 col-md-3">
        <Form.Label>{label}</Form.Label>
      </div>
      <div className="col-12 col-md-9">
        <Checkbox
          notification={notification}
          type="email"
          checked={user.notification_configuration[notification].email}
          onChange={onChange}
          label={t`email`}
          className="me-2 d-inline-block" />
        <Checkbox
          notification={notification}
          type="internal"
          checked={user.notification_configuration[notification].internal}
          onChange={onChange}
          label={t`internal`}
          className="me-2 d-inline-block" />
        <Checkbox
          notification={notification}
          type="desktop"
          checked={user.notification_configuration[notification].desktop}
          onChange={onChange}
          label={t`desktop`}
          className="me-2 d-inline-block" />
      </div>
    </div>
  );
}

function NotificationConfiguration(props) {
  const {
    user_pk,
  } = props;

  const finalStateKey = `users:${user_pk}`;
  const storedItem = getStateItem(finalStateKey);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!storedItem) return null;
  const user = JSON.parse(JSON.stringify(storedItem));

  const handleChange = (notification, type, checked) => {
    user.notification_configuration[notification][type] = checked;
    setLoading(true);
    axios.put(`users/${user.pk}`, user).then(res => {
      user.notification_configuration = res.data.notification_configuration;
      setStateItem(finalStateKey, res.data, { isFull: true });
      setLoading(false);
    }).catch(err => {
      const { globalErrors } = manageFormErrors(null, err, true);
      setError(globalErrors.map(e => e.error).join(<br/>));
      setLoading(false);
    });
  };

  return (
    <OverlayTrigger
      show={error !== null}
      placement="right"
      overlay={(
        <Popover id="checkbox-item-button-popover">
          <Popover.Body>
            { error }
          </Popover.Body>
        </Popover>
      )}>
      {({ ref, ...triggerHandler }) => (
        <div className="relative" {...triggerHandler} ref={ref}>
          <CheckboxGroup
            user={user}
            label={t`System`}
            notification="system"
            onChange={handleChange}/>
          <CheckboxGroup
            user={user}
            label={t`Assignation`}
            notification="assignment"
            onChange={handleChange}/>
          <CheckboxGroup
            user={user}
            label={t`Mention`}
            notification="mention"
            onChange={handleChange}/>
          <CheckboxGroup
            user={user}
            label={t`Importation`}
            notification="importation"
            onChange={handleChange}/>
          <CheckboxGroup
            user={user}
            label={t`Timetracking`}
            notification="timetracking"
            onChange={handleChange}/>
          { loading && (
            <LoaderContainer size="tiny" />
          ) }
        </div>
      )}
    </OverlayTrigger>
  );

}

export default NotificationConfiguration;
