import { Trans, t } from '@lingui/macro';
import AppContext from 'AppContext';
import axios from 'axios';
import OtpDisableForm from 'components/forms/OtpDisableForm';
import OtpValidateForm from 'components/forms/OtpValidateForm';
import ModalForm from 'components/modal/ModalForm';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import React, { useContext, useEffect, useState } from 'react';

function OtpSettingsConfirm(props) {
  const { setEnabling } = props;
  const { user, setUser } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [setupUri, setSetupUri] = useState();
  useEffect(() => {
    axios.get('auth/otp/setup-uri').then(response => {
      setSetupUri(response.data.setup_uri);
      setLoading(false);
    }).catch(() => {
      addMessage('otp-enable', t`Unknown error`, t`Impossible to enable two-factor authentication.`);
      setEnabling(false);
    });
  }, [setEnabling]);

  if (loading) {
    return (
      <LoaderContainer height="1"/>
    );
  }

  const onClose = () => { setEnabling(false); };
  const onSave = () => {
    onClose();
    const newUser = { ...user };
    newUser.is_otp = true;
    setUser(newUser);
  };
  return (
    <>
      <LoaderContainer height="1"/>
      <ModalForm
        handleClose={onClose}
        title={t`Validate code`}
        show
        hideButton>
        <OtpValidateForm onSave={onSave} onCancel={onClose} setupUri={setupUri}></OtpValidateForm>
      </ModalForm>
    </>
  );
}

function OtpSettingsDisableConfirm(props) {
  const { setDisabling } = props;
  const { user, setUser } = useContext(AppContext);
  const onClose = () => { setDisabling(false); };
  const onSave = () => {
    onClose();
    const newUser = { ...user };
    newUser.is_otp = false;
    setUser(newUser);
  };
  return (
    <>
      <LoaderContainer height="1"/>
      <ModalForm
        handleClose={onClose}
        title={t`Validate code`}
        show
        hideButton>
        <OtpDisableForm onSave={onSave} onCancel={onClose} />
      </ModalForm>
    </>
    
  );
}

function OtpSettingsDisable(props) {
  const [disabling, setDisabling] = useState(false);
  if (disabling) {
    return <OtpSettingsDisableConfirm setDisabling={setDisabling} />;
  }
  return (
    <button onClick={() => { setDisabling(true); }} type="button" className="btn btn-outline-secondary">
      <Trans>Disable</Trans>
    </button>
  );
}

function OtpSettingsEnable(props) {
  const [enabling, setEnabling] = useState(false);
  if (enabling) {
    return <OtpSettingsConfirm setEnabling={setEnabling} />;
  }
  return (
    <button onClick={() => { setEnabling(true); }} type="button" className="btn btn-outline-secondary">
      <Trans>Enable</Trans>
    </button>
  );
}

export default function OtpSettings(props) {
  const { user } = props;
  if (user.is_otp) return <OtpSettingsDisable />;
  return (
    <OtpSettingsEnable />
  );
}
