import axios from 'axios';
import { setStateItem } from 'utils/Item';
import BacklogItem from './BacklogItem';
import Organization from './Organization';
import Product from './Product';

export default function getSearchResults(settings) {
  const options = {
    state: true,
    orgs_limit: 2,
    products_limit: 3,
    items_limit: 6,
    params: {},
    search: '',
    url: '/search',
    ...settings
  };

  return new Promise((resolve, reject) => {
    const params = new URLSearchParams();
    for (const p in options.params) {
      params.append(p, options.params[p]);
    }
    if (options.orgs_limit) {
      params.set('orgs_limit', options.orgs_limit);
    }
    if (options.products_limit) {
      params.set('products_limit', options.products_limit);
    }
    if (options.items_limit) {
      params.set('items_limit', options.items_limit);
    }
    params.set('value', options.search);
    let { url } = options;
    url += '?' + params.toString();
    const itemsTypes = {
      orgs: Organization,
      products: Product,
      items: BacklogItem
    };
    axios.get(url).then((response) => {
      const res = { orgs: [], products: [], items: [], order: [] };
      const order = [];
      for (const type in itemsTypes) {
        for (let i = 0; i < response.data[type].length; i++) {
          const item = new itemsTypes[type]();
          item.loadFromObject(response.data[type][i]);
          if (options.state) {
            setStateItem(item._getStateKey(), item, { refreshParent: false });
          }
          res[type].push(item);
        }
        order.push({
          name: type,
          weight: response.data[type].length > 0 ? res[type][0].search_rank : 0
        });
      }
      res.order = order.sort((a, b) => b.weight - a.weight).map(el => el.name);
      resolve(res);
    }).catch((error) => { reject(error); });
  });
}
