import React, { useCallback, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import ContentBlock from 'components/content/ContentBlock';
import Icon from 'components/ui/Icon';
import { useStateItem } from 'utils/Item';

function SortTh(props) {
  const { children, sortName, name, setSort, sortDir } = props;
  return (
    <th className={name === 'title' ? '' : 'text-end'}>
      <button className="btn btn-simplelink" type="button" onClick={() => setSort(name)}>
        { children }{' '}
        { sortName === name && (
          <Icon name={sortDir === 'ASC' ? 'arrow-down-circle' : 'arrow-up-circle'}/>
        ) }
      </button>
    </th>
  );
}

function StatsTable(props) {
  const { type, title, data, product } = props;
  const [sortName, setSortName] = useState('title');
  const [sortDir, setSortDir] = useState('ASC');

  const setSort = useCallback(function(name) {
    if (sortName === name) {
      setSortDir(sortDir === 'ASC' ? 'DESC' : 'ASC');
    }
    else {
      setSortName(name);
      setSortDir('ASC');
    }
  }, [sortDir, sortName]);

  const getSortVal = (val) => {
    if (sortName === 'title') {
      return val[type] ? val[type].resource_name : '';
    }
    if (sortName === 'work') {
      return val.sum_worklog_hours ? val.sum_worklog_hours.minutes : 0;
    }
    if (sortName === 'eval') {
      if (product.estimate_mode.value === 'hours') {
        return val.sum_hours_estimate ? val.sum_hours_estimate.minutes : 0;
      }
      return val.sum_points_estimate;
    }
    return val[sortName];
  };

  data.sort((a, b) => {
    const val1 = getSortVal(a);
    const val2 = getSortVal(b);
    if (val1 > val2) {
      return sortDir === 'ASC' ? 1 : -1;
    }
    if (val1 < val2) {
      return sortDir === 'ASC' ? -1 : 1;
    }
    return 0;
  });

  return (
    <table className="table table-sm-responsive">
      <thead>
        <tr>
          <SortTh sortName={sortName} name="title" setSort={setSort} sortDir={sortDir}>
            { title }
          </SortTh>
          <SortTh sortName={sortName} name="count_items" setSort={setSort} sortDir={sortDir}>
            <Trans context="small">Items</Trans>
          </SortTh>
          <SortTh sortName={sortName} name="sum_business_value" setSort={setSort} sortDir={sortDir}>
            <Trans>B.V.</Trans>
          </SortTh>
          <SortTh sortName={sortName} name="work" setSort={setSort} sortDir={sortDir}>
            <Trans>Work</Trans>
          </SortTh>
          <SortTh sortName={sortName} name="eval" setSort={setSort} sortDir={sortDir}>
            <Trans>Eval.</Trans>
          </SortTh>
        </tr>
      </thead>
      <tbody>
        {data.map((stat) => (
          <tr
            key={stat[type] ? stat[type].pk : 'none'}
            data-cy={`${type}_${(stat[type] ? stat[type].resource_name : 'none')}`}>
            <td className="no-title">
              { stat[type] ? (
                <span className={`badge bg-col-${stat[type].color || 'white'} epic me-2`}>{ stat[type].resource_name }</span>
              ) : t`None` }
            </td>
            <td className="text-end" data-cy="count_items" data-title={t`Items`}>
              { stat.count_items }
            </td>
            <td className="text-end" data-cy="business_value" data-title={t`B.V.`}>{ stat.sum_business_value || '0' }</td>
            <td className="text-end" data-cy="work_hours" data-title={t`Work`}>
              { stat.sum_worklog_hours ? stat.sum_worklog_hours.human_format : '0h' }
            </td>
            <td className="text-end" data-cy="estimate" data-title={t`Eval.`}>
              { product.estimate_mode.value === 'hours' ? (
                stat.sum_hours_estimate ? stat.sum_hours_estimate.human_format : '0h'
              ) : (
                stat.sum_points_estimate ? stat.sum_points_estimate : '0'
              ) }
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default function SprintStats(props) {
  const { refresh, product, sprint } = props;
  const [currentSprint, setCurrentSprint] = useState(sprint);
  const [error, setError] = useState(null);
  const [epicsItems, setEpicsItems] = useState(null);
  const [typesItems, setTypesItems] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const refr = useStateItem('backlog-items');

  useEffect(() => {
    if (!currentSprint && product.mode.value === 'scrum') {
      axios.get('/sprints?meta=&current=true&product=' + product.pk).then(res => {
        if (res.data.items.length < 1) {
          setCurrentSprint(null);
        }
        else {
          setCurrentSprint(res.data.items[0]);
        }
      }).catch(err => {
        setError(t`Impossible to show statistics`);
      });
    }
  }, [product.pk, product.mode.value, currentSprint]);

  useEffect(() => {
    if (currentSprint) {
      axios.get(`/backlog-items/statistics?sprint=${currentSprint.pk}&group_by=epic`).then((response) => {
        setEpicsItems(response.data.items);
      }).catch((err) => { setError(t`Impossible to show statistics`); });
      axios.get(`/backlog-items/statistics?sprint=${currentSprint.pk}&group_by=item_type`).then((response) => {
        setTypesItems(response.data.items);
      }).catch((err) => { setError(t`Impossible to show statistics`); });
    }
    else if (product.mode.value === 'kanban') {
      axios.get(`/backlog-items/statistics?${product.getStatusParams(['todo', 'in_progress', 'done'])}&group_by=epic`).then((response) => {
        setEpicsItems(response.data.items);
      }).catch((err) => { setError(t`Impossible to show statistics`); });
      axios.get(`/backlog-items/statistics?${product.getStatusParams(['todo', 'in_progress', 'done'])}&group_by=item_type`)
        .then((response) => {
          setTypesItems(response.data.items);
        }).catch((err) => { setError(t`Impossible to show statistics`); });
    }
  }, [currentSprint, product, refresh, refr]);

  if (((!currentSprint && product.mode.value === 'scrum') || !typesItems || !epicsItems) && !error) {
    return null;
  }
  const actions = [];
  if (!error) {
    actions.push(
      <button
        key="view-more"
        type="button"
        className="btn btn btn-outline-secondary"
        onClick={() => setViewMore(!viewMore)}>
        { viewMore ? t`Hide` : t`View more` }
      </button>
    );
  }
  return (
    <ContentBlock
      title={product.mode.value === 'scrum' ? (currentSprint.resource_name + ' / ' + t`Items statistics`) : t`Opened items statistics`}
      className="small-head mb-2 p-3 sprint-stats"
      actions={actions}>
      {epicsItems && epicsItems.map(stat => (stat.epic ? (
        <span key={stat.epic.pk} className={`badge bg-col-${stat.epic.color || 'white'} epic me-2 mb-1`}>
          { stat.epic.resource_name }
          <span className="badge bg-light rounded-pill ms-2">{ stat.count_items }</span>
        </span>
      ) : null))}
      {typesItems && typesItems.map(stat => (stat.item_type ? (
        <span key={stat.item_type.pk} className={`badge bg-col-${stat.item_type.color || 'white'} epic me-2 mb-1`}>
          { stat.item_type.resource_name }
          <span className="badge bg-light rounded-pill ms-2">{ stat.count_items }</span>
        </span>
      ) : null))}
      { viewMore && (
        <>
          <StatsTable type="item_type" title={t`Item types`} data={typesItems} product={product} />
          <StatsTable type="epic" title={t`Epics`} data={epicsItems} product={product} />
        </>
      ) }

      { error && (
        <div className="alert alert-danger">{ error }</div>
      ) }
    </ContentBlock>
  );
}
