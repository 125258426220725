import React, { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import Datatable from 'components/datatable/Datatable';
import Icon from 'components/ui/Icon';
import AdminPage from 'components/page/AdminPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { manageFormErrors } from 'components/form/Form';

const EditField = function(props) {
  const { item } = props;
  const [name, setName] = useState(item.name);
  return (
    <FieldShowUpdate
      content={name}
      pk={item.pk}
      api="customers"
      field="name"
      label={t`Name`}
      onSave={(field, value) => {
        setName(value);
      }}>
    </FieldShowUpdate>
  );
};

const AddCustomerForm = function(props) {
  const { onRefresh, organization } = props;
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  const onAddSubmit = useCallback(function(e) {
    e.preventDefault();
    if (value.length < 1) return;
    axios.post('/customers', {
      name: value,
      organization: {
        pk: organization.pk
      }
    }).then(() => {
      onRefresh();
      setValue('');
      setError(null);
    }).catch((err) => {
      const { globalErrors } = manageFormErrors(null, err, true);
      setError(globalErrors.map(er => er.error).join(<br/>));
    });
  }, [onRefresh, value, organization.pk]);

  return (
    <form onSubmit={onAddSubmit}>
      <div className="form-group">
        <div className="input-wrapper d-inline-block">
          <label htmlFor="add-customer"><Trans>Name</Trans></label>
          <input
            onInput={(e) => setValue(e.target.value)}
            value={value}
            className={'form-control' + (error ? ' is-invalid' : '')}
            type="text"
            id="add-customer"
            name="add-customer"
            required/>
        </div>
        <button type="submit" className="ms-2 btn btn-primary">
          <Icon name="plus-circle" title={t`Add`}/> <Trans>Add a new customer</Trans>
        </button>
        { error && (
          <span className="text-danger ms-2">{ error }</span>
        ) }
      </div>
    </form>
  );
};

export default function CustomerList() {
  const organization = useContext(OrganizationContext);
  const [refresh, setRefresh] = useState(0);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/manage/worklogs`}><Trans>Work log</Trans></Link>,
    t`Customers`
  ];

  const onRefresh = () => {
    setRefresh(refresh + 1);
  };

  return (
    <AdminPage
      name="customer-list-page"
      title={t`Customers`}
      breadcrumb={breadcrumb}>
      {organization.can_add_customer && (
        <AddCustomerForm organization={organization} onRefresh={onRefresh}/>
      )}
      <Datatable
        api="customers"
        apiContext={{ organization: organization.pk }}
        canDelete={organization.can_delete_customer}
        editHandler={false}
        refresh={refresh}>
        <Datatable.Column name="name" label={t`Name`}>
          {
            (item) => {
              if (!item) return null;
              if (organization.can_edit_customer) return <EditField item={item}/>;
              return item.name;
            }
          }
        </Datatable.Column>
      </Datatable>
    </AdminPage>
  );
}
