import React from 'react';
import { NavLink } from 'react-router-dom';
import Icon from 'components/ui/Icon';

function SidebarLink(props) {
  const liClasses = ['nav-item'];
  const classes = ['nav-link'];
  const { setSubActive, isActive, to, icon, children } = props;
  if (isActive) {
    classes.push('active');
    liClasses.push('active');
  }
  return (
    <li className={liClasses.join(' ')}>
      { to === 'BACK' ? (
        <button type="button" className="btn-simplelink nav-link" onClick={() => { setSubActive(null); }}>
          <Icon name={icon}/> <span>{children}</span>
        </button>
      ) : (
        <NavLink className={classes.join(' ')} to={to}>
          <Icon name={icon}/> <span>{children}</span>
        </NavLink>
      ) }
    </li>
  );
}

export default SidebarLink;
