import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import { ConfirmButton } from 'components/utils';
import AdminPage from 'components/page/AdminPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { addMessage } from 'components/ui/Messages';
import Icon from 'components/ui/Icon';
import SubscriptionForm from 'components/forms/SubscriptionForm';
import { LoaderContainer } from 'components/ui/Loader';
import AppContext from 'AppContext';
import { GitPatternFields } from 'components/utils/gitcheckout';
import Features from 'utils/Features';

function AlertBox(props) {
  const { organization } = props;
  if (organization && organization.subscription.value === 'custom') {
    return (
      <div className="alert alert-warning" role="alert">
        Your subscription is managed outside the application. Please contact
        sales@optera.io for any change request.
      </div>
    );
  }
  if (organization && organization.subscription.value === 'trial') {
    if (organization.trial_remaining_days > 0) {
      return (
        <div className="alert alert-warning" role="alert">
          Your trial will end in {organization.trial_remaining_days} days.
          Please chose a subscription before it ends.
        </div>
      );
    }
    return (
      <div className="alert alert-danger" role="alert">Your trial period is ended. Please chose a subscription.</div>
    );
  }
  return null;
}

function SubscriptionBlock(props) {
  const { organization, reloadOrg } = props;
  const [editSubscription, setEditSubscription] = useState();

  if (editSubscription) {
    const handleReset = () => {
      axios.put(`/organizations/${organization.pk}/reset-subscription`).then((res) => {
        window.location.reload();
      }).catch(err => {
        addMessage('checkout-session', 'Unknown error', 'Impossible to open reset subscription.');
      });
    };
    return (
      <>
        <SubscriptionForm
          organization={organization}
          onSave={() => {
            setEditSubscription(false);
            reloadOrg();
          }}
          onCancel={() => setEditSubscription(false)} />
        {process.env.REACT_APP_STRIPE_MODE === 'test' && (
          <button type="button" className="btn btn-theme ms-1" onClick={handleReset}>
            <Trans>RESET subscription (test-mode)</Trans>
          </button>
        )}
      </>
    );
  }

  const handlePortal = () => {
    axios.get(`/organizations/${organization.pk}/portal-session`).then((res) => {
      window.location.href = res.data.session_url;
    }).catch(err => {
      addMessage('checkout-session', 'Unknown error', 'Impossible to open portal');
    });
  };

  return (
    <>
      <span className="badge normal-size bg-primary me-2">{organization.subscription.label}</span>
      {organization.subscription.value !== 'trial' && organization.payment_mode && (
        <span className="badge normal-size bg-light me-2">{organization.payment_mode.label}</span>
      )}
      {organization.subscription.value === 'premium' && (
        <>
          <Icon name="people"/> <Trans>{organization.max_users} users</Trans>
        </>
      )}
      <div className="mt-2">
        <button type="button" className="btn btn-outline-secondary me-1" onClick={() => setEditSubscription(true)}>
          <Icon name="credit-card"/>{' '}<Trans>Edit subscription</Trans>
        </button>
        { organization.subscription.value !== 'trial' && (
          <>
            {' '}
            <Trans>or</Trans>
            <button type="button" className="btn btn-link ms-1" onClick={handlePortal}>
              <Trans>access payment portal</Trans>
            </button>
          </>
        ) }

      </div>
    </>
  );
}

export default function OrganizationSettingsPage(props) {
  const organization = useContext(OrganizationContext);
  const { user } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const params = new URLSearchParams(location.search);
  const paymentStatus = params.get('payment');

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    t`settings`
  ];

  const handleDelete = () => {
    axios.delete(`/organizations/${organization.pk}`).then((res) => {
      user.load(user.pk).then(() => {
        history.push('/');
      }).catch(err => {
        addMessage('user-reload', t`Unknown error`, t`User can't be loaded`);
      });
    }).catch(err => {

    });
  };

  const reloadOrg = useCallback(() => {
    setLoading(true);
    organization.reload().then(() => {
      setLoading(false);
    }).catch((err) => {
      addMessage('org-load', t`Unknown error`, t`Impossible to load organization`);
      setLoading(false);
    });
  }, [organization]);

  useEffect(() => {
    reloadOrg();
  }, [reloadOrg]);

  return (
    <AdminPage
      name="org-form-page org-form-page__edit"
      title={t`Settings`}
      breadcrumb={breadcrumb}>
      { loading && (
        <LoaderContainer fullpage />
      ) }
      { paymentStatus === 'success' && (
        <div className="alert alert-success" role="alert"><Trans>Your payment has been registered.</Trans></div>
      ) }
      { paymentStatus === 'cancelled' && (
        <div className="alert alert-warning" role="alert"><Trans>Your payment has been cancelled.</Trans></div>
      ) }
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <div className="row">
            <div className="col-sm-2">
              <strong><Trans>Name</Trans></strong>
            </div>
            <div className="col-sm-10">
              <FieldShowUpdate
                stateKey={organization._getStateKey()}
                pk={organization.pk}
                api="organizations"
                field="name"
                label={t`Name`}>
              </FieldShowUpdate>
            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div className="row">
            <div className="col-sm-2">
              <strong><Trans>Definition of done</Trans></strong>
            </div>
            <div className="col-sm-10">
              <FieldShowUpdate
                type="textarea"
                pk={organization.pk}
                api={organization._genericApi}
                field="definition_of_done"
                label={t`Definition of done`}
                stateKey={organization._getStateKey()}>
                { org => (
                  !org.definition_of_done ? (
                    <span className="text-disabled"><em><Trans>Add definition of done</Trans></em></span>
                  ) : (
                    <pre>{ org.definition_of_done }</pre>
                  )
                ) }
              </FieldShowUpdate>
            </div>
          </div>
        </li>
        { organization.features.length > 0 && (
          <li className="list-group-item">
            <div className="row">
              <div className="col-sm-2">
                <strong><Trans>Additional features</Trans></strong>
              </div>
              <div className="col-sm-10">
                { organization.features.map((flag) => (
                  <span
                    key={flag}
                    className={`badge bg-${Object.values(Features).includes(flag) ? 'info' : 'secondary'} me-2`}>
                    { flag }
                  </span>
                ))}
              </div>
            </div>
          </li>
        ) }
        { organization.is_account_manager && (
          <>
            <li className="list-group-item py-3">
              <div className="row">
                <div className="col-sm-2">
                  <strong><Trans>Subscription</Trans></strong>
                </div>
                <div className="col-sm-10">
                  <AlertBox organization={organization}/>
                  <div className="active-users-comment mb-2">
                    <div>
                      <strong><Trans>Users:</Trans></strong> {organization.nb_members}
                      {organization.max_users > 0 && `/${organization.max_users}`}
                    </div>
                    <div>
                      <strong><Trans>Guests:</Trans></strong> {organization.nb_guests}
                      {organization.max_guests > 0 && `/${organization.max_guests}`}
                    </div>
                  </div>
                  { organization.subscription.value !== 'custom' && (
                    <SubscriptionBlock organization={organization} reloadOrg={reloadOrg} />
                  )}
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <div className="col-sm-2">
                  <strong><Trans>Git pattern for checkout</Trans></strong>
                </div>
                <div className="col-sm-8">
                  <GitPatternFields entity={organization} isOrg />
                </div>
              </div>
            </li>
            <li className="list-group-item">
              <div className="row">
                <div className="col-sm-2">

                </div>
                <div className="col-sm-8">
                  <ConfirmButton
                    variant="danger"
                    confirmLabel={t`Delete Permanently`}
                    confirmVariant="danger"
                    data-cy="bt-delete-org"
                    message={(
                      <Trans>
                        <p>
                          Are you sure you want to delete
                          organization <strong>{organization.name}</strong>?
                        </p>
                        <p>
                          Deleting an organization will permanently delete all
                          related products, members, customers, accounts,
                          backlog items and worklogs.
                        </p>
                      </Trans>
                    )}
                    confirmValue={organization.name}
                    confirmValueLabel={t`Please confirm by typing the organization name`}
                    onConfirm={handleDelete}>
                    <Trans>Delete Permanently</Trans>
                  </ConfirmButton>
                </div>
              </div>
            </li>
          </>
        ) }
      </ul>
    </AdminPage>
  );

}
