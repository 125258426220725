import { Trans, t } from '@lingui/macro';
import React, { useContext, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import Icon from 'components/ui/Icon';
import { Loader } from 'components/ui/Loader';
import { useStateItem } from 'utils/Item';
import ProductContext from 'components/utils/ProductContext';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import axios from 'axios';
import { addMessage } from 'components/ui/Messages';
import BacklogModalContext from 'components/modal/BacklogModalContext';
import BacklogItemStorymapModal from 'components/modal/BacklogItemStorymapModal';
import StoryMapContext from './StoryMapContext';

export default function StoryMapTask(props) {
  const { item, pk, ...rest } = props;
  if (item) return <StoryMapTaskContent task={item} {...rest}/>;
  return <StoryMapTaskState pk={pk} {...rest} />;
}

function StoryMapTaskState(props) {
  const { pk, ...rest } = props;
  const task = useStateItem('tasks:' + pk);

  if (!task) return null;
  return <StoryMapTaskContent task={task} {...rest} />;
}

function StoryMapTaskContent(props) {
  const { task, provided, isDragging } = props;
  const product = useContext(ProductContext);
  const { loadTasks } = useContext(StoryMapContext);
  const [loading, setLoading] = useState(false);
  const [showAddBl, setShowAddBl] = useState(false);
  const { showModal } = useContext(BacklogModalContext);

  const removeItem = () => {
    const { pk } = task;
    return axios.delete('tasks/' + pk).then(() => {
      loadTasks(oldTasks => oldTasks.filter(ta => ta.pk !== pk));
    });
  };

  const onRemove = () => {
    setLoading(true);
    removeItem().catch(() => {
      addMessage('delete-task', t`Unknown error`, t`Impossible to delete task`);
      setLoading(false);
    });
  };

  return (
    <div
      className={'story-map-card story-map-card--task' + (isDragging ? ' is-draging' : '')}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}>
      { loading && (
        <Loader size="tiny" />
      ) }
      { product.can_edit_item ? (
        <>
          { showAddBl && product.can_add_item && (
            <BacklogItemStorymapModal
              filter={ta => ta.pk === task.pk}
              status="backlog"
              show
              onClose={() => { setShowAddBl(false); }}
              hideButton />
          ) }
          
          <FieldShowUpdate
            element={task}
            pk={task.pk}
            api="tasks"
            field="name"
            label={t`Name`}>
            {it => (
              <span className="name">{ it.resource_name }</span>
            )}
          </FieldShowUpdate>
          { task.backlog_item && (
            <span className="linked-item">
              <Icon name="link" />
            </span>
          ) }
          <Dropdown className="no-carret">
            <Dropdown.Toggle variant="sm">
              <Icon name="three-dots" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              { product.can_edit_item && removeItem && (
                <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={() => { onRemove(); }}>
                  <Trans>Remove task</Trans>
                </Dropdown.Item>
              ) }
              { task.backlog_item ? (
                <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={() => { showModal(task.backlog_item); }}>
                  <Trans>View linked item</Trans>
                </Dropdown.Item>
              ) : (product.can_add_item ? (
                <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={() => { setShowAddBl(true); }}>
                  <Trans>Create backlog item</Trans>
                </Dropdown.Item>
              ) : null) }  
            </Dropdown.Menu>
          </Dropdown>
        </>
      ) : task.name }
    </div>
  );
}
