class PersistentSocket {
  constructor(url, settings) {
    this.url = url;
    this.settings = settings;
    this.opened = false;
    this.try = 0;
    this.timeout = null;
    this.connect();
    this.checkVisibility = () => {
      if (!document.hidden && this.socket && this.socket.readyState === WebSocket.CLOSED) {
        this.reconnect(true);
        if ('onwakeup' in this.settings) {
          this.settings.onwakeup();
        }
      }
    };
    document.addEventListener('visibilitychange', this.checkVisibility);
  }

  connect() {
    this.try += 1;
    this.socket = new WebSocket(this.url);
    if ('onmessage' in this.settings) {
      this.socket.onmessage = e => {
        this.settings.onmessage(e);
      };
    }
    
    this.socket.onopen = e => {
      this.opened = true;
      this.try = 0;
      if ('onopen' in this.settings) {
        this.settings.onopen(e);
      }
    };

    this.socket.onclose = e => {
      this.opened = false;
      if ('onclose' in this.settings) {
        this.settings.onclose(e);
      }

      if (!e.wasClean) {
        // Socket closed unexpectedly
        console.error(e);
        switch (e.code) {
          case 1000:
            // Normal Closure
            break;
          case 1001:
            // Going away
            break;
          default:
            this.reconnect();
        }
      }
      
    };
  }

  reconnect(userInitiated) {
    if (userInitiated) {
      this.try = 0;
      this.connect();
    }
    else if (this.try < 2) {
      this.connect();
    }
    else if (this.try > 4) {
      if ('onfail' in this.settings) {
        this.settings.onfail();
      }
    }
    else {
      this.timeout = setTimeout(() => {
        this.connect();
      }, (this.try - 1) * 2000);
    }
  }

  close() {
    document.removeEventListener('visibilitychange', this.checkVisibility);
    clearTimeout(this.timeout);
    this.socket.close(1000);
  }
}

export default PersistentSocket;
