import React, { useContext, useState } from 'react';
import { Error } from './BaseField';
import { FormContext } from './FormContext';

export default function ErrorCollector({ children, ...props }) {
  const { addErrorField, errors } = useContext(FormContext);
  const { field, ...rest } = props;

  useState(() => {
    addErrorField(field);
  }, [field]);
  
  return (
    <div {...rest}>
      { errors[field] ? (
        <div className="is-invalid">
          { children }
        </div>
      ) : children}
      <Error errors={errors[field]} />
    </div>
  );
}
