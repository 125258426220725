import axios from 'axios';
import Organization from 'entity/Organization';

export default function getOrganizations() {
  return new Promise((resolve, reject) => {
    axios.get('/organizations').then((response) => {
      const orgs = [];
      for (let i = 0; i < response.data.items.length; i++) {
        const org = new Organization();
        org.loadFromObject(response.data.items[i]);
        orgs.push(org);
      }
      resolve(orgs);
    }).catch((error) => { reject(error); });
  });
}
