import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { Form } from 'react-bootstrap';
import axios from 'axios';
import AdminPage from 'components/page/AdminPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import Icon from 'components/ui/Icon';
import { LoaderContainer } from 'components/ui/Loader';
import getBacklogItems from 'entity/BacklogItems';
import { addMessage } from 'components/ui/Messages';
import BacklogItemTeaser from 'components/product/BacklogItemTeaser';

export default function HelpscoutLinkItemPage(props) {
  const organization = useContext(OrganizationContext);
  const params = new URLSearchParams(window.location.search);
  const [term, setTerm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState(false);
  const inputRef = useRef();
  const history = useHistory();

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    t`Link an existing item`
  ];

  const onSubmit = e => {
    e.preventDefault();
    setTerm(inputRef.current.value);
  };

  const doSearch = useCallback(() => {
    setLoading(true);
    if (!term) {
      setLoading(false);
      inputRef.current.focus();
      return;
    }
    getBacklogItems(null, {
      limit: 10,
      params: [
        ['organization', organization.pk],
        ['search', term],
        ['exclude_helpscout_link', params.get('ticket')],
      ] }).then(backlogItems => {
      setItems(backlogItems);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
      addMessage('search-impossible', t`Unknown error`, t`Impossible to access search`);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [term]);

  useEffect(() => {
    doSearch();
  }, [doSearch]);

  const handleItemSelect = (item) => {
    axios.post(`/integrations/helpscout/item/${item.pk}/link`, {
      ticket: params.get('ticket')
    }).then(() => {
      history.push(`/${organization.slug}/${item.product.resource_slug}/item/${item.resource_reference}`);
    }).catch((e) => {
      addMessage('helpscout-create-link', t`Unknown error`, t`Impossible to create the Helpscout link`);
    });
  };

  return (
    <AdminPage
      name="helpscout-page helpscout-page-link-item"
      title={t`Link an existing item`}
      breadcrumb={breadcrumb}>
      <Form onSubmit={onSubmit} className="d-flex">
        <Form.Control
          ref={inputRef}
          type="text"
          id="search"
          placeholder="search"/>
        <button className="btn" type="submit">
          <Icon name="search"/>
        </button>
      </Form>
      <div className="mt-3 relative">
        { loading && (
          <LoaderContainer/>
        ) }
        { items.length === 0 && (
          <div className="alert alert-secondary" role="alert">
            <Trans>There are no items matching your search</Trans>
          </div>
        ) }
        { items && (
          <div className="list-group">
            { items.map(item => (
              <button
                key={item.pk}
                type="button"
                onClick={() => handleItemSelect(item)}
                className="list-group-item list-group-item-action card backlog-item-teaser mb-1">
                <BacklogItemTeaser showStatus layout="link" backlogItem={item} hideMore />
              </button>
            ))}
          </div>
        ) }
      </div>
    </AdminPage>
  );

}
