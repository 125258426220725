import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';
import { Form, FormContext, FormContextProvider } from 'components/form';
import { ConfirmButton } from 'components/utils';
import ContentBlock from 'components/content/ContentBlock';
import BacklogModalContext from 'components/modal/BacklogModalContext';
import { setStateItem, useStateItem } from 'utils/Item';

function ItemLinkForm(props) {
  const { backlogItem, isEdit } = props;
  const { onSave, onCancel } = props;
  const { item, setItem } = useContext(FormContext);

  useEffect(() => {
    if (item && backlogItem) {
      item.item = backlogItem.pk;
    }
  }, [item, setItem, backlogItem]);

  const onSaveItem = (data) => {
    if (isEdit) {
      const index = backlogItem.links.findIndex(i => i.pk === item.pk);
      backlogItem.links[index] = {
        pk: data.pk,
        linked_item: item.linked_item,
        type: item.type,
      };
    } else {
      backlogItem.links.push({
        pk: data.pk,
        linked_item: item.linked_item,
        type: item.type,
      });
    }
    onSave();
  };

  const getApi = (params) => (
    `/backlog-items?organization=${backlogItem.product.organization.pk}&exclude=${backlogItem.pk}` +
    `&${params.search ? 'search_product_first' : 'product_updated'}=${backlogItem.product.pk}`
  );

  return (
    <Form
      name="item-link-add-form"
      noValidate
      onSave={onSaveItem}>
      <div className="item-link-edit">

        <div className="d-inline-block align-top me-2 mb-2">
          <Form.Select id="type" name="type" nolabel={1} required />
        </div>
        <div className="d-inline-block align-top me-2 mb-2">
          <Form.Field type="hidden" id="item" name="item" required />
          <Form.Many2One
            id="linked_item"
            name="linked_item"
            api={getApi}
            nolabel
            required />
        </div>
        <div className="d-inline-block align-top mb-2">
          <button className="btn btn-primary me-2" type="submit">
            {isEdit ? <Trans>Update</Trans> : <Trans>Link</Trans>}
          </button>
          <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
        </div>
      </div>
    </Form>
  );
}

function ItemLink(props) {
  const { link } = props;
  const linkedItem = useStateItem('backlog-items:' + link.linked_item.pk);
  if (!linkedItem) {
    return null;
  }
  return (
    <ItemLinkItem {...props} linkedItem={linkedItem} />
  );
}
function ItemLinkItem(props) {
  const { isFirst, link, backlogItem, readonly, linkedItem } = props;
  const { showModal } = useContext(BacklogModalContext);

  const handleDelete = () => {
    axios.delete(`/item-links/${link.pk}`)
      .then(() => {
        setStateItem('backlog-items:' + backlogItem.pk, {
          links: backlogItem.links.filter(i => i.pk !== link.pk)
        });
      })
      .catch((error) => addMessage('delete-error', t`Server error`, error.response.data.message));
  };

  if (link) {
    return (
      <div className={'item-link' + (!isFirst ? ' hidden-label' : ' first')}>
        <div className="item-link-label me-2">
          { link.type.label }
        </div>
        <div className="item-link-item">
          <a
            href={link.linked_item.path}
            onClick={e => {
              e.preventDefault();
              showModal(link.linked_item);
            }}>
            { linkedItem.resource_reference }
          </a>{' '}
          { linkedItem.resource_name }{' '}
          <div className="badge rounded-pill bg-light">
            { linkedItem.status.resource_name }
          </div>
        </div>
        <div className="actions">
          {!readonly && (
            <ConfirmButton
              variant="transparent"
              message={t`Are you sure you want to delete this element?`}
              confirmLabel={t`Delete`}
              confirmVariant="danger"
              onConfirm={handleDelete}>
              <Icon name="x-circle" title={t`Delete`}></Icon>
            </ConfirmButton>
          )}
        </div>
      </div>
    );
  }
  return '';
}

export default function BacklogItemLinks(props) {
  const { backlogItem, organization, onEdit, onEditClose } = props;
  const [showAdd, setShowAdd] = useState(false);
  const [refresh, setRefresh] = useState(1);

  const handleUpdate = () => {
    setRefresh(refresh + 1);
    setShowAddHandler(false);
  };

  const setShowAddHandler = useCallback((show) => {
    if (show) {
      if (onEdit) onEdit('additemlink-' + backlogItem.pk);
    }
    else if (onEditClose) {
      onEditClose('additemlink-' + backlogItem.pk);
    }
    setShowAdd(show);
  }, [onEdit, onEditClose, backlogItem.pk]);

  const actions = props.readonly ? [] : [(
    <button key="add-link" type="button" className="btn btn-outline-secondary" onClick={() => { setShowAddHandler(true); }}>
      <Icon name="plus"/> <Trans>Add link</Trans>
    </button>
  )];

  const linkItemsWeights = {
    is_blocked_by: 1,
    blocks: 2,
    is_cloned_by: 3,
    clones: 4,
    is_duplicated_by: 4,
    duplicates: 5,
    is_caused_by: 6,
    causes: 7,
    relates_to: 8,
  };

  backlogItem.links.sort((a, b) => {
    if (linkItemsWeights[a.type.value] === linkItemsWeights[b.type.value]) return 0;
    return linkItemsWeights[a.type.value] < linkItemsWeights[b.type.value] ? -1 : 1;
  });

  for (let i = 0; i < backlogItem.links.length; i++) {
    setStateItem('backlog-items:' + backlogItem.links[i].linked_item.pk, backlogItem.links[i].linked_item);
  }

  return (
    <ContentBlock
      title={t`Backlog Item links`}
      className="itemlinks bordered small-head mt-2 mb-2"
      actions={actions}>
      <div className="itemlinks">
        {showAdd && (
          <div className="row">
            <FormContextProvider api="/item-links">
              <ItemLinkForm
                backlogItem={backlogItem}
                onSave={handleUpdate}
                onCancel={() => { setShowAddHandler(false); }}/>
            </FormContextProvider>
          </div>
        )}
        <div className="itemlinks__list">
          { backlogItem.links.map((link, index) => (
            <ItemLink
              key={link.pk}
              link={link}
              backlogItem={backlogItem}
              organization={organization}
              isFirst={index === 0 || backlogItem.links[index - 1].type.value !== link.type.value}
              readonly={props.readonly}/>
          )) }
        </div>
      </div>
    </ContentBlock>
  );
}
