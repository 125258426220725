import React, { useContext, useEffect, useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Link, useHistory, useParams } from 'react-router-dom';
import OrganizationContext from 'components/utils/OrganizationContext';
import BacklogItemModel from 'entity/BacklogItem';
import { LoaderContainer } from 'components/ui/Loader';
import AdminPage from 'components/page/AdminPage';
import NotFoundPage from './NotFoundPage';

function BacklogItemError() {
  return (
    <div className="alert alert-danger" role="alert">
      <Trans>Can’t load backlog item.</Trans>
    </div>
  );
}

export default function BacklogItemPageLink() {
  const history = useHistory();
  const organization = useContext(OrganizationContext);
  const { reference } = useParams();
  const [error, setError] = useState(null);

  useEffect(() => {
    const item = new BacklogItemModel();
    item.load_by_reference(organization.slug, reference, true).then(() => {
      history.replace(`/${organization.slug}/${item.product.resource_slug}/item/${item.reference}`);
    }).catch(err => {
      if (err.response && err.response.status === 404) {
        setError('notfound');
      } else {
        setError('other');
      }
    });
  }, [reference, organization.slug, history]);

  const breadcrumb = [
    <Link key="1" to="/"><Trans>Home</Trans></Link>,
    <Link key="2" to={`/${organization.slug}`}>{ organization.name }</Link>,
    t`Backlog item`,
    reference
  ];

  if (error === 'notfound') {
    return <NotFoundPage breadcrumb={breadcrumb} />;
  }
  if (error) {
    return (
      <AdminPage
        name="member-list-page"
        title={t`Members`}
        breadcrumb={breadcrumb}>
        <BacklogItemError/>
      </AdminPage>
    );
  }
  return <LoaderContainer fullpage />;
}
