/* eslint-disable spaced-comment */
import { t } from '@lingui/macro';

export const statuslist = ['backlog', 'todo', 'in_progress', 'done', 'closed'];
export const statuslistKanban = ['todo', 'in_progress', 'done'];
export const statusNames = {
  backlog: t`Backlog`,
  todo: t`To do`,
  in_progress: t`In progress`,
  done: t`Done`,
  closed: t`Closed`
};

export const currencySymbol = { USD: '$', EUR: '€', CHF: 'CHF' };

export const priceList = {
  starter: {
    monthly: { USD: 10, EUR: 10, CHF: 10 },
    yearly: { USD: 100, EUR: 100, CHF: 100 } },
  premium: {
    monthly: { USD: 6, EUR: 6, CHF: 6 },
    yearly: { USD: 60, EUR: 60, CHF: 60 } },
};

export const modelsApi = {
  Organization: {
    api: 'organizations',
    name: t`Organization`
  },
  Product: {
    api: 'products',
    name: t`Product`
  },
  Customer: {
    api: 'customers',
    name: t`Customer`
  },
  Account: {
    api: 'accounts',
    name: t`Account`
  },
  Sprint: {
    api: 'sprints',
    name: t`Sprint`
  },
  Member: {
    api: 'members',
    name: t`Member`
  },
  TeamMember: {
    api: 'team-members',
    name: t`Team member`
  },
  BacklogItem: {
    api: 'backlog-items',
    name: t`Backlog item`
  },
  User: {
    api: 'users',
    name: t`User`
  },
  Epic: {
    api: 'epics',
    name: t`Epic`
  },
  ItemStatus: {
    api: 'item-status',
    name: t`Item status`
  },
  ItemType: {
    api: 'item-types',
    name: t`Item type`
  },
  Release: {
    api: 'releases',
    name: t`Release`
  },
  Trigger: {
    api: 'triggers',
    name: t`Trigger`
  }
};

export const triggersConditionList = {
  created: t`On creation`,
  transitioned: t`On transition`,
  need_estimate_changed: 'On need estimate change',
  gitlab_mr_merged: 'On gitlab merge'
};
