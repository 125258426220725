import { useContext } from 'react';
import AppContext from 'AppContext';

const DateFormat = function(props) {
  const { lang } = useContext(AppContext);
  const { date, datestring, ...options } = props;
  if (!date && (datestring === null || datestring.length < 1)) return null;
  const finalOptions = Object.keys(options).length < 1 ? {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  } : options;
  try {
    return new Intl.DateTimeFormat(lang.pk, finalOptions).format(date || new Date(datestring));
  } catch (error) {
    return null;
  }
};
DateFormat.defaultProps = {
  datestring: null
};
export default DateFormat;
