import React, { useContext } from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import AppContext from 'AppContext';
import HoursDuration from 'components/ui/HoursDuration';
import useStickyHeader from 'components/utils/stickyHeader';

function getLineKey(line) {
  let k = 'l' + line.organization.pk;
  if ('worker' in line) k += '-' + line.worker.pk;
  if ('account' in line) k += '-' + line.account.pk;
  if ('year' in line) k += '-' + line.year;
  if ('month' in line) k += '-' + line.month;
  if ('date' in line) k += '-' + line.date;
  if ('item' in line) k += '-' + line.item.pk;
  return k;
}

function getLineKeyDate (line) {
  let k = 'l' + line.organization.pk;
  if ('worker' in line) k += '-w' + line.worker.pk;
  if ('account' in line) k += '-a' + line.account.pk;
  if ('item' in line) k += '-i' + line.item.pk;
  return k;
}

function WorklogsContentTable(props) {
  const { groupBy, results } = props;

  return (
    <table className="table">
      <thead>
        <tr>
          {groupBy.indexOf('worker') !== -1 && (
            <th><Trans>Member</Trans></th>
          )}
          {groupBy.indexOf('item') !== -1 && (
            <th><Trans>Item</Trans></th>
          )}
          {groupBy.indexOf('account') !== -1 && (
            <th><Trans>Account</Trans></th>
          )}
          {groupBy.indexOf('date') !== -1 && (
            <th><Trans>Date</Trans></th>
          )}
          <th><Trans>Total Work</Trans></th>
        </tr>
      </thead>
      <tbody>
        { results.map(line => (
          <tr key={getLineKey(line)}>
            {('worker' in line) && (
              <td>{ line.worker.resource_name }</td>
            )}
            {('item' in line) && (
              <td>
                <Link to={line.item.path}>
                  { line.item.resource_reference }:
                  { line.item.resource_name }
                </Link>
              </td>
            )}
            {('account' in line) && (
              <td>{ line.account.resource_name }</td>
            )}
            {('date' in line) && (
              <td>{ line.date }</td>
            )}
            <td>{ line.total_work.human_format }</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function WorklogsContentTableDate(props) {
  const { groupBy, results, startDateStr, endDateStr } = props;
  const { lang } = useContext(AppContext);
  const { tableRef, isSticky, stickyTop } = useStickyHeader();

  if (results.length < 1) {
    return null;
  }
  let dateType = 'year';
  if ('month' in results[0]) dateType = 'month';
  if ('date' in results[0]) dateType = 'date';

  const monthFormatter = new Intl.DateTimeFormat(lang.pk, { month: 'short' });
  const getKeyForDateGroup = (group, date) => {
    if (group === 'date') {
      return {
        key: date.toISOString().split('T')[0],
        name: date.getDate()
      };
    }
    if (group === 'month') {
      return {
        key: date.getFullYear() + '-' + (date.getMonth() + 1),
        name: monthFormatter.format(date)
      };
    }
    return {
      key: date.getFullYear(),
      name: date.getFullYear()
    };
  };

  const date = new Date(startDateStr + 'T12:00:00');
  const dateLimit = new Date(endDateStr + 'T12:00:00');
  dateLimit.setDate(dateLimit.getDate() + 1);
  const columns = [];
  while (date.toISOString() < dateLimit.toISOString()) {
    const { key, name } = getKeyForDateGroup(dateType, date);
    columns.push({
      month: monthFormatter.format(date),
      year: date.getFullYear(),
      name: name,
      key: key,
      worked: 0,
      weekend: dateType === 'date' && (date.getDay() === 0 || date.getDay() === 6)
    });
    if (dateType === 'date') {
      date.setDate(date.getDate() + 1);
    }
    else if (dateType === 'month') {
      date.setMonth(date.getMonth() + 1);
    }
    else {
      date.setFullYear(date.getFullYear() + 1);
    }
  }

  const rows = [];
  const columnsCopy = JSON.parse(JSON.stringify(columns));
  for (const line of results) {
    const lineKey = getLineKeyDate(line);
    let lineDate;
    if (dateType === 'date') {
      lineDate = new Date(line.date);
    }
    else if (dateType === 'month') {
      lineDate = new Date(line.year, line.month - 1, 1);
    }
    else {
      lineDate = new Date(line.year, 0, 1);
    }
    const { key: resKey } = getKeyForDateGroup(dateType, lineDate);
    let index = rows.findIndex(row => row.key === lineKey);
    if (index === -1) {
      rows.push({
        key: lineKey,
        line: line,
        cols: JSON.parse(JSON.stringify(columnsCopy)),
        minutes: 0
      });
      index = rows.length - 1;
    }
    const index2 = rows[index].cols.findIndex(col => col.key === resKey);

    if (index2 !== -1) {
      rows[index].cols[index2].worked = line.total_work;
      columns[index2].worked += line.total_work.minutes;
    }
  }

  for (let i = 0; i < rows.length; i++) {
    rows[i].minutes = rows[i].cols.reduce((acc, current) => (current.worked ? (acc + current.worked.minutes) : acc), 0);
  }

  const renderHeader = () => (
    <thead>
      <tr>
        <th><Trans>Total</Trans></th>
        { columns.map(col => (
          <th key={col.key} className={col.weekend ? 'we' : ''}>
            { col.name }
            { dateType === 'month' && (
              <span className="year">{ col.year }</span>
            ) }
          </th>
        )) }
      </tr>
    </thead>
  );

  return (
    <div className="worklogs-table mt-2">
      <div className={'worklogs-table__header worklogs-table__' + dateType}>
        <table className="table">
          <thead>
            <tr>
              {groupBy.indexOf('worker') !== -1 && (
                <th>
                  { dateType === 'month' && (
                    <span className="year">&nbsp;</span>
                  ) }
                  <Trans>Member</Trans>
                </th>
              )}
              {groupBy.indexOf('item') !== -1 && (
                <th>
                  { dateType === 'month' && (
                    <span className="year">&nbsp;</span>
                  ) }
                  <Trans>Item</Trans>
                </th>
              )}
              {groupBy.indexOf('account') !== -1 && (
                <th>
                  { dateType === 'month' && (
                    <span className="year">&nbsp;</span>
                  ) }
                  <Trans>Account</Trans>
                </th>
              )}
              { groupBy.length === 0 && (
                <th>
                  &nbsp;
                  { dateType === 'month' && (
                    <span className="year">&nbsp;</span>
                  ) }
                </th>
              ) }
            </tr>
          </thead>
          <tbody>
            { rows.map(row => (
              <tr key={row.key}>
                {('worker' in row.line) && (
                  <td>{ row.line.worker.resource_name }</td>
                )}
                {('item' in row.line) && (
                  <td>
                    <Link to={row.line.item.path}>
                      { row.line.item.resource_reference }:
                      { row.line.item.resource_name }
                    </Link>
                  </td>
                )}
                {('account' in row.line) && (
                  <td>{ row.line.account.resource_name }</td>
                )}
                { groupBy.length === 0 && (
                  <td>
                    &nbsp;
                  </td>
                ) }
              </tr>
            ))}
            <tr className="total-bottom">
              <td colSpan={(groupBy.length - 1) || 1}><strong><Trans>Total</Trans></strong></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={'worklogs-table__data worklogs-table__' + dateType}>
        {isSticky && (
          <table
            className="table sticky"
            style={{
              position: 'absolute',
              top: stickyTop + 'px',
              left: 0,
              right: 0,
              transition: 'top 0.1s linear'
            }}
          >
            {renderHeader()}
          </table>
        )}
        <table className="table" ref={tableRef}>
          {renderHeader()}
          <tbody>
            { rows.map(row => (
              <tr key={row.key}>
                <td key="total"><HoursDuration value={row.minutes}/></td>
                { row.cols.map(col => (
                  <td key={col.key} className={col.weekend ? 'we' : ''}>{ col.worked ? col.worked.clock_format : ' ' }</td>
                )) }
              </tr>
            ))}
            <tr className="total-bottom">
              <td></td>
              { columns.map(col => (
                <td key={col.key} className={col.weekend ? 'we' : ''}>
                  { col.worked > 0 && (
                    <HoursDuration type="clock" value={col.worked}/>
                  ) }
                </td>
              )) }
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export { WorklogsContentTable, WorklogsContentTableDate };
