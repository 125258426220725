import React, { useContext } from 'react';
import { Trans, t } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';
import OrganizationContext from 'components/utils/OrganizationContext';

function ProductImportFinalForm(props) {
  const { onSave, onCancel } = props;

  return (
    <Form
      name="product-import-form"
      onSave={onSave}>
      <Form.Field id="prefix" className="full-w" name="prefix" />
      <Form.Field id="name" className="w-100" name="name" />
      <Form.Select id="mode" name="mode" required/>
      <Form.Select id="estimate_mode" name="estimate_mode" required/>
      <div id="form-group-jira-key" className="form-group">
        <input type="submit" className="btn btn-primary me-2" value={t`Import`} />
        <button className="btn btn-outline-secondary" type="button" onClick={e => { onCancel(); }}><Trans>Cancel</Trans></button>
      </div>
    </Form>
  );
}

const ProductImportForm = function(props) {
  const { project, jiraToken, meta, onSave, onCancel } = props;
  const organization = useContext(OrganizationContext);
  const asProductImport = p => ({
    type: 'jira',
    source_key: jiraToken.jira_url,
    source_product_key: p.jira_key,
    member: organization.member.pk,
    prefix: p.prefix,
    name: p.name,
    mode: 'kanban',
    estimate_mode: 'hours',
  });

  return (
    <FormContextProvider meta={meta} key={project.jira_key} item={asProductImport(project)} api="/product-imports">
      <ProductImportFinalForm onSave={onSave} onCancel={onCancel} />
    </FormContextProvider>
  );
};

export default ProductImportForm;
