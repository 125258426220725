import React, { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';
import QRCode from 'react-qr-code';
import axios from 'axios';
import CodeField from 'components/form/CodeField';
import { websiteUrl } from 'components/utils/urls';

function OtpValidateFinalForm(props) {
  const { onSave, onCancel, setupUri } = props;
  const [validated, setValidated] = useState(false);

  const onInvalid = () => {
    setValidated(true);
  };

  return (
    <div className="text-center">
      <p>
        <Trans>Please scan the following QR code with your authenticator app and provide the resulting code.</Trans><br />
        <a
          href={websiteUrl('/docs/core-concept/user#otp')}
          target="_blank"
          rel="noreferrer">
          <Trans>How can I get an authenticator app?</Trans>
        </a>
      </p>
      <Form
        method="put"
        name="otp-validate-form"
        onInvalid={onInvalid}
        onSave={onSave}>
        <QRCode size={240} value={setupUri} className="qr-code" data-secret={setupUri} />
        <CodeField size="6" id="otp_code" name="otp_code" required autofocus validated={validated} />
        <div className="actions">
          <button className="btn btn-primary" type="submit">
            <Trans>Validate code</Trans>
          </button>
          <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
        </div>
      </Form>
    </div>
  );
}

const OtpValidateForm = function(props) {
  const { onSave, onCancel, setupUri } = props;
  const item = {
    
    clone: () => ({
      otp_code: '',
      save: (it) => axios.put('auth/otp/enable', { otp_code: it.otp_code }),
    })
  };

  return (
    <FormContextProvider
      item={item}
      api="/auth/otp/enable"
      meta={{
        fields: {
          otp_code: {
            type: 'CharField',
            name: t`Code`,
            readonly: {
              post: false,
              put: false
            },
            required: true
          }
        }
      }}>
      <OtpValidateFinalForm
        setupUri={setupUri}
        onSave={onSave}
        onCancel={onCancel} />
    </FormContextProvider>
  );
};

export default OtpValidateForm;
