import React, { useContext, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { FormContext } from './FormContext';

function Error(props) {
  const { errors } = props;
  if (!errors) return null;
  const errorList = (typeof errors === 'string') ? [errors] : errors;
  if (errorList.length === 1) {
    return (
      <Form.Control.Feedback type="invalid">
        { errorList[0] }
      </Form.Control.Feedback>
    );
  }
  return (
    <Form.Control.Feedback type="invalid">
      <ul>
        { errorList.map(err => (
          <li key={err}>
            { err }
          </li>
        )) }
      </ul>
    </Form.Control.Feedback>
  );
}

export default function BaseField({ children, ...props }) {
  const { registerField, errors: contextErrors } = useContext(FormContext);
  const { id, label, readOnly, humanValue, containerClassName, fieldErrors } = props;
  const errors = fieldErrors ? { ...contextErrors, ...fieldErrors } : contextErrors;
  const groupClassName = [];
  if (props.className) groupClassName.push(props.className);
  if (containerClassName) groupClassName.push(containerClassName);

  useEffect(() => {
    if (!readOnly) registerField(props.name);
  }, [props.name, readOnly, registerField]);

  if (readOnly) {
    if (!humanValue) return null;
    return (
      <>
        <Form.Group className={groupClassName.join(' ')} id={'form-group-' + id}>
          <div className="form-label">{ label }</div>
          <div className="form-value">{ humanValue }</div>
        </Form.Group>
        { 'help' in props && (
          <Form.Text className="text-muted">
            { props.help }
          </Form.Text>
        ) }
      </>
    );
  }
  return (
    <Form.Group className={groupClassName.join(' ')} id={'form-group-' + id}>
      { props.type !== 'checkbox' && (
        <Form.Label visuallyHidden={props.nolabel} htmlFor={id}>
          { label }
        </Form.Label>
      ) }
      { children }
      <Error errors={errors[props.name]} />
      { 'help' in props && (
        <Form.Text className="text-muted">
          { props.help }
        </Form.Text>
      ) }
    </Form.Group>
  );
}

export { Error };
