import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import axios from 'axios';
import OrganizationContext from 'components/utils/OrganizationContext';
import ProductContext from 'components/utils/ProductContext';
import ProductPage from 'components/page/ProductPage';
import getBacklogItems from 'entity/BacklogItems';
import { Loader, LoaderContainer } from 'components/ui/Loader';
import BacklogModalContext from 'components/modal/BacklogModalContext';
import { ColorSticker } from 'components/ui/ColorSticker';
import { FieldEstimates } from 'components/product/backlogitem/FieldEstimate';
import { addMessage } from 'components/ui/Messages';
import PersistentSocket from 'components/utils/PersistentSocket';

function ConfirmButton(props) {
  const { onConfirm, product } = props;
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    setLoading(true);
    axios.put('products/' + product.pk + '/mark-all-evaluated').then(response => {
      setLoading(false);
      addMessage('mark-all-evaluated-success', t`Info`, response.data.message);
      if (response.data.count > 0) {
        onConfirm();
      }
    });
  };

  return (
    <div className="text-end">
      <button onClick={onClick} type="button" className="btn btn-outline-secondary">
        <Trans>Confirm all completed estimates</Trans>
        { loading && (
          <>{' '}<Loader size="tiny" /></>
        ) }
      </button>
    </div>
  );
}

function EstimatesPageContent(props) {
  const { product } = props;
  const [items, setItems] = useState(null);
  const [, setRefresh] = useState(0);
  const [refreshItems, setRefreshItems] = useState(0);
  const { showModal } = useContext(BacklogModalContext);

  useEffect(() => {
    getBacklogItems(product.pk, { params: [['need_estimate', 'true']] }).then((response) => {
      setItems(response);
    });
  }, [product.pk, refreshItems]);

  useEffect(() => {
    if (items === null) return () => {};
    const estimateSocket = new PersistentSocket(
      `${process.env.REACT_APP_WS_URL}/ws/products/${product.pk}/kanban`,
      {
        onmessage: e => {
          const data = JSON.parse(e.data);
          data.message.items.forEach((item, i) => {
            const fItem = items.find(it => it.pk === item.pk);
            if (fItem) {
              Object.assign(fItem, item);
              setRefresh(v => v + 1);
            }
          });
        }
      }
    );
    return () => {
      estimateSocket.close();
    };
  }, [items, product.pk]);

  if (items === null) {
    return <LoaderContainer height="4" />;
  }
  if (items.length < 1) {
    return (
      <div className="alert alert-info mt-4 mb-3">
        <Trans>There is currently no items to evaluate</Trans>
      </div>
    );
  }

  return (
    <>
      <ConfirmButton product={product} onConfirm={() => { setRefreshItems(v => v + 1); }} />
      <table className="table table-responsive">
        <thead>
          <tr>
            <th><Trans>Item</Trans></th>
            <th><Trans>Item type</Trans></th>
            <th><Trans>Epic</Trans></th>
            <th><Trans>Estimate</Trans></th>
          </tr>
        </thead>
        <tbody>
          { items.map(item => (
            <tr key={item.pk} className={item.parent ? '' : 'table-light'}>
              <td data-label={t`Item`} className={item.parent ? 'ps-4' : ''}>
                { item.resource_reference }:{' '}
                <button
                  type="button"
                  className="btn btn-simplelink"
                  onClick={() => { showModal(item, {
                    onClose: () => {
                      setRefreshItems(v => v + 1);
                    }
                  }); }} >
                  { item.resource_name }
                </button>
              </td>
              <td data-label={t`Item type`}>
                { item.parent === null && (
                  <>
                    <ColorSticker color={item.item_type.color}/>{' '}
                    { item.item_type.resource_name }
                  </>
                ) }
              </td>
              <td data-label={t`Epic`}>
                { item.parent === null && item.epic && (
                  <>
                    <ColorSticker color={item.epic.color}/>{' '}
                    { item.epic.resource_name }
                  </>
                ) }
              </td>
              <td data-label={t`Estimate`} className="responsive-flex">
                <FieldEstimates product={product} backlogItem={item} onEvalUpdate={() => {}} showLabel={false} />
              </td>
            </tr>
          )) }
        </tbody>
      </table>
    </>
  );
}

export default function EstimatesPage(props) {
  const organization = useContext(OrganizationContext);
  const product = useContext(ProductContext);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/${product.slug}`}>{ product.name }</Link>,
    t`Estimates`
  ];

  return (
    <ProductPage
      name="estimates-page"
      title={t`Estimates`}
      breadcrumb={breadcrumb}>
      <EstimatesPageContent
        product={product} />
    </ProductPage>
  );

}
