import { t } from '@lingui/macro';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ContentBlock from 'components/content/ContentBlock';
import LastItemList from '../LastItemList';
import HistoryList from './HistoryList';

function BacklogProductHistory(props) {
  const [key, setKey] = useState('update');
  const { product } = props;

  return (
    <ContentBlock
      className="p-3"
      title={t`Last items`}>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
        mountOnEnter>
        <Tab eventKey="update" title={t`Updates`}>
          <LastItemList columns="1" limit="10" product={product}/>
        </Tab>
        { product.can_see_worklogs && (
          <Tab eventKey="worklogs" title={t`Worklogs`}>
            <HistoryList {...props} model="Worklog" hidePagination />
          </Tab>
        )}
      </Tabs>
    </ContentBlock>
  );
}

export default BacklogProductHistory;
