import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import Datatable from 'components/datatable/Datatable';
import AdminPage from 'components/page/AdminPage';
import OrganizationContext from 'components/utils/OrganizationContext';
import AccountModal from 'components/modal/AccountModal';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';

export default function AccountList() {
  const organization = useContext(OrganizationContext);
  const [refresh, setRefresh] = useState(0);
  const [editItem, setEditItem] = useState(null);

  const breadcrumb = [
    <Link key="0" to="/"><Trans>Home</Trans></Link>,
    <Link key="1" to={`/${organization.slug}`}>{ organization.name }</Link>,
    <Link key="2" to={`/${organization.slug}/manage/worklogs`}><Trans>Work log</Trans></Link>,
    t`Accounts`
  ];

  const onRefresh = () => {
    setRefresh(refresh + 1);
  };

  const onEditClose = () => {
    setRefresh(oldVal => oldVal + 1);
    setEditItem(null);
  };
  const onEdit = (item) => {
    setEditItem(item);
  };
  const onArchive = item => {
    axios.put(`/accounts/${item.pk}`, { archived: true })
      .then((response) => {
        addMessage(
          'archive-success', t`Success`, response.data.meta.message);
        setRefresh(current => current + 1);
      }).catch((error) => addMessage(
        'archive-error', t`Server error`, error.response.data.message));
  };
  const onUnArchive = item => {
    axios.put(`/accounts/${item.pk}`, { archived: false })
      .then((response) => {
        addMessage(
          'unarchive-success', t`Success`, response.data.meta.message);
        setRefresh(current => current + 1);
      }).catch((error) => addMessage(
        'unarchive-error', t`Server error`, error.response.data.message));
  };

  const getActions = item => {
    if (!organization.can_edit_account) return [];
    if (!item.archived) {
      return [
        {
          key: 'onarchive',
          onClick: () => onArchive(item),
          content: <><Trans>Archive</Trans> <Icon name="archive"/></>
        }
      ];
    }
    return [
      {
        key: 'onunarchive',
        onClick: () => onUnArchive(item),
        content: <><Trans>Unarchive</Trans> <Icon name="arrow-counterclockwise"/></>
      }
    ];
  };

  return (
    <AdminPage
      name="account-list-page"
      title={t`Accounts`}
      breadcrumb={breadcrumb}>
      { organization.can_add_account && (
        <AccountModal
          variant="primary"
          onClose={onRefresh}
          organization={organization}
          title={(<><Icon name="plus-circle"/> <Trans>Add account</Trans></>)}
          key="account-add-modal" />
      ) }
      <AccountModal
        account={editItem}
        organization={organization}
        onClose={onEditClose}
        show={!!editItem}
        modalTitle={t`Edit account`}
        hideButton />
      <Datatable
        api="/accounts"
        apiContext={{ organization: organization.pk }}
        editHandler={organization.can_edit_account && onEdit}
        canDelete={organization.can_delete_account}
        refresh={refresh}
        actions={getActions}>
        <Datatable.Column name="name" label={t`Name`}/>
        <Datatable.Column name="customer" label={t`Customer`}>
          {(item) => item.customer.resource_name}
        </Datatable.Column>
        <Datatable.Column name="manager" label={t`Manager`}>
          {(item) => (item.manager ? item.manager.resource_name : '')}
        </Datatable.Column>
        <Datatable.Column name="time_spent" label={t`Time spent`}>
          {(item) => (
            item.time_spent.minutes && item.time_budget && item.time_budget.minutes > 0
              ? `${item.time_spent.human_format} / ${item.time_budget.human_format}` +
              ` (${Math.round(100 * (100 * item.time_spent.minutes / item.time_budget.minutes) + Number.EPSILON) / 100}%)`
              : (item.time_spent.minutes ? item.time_spent.human_format : ''))}
        </Datatable.Column>
        <Datatable.Column name="archived" label={t`Archived`}>
          {(item) => (item.archived && <span className="text-muted"><Trans>Archived</Trans></span>) }
        </Datatable.Column>
      </Datatable>
    </AdminPage>
  );
}
