import React from 'react';
import { Trans, t } from '@lingui/macro';
import FieldShowUpdate from 'components/form/FieldShowUpdate';

export default function FieldEstimate(props) {
  const { backlogItem, title, getValue, field, placeholder, showLabel, readonly } = props;

  const content = (readonly || backlogItem.subtasks_count > 0) ? getValue(backlogItem) : (
    <FieldShowUpdate
      getValue={getValue}
      pk={backlogItem.pk}
      api={backlogItem._genericApi}
      field={field}
      label={title}
      placeholder={placeholder}>
      { bl => (
        bl[field] === null ? (
          <span className="text-secondary"><Trans>Add estimate</Trans></span>
        ) : getValue(bl)
      ) }
    </FieldShowUpdate>
  );

  if (!showLabel) return content;

  return (
    <div className="field-estimate hstack field-value">
      <span className="text-secondary">{ title }</span>
      <div className="ms-auto">
        { content }
      </div>
    </div>
  );
}

const FieldEstimates = function(props) {
  const { backlogItem, product, showLabel, readonly } = props;
  return product.estimate_mode.value === 'hours' ? (
    <FieldEstimate
      backlogItem={backlogItem}
      title={t`Hours estimate`}
      getValue={it => (it.hours_estimate ? it.hours_estimate.human_format : '')}
      field="hours_estimate"
      placeholder="1h 30m"
      showLabel={showLabel}
      readonly={readonly} />
  ) : (
    <FieldEstimate
      backlogItem={backlogItem}
      title={t`Points estimate`}
      getValue={it => (it.points_estimate === null ? '' : it.points_estimate)}
      field="points_estimate"
      showLabel={showLabel}
      readonly={readonly} />
  );
};
FieldEstimates.defaultProps = {
  showLabel: true
};
export { FieldEstimates };
