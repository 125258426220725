import React, { useContext } from 'react';
import { t } from '@lingui/macro';
import { Redirect } from 'react-router';
import { SetPassword } from 'components/auth';
import HomePage from 'components/page/HomePage';
import AppContext from 'AppContext';

export default function SetPasswordPage() {
  const { user } = useContext(AppContext);
  if (user) {
    return <Redirect to="/"/>;
  }

  return (
    <HomePage name="set-password-page" title={t`Set password`}>
      <SetPassword />
    </HomePage>
  );
}
