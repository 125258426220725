import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import ProductForm from 'components/forms/ProductForm';
import Product from 'entity/Product';

export default function ProductModal(props) {
  const { title, onClose, onSave, modalTitle, show, product, organization, hideButton, variant } = props;
  let formProduct = product;
  if (!formProduct) {
    formProduct = new Product();
    if (organization) formProduct.organization = { pk: organization.pk };
  }
  const [showModal, setShowModal] = useState(show);
  const handleClose = () => {
    if (onClose) onClose();
    setShowModal(false);
  };
  const handleSave = (data) => {
    if (onSave) onSave(data);
    setShowModal(false);
  };
  const handleShow = (e) => {
    setShowModal(true);
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <ModalForm
      variant={variant}
      show={showModal}
      handleClose={handleClose}
      handleShow={handleShow}
      title={modalTitle || t`New product`}
      buttonTitle={title || t`Add product`}
      hideButton={hideButton}>
      <ProductForm
        product={formProduct}
        onSave={handleSave}
        onCancel={handleClose}
        organization={organization}>
      </ProductForm>
    </ModalForm>
  );
}
