import React, { useEffect, useState } from 'react';
import { t } from '@lingui/macro';
import ModalForm from 'components/modal/ModalForm';
import StoryMapForm from 'components/forms/StoryMapForm';

function StoryMapModal(props) {
  const { title, modalTitle, hideButton, onClose, show, variant, ...rest } = props;
  const [showModal, setShowModal] = useState(!!show);
  const handleClose = () => {
    if (onClose) onClose();
    setShowModal(false);
  };
  const handleShow = (e) => {
    setShowModal(true);
  };

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  return (
    <ModalForm
      variant={variant}
      show={showModal}
      handleClose={handleClose}
      handleShow={handleShow}
      title={modalTitle || t`New story map`}
      buttonTitle={title || t`Add story map`}
      hideButton={hideButton}
      confirmClose >
      <StoryMapForm {...rest} onSave={handleClose} onCancel={handleClose}></StoryMapForm>
    </ModalForm>
  );
}
StoryMapModal.defaultProps = {
  storyMap: { name: '' }
};

export default StoryMapModal;
