import { t } from '@lingui/macro';
import React, { useContext, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import ProductContext from 'components/utils/ProductContext';
import BacklogItemGitLab from 'integrations/gitlab/BacklogItemGitLab';
import BacklogItemComments from 'components/product/backlogitem/BacklogItemComments';
import BacklogItemWorklogs from 'components/product/backlogitem/BacklogItemWorklogs';
import HistoryList from './HistoryList';

function BacklogHistory(props) {
  const product = useContext(ProductContext);
  const [key, setKey] = useState('comments');
  const { backlogItem } = props;

  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="mb-3"
      mountOnEnter>
      <Tab eventKey="comments" title={t`Comments`}>
        <BacklogItemComments backlogItem={backlogItem}/>
      </Tab>
      { product.can_see_history && (
        <Tab eventKey="update" title={t`Updates`}>
          <HistoryList {...props} model="BacklogItem ItemLink" />
        </Tab>
      ) }
      { product.can_see_worklogs && (
        <Tab eventKey="worklogs" title={t`Worklogs`}>
          <BacklogItemWorklogs {...props} backlogItem={backlogItem} />
        </Tab>
      ) }
      { product.can_see_versioning && backlogItem.integrations_detail.gitlab && (
        <Tab eventKey="gitlab" title={t`GitLab`}>
          <BacklogItemGitLab {...props} data={backlogItem.integrations_detail.gitlab} />
        </Tab>
      )}
      { product.can_see_versioning && backlogItem.integrations_detail.gitlab_webhook && (
        <Tab eventKey="gitlab" title={t`GitLab`}>
          <BacklogItemGitLab {...props} data={backlogItem.integrations_detail.gitlab_webhook} />
        </Tab>
      )}
    </Tabs>
  );
}

export default BacklogHistory;
