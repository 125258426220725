import React, { useCallback, useEffect, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import SelectSearch from 'components/ui/SelectSearch';
import { LoaderContainer } from 'components/ui/Loader';
import { addMessage } from 'components/ui/Messages';
import cache from 'components/utils/cache';
import { ConfirmButton } from 'components/utils';
import { getStateItem, setStateItem } from 'utils/Item';
import { manageFormErrors } from './Form';

function getOptionsFromAPI(cid, api, field, none_label) {
  return () => new Promise((resolve, reject) => {
    let options = cache.get(cid, false, field ? null : 600);
    if (!options) {
      axios.get(api).then((result) => {
        options = [{ label: (none_label || t`None`), value: null }];
        if (field) {
          for (let i = 0; i < result.data.fields[field].choices.length; i++) {
            options.push({
              label: result.data.fields[field].choices[i].label,
              value: result.data.fields[field].choices[i].value
            });
          }
        }
        else {
          for (let i = 0; i < result.data.items.length; i++) {
            options.push({
              label: result.data.items[i].resource_name,
              value: result.data.items[i].pk,
              item: result.data.items[i]
            });
          }
        }
        cache.set(cid, options);
        resolve(options);
      }).catch(err => {
        addMessage(
          cid,
          t`Unknown error`,
          t`Impossible to load option list.`
        );
        reject(err);
      });
    }
    else {
      resolve(options);
    }
  });
}

function SelectSearchEditable(props) {
  const {
    api,
    searchApi,
    field,
    fieldLabel,
    onBeforeUpdate,
    onUpdate,
    emptyLabel,
    pk,
    stateKey,
    overlay,
    variant,
    dropRight
  } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [confirm, setConfirm] = useState(false);

  const finalStateKey = stateKey || `${api}:${pk}`;
  const item = getStateItem(finalStateKey);

  const setValue = useCallback((val) => {
    setLoading(true);
    let obj = {};
    obj[field] = val;
    setError(null);
    let sendValue = true;
    if (onBeforeUpdate) {
      if (confirm && confirm.confirmed) {
        obj = confirm.obj;
      }
      else {
        const ret = onBeforeUpdate(val, obj);
        if (ret) {
          sendValue = false;
          setConfirm({
            message: ret.message,
            confirmed: false,
            obj: ret.obj
          });
        }
      }
    }
    if (sendValue) {
      axios.put(`${api}/${item.pk}`, obj).then(res => {
        item[field] = res.data[field];
        setStateItem(finalStateKey, res.data, { isFull: true });
        setLoading(false);
        if (onUpdate) onUpdate(field, res.data[field], item);
      }).catch(err => {
        const { globalErrors } = manageFormErrors(null, err, true);
        setError(globalErrors.map(e => e.error).join(<br/>));
        setLoading(false);
      });
    }

  }, [item, onUpdate, field, api, onBeforeUpdate, confirm, finalStateKey]);

  useEffect(() => {
    if (confirm && confirm.confirmed) {
      setValue(confirm.obj[field]);
    }
  }, [field, setValue, confirm]);

  if (!item || !(field in item)) {
    return null;
  }

  let btnTitle = emptyLabel || t`None`;
  if (item[field]) {
    btnTitle = item[field][fieldLabel];
  }

  return (
    <div className="d-inline-block relative">
      { (loading) && (
        <LoaderContainer size="tiny"/>
      ) }

      <OverlayTrigger
        show={error !== null}
        placement={overlay}
        overlay={(
          <Popover id={'dropdown-item-button-popover--' + field}>
            <Popover.Body>
              { error }
            </Popover.Body>
          </Popover>
        )}>
        {({ ref, ...triggerHandler }) => (
          <div {...triggerHandler} ref={ref}>
            <SelectSearch
              value={item[props.name]}
              handleSelect={(o) => setValue(o)}
              searchApi={searchApi}
              isInvalid={error !== null}
              validation={false}
              id={'dropdown-item-button--' + field}
              buttonText={btnTitle}
              variant={variant}
              dMenu={(dropRight ? 'dropdown-menu-end ' : '') + 'w-260'}
              noPopper>
            </SelectSearch>
          </div>
        )}
      </OverlayTrigger>
      { confirm && !confirm.confirmed && (
        <ConfirmButton
          noButton
          message={confirm.message}
          variant="outline-secondary"
          onConfirm={() => {
            setConfirm({
              message: confirm.message,
              obj: confirm.obj,
              confirmed: true
            });
          }}
          onCancel={() => {
            setLoading(false);
            setConfirm(false);
          }}>
          <Trans>Remove</Trans>
        </ConfirmButton>
      ) }
    </div>
  );
}
SelectSearchEditable.defaultProps = {
  overlay: 'right',
  dropRight: false
};

export { getOptionsFromAPI };
export default SelectSearchEditable;
