import React from 'react';
import { Helmet } from 'react-helmet-async';
import ContentBlock from 'components/content/ContentBlock';
import Breadcrumb from 'components/content/Breadcrumb';
import Actions from 'components/content/Actions';
import NotificationsDisabled from 'components/topnav/NotificationsDisabled';

function InternalPage(props) {
  const classes = ['internal-page'];

  const { name, title, breadcrumb, actions, nocontent, className, ...rest } = props;
  if (name) classes.push(props.name);
  if (nocontent) classes.push('nocontent');
  if (className) classes.push(className);

  const body = nocontent ? props.children : (
    <ContentBlock
      className="p-3"
      nocontent={nocontent}>
      {props.children}
    </ContentBlock>
  );

  return (
    <div className={classes.join(' ')} {...rest}>
      <NotificationsDisabled />
      <main className="flex-grow-1">
        <div className="px-2">
          { title && (
            <>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              <h1 className="main-title">{ title }</h1>
            </>
          ) }
          <div className="page-header">
            <Breadcrumb links={breadcrumb}/>
            { actions && (
              <div className="d-flex justify-content-end align-items-center">
                <Actions links={actions}/>
              </div>
            ) }
          </div>
          { body }
        </div>
      </main>
    </div>
  );
}

export default InternalPage;
