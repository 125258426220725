import React, { useContext, useEffect, useState } from 'react';
import * as BS from 'react-bootstrap';
import { t } from '@lingui/macro';
import { ColorSticker } from 'components/ui/ColorSticker';
import { FormContext } from './FormContext';
import BaseField from './BaseField';

const colorList = [
  { name: t`Yellow`, value: 'yellow' },
  { name: t`Orange`, value: 'orange' },
  { name: t`Red`, value: 'red' },
  { name: t`Carmine`, value: 'carmine' },
  { name: t`Pink`, value: 'pink' },
  { name: t`Purple`, value: 'purple' },
  { name: t`Indigo`, value: 'indigo' },
  { name: t`Blue`, value: 'blue' },
  { name: t`Cyan`, value: 'cyan' },
  { name: t`Teal`, value: 'teal' },
  { name: t`Green`, value: 'green' },
  { name: t`Lime`, value: 'lime' },
  { name: t`White`, value: 'white' },
  { name: t`Grey`, value: 'grey' },
];

export default function Color({ children, ...props }) {
  const { item, version, setItem, meta, errors } = useContext(FormContext);
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    setValidated(false);
  }, [version]);

  const setColor = color => {
    const { name } = props;
    setValidated(true);
    item[name] = color;
    setItem(item);
  };

  const { label } = props;
  const groupClasses = ['form-group'];
  if (props.required) groupClasses.push('required');
  if (validated) groupClasses.push('was-validated');

  let defaultOption = null;
  if (props.required && !item[props.name]) {
    defaultOption = t`Please choose a value`;
  }
  else if (!props.required) {
    defaultOption = t`None`;
  }

  if (!meta.fields || !(props.name in meta.fields)) return null;

  const defaultVal = item[props.name] && item[props.name].value || item[props.name] || null;
  const actValue = defaultVal ? (
    <>
      <span className={'color-pill bg-col-' + defaultVal}></span> { defaultVal }
    </>
  ) : defaultOption;
  const fieldProps = {};
  if (errors[props.name]) {
    fieldProps.className = 'is-invalid';
  }
  return (
    <BaseField
      {...props}
      className={groupClasses.join(' ')}
      label={label || meta && meta.fields && meta.fields[props.name] && meta.fields[props.name].name}
      humanValue={actValue}>
      <BS.Dropdown {...fieldProps}>
        <BS.Dropdown.Toggle variant="light">
          { actValue }
        </BS.Dropdown.Toggle>
        <BS.Dropdown.Menu className="dropdown-menu-colors">
          { colorList.map(col => (
            <BS.Dropdown.Item
              key={col.value}
              as="button"
              type="button"
              className={'btn' + (defaultVal === col.value ? ' is-selected' : '')}
              onClick={(e) => setColor(col.value)}>
              <ColorSticker color={col.value}/> { col.name }
            </BS.Dropdown.Item>
          )) }
        </BS.Dropdown.Menu>
      </BS.Dropdown>
    </BaseField>
  );
}

export { colorList };
