/* eslint-disable no-alert */
import { t } from '@lingui/macro';
import axios from 'axios';
import { getStateItem, setStateItem } from 'utils/Item';

export default class Entity {
  constructor(params, api, genericApi, fields) {
    this._api = api;
    this._genericApi = genericApi;
    this._paramsList = params;
    this._params = {};
    this._fields = fields;
    this._meta = null;
    this._events = {};
    this._url = null;
  }

  _getApiUrl() {
    if (this._url) return this._url;
    const re = new RegExp(Object.keys(this._params).join('|'), 'gi');
    this._url = this._api.replace(re, (matched) => this._params[matched]);
    return this._url;
  }

  _getStateKey() {
    return this._genericApi + ':' + this.pk;
  }

  reload(refreshParent = true) {
    
    return new Promise((resolve, reject) => {
      axios.get(this._getApiUrl()).then((response) => {
        this.loadFromObject(response.data);
        setStateItem(this._getStateKey(), this, {
          isFull: true,
          refreshParent: refreshParent
        });
        resolve(response);
      }).catch((error) => { reject(error); });
    });
  }

  load(params, refreshParent = true) {
    this.loadParams(params);
    return this.reload(refreshParent);
  }

  loadFromState(pk, params) {
    this.pk = pk;
    const state = getStateItem(this._getStateKey(), null, { isFull: true });
    if (state !== null) {
      this.loadParams(params);
      this.loadFromObject(state);
      return new Promise(resolve => {
        resolve(state);
      });
    }
    return this.load(params);
  }

  loadMeta() {
    return new Promise((resolve, reject) => {
      axios.get(this._genericApi + '/meta').then((response) => {
        this._meta = response.data;
        resolve(response.data);
      }).catch((error) => { reject(error); });
    });
  }

  loadFromObject(obj, params, updateState) {
    this.loadParams(params);
    Object.assign(this, obj);
    if (updateState) setStateItem(this._genericApi + ':' + this.pk, this, { isFull: true });
  }

  loadParams(params) {
    if (!params) return;
    if (typeof params === 'object') {
      for (const key in params) {
        this._params[':' + key] = params[key];
      }
    }
    else {
      this._params[':' + this._paramsList[0]] = params;
    }
  }

  getEntityToSave() {
    return this._fields.reduce((obj, key) => {
      if (key in this) obj[key] = this[key];
      return obj;
    }, {});
  }

  save() {
    const entity = this.getEntityToSave();

    return new Promise((resolve, reject) => {
      if (this.pk) {
        axios.put(`${this._genericApi}/${this.pk}`, entity)
          .then(response => {
            resolve(response);
          })
          .catch((error) => { reject(error); });
      }
      else {
        axios.post(this._genericApi, entity)
          .then(response => {
            resolve(response);
          })
          .catch((error) => { reject(error); });
      }
    });
  }

  saveField(field) {
    return new Promise((resolve, reject) => {
      const entity = {};
      entity[field] = this[field];
      axios.put(`${this._genericApi}/${this.pk}`, entity)
        .then(response => {
          setStateItem(this._getStateKey(), this, { isFull: true });
          resolve(response);
        })
        .catch((error) => { reject(error); });
    });
  }

  delete(message) {
    if (window.confirm(message || t`Are you sure you want to delete this item?`)) {
      return axios.delete(`${this._genericApi}/${this.pk}`);
    }
    return new Promise((resolve) => { resolve(); });
  }

  clone() {
    const newObj = Object.assign(
      Object.create(
        Object.getPrototypeOf(this),
      ),
      JSON.parse(JSON.stringify(this))
    );
    newObj._events = this._events;
    return newObj;
  }

  getPath() {
    if (this.path) return this.path;
    return null;
  }
}
