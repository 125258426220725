import React, { useContext, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';
import StoryMapItemModal from 'components/modal/StoryMapItemModal';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { useStateItem } from 'utils/Item';
import BacklogItemStorymapModal from 'components/modal/BacklogItemStorymapModal';
import StoryMapContext from './StoryMapContext';
import StoryMapCell from './StoryMapCell';

function EditReleaseDropdown(props) {
  const { storyMap, release, product, setShowAddBl } = props;
  const { loadStoryMapReleases, storyMapReleases, loadTasks } = useContext(StoryMapContext);

  const onHide = () => {
    release.hidden = !release.hidden;
    axios.put('/story-map-releases/' + release.pk, release).then(res => {
      loadStoryMapReleases();
    }).catch(err => {
      addMessage('edit-release', t`Unknown error`, t`Impossible to edit release`);
    });
  };

  const onRemoved = () => {
    axios.delete('/story-map-releases/' + release.pk).then(() => {
      loadStoryMapReleases();
      loadTasks();
    }).catch(err => {
      addMessage('delete-release', t`Unknown error`, t`Impossible to delete release`);
    });
  };

  const onSaveRelease = (item) => {
    const newReleases = [...storyMapReleases];
    const ind = newReleases.findIndex(s => s.pk === release.pk);
    newReleases[ind] = item;
    loadStoryMapReleases(newReleases);
  };

  return (
    <Dropdown className="no-carret">
      <Dropdown.Toggle variant="sm">
        <Icon name="three-dots" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={onHide}>
          <Icon name="eye-slash" /> { release.hidden ? t`Show release` : t`Hide release`}
        </Dropdown.Item>
        <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={onRemoved}>
          <Icon name="x-circle"/> <Trans>Remove release</Trans>
        </Dropdown.Item>
        <Dropdown.Item
          as={StoryMapItemModal}
          api="story-map-releases"
          product={product}
          organization={product.organization}
          onSave={onSaveRelease}
          modalTitle={release.name}
          title={<><Icon name="pencil"/> <Trans>Edit</Trans></>}
          parent="story_map"
          parentItem={storyMap}
          item={release}
          variant="outline-secondary dropdown-item" />
        { product.can_add_item && (
          <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={() => { setShowAddBl(true); }}>
            <Icon name="list-check"/> <Trans>Create backlog items</Trans>
          </Dropdown.Item>
        ) }
        
      </Dropdown.Menu>
    </Dropdown>
  );
}

function StoryMapRelease(props) {
  const { storyMapReleasePk, product, dragHandleProps, rows, columns, isDragging } = props;
  const r = useStateItem('story-map-releases:' + storyMapReleasePk);
  const [showAddBl, setShowAddBl] = useState(false);
  if (!r) return null;
  return (
    <>
      <div className="story-map-release-header">
        { product.can_add_item && (
          <BacklogItemStorymapModal
            filter={ta => ta.release.pk === storyMapReleasePk}
            status="backlog"
            show={showAddBl}
            onClose={() => { setShowAddBl(false); }}
            hideButton />
        ) }
        
        <div
          className={'story-map-card story-map-card--release' + (r.hidden ? ' hidden' : '') + (isDragging ? ' is-draging' : '')}
          {...dragHandleProps}>
          <div className="story-map-card__header bg-col-cyan">
            <Icon size="18" name="file-diff" />
            {' '}
            <span><Trans>Release</Trans></span>
            { product.can_edit_item && (
              <EditReleaseDropdown
                storyMap={r.storyMap}
                release={r}
                product={product}
                setShowAddBl={setShowAddBl} />
            ) }
          </div>
          <div className="story-map-card__body">
            { product.can_edit_item ? (
              <FieldShowUpdate
                element={r}
                pk={r.pk}
                api="story-map-releases"
                field="name"
                label={t`Name`}>
                {it => (
                  <span className="name">{ it.resource_name }</span>
                )}
              </FieldShowUpdate>
            ) : r.name }
          </div>
        </div>
      </div>
      { columns.map((activityPk, index2) => (
        <StoryMapCell
          key={activityPk !== null ? activityPk : ('ind-' + index2)}
          rows={rows}
          storyMapRelease={r}
          activityPk={activityPk} />
      )) }
      <StoryMapCell
        rows={rows}
        storyMapRelease={r}
        activityPk="NONE"
        emptyActivity />
    </>
  );
}

// eslint-disable-next-line import/prefer-default-export
export { EditReleaseDropdown };
export default StoryMapRelease;
