import React from 'react';
import { Button } from 'react-bootstrap';
import { Trans } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';

function OrganizationFinalForm(props) {
  const { onSave } = props;

  return (
    <Form
      name="organization-add-form"
      noValidate
      onSave={onSave}>
      <Form.Field id="name" name="name" required/>
      <div className="actions">
        <Button variant="primary" type="submit"><Trans>Next</Trans></Button>
      </div>
    </Form>
  );
}

export default function OrganizationForm(props) {
  const { organization, ...rest } = props;

  return (
    <FormContextProvider item={organization} api="/organizations">
      <OrganizationFinalForm {...rest} />
    </FormContextProvider>
  );
}
