import React, { useEffect, useState } from 'react';
import { Form, FormCheck } from 'react-bootstrap';
import axios from 'axios';
import { t } from '@lingui/macro';
import { LoaderContainer } from 'components/ui/Loader';
import { getSWRegistration } from 'utils/ServiceWorkerLoader';

function urlB64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function DesktopNotificationSwitch() {
  const [serverPublicKey, setServerPublicKey] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    axios.get('users/web-push-notification').then(res => {
      setServerPublicKey(res.data.public_key);
    }).catch(function(err) {
      console.error('Failed to get server public key ', err);
      setSubscribed(false);
      setDisabled(true);
    });
    if (getSWRegistration()) {
      getSWRegistration().pushManager
        .getSubscription()
        .then((subscription) => {
          setSubscribed(subscription !== null);
        });
    } else {
      setDisabled(true);
    }
  }, []);

  const subscribeUser = () => {
    if (!getSWRegistration()) return;
    getSWRegistration().pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(serverPublicKey)
    }).then(function(subscription) {
      axios.post('users/web-push-notification', subscription).then(result => {
        setSubscribed(true);
        setLoading(false);
      }).catch(function(err) {
        subscription.unsubscribe();
        console.error('Failed to subscribe the user: ', err);
        setLoading(false);
      });
    }).catch(function(err) {
      console.error('Failed to subscribe the user: ', err);
      setLoading(false);
    });
  };

  function unsubscribeUser() {
    if (!getSWRegistration()) return;
    getSWRegistration().pushManager.getSubscription()
      .then(function(subscription) {
        if (subscription) {
          axios.delete('users/web-push-notification', { data: subscription }).then(result => {
            setSubscribed(false);
            subscription.unsubscribe();
          }).catch(error => {
            console.error('Failed to unsubscribe the user: ', error);
          });
        }
        setLoading(false);
      }).catch(function(err) {
        console.error('Error unsubscribing', err);
        setLoading(false);
      });
  }

  const handleChange = (checked) => {

    setLoading(true);
    if (subscribed) {
      unsubscribeUser();
    } else {
      subscribeUser();
    }
  };

  return (
    <>
      { loading && (
        <LoaderContainer size="tiny" />
      ) }
      <FormCheck
        id="toggle-desktop-notification"
        checked={subscribed}
        onChange={(e) => handleChange(e.currentTarget.checked)}
        label={t`Desktop notification on this device`}
        className="me-2 d-inline-block"
        type="switch"
        disabled={disabled}
        aria-describedby="desktop-notification-help-block"
      /><br/>
      <Form.Text id="desktop-notification-help-block" muted>
        {
          (disabled ? t`Your device doesn't support desktop notifications` :
            t`Tick to enable desktop notifications`)
        }
      </Form.Text>
    </>
  );
}

export default DesktopNotificationSwitch;
