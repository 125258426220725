import React, { useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Helmet } from 'react-helmet-async';
import HomePage from 'components/page/HomePage';

export default function ErrorPage(props) {
  const { error, componentStack } = props.params;
  const [showMore, setShowMore] = useState(false);
  return (
    <HomePage name="page-error">
      <Helmet>
        <title>{t`Error`} – {t`Optera.io`}</title>
      </Helmet>
      <h4><Trans>It looks like we have some internal issues</Trans></h4>
      <p><Trans>Our team has been notified</Trans></p>
      <p><a href="/" className="btn btn-outline-light"><Trans>Go back to home</Trans></a></p>
      <hr />
      <button type="button" className="btn btn-simplelink" onClick={() => { setShowMore(!showMore); }}>
        { showMore ? t`Hide details` : t`Show details` }
      </button>
      { showMore && (
        <div className="my-2 alert alert-danger text-small">
          <div>{error.toString()}</div>
          <hr />
          <pre>{componentStack}</pre>
        </div>
      ) }
    </HomePage>
  );
}
