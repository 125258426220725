import { modelsApi } from 'components/utils/constants';
import Entity from './Entity';

export default class Trigger extends Entity {

  constructor() {
    super(
      ['pk'],
      modelsApi.Trigger.api + '/:pk',
      modelsApi.Trigger.api,
      [
        'pk',
        'name',
        'when',
        'active'
      ]);
  }
}
