import React, { useContext, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import { Link, useHistory } from 'react-router-dom';
import AppContext from 'AppContext';
import UserPage from 'components/page/UserPage';
import ContentBlock from 'components/content/ContentBlock';
import Icon from 'components/ui/Icon';
import ProductModal from 'components/modal/ProductModal';
import ProductList from 'components/product/ProductList';
import LastItemList from 'components/product/LastItemList';

export default function DashboardPage() {
  const { user } = useContext(AppContext);
  const [refresh, setRefresh] = useState(1);
  const onClose = () => setRefresh(refresh + 1);
  const history = useHistory();

  if (user.memberships.length < 1) {
    history.replace('/organizations/add');
    return null;
  }

  const productsActions = [
    <Link key="1" to="/products"><Trans>See all</Trans></Link>
  ];
  if (user.can_add_product) {
    productsActions.push(
      <ProductModal
        onSave={onClose}
        key="2" />
    );
  }

  const orgActions = [
    <Link key="1" to="/organizations"><Trans>See all</Trans></Link>
  ];
  orgActions.push(
    <Link key="2" className="btn btn-secondary" to="organizations/add">
      <Icon name="plus"/><Trans>Add</Trans>
    </Link>
  );

  const myitemsActions = [
    (
      <Link
        className="btn btn-outline-secondary"
        key="see-all-my-items"
        to={'/user/items?status_type=backlog&status_type=todo&status_type=in_progress&assigned_user=' + user.pk}>
        <Trans>See all</Trans>
      </Link>
    )
  ];
  return (
    <UserPage name="dashboard-page" title={t`Dashboard`} nocontent>
      <ContentBlock
        className="p-3 mb-3"
        title={t`Products`}
        actions={productsActions}>
        <ProductList limit="4" type="small" columns="4" refresh={refresh} onAddProduct={onClose} />
      </ContentBlock>
      <div className="row">
        <div className="col-xl-6" data-cy="my-items">
          <LastItemList 
            title={t`My items`}
            actions={myitemsActions}
            columns="2"
            assignee={user}
            limitLess="8"
            limit="16"/>
        </div>
        <div className="col-xl-6" data-cy="last-items">
          <LastItemList
            columns="2"
            title={t`Last items`}
            user={user}
            limitLess="8"
            limit="16"/>
        </div>
      </div>
    </UserPage>
  );
}
