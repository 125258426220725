import React from 'react';
import { colorList } from 'components/form/Color';

function ColorSticker(props) {
  const { color } = props;
  if (!color) return null;
  return (
    <span className={'color-pill bg-col-' + color}></span>
  );
}

function ColorStickerName(props) {
  const { color } = props;
  if (!color) return null;
  const def = colorList.find(el => el.value === color);
  return (
    <>
      <ColorSticker {...props}/>{' '}
      { def ? def.name : '' }
    </>
  );
}

export { ColorSticker, ColorStickerName };
