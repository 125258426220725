import { i18n } from '@lingui/core';

function websiteUrl(path) {
  let url = process.env.REACT_APP_WEBSITE_URL;
  const finalPath = path.startsWith('/') ? path.substr(1) : path;
  url += `${(i18n.locale === 'en' ? '' : '/' + i18n.locale)}/${finalPath}`;
  return url;
}

const getAbsoluteUrl = (path, query) => {
  const base = process.env.REACT_APP_PUBLIC_URL;
  const hasTrailing = base.charAt(base.length - 1) === '/';
  let url = base;
  if (path.charAt(0) === '/') {
    if (hasTrailing) {
      url += path.substring(1);
    } else {
      url += path;
    }
  } else {
    url += (hasTrailing ? '' : '/') + path;
  }
  if (query) {
    const queryString = query.map(entry => entry[0] + '=' + encodeURIComponent(entry[1])).join('&');
    return url + '?' + queryString;
  }
  return url;
};

export { getAbsoluteUrl, websiteUrl };
