/* eslint-disable no-lonely-if */

import React, { useRef, useState } from 'react';
import { Dropdown, FormControl } from 'react-bootstrap';
import { t, Trans } from '@lingui/macro';
import Icon from 'components/ui/Icon';
import Pagination from 'components/ui/Pagination';
import QueryParams from 'utils/QueryParams';
import { modelsApi } from 'components/utils/constants';
import SimpleModal from 'components/modal/SimpleModal';
import { ActiveFilters, FiltersDropdown, normalizeFilters, useParamsFilters } from 'components/filters/Filters';
import { LoaderContainer } from 'components/ui/Loader';
import BulkExportModal from 'components/modal/BulkExportModal';
import BulkPrintModal from 'components/modal/BulkPrintModal';

function manageFilter(item, filters, optsGroup, apiContext, filtersData, filtersBase, filtersExtra) {
  const showFilter = it => {
    const { name, technical } = it;
    if (name in apiContext) return false;
    if (technical) return false;
    if (filtersExtra && filtersExtra.indexOf(name) !== -1) {
      return 'more';
    }
    if (filtersBase) {
      if (filtersBase.indexOf(name) !== -1) {
        return 'base';
      }
      return false;
    }
    return 'base';
  };

  const filterView = showFilter(item);
  if (filterView !== false) {
    if ('choices' in item) {
      if (filterView === 'more') {
        const data = {
          name: item.name,
          label: item.label,
          items: item.choices
        };
        filters[filterView].checkboxes.push(data);
        filters[filterView].total += 1;
      }
      else {
        for (const option of item.choices) {
          optsGroup.items.push({
            field: item.name,
            ...option
          });
        }
      }

    }
    else if (item.type === 'ModelObject') {
      if ('related_model' in item &&
        item.related_model in modelsApi) {
        let { api } = modelsApi[item.related_model];
        if (item.name === 'parent') {
          const context = apiContext ? { ...apiContext, subtasks: 'parent' } : { subtasks: 'parent' };
          const p = new URLSearchParams(context);
          api += '?' + p.toString();
        }
        else if (apiContext) {
          const p = new URLSearchParams(apiContext);
          api += '?' + p.toString();
        }
        filters[filterView].extra.push({
          api: api,
          ...item
        });
        filters[filterView].total += 1;
      }
      else if (item.related_model === 'ItemStatus' && filtersData && ('product' in filtersData)) {
        filters[filterView].extra.push({
          data: filtersData.product.statuses,
          ...item
        });
        filters[filterView].total += 1;
      }
    }
    else if (item.type === 'BooleanField') {
      if (filterView === 'more') {
        filters[filterView].check.push({
          name: item.name,
          label: item.label
        });
        filters[filterView].total += 1;
      }
      else {
        optsGroup.items.push({
          field: item.name,
          label: item.label,
          value: true
        });
      }
    }
    else if (item.type === 'autocomplete') {
      filters[filterView].autocomplete.push(
        {
          field: item.name,
          label: item.label,
          api: item.api
        }
      );
      filters[filterView].total += 1;
    }
    else if (item.type === 'callback') {
      item.callback(filters[filterView]);
    }
  }
}
function prepareFilters(filtersItems, apiContext, filtersData, filtersBase, filtersExtra) {
  const filters = {
    dropdown: [],
    base: {
      extra: [],
      autocomplete: [],
      check: [],
      checkboxes: [],
      total: 0
    },
    more: {
      extra: [],
      check: [],
      autocomplete: [],
      checkboxes: [],
      total: 0
    }

  };
  const optsGroupDefault = {
    label: 'default',
    name: 'default',
    items: []
  };
  const traverse = function(group, optsGroup) {
    if (!group.is_group && !('choices' in group)) {
      manageFilter(group, filters, optsGroup, apiContext, filtersData, filtersBase, filtersExtra);
    }
    else {
      const optsGroupLocal = {
        label: group.label,
        name: group.name,
        items: []
      };
      if ('choices' in group) {
        manageFilter(group, filters, optsGroupLocal, apiContext, filtersData, filtersBase, filtersExtra);
      }
      else {
        for (const item of group.items) {
          traverse(item, optsGroupLocal);
        }
      }
      if (optsGroupLocal.items.length > 0) {
        filters.dropdown.push(optsGroupLocal);
      }
    }
  };
  for (const group of filtersItems) {
    traverse(group, optsGroupDefault);
  }
  if (optsGroupDefault.items.length > 0) {
    filters.dropdown.push(optsGroupDefault);
  }
  return normalizeFilters(filters);
}

function SelectedActions(props) {
  const { selectedComponents, pks, setSelectedPk, params, variant, align, dropdown } = props;
  const [isLoading, setLoading] = useState(false);
  if (selectedComponents.length < 1 || pks.length < 1) return null;
  if (selectedComponents.length < 2 && !dropdown) {
    const Comp = selectedComponents[0];
    return (
      <>
        <Comp pks={pks} setSelectedPk={setSelectedPk} setLoading={setLoading} />
        { setSelectedPk && (
          <button type="button" className="btn btn-outline-dark" onClick={() => { setSelectedPk([]); }}>
            <Trans>Unselect all items</Trans>
          </button>
        ) }
        { isLoading && (
          <LoaderContainer position="fixed" />
        ) }
      </>
    );
  }
  return (
    <>
      <Dropdown className="d-inline-block">
        <Dropdown.Toggle className="btn" variant={variant}>
          { props.children ? props.children : (
            <>
              <Trans>Actions</Trans>
              <span className="badge text-bg-secondary ms-2">{ pks.length }</span>
            </>
          ) }
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-2" align={align}>
          { selectedComponents.map(Comp => (
            <Comp key={Comp} dropdownItem pks={pks} setLoading={setLoading} setSelectedPk={setSelectedPk} params={params}/>
          )) }
        </Dropdown.Menu>
      </Dropdown>
      { setSelectedPk && (
        <button type="button" className="btn btn-outline-dark" onClick={() => { setSelectedPk([]); }}>
          <Trans>Unselect all items</Trans>
        </button>
      ) }
      { isLoading && (
        <LoaderContainer />
      ) }
    </>
  );
}
SelectedActions.defaultProps = {
  variant: 'outline-dark',
  align: 'start',
  dropdown: false
};

function DatatableFilters(props) {
  const { data, setPage, filtersItems, filtersData, filtersBase, filtersExtra, requestParams,
    pageSize, setPSize, pSizes, search, apiContext, selectedComponents, selectedPks, setSelectedPk } = props;
  const searchRef = useRef(null);
  const { history } = useParamsFilters();

  const onNav = (page) => {
    setPage(page);
  };
  const handleSearch = async() => {
    const { value } = searchRef.current;
    const params = new QueryParams();
    if (value.trim().length > 0) {
      params.set('search', value.trim());
    }
    else {
      params.delete('search');
    }
    history.push({ search: params.toString() });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleSearch();
    }
    if (e.keyCode === 8) {
      if (e.target.value.length === 0) {
        handleSearch();
      }
    }
  };

  if (filtersItems === null || data === null) return null;

  const handleClose = () => {};
  const availableFilters = prepareFilters(filtersItems, apiContext, filtersData, filtersBase, filtersExtra);
  const startItem = (data.meta.subset.page - 1) * data.meta.subset.page_size + 1;
  const endItem = Math.min(
    data.meta.subset.count,
    data.meta.subset.page * data.meta.subset.page_size
  );
  const hasBulkExport = !!selectedComponents.find(c => c === BulkExportModal);
  const hasBulkPrint = !!selectedComponents.find(c => c === BulkPrintModal);

  return (
    <>
      <div className="table-datatable-head d-md-flex justify-content-between">
        <div className="d-lg-none">
          <SimpleModal
            handleClose={handleClose}
            title={t`Filter list`}
            variant="primary"
            buttonTitle={t`Filter list`}
            className="modal-datatable-filters">
            <FiltersDropdown availableFilters={availableFilters} />
            { search && (
              <div className="search">
                <div className="input-group flex-nowrap">
                  <div className="form-floating">
                    <FormControl
                      ref={searchRef}
                      className="form-control w-100"
                      type="text"
                      aria-label="Search"
                      placeholder="Search"
                      id="datatable-search-mobile"
                      onKeyDown={handleKeyDown}/>
                    <label htmlFor="datatable-search-mobile"><Trans>Search</Trans></label>
                  </div>
                  <button type="button" className="btn btn-dark" onClick={handleSearch}><Icon name="search"/></button>
                </div>
              </div>
            )}
            <div className="filters">
              <ActiveFilters availableFilters={availableFilters} />
            </div>
          </SimpleModal>
          <div className="filters mt-2">
            <ActiveFilters availableFilters={availableFilters} />
            <SelectedActions selectedComponents={selectedComponents} pks={selectedPks} setSelectedPk={setSelectedPk} />
          </div>
        </div>
        <div className="filter-input filter-input--desktop d-none d-lg-block">
          <FiltersDropdown availableFilters={availableFilters} />
          { search && (
            <div className="search d-none d-lg-inline-block">
              <div className="input-group flex-nowrap">
                <div className="form-floating">
                  <FormControl
                    ref={searchRef}
                    className="w-100"
                    type="text"
                    aria-label="Search"
                    placeholder="Search"
                    id="datatable-search"
                    onKeyDown={handleKeyDown}/>
                  <label htmlFor="datatable-search"><Trans>Search</Trans></label>
                </div>
                <button type="button" className="btn btn-dark" onClick={handleSearch}><Icon name="search"/></button>
              </div>
            </div>
          )}
        </div>
        <div className="table-pagination">
          <div className="mb-1">
            <small className="d-block d-md-inline me-1">
              {data.meta.subset.page_count > 1 && (
                <>
                  {startItem}-{endItem} /{' '}
                </>
              )}
              {data.meta.subset.count}&nbsp;<Trans>results</Trans>
            </small>
            <small className="d-block d-md-inline me-1">
              <select
                className="ms-1 form-select form-select-sm select-items-by-page"
                onChange={e => { setPSize(parseInt(e.target.value, 10)); setPage(1); }}
                value={pageSize}>
                { pSizes.map(size => (
                  <option value={size} key={size}>{size}</option>
                ))}
              </select> / <Trans>Page</Trans>
            </small>
          </div>
          { hasBulkExport && (
            <BulkExportModal className="me-2" requestParams={requestParams} limit={5000} count={data.meta.subset.count} />
          )}
          { hasBulkPrint && (
            <BulkPrintModal requestParams={requestParams} limit={500} count={data.meta.subset.count} />
          )}
          <Pagination
            type="mini"
            className="justify-content-end"
            count={Math.ceil(data.meta.subset.count / pageSize)}
            page={data.meta.subset.page}
            onNav={onNav} />
        </div>
      </div>
      <div className="filters d-none d-lg-block">
        <ActiveFilters availableFilters={availableFilters} />
        <SelectedActions selectedComponents={selectedComponents} pks={selectedPks} setSelectedPk={setSelectedPk} />
      </div>
    </>
  );
}
DatatableFilters.defaultProps = {
  selectedComponents: [],
  selectedPks: []
};

export default DatatableFilters;
export { SelectedActions };
