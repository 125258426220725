import React, { useContext, useState } from 'react';
import { t, Trans } from '@lingui/macro';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';
import StoryMapItemModal from 'components/modal/StoryMapItemModal';
import FieldShowUpdate from 'components/form/FieldShowUpdate';
import { useStateItem } from 'utils/Item';
import StoryMapContext from './StoryMapContext';
import { StoryMapTableHeaderGoals } from './StoryMapGoal';

export function StoryMapTableHeaderPersonas(props) {
  const { storyMap } = props;
  const { personas, loadPersonas, loadStoryMapReleases } = useContext(StoryMapContext);
  return (
    <Droppable droppableId="personas" direction="horizontal" type="personas">
      {(provided, snapshot) => (
        <div
          className={'story-map-personas droppable-container' + (snapshot.isDraggingOver ? ' is-drag-over' : '')}
          ref={provided.innerRef}>
          <div className="story-map-personas__header">
            <StoryMapItemModal
              api="personas"
              title={t`New persona`}
              buttonTitle={t`Add persona`}
              parent="story_map"
              parentItem={storyMap}
              onSave={() => { loadPersonas(); }} />
            <StoryMapItemModal
              api="story-map-releases"
              title={t`New release`}
              buttonTitle={t`Add release`}
              parent="story_map"
              parentItem={storyMap}
              onSave={() => { loadStoryMapReleases(); }} />
          </div>
          { personas.map((p, index2) => (
            <Draggable
              key={p}
              draggableId={'p-' + String(p)}
              index={index2}>
              {(provided2, snapshot2) => (
                <div
                  ref={provided2.innerRef}
                  className="story-map-persona"
                  data-colid={'p-' + p}
                  key={'p-' + p}
                  {...provided2.draggableProps}>
                  <StoryMapPersona
                    dragHandleProps={provided2.dragHandleProps}
                    isDragging={snapshot2.isDragging}
                    personaPk={p}
                    {...props} />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}

function EditPersonaDropdown(props) {
  const { persona } = props;
  const { loadPersonas, loadTasks, loadGoals } = useContext(StoryMapContext);
  const [showGoalModal, setShowGoalModal] = useState(false);

  const onHide = () => {
    persona.hidden = !persona.hidden;
    axios.put('/personas/' + persona.pk, persona).then(() => {
      loadPersonas();
    }).catch(err => {
      addMessage('edit-goal', t`Unknown error`, t`Impossible to edit persona`);
    });
  };

  const onRemoved = () => {
    axios.delete('/personas/' + persona.pk, persona).then(() => {
      loadPersonas();
      loadTasks();
    }).catch(err => {
      addMessage('delete-goal', t`Unknown error`, t`Impossible to delete persona`);
    });
  };

  const onClose = () => {
    setShowGoalModal(false);
  };
  const onGoalSave = () => {
    setShowGoalModal(false);
    loadGoals();
  };

  return (
    <>
      <StoryMapItemModal
        api="goals"
        title={t`New goal`}
        buttonTitle={t`Add goal`}
        onClose={onClose}
        onSave={onGoalSave}
        parent="persona"
        parentApi="personas"
        parentContext={'story_map=' + persona.story_map.pk}
        parentItem={persona}
        show={showGoalModal}
        hideButton />
      <Dropdown className="no-carret">
        <Dropdown.Toggle variant="sm">
          <Icon name="three-dots" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={onHide}>
            { persona.hidden ? t`Show persona` : t`Hide persona`}
          </Dropdown.Item>
          <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={onRemoved}>
            <Trans>Delete persona</Trans>
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as="button" className="btn btn-outline-secondary" onClick={() => { setShowGoalModal(true); }}>
            <Trans>Add goal</Trans>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

function StoryMapPersona(props) {
  const { personaPk, dragHandleProps, isDragging, ...rest } = props;
  const { product, personasData } = props;
  const p = useStateItem('personas:' + personaPk);
  if (!p) return null;
  return (
    <>
      <div
        className={'story-map-card story-map-card--persona' + (p.hidden ? ' hidden' : '') + (isDragging ? ' is-draging' : '')}
        {...dragHandleProps}>
        <div className="story-map-card__header bg-col-yellow">
          <Icon size="18" name="file-person"/>
          {' '}
          <span><Trans>Persona</Trans></span>
          { product.can_edit_item && (
            <EditPersonaDropdown
              product={product}
              persona={p} />
          ) }
        </div>
        <div className="story-map-card__body">
          { product.can_edit_item ? (
            <FieldShowUpdate
              element={p}
              pk={p.pk}
              api="personas"
              field="name"
              label={t`Name`}>
              {it => (
                <span className="name">{ it.resource_name }</span>
              )}
            </FieldShowUpdate>
          ) : p.name }
        </div>
      </div>
      <StoryMapTableHeaderGoals goals={personasData[p.pk].goals} persona={p} {...rest} />
    </>
  );
}

export { EditPersonaDropdown };
export default StoryMapPersona;
