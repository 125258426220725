import React, { useState } from 'react';
import axios from 'axios';
import { FormCheck, OverlayTrigger, Popover } from 'react-bootstrap';
import { Loader } from 'components/ui/Loader';
import { getStateItem, setStateItem } from 'utils/Item';
import { manageFormErrors } from './Form';

function CheckboxEditable(props) {
  const {
    pk,
    api,
    field,
    label,
    onUpdate,
    stateKey,
    ...rest
  } = props;

  const finalStateKey = stateKey || `${api}:${pk}`;
  const storedItem = getStateItem(finalStateKey);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  if (!storedItem) return null;
  const item = JSON.parse(JSON.stringify(storedItem));

  const handleChange = event => {
    const { checked } = event.target;
    item[field] = checked;
    const obj = {};
    obj[field] = checked;
    setLoading(true);
    axios.put(`${api}/${item.pk}`, obj).then(res => {
      item[field] = res.data[field];
      setStateItem(finalStateKey, res.data, { isFull: true });
      setLoading(false);
      if (onUpdate) onUpdate(field, res.data[field], item);
    }).catch(err => {
      const { globalErrors } = manageFormErrors(null, err, true);
      setError(globalErrors.map(e => e.error).join(<br/>));
      setLoading(false);
    });
  };

  return (
    <OverlayTrigger
      show={error !== null}
      placement="right"
      overlay={(
        <Popover id="checkbox-item-button-popover">
          <Popover.Body>
            { error }
          </Popover.Body>
        </Popover>
      )}>
      {({ ref, ...triggerHandler }) => (
        <div {...triggerHandler} ref={ref}>
          <FormCheck
            id={field}
            checked={item[field] || false}
            onChange={handleChange}
            label={label}
            className="me-2 d-inline-block"
            {...rest} />
          { loading && (
            <Loader size="tiny" />
          ) }
        </div>
      )}
    </OverlayTrigger>
  );
}

export default CheckboxEditable;
