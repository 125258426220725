import React from 'react';
import { Trans } from '@lingui/macro';
import { Form, FormContextProvider } from 'components/form';

function StoryMapFinalForm(props) {
  const { onSave, onCancel } = props;

  return (
    <Form
      name="storymap-add-form"
      noValidate
      onSave={onSave}>
      <Form.Field id="name" name="name" required />
      <div className="actions">
        <button className="btn btn-primary" type="submit">
          <Trans>Save</Trans>
        </button>
        <button className="btn btn-outline-secondary" type="button" onClick={onCancel}><Trans>Cancel</Trans></button>
      </div>
    </Form>
  );
}

const StoryMapForm = function(props) {
  const { onSave, onCancel, product, storyMap } = props;

  if (storyMap && !('product' in storyMap)) {
    storyMap.product = { pk: product.pk };
  }
  return (
    <FormContextProvider item={storyMap} api="/story-maps">
      <StoryMapFinalForm
        isEdit={storyMap && !!storyMap.pk}
        product={product}
        onSave={onSave}
        onCancel={onCancel} />
    </FormContextProvider>
  );
};

export default StoryMapForm;
