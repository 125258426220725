import React, { useEffect, useState, useCallback } from 'react';
import { Trans, t } from '@lingui/macro';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import axios from 'axios';
import Icon from 'components/ui/Icon';
import { addMessage } from 'components/ui/Messages';
import { LoaderContainer } from 'components/ui/Loader';
import DateFormat from 'components/ui/DateFormat';
import WorklogForm from 'components/forms/WorklogForm';
import { ConfirmButton } from 'components/utils';
import DateBlock from 'components/ui/DateBlock';
import { setStateItem, useStateItem } from 'utils/Item';

function getWorklogs(pk, size) {
  return new Promise(resolve => {
    axios.get('/worklogs?subset=offset&order=-date&limit=5&offset=' + size + '&item=' + pk)
      .then(results => {
        for (let i = 0; i < results.data.items.length; i++) {
          const item = results.data.items[i];
          setStateItem('worklogs:' + item.pk, item, { refreshParent: false });
        }
        resolve({
          total: results.data.meta.subset.count,
          items: results.data.items,
        });
      })
      .catch(err => {
        addMessage(
          `comments-${pk}-${size}`,
          t`An error occured`,
          t`Impossible to load worklogs`
        );
      });
  });
}

function ItemWorklog(props) {
  const { stateKey, onEdit, onDelete, backlogItem, product } = props;
  const [isEdit, setIsEdit] = useState(false);
  const worklog = useStateItem(stateKey);

  const handleDelete = () => {
    axios.delete(`/worklogs/${worklog.pk}`)
      .then(onDelete(worklog))
      .catch((error) => addMessage('delete-error', t`Server error`, error.response.data.message));
  };

  const onSave = (wl) => {
    onEdit(wl);
    setIsEdit(false);
  };

  if (!worklog) return null;

  if (isEdit) {
    return (
      <tr>
        <td colSpan="7">
          <WorklogForm
            backlogItem={backlogItem}
            worklog={worklog}
            onSave={onSave}
            product={product}
            onCancel={() => setIsEdit(false)}/>
        </td>
      </tr>
    );
  }
  return (
    <tr className="align-middle">
      <td className="text-nowrap text-end">
        <DateFormat datestring={worklog.date}/>
      </td>
      <td>
        <strong>{ worklog.work.human_format }</strong>
      </td>
      <td>
        <span className="badge bg-light text-dark assignee">
          { worklog.worker.resource_name }
        </span>
      </td>
      <td>
        { worklog.account.resource_name }
      </td>
      <td className="comment">
        { worklog.comment }
      </td>
      <td className="text-secondary">
        <DateBlock datestring={worklog.created_at}/>
      </td>
      <td>
        {product.can_edit_item && (
          <DropdownButton
            title={<Icon name="three-dots-vertical" title={t`Menu`}/>}
            className="contextual-menu-dropdown no-carret"
            variant="outligned-light">
            <Dropdown.Item as="button" onClick={() => setIsEdit(true)}><Trans>Edit</Trans></Dropdown.Item>
            <Dropdown.Item
              as={ConfirmButton}
              message={t`Are you sure you want to delete this element?`}
              confirmLabel={t`Delete`}
              confirmVariant="danger"
              onConfirm={handleDelete}><Trans>Delete</Trans></Dropdown.Item>
          </DropdownButton>
        )}
      </td>
    </tr>
  );
}

export default function BacklogItemWorklogs(props) {
  const { backlogItem, product } = props;
  const [worklogsLoading, setWorklogsLoading] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [worklogs, setWorklogs] = useState(null);
  const [worklogsCount, setWorklogsCount] = useState(0);

  const loadWorklogs = useCallback(() => {
    getWorklogs(backlogItem.pk, 0).then((response) => {
      setWorklogsCount(response.total);
      setWorklogs(response.items);
    });
  }, [backlogItem.pk]);

  useEffect(() => {
    loadWorklogs();
  }, [loadWorklogs]);

  const onLoadMore = () => {
    setWorklogsLoading(true);
    getWorklogs(backlogItem.pk, worklogs.length).then((response) => {
      setWorklogsCount(response.total);
      setWorklogs([...worklogs, ...response.items]);
      setWorklogsLoading(false);
    });
  };

  const onAddWorklog = useCallback(wl => {
    setWorklogsCount((v) => v + 1);
    setWorklogs(l => [wl, ...l]);
    setStateItem('worklogs:' + wl.pk, wl);
    setStateItem('backlog-items:' + backlogItem.pk, { total_work: wl.item__total_work });
    setShowAddForm(false);
  }, [backlogItem.pk]);

  const onRemove = useCallback(wl => {
    setWorklogsCount((v) => v - 1);
    setWorklogs(l => l.filter(a => a.pk !== wl.pk));
    backlogItem.reload();
  }, [backlogItem]);

  const onEdit = useCallback(wl => {
    if (wl.item.pk !== backlogItem.pk) {
      loadWorklogs();
      backlogItem.reload();
    }
    else {
      setStateItem('worklogs:' + wl.pk, wl);
      setStateItem('backlog-items:' + backlogItem.pk, { total_work: wl.item__total_work });
    }
    
  }, [backlogItem, loadWorklogs]);

  if (worklogs === null) {
    return (
      <div className="worklogs">
        <LoaderContainer height="2" />
      </div>
    );
  }

  return (
    <div className="worklogs">
      <div className="worklogs__list mb-2 relative">
        { showAddForm ? (
          <WorklogForm
            backlogItem={backlogItem}
            product={product}
            onSave={onAddWorklog}
            onCancel={() => setShowAddForm(false)}/>
        ) : (
          product.can_edit_item && (
            <button type="button" className="btn btn-outline-secondary" onClick={() => { setShowAddForm(true); }}>
              <Icon name="plus"/> <Trans>Add a new worklog</Trans>
            </button>
          )
        )}
        <div className="worklogs mt-3">
          <table className="table">
            <tbody>
              { worklogs.map(wl => (
                <ItemWorklog
                  key={wl.pk}
                  stateKey={'worklogs:' + wl.pk}
                  backlogItem={backlogItem}
                  onDelete={onRemove}
                  onEdit={onEdit}
                  product={product} />
              )) }
            </tbody>
          </table>
          { worklogsLoading && (
            <LoaderContainer />
          ) }
        </div>
        { worklogsCount > worklogs.length && (
          <div className="mb-2 mt-2 text-center">
            <button type="button" className="btn btn-outline-dark" onClick={onLoadMore}>
              <Trans>Load more</Trans>
            </button>
          </div>
        ) }
      </div>
    </div>
  );
}
